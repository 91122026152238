import { type AxiosResponse } from 'axios'
import { type Action, type PriorityList } from '../../interfaces'
import { COMMON_ACTIONS, PRIORITY_ACTIONS } from '../../utils/constants'
import { call, put, takeEvery } from 'redux-saga/effects'
import { priorityService } from '../../services'

function * getPriorityList (action: Action<null>) {
  try {
    const response: AxiosResponse<PriorityList[]> = yield call(priorityService.getPriorityList)
    yield put<Action<PriorityList[]>>({
      type: PRIORITY_ACTIONS.GET_PRIORITY_LIST + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
  } catch (error) {
    yield put<Action<PriorityList[]>>({
      type: PRIORITY_ACTIONS.GET_PRIORITY_LIST + COMMON_ACTIONS.ERROR,
      data: [],
      error: error as string
    })
  }
}
function * prioritySagas () {
  yield takeEvery(PRIORITY_ACTIONS.GET_PRIORITY_LIST + COMMON_ACTIONS.REQUEST, getPriorityList)
}

export default prioritySagas
