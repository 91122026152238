/* eslint-disable no-console */

import Cookies from 'universal-cookie'
import { type AxiosResponse } from 'axios'
import { APP_CONFIGS } from '../utils/constants'
import { axiosPrivateAPI } from '.'
import { type AuthorizedUserDto } from '../interfaces/auth'

const cookies = new Cookies()

const authorizeUser = async (): Promise<AxiosResponse<AuthorizedUserDto>> => {
  localStorage.removeItem(APP_CONFIGS.USER_PERMISSIONS)
  localStorage.removeItem(APP_CONFIGS.USER_ENTITLEMENT)
  localStorage.removeItem(APP_CONFIGS.LOGGED_USER)
  cookies.remove(APP_CONFIGS.USER_INFO_COOKIE)
  return await axiosPrivateAPI.get('/api/v1/auth')
}

export const setAuthorizedUserInfo = async (userInfo: string): Promise<boolean> => {
  return await new Promise((resolve, reject) => {
    try {
      const expireDate = new Date()
      expireDate.setDate(expireDate.getDate() + 1)

      cookies.set(APP_CONFIGS.IS_LOGGED, true, { path: '/', expires: expireDate })
      cookies.set(APP_CONFIGS.USER_INFO_COOKIE, userInfo, { path: '/', expires: expireDate })
      resolve(true)
    } catch (e) {
      reject(new Error())
    }
  })
}

const setAuthorizedUserPermissions = async (permissions: string) => {
  return await new Promise((resolve, reject) => {
    try {
      localStorage.setItem(APP_CONFIGS.USER_PERMISSIONS, permissions)
      resolve(true)
    } catch (e) {
      reject(Error())
    }
  })
}

export const fetchAuthorizedUserInfo = (): string | undefined => {
  return cookies.get(APP_CONFIGS.USER_INFO_COOKIE)
}

export const fetchAuthorizedUserPermissions = (): string | null => {
  return localStorage.getItem(APP_CONFIGS.USER_PERMISSIONS)
}

const logout = async (): Promise<boolean> => {
  return await new Promise((resolve) => {
    cookies.remove(APP_CONFIGS.USER_INFO_COOKIE)
    cookies.remove(APP_CONFIGS.IS_LOGGED)
    localStorage.removeItem(APP_CONFIGS.USER_PERMISSIONS)
    localStorage.removeItem(APP_CONFIGS.USER_ENTITLEMENT)
    localStorage.removeItem(APP_CONFIGS.LOGGED_USER)
    resolve(true)
  })
}

export const authService = {
  authorizeUser,
  setAuthorizedUserInfo,
  fetchAuthorizedUserInfo,
  setAuthorizedUserPermissions,
  fetchAuthorizedUserPermissions,
  logout
}
