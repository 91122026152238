import * as React from 'react'
import Box from '@mui/material/Box'
import {
  Button,
  Chip,
  InputAdornment,
  TextField,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material'

import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import { ENGAGEMENT_TYPES, PERMISSION } from '../../../utils/constants'
import { hasPermission } from '../../../utils/helpers'

const EngagementTabPanel: React.FC<{
  engagementType: ENGAGEMENT_TYPES
  myEngagementCountAsCoachee: number
  myEngagementCountAsCoach: number
  allEngagementCount: number
  handleChangeEngagementType: (event: React.MouseEvent<HTMLElement>, newType: ENGAGEMENT_TYPES) => void
  toggleDrawer: (open: boolean) => any
  searchKey: string
  onSearchEngagement: (event: React.ChangeEvent<HTMLInputElement>) => void
  clearSearch: () => void
}> = ({
  toggleDrawer,
  engagementType,
  handleChangeEngagementType,
  myEngagementCountAsCoachee,
  myEngagementCountAsCoach,
  allEngagementCount,
  searchKey,
  onSearchEngagement,
  clearSearch
}) => {
  const showCoacheeTab = hasPermission([PERMISSION.VIEW_ENGAGEMENTS_ASSIGNED_TO_HIM_SELF])
  const showCoachTab = hasPermission([PERMISSION.VIEW_ENGAGEMENTS_ASSIGNED_TO_HIM_SELF_AS_COACH])
  const showSupervisorTab = hasPermission([PERMISSION.VIEW_ENGAGEMENTS_CEATED_BY_HIM_SELF])
  return (
            <Box sx={{ width: '100%' }}>
                <Box
                    sx={{
                      borderColor: 'divider',
                      display: 'flex',
                      flexDirection: { md: 'row', xs: 'column-reverse', sm: 'column-reverse' },
                      justifyContent: 'space-between'
                    }}
                >
                    <Box sx={{
                      width: { md: '40%', sm: '100%' },
                      marginTop: { md: 0, xs: '1rem' }
                    }}>
                        <ToggleButtonGroup
                            value={engagementType}
                            onChange={handleChangeEngagementType}
                            exclusive
                        >
                            {showCoacheeTab
                              ? <ToggleButton
                                    sx={{ textTransform: 'none' }}
                                    value={ENGAGEMENT_TYPES.COACHEE}
                                    size="small"
                                >
                                    Coachee
                                    {engagementType === ENGAGEMENT_TYPES.COACHEE
                                      ? <Chip size="small"
                                              sx={{ mx: '0.5rem', backgroundColor: '#437EF7', color: 'white' }}
                                              label={myEngagementCountAsCoachee} />
                                      : <Chip size="small"
                                              sx={{ mx: '0.5rem', color: '#437EF7', backgroundColor: '#F3F9FE' }}
                                              label={myEngagementCountAsCoachee} />
                                    }
                                </ToggleButton>
                              : null}
                            {showCoachTab
                              ? <ToggleButton
                                    sx={{ textTransform: 'none' }}
                                    value={ENGAGEMENT_TYPES.COACH}
                                    size="small"
                                >
                                    Coach
                                    {engagementType === ENGAGEMENT_TYPES.COACH
                                      ? <Chip size="small"
                                              sx={{ mx: '0.5rem', backgroundColor: '#437EF7', color: 'white' }}
                                              label={myEngagementCountAsCoach} />
                                      : <Chip size="small"
                                              sx={{ mx: '0.5rem', color: '#437EF7', backgroundColor: '#F3F9FE' }}
                                              label={myEngagementCountAsCoach} />
                                    }
                                </ToggleButton>
                              : null}
                            {showSupervisorTab
                              ? <ToggleButton
                                sx={{ textTransform: 'none' }}
                                value={ENGAGEMENT_TYPES.SUPERVISOR}
                                size="small"
                            >
                                Supervisor
                                {engagementType === ENGAGEMENT_TYPES.SUPERVISOR
                                  ? <Chip size="small"
                                          sx={{ mx: '0.5rem', backgroundColor: '#437EF7', color: 'white' }}
                                          label={allEngagementCount} />
                                  : <Chip size="small"
                                          sx={{ mx: '0.5rem', color: '#437EF7', backgroundColor: '#F3F9FE' }}
                                          label={allEngagementCount} />
                                }
                            </ToggleButton>
                              : null}

                        </ToggleButtonGroup>
                    </Box>
                    <Box sx={{
                      width: { md: '40%', sm: '100%' },
                      display: 'flex',
                      gap: '1rem',
                      justifyContent: 'space-between'
                    }}
                    >
                        <Box sx={{ width: '80%' }}>
                            <TextField
                                value={searchKey}
                                onChange={onSearchEngagement}
                                placeholder="Search"
                                size="small"
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="start">
                                      {(searchKey.length > 0)
                                        ? (
                                        <ClearIcon onClick={clearSearch} style={{ cursor: 'pointer' }} />
                                          )
                                        : (
                                        <SearchIcon />
                                          )}
                                    </InputAdornment>
                                  )
                                }}
                                sx={{ width: '100%' }}
                              />
                        </Box>
                        <Box>
                            <Button sx={{ textTransform: 'none', height: '2.25rem' }} color="secondary" size="small"
                                variant="contained" onClick={toggleDrawer(true)} endIcon={<FilterAltOutlinedIcon />}>
                                Filters
                            </Button>
                        </Box>
                    </Box>
                </Box>
                <Box marginTop="2rem">
                    {/* {goalViewCard} */}
                </Box>
            </Box>
  )
}

export default EngagementTabPanel
