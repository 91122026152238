import { call, put, takeEvery } from 'redux-saga/effects'
import { COMMON_ACTIONS, HIERARCHY_ACTIONS } from '../../utils/constants'
import {
  type OrgAndRoleHierarchy,
  type Action, type GetHierarchyRequestParams,
  type UserHierarchy
} from '../../interfaces'
import { hierarchyService } from '../../services'
import { type AxiosResponse } from 'axios'

function * getOrganizationHierarchy (action: Action<GetHierarchyRequestParams>) {
  try {
    const response: AxiosResponse<OrgAndRoleHierarchy> = yield call(
      hierarchyService.getOrgAndRoleHierarchy,
      action.data
    )
    yield put<Action<OrgAndRoleHierarchy>>({
      type: HIERARCHY_ACTIONS.GET_ORGANIZATION_HIERARCHY + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
  } catch (error) {
    yield put<Action<OrgAndRoleHierarchy>>({
      type: HIERARCHY_ACTIONS.GET_ORGANIZATION_HIERARCHY + COMMON_ACTIONS.ERROR,
      data: { levelName: '', levelId: '', levelIndex: -1, subLevels: [] },
      error: error as string
    })
  }
}

function * getRoleHierarchy (action: Action<GetHierarchyRequestParams>) {
  try {
    const response: AxiosResponse<OrgAndRoleHierarchy> = yield call(
      hierarchyService.getOrgAndRoleHierarchy,
      action.data
    )
    yield put<Action<OrgAndRoleHierarchy>>({
      type: HIERARCHY_ACTIONS.GET_ROLE_HIERARCHY + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
  } catch (error) {
    yield put<Action<OrgAndRoleHierarchy>>({
      type: HIERARCHY_ACTIONS.GET_ROLE_HIERARCHY + COMMON_ACTIONS.ERROR,
      data: { levelName: '', levelId: '', levelIndex: -1, subLevels: [] },
      error: error as string
    })
  }
}

function * getUserHierarchy (action: Action<GetHierarchyRequestParams>) {
  try {
    const response: AxiosResponse<UserHierarchy[]> = yield call(hierarchyService.getUserHierarchy, action.data)
    yield put<Action<UserHierarchy[]>>({
      type: HIERARCHY_ACTIONS.GET_USER_HIERARCHY + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
  } catch (error) {
    yield put<Action<UserHierarchy[]>>({
      type: HIERARCHY_ACTIONS.GET_USER_HIERARCHY + COMMON_ACTIONS.ERROR,
      data: [],
      error: error as string
    })
  }
}

function * hierarchySagas () {
  yield takeEvery(HIERARCHY_ACTIONS.GET_ORGANIZATION_HIERARCHY + COMMON_ACTIONS.REQUEST, getOrganizationHierarchy)

  yield takeEvery(HIERARCHY_ACTIONS.GET_ROLE_HIERARCHY + COMMON_ACTIONS.REQUEST, getRoleHierarchy)

  yield takeEvery(HIERARCHY_ACTIONS.GET_USER_HIERARCHY + COMMON_ACTIONS.REQUEST, getUserHierarchy)
}

export default hierarchySagas
