import * as React from 'react'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { Box, Button, Card, CardContent, Divider, IconButton, TextField } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import styles from './SessionFeedbacksToCoacheesModal.module.scss'
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt'
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied'
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral'
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied'
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied'

const SessionFeedbacksToCoacheesModal: React.FC<{
  modalOpen: boolean
  handleClose: () => void
  handleSelectedRating: (sentimentId: number) => void
  selectedRating: number
  handleFeedbackMessage: (feedback: any) => void
  feedbackMessage: any
  submitCoacheFeedbackToCoachees: () => void
  isValidFeedback: boolean
}> = ({
  modalOpen,
  handleClose,
  handleSelectedRating,
  selectedRating,
  handleFeedbackMessage,
  feedbackMessage,
  submitCoacheFeedbackToCoachees,
  isValidFeedback
}) => {
  const sentimentals = [{
    name: 'Terrible',
    sentimentValue: 5
  }, {
    name: 'Sad',
    sentimentValue: 4
  }, {
    name: 'Neutral',
    sentimentValue: 3
  }, {
    name: 'Happy',
    sentimentValue: 2
  }, {
    name: 'Amazing',
    sentimentValue: 1
  }]

  return (
      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card className={styles.sessionFeedbackOuterCard}>
          <Box sx={{ padding: '1rem' }} display='flex' justifyContent='space-between'>
            <Typography id="modal-modal-title" fontWeight={600} fontSize='1rem'>
              Feedback
            </Typography>
            <IconButton onClick={handleClose} aria-label="delete" size="small">
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider variant="fullWidth" />
          <CardContent>
            <Box display='flex' my='1.5rem'><Typography
              fontWeight={500}>What do you think about the coachee/s?</Typography></Box>

            <Box display='flex' alignItems='center' justifyContent='space-between' sx={{
              width: '40rem',
              marginBottom: '2rem'
            }}>
              {sentimentals.map((sentiment: any, index: number) => {
                return (<Box key={index}
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  alignItems='center'>
                  <Button onClick={() => { handleSelectedRating(sentiment.sentimentValue) }} sx={{
                    backgroundColor: selectedRating === sentiment.sentimentValue ? '#CBDAFC' : '#F5FAFF',
                    border: '1px solid #437EF7',
                    width: '5rem',
                    height: '5rem'
                  }}>
                    {sentiment.sentimentValue === 1
                      ? <SentimentVerySatisfiedIcon fontSize='large' color='primary' />
                      : (sentiment.sentimentValue === 2
                          ? <SentimentSatisfiedAltIcon fontSize='large' color='primary' />
                          : (sentiment.sentimentValue === 3
                              ? <SentimentNeutralIcon fontSize='large' color='primary' />
                              : (sentiment.sentimentValue === 4
                                  ? <SentimentDissatisfiedIcon fontSize='large' color='primary' />
                                  : (sentiment.sentimentValue === 5
                                      ? <SentimentVeryDissatisfiedIcon
                                fontSize='large'
                                color='primary' />
                                      : null))))}

                  </Button>
                  <Typography
                    fontWeight={600} color='#437EF7'>{sentiment.name}</Typography>
                </Box>)
              })}
            </Box>
            <Box>
              <TextField
                multiline
                rows={5}
                fullWidth
                placeholder="What are the reasons for your feedback?"
                value={feedbackMessage}
                error={isValidFeedback}
                helperText="please provide comments"
                onChange={(event) => { handleFeedbackMessage(event.target.value) }}
                inputProps={{ maxLength: 255 }}
              />
            </Box>
          </CardContent>
          <Divider variant="fullWidth" />
          <Box sx={{ padding: '1rem' }} display='flex' justifyContent='end' gap={2}>
            <Button
              onClick={handleClose}
              sx={{
                textTransform: 'none'
              }}
              color='secondary'
              variant="contained">Cancel</Button>
            <Button
              onClick={submitCoacheFeedbackToCoachees}
              sx={{
                textTransform: 'none'
              }}
              color='primary'
              disabled={(feedbackMessage.trim().length === 0 || /^\s*$/.test(feedbackMessage))}
              variant="contained">Submit</Button>
          </Box>

        </Card>
      </Modal>
  )
}

export default SessionFeedbacksToCoacheesModal
