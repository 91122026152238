import React from 'react'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import { Box } from '@mui/system'

const ModalSkeleton = () => {
  return (
        <Stack spacing={1}>
            <Box display='flex' flexDirection='column' gap={3}>
                <Box><Skeleton animation="wave" variant="rounded" width='20%' height='1.5rem' /></Box>
                <Box><Skeleton animation="wave" variant="rounded" width='80%' height='2.5rem' /></Box>
                <Box><Skeleton animation="wave" variant="rounded" width='10%' height='1.5rem' /></Box>
                <Box display='flex' flexDirection='row' justifyContent='space-between'>
                    <Skeleton animation="wave" variant="rounded" width='100%' height='10rem' />
                </Box>
            </Box>
        </Stack>
  )
}

export default ModalSkeleton
