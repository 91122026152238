/* eslint-disable no-console */
import axios from 'axios'
import { APP_CONFIGS } from '../utils/constants'
import { msalInstance } from '..'
import { InteractionRequiredAuthError } from '@azure/msal-browser'
import { exceptionHandler } from '../utils/helpers'

axios.defaults.baseURL = APP_CONFIGS.API_BASE

export const axiosPublicAPI = axios.create()
export const axiosPrivateAPI = axios.create()

// Request interceptor to manage authorization & headers
axiosPrivateAPI.interceptors.request.use(async (request: any) => {
  const msalRequest = {
    scopes: [APP_CONFIGS.APP_SCOPES ?? '']
  }
  const tokenResponse = await msalInstance.acquireTokenSilent(msalRequest)
    .catch(async (error: any) => {
      if (error instanceof InteractionRequiredAuthError) {
      // fallback to interaction when silent call fails
        await msalInstance.acquireTokenRedirect(msalRequest)
      }
    })
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  request.headers.Authorization = `Bearer ${tokenResponse?.accessToken}`
  return request
}, (error) => {
  console.log('Req interceptor Error', error)
})

axiosPrivateAPI.interceptors.response.use(
  async (response) => {
    return response
  },
  async (error) => {
    return await Promise.reject(await exceptionHandler(error.response))
  }
)

export * from './goalService'
export * from './hierarchyService'
export * from './priorityService'
export * from './engagementService'
export * from './statusService'
export * from './assessmentService'
export * from './sessionService'
export * from './feedBackService'
export * from './auth.service'
export * from './users.service'
export * from './dashboard.service'
export * from './notesService'
