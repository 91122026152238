import * as React from 'react'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { Box, Button, Card, CardContent, Divider, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import styles from './CompleteSessionModal.module.scss'

const CompleteSessionModal: React.FC<{
  modalOpen: boolean
  handleClose: () => void
  sessionID: string
  completeSession: (sessionID: string) => void
}> = ({
  modalOpen,
  handleClose,
  sessionID,
  completeSession
}) => {
  return (
            <Modal
                open={modalOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Card className={styles.completeSessionOuterCard}>
                    <Box sx={{ padding: '1rem' }} display='flex' justifyContent='space-between'>
                        <Typography id="modal-modal-title" fontWeight={600} fontSize='1rem'>
                            Mark as complete
                        </Typography>
                        <IconButton onClick={handleClose} aria-label="delete" size="small">
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Divider variant="fullWidth" />
                    <CardContent>
                        <Box display='flex' my='1.5rem'><Typography
                        fontWeight={500}>Are you sure want to mark this session as completed?</Typography></Box>
                        <Card sx={{ border: '1px solid #EEA23E', padding: '1rem', backgroundColor: '#FFF8EB' }}>
                            <Box display='flex' alignItems='center'>
                                <Typography ml='0.5rem' id="modal-modal-title" variant="body1" component="h2">
                                You will no longer be able to read the documents of this session after you complete.
                                </Typography>
                            </Box>
                        </Card>
                    </CardContent>
                    <Divider variant="fullWidth" />
                    <Box sx={{ padding: '1rem' }} display='flex' justifyContent='end' gap={2}>
                        <Button
                            onClick={handleClose}
                            sx={{
                              textTransform: 'none'
                            }}
                            color='secondary'
                            variant="contained">Cancel</Button>
                        <Button
                            onClick={() => { completeSession(sessionID) }}
                            sx={{
                              textTransform: 'none'
                            }}
                            color='primary'
                            variant="contained">Complete</Button>
                    </Box>

                </Card>
            </Modal>
  )
}

export default CompleteSessionModal
