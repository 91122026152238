import { COMMON_ACTIONS, FEEDBACK_ACTIONS } from '../../utils/constants'
import {
  type ViewCoacheeFeedbackParam,
  type CoacheeFeedbackPayload,
  type FeedBackRequestParams,
  type ViewCoacheeGroupFeedbackParam,
  type GiveFeedbackToCoacheePayload,
  type ViewCoacheeFeedbackCommentsRequestParams
} from '../../interfaces/feedBack'
import { type Action } from '../../interfaces'

const getFeedBackDetails = (payload: FeedBackRequestParams): Action<FeedBackRequestParams> => {
  return { type: FEEDBACK_ACTIONS.GET_FEEDBACK_DETAILS + COMMON_ACTIONS.REQUEST, data: payload }
}
const clearGetFeedBackDetails = (): Action<null> => {
  return { type: FEEDBACK_ACTIONS.GET_FEEDBACK_DETAILS + COMMON_ACTIONS.CLEAR, data: null }
}
const addFeedback = (payload: CoacheeFeedbackPayload): Action<CoacheeFeedbackPayload> => {
  return { type: FEEDBACK_ACTIONS.ADD_FEEDBACK + COMMON_ACTIONS.REQUEST, data: payload }
}
const addFeedbackClear = () => {
  return { type: FEEDBACK_ACTIONS.ADD_FEEDBACK + COMMON_ACTIONS.CLEAR }
}

const viewFeedback = (param: ViewCoacheeFeedbackParam): Action<ViewCoacheeFeedbackParam> => {
  return { type: FEEDBACK_ACTIONS.VIEW_FEEDBACK + COMMON_ACTIONS.REQUEST, data: param }
}
const clearViewFeedback = (): Action<null> => {
  return { type: FEEDBACK_ACTIONS.VIEW_FEEDBACK + COMMON_ACTIONS.CLEAR, data: null }
}
const viewGroupFeedback = (param: ViewCoacheeGroupFeedbackParam): Action<ViewCoacheeGroupFeedbackParam> => {
  return { type: FEEDBACK_ACTIONS.VIEW_GROUP_FEEDBACK + COMMON_ACTIONS.REQUEST, data: param }
}

const giveCoachFeedbackToCoachee = (payload: GiveFeedbackToCoacheePayload): Action<GiveFeedbackToCoacheePayload> => {
  return { type: FEEDBACK_ACTIONS.ADD_COACH_FEEDBACK_TO_COACHEES + COMMON_ACTIONS.REQUEST, data: payload }
}
const ViewCoacheeFeedbackComments = (params: ViewCoacheeFeedbackCommentsRequestParams): Action<ViewCoacheeFeedbackCommentsRequestParams> => {
  return { type: FEEDBACK_ACTIONS.VIEW_FEEDBACK_COMMENTS + COMMON_ACTIONS.REQUEST, data: params }
}
const clearViewCoacheeFeedbackComments = (): Action<null> => {
  return { type: FEEDBACK_ACTIONS.VIEW_FEEDBACK_COMMENTS + COMMON_ACTIONS.CLEAR, data: null }
}

export const feedBackActions = {
  getFeedBackDetails,
  addFeedback,
  addFeedbackClear,
  viewFeedback,
  clearViewFeedback,
  clearGetFeedBackDetails,
  viewGroupFeedback,
  giveCoachFeedbackToCoachee,
  ViewCoacheeFeedbackComments,
  clearViewCoacheeFeedbackComments
}
