/* eslint-disable @typescript-eslint/restrict-plus-operands */
import {
  type CoachGoalSummaryRequestParam,
  type Action,
  type CoacheeGoalSummaryRequestParam,
  type CoacheeSessionSummaryRequestParam,
  type CoachSessionSummaryRequestParam
} from '../../interfaces'
import { COMMON_ACTIONS, DASHBOARD_ACTION_TYPE } from '../../utils/constants'

const getCoacheeStats = (): Action<null> => {
  return { type: DASHBOARD_ACTION_TYPE.GET_COACHEE_STATS + COMMON_ACTIONS.REQUEST, data: null }
}

const getCoacheeGoalSummary = (param: CoacheeGoalSummaryRequestParam): Action<CoacheeGoalSummaryRequestParam> => {
  return { type: DASHBOARD_ACTION_TYPE.GET_COACHEE_GOAL_SUMMARY + COMMON_ACTIONS.REQUEST, data: param }
}

const getCoacheeSessionSummary =
  (param: CoacheeSessionSummaryRequestParam): Action<CoacheeSessionSummaryRequestParam> => {
    return { type: DASHBOARD_ACTION_TYPE.GET_COACHEE_SESSION_SUMMARY + COMMON_ACTIONS.REQUEST, data: param }
  }

const getCoacheeRecentEngagement = (): Action<null> => {
  return { type: DASHBOARD_ACTION_TYPE.GET_RECENT_ENGAGEMENTS_AS_COACHEE + COMMON_ACTIONS.REQUEST, data: null }
}

const getCoachStats = (): Action<null> => {
  return { type: DASHBOARD_ACTION_TYPE.GET_COACH_STATS + COMMON_ACTIONS.REQUEST, data: null }
}

const getCoachGoalSummary = (param: CoachGoalSummaryRequestParam): Action<CoachGoalSummaryRequestParam> => {
  return { type: DASHBOARD_ACTION_TYPE.GET_COACH_GOAL_SUMMARY + COMMON_ACTIONS.REQUEST, data: param }
}

const getCoachSessoinSummary = (param: CoachSessionSummaryRequestParam): Action<CoachSessionSummaryRequestParam> => {
  return { type: DASHBOARD_ACTION_TYPE.GET_COACH_SESSION_SUMMARY + COMMON_ACTIONS.REQUEST, data: param }
}

const getCoachRecentEngagement = (): Action<null> => {
  return { type: DASHBOARD_ACTION_TYPE.GET_RECENT_ENGAGEMENTS_AS_COACH + COMMON_ACTIONS.REQUEST, data: null }
}

const getSupervisorStats = (): Action<null> => {
  return { type: DASHBOARD_ACTION_TYPE.GET_SUPERVISOR_STATS + COMMON_ACTIONS.REQUEST, data: null }
}

const clearGetCoacheeStats = (): Action<null> => {
  return { type: DASHBOARD_ACTION_TYPE.GET_COACHEE_STATS + COMMON_ACTIONS.CLEAR, data: null }
}

const clearGetCoacheeGoalSummary = (): Action<null> => {
  return { type: DASHBOARD_ACTION_TYPE.GET_COACHEE_GOAL_SUMMARY + COMMON_ACTIONS.CLEAR, data: null }
}

const clearGetCoacheeSessionSummary = (): Action<null> => {
  return { type: DASHBOARD_ACTION_TYPE.GET_COACHEE_SESSION_SUMMARY + COMMON_ACTIONS.CLEAR, data: null }
}

const clearGetCoacheeRecentEngagement = (): Action<null> => {
  return { type: DASHBOARD_ACTION_TYPE.GET_RECENT_ENGAGEMENTS_AS_COACHEE + COMMON_ACTIONS.CLEAR, data: null }
}

const clearGetCoachStats = (): Action<null> => {
  return { type: DASHBOARD_ACTION_TYPE.GET_COACH_STATS + COMMON_ACTIONS.CLEAR, data: null }
}

const clearGetCoachGoalSummary = (): Action<null> => {
  return { type: DASHBOARD_ACTION_TYPE.GET_COACH_GOAL_SUMMARY + COMMON_ACTIONS.CLEAR, data: null }
}

const clearGetCoachSessoinSummary = (): Action<null> => {
  return { type: DASHBOARD_ACTION_TYPE.GET_COACH_SESSION_SUMMARY + COMMON_ACTIONS.CLEAR, data: null }
}

const clearGetCoachRecentEngagement = (): Action<null> => {
  return { type: DASHBOARD_ACTION_TYPE.GET_RECENT_ENGAGEMENTS_AS_COACH + COMMON_ACTIONS.CLEAR, data: null }
}

const clearGetSupervisorStats = (): Action<null> => {
  return { type: DASHBOARD_ACTION_TYPE.GET_SUPERVISOR_STATS + COMMON_ACTIONS.CLEAR, data: null }
}

export const dashboardActions = {
  getCoacheeStats,
  getCoachStats,
  getSupervisorStats,
  getCoachGoalSummary,
  getCoacheeGoalSummary,
  getCoacheeSessionSummary,
  getCoachSessoinSummary,
  getCoacheeRecentEngagement,
  getCoachRecentEngagement,
  clearGetCoacheeStats,
  clearGetCoacheeGoalSummary,
  clearGetCoacheeSessionSummary,
  clearGetCoacheeRecentEngagement,
  clearGetCoachStats,
  clearGetCoachGoalSummary,
  clearGetCoachSessoinSummary,
  clearGetCoachRecentEngagement,
  clearGetSupervisorStats
}
