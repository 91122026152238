import { styled } from '@mui/material'
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector'

export const ColorlibConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#437EF7'
    }
  },
  [`& .${stepConnectorClasses.lineHorizontal}`]: {
    height: 3,
    border: '0.1rem #DAE0E6',
    backgroundColor: '#eaeaf0',
    borderRadius: 0,
    margin: '0 1.5rem'
  }
}))
