/* eslint-disable max-len */
import { Box, Card, CardContent } from '@mui/material'
import React from 'react'
import { SingleNoteSkelton } from '../../skeleton'
import SingleNote from '../SingleNote/SingleNote'
import { type NoteDto } from '../../../interfaces/notes'
import styles from './CoachNotes.module.scss'
import { type AsyncStateObject } from '../../../interfaces'
import { isNullOrUndefinedOrEmpty } from '../../../utils/helpers'
import { SomethingWentWrong } from '../../common'

const CoachNotes: React.FC<{
  isLoading: boolean
  isErrorFetchData: string
  viewOthersNoteResponse: AsyncStateObject<NoteDto[]>
}> = ({
  isLoading,
  isErrorFetchData,
  viewOthersNoteResponse
}) => {
  return (
    <Card sx={{ marginTop: '2rem' }}>
      <CardContent className={styles.rootCard}>
        {isLoading &&
          <Box display='flex' flexDirection='column'>
            <SingleNoteSkelton/><SingleNoteSkelton/><SingleNoteSkelton/>
          </Box>
        }
        {!isLoading &&
          <>
            {!isNullOrUndefinedOrEmpty(isErrorFetchData) &&
              <SomethingWentWrong
                height='100%'
                isShowDefault={false}
                subNotification={isErrorFetchData}
              />
            }
            {isNullOrUndefinedOrEmpty(isErrorFetchData) &&
              <>
                {viewOthersNoteResponse?.data?.map((note: NoteDto, index: number) =>
                  <Box key={index}>
                    <SingleNote
                      index={index}
                      note={note}
                      isShowAction={false}
                    />
                  </Box>
                )}
                {viewOthersNoteResponse?.data?.length === 0 && (
                  <div className={styles.noDataNotification}>
                    There are no notes to preview.!
                  </div>
                )}
              </>
            }
          </>
        }
      </CardContent>
    </Card>
  )
}

export default CoachNotes
