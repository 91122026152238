import React from 'react'
import AppRoutes from './routes'
import { useSnackBar } from './utils/helpers'
import { useDispatch } from 'react-redux'
import { authActions } from './redux/actions'
function App () {
  useSnackBar()
  const dispatch = useDispatch()
  React.useEffect(() => {
    dispatch(authActions.fetchAuthorizedUser())
    dispatch(authActions.fetchAuthorizedUserPermission())
  }, [])

  return (
    <AppRoutes />
  )
}

export default App
