import { Box, Grid, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import React from 'react'
import { DASHBOARD_VIEW_TYPES, PERMISSION, PERMISSION_TYPES } from '../../../utils/constants'
import styles from './DashboardHeader.module.scss'
import { type AppState } from '../../../interfaces'
import { useSelector } from 'react-redux'
import { hasPermission } from '../../../utils/helpers'

const DashboardHeader: React.FC<{
  viewType: DASHBOARD_VIEW_TYPES
  handleChangeViewType: (event: React.MouseEvent<HTMLElement>, type: DASHBOARD_VIEW_TYPES) => void
}> = ({
  viewType,
  handleChangeViewType
}) => {
  const authorizedUser = useSelector((state: AppState) => state.authUser.authorizedUser)

  const showCoacheeTab = hasPermission(
    [PERMISSION.VIEW_ENGAGEMENTS_ASSIGNED_TO_HIM_SELF, PERMISSION.VIEW_GOALS_ASSIGNED_TO_HIM_SELF],
    PERMISSION_TYPES.FULL_PERMISSION
  )
  const showCoachTab = hasPermission(
    [PERMISSION.VIEW_GOALS_CREATED_BY_HIM_SELF, PERMISSION.VIEW_ENGAGEMENTS_ASSIGNED_TO_HIM_SELF_AS_COACH],
    PERMISSION_TYPES.FULL_PERMISSION
  )
  const showSupervisorTab = hasPermission(
    [PERMISSION.VIEW_GOALS_CREATED_BY_HIM_SELF, PERMISSION.VIEW_ENGAGEMENTS_CEATED_BY_HIM_SELF],
    PERMISSION_TYPES.FULL_PERMISSION
  )

  return (
    <Grid container className={styles.dashboardHeader}>
      <Grid item sm={12} marginBottom="2rem">
        <Box>
          <Typography textTransform="none" fontWeight="600"
            sx={{
              fontSize: {
                xs: '1rem',
                sm: '1.25rem',
                md: '1.5rem',
                lg: '2rem'
              }
            }}>Hey there, {authorizedUser?.data?.name}!</Typography>
          <Typography textTransform="none" fontWeight="600" sx={{
            fontSize: {
              xs: '0.75rem',
              sm: '0.75rem',
              md: '1rem',
              lg: '1rem'
            }
          }}
            variant='subtitle1' color="#5F6D7E">Welcome back, we are happy to have you here!</Typography>
        </Box>
      </Grid>
      <Grid item sm={12}>
        <Box
          sx={{
            borderColor: 'divider',
            flexDirection: { md: 'row', xs: 'column-reverse', sm: 'column-reverse' }
          }}
        >
          <Box sx={{
            width: { md: '40%', sm: '100%' },
            marginTop: { md: 0, xs: '1rem' }
          }}>
            <ToggleButtonGroup
              value={viewType}
              onChange={handleChangeViewType}
              exclusive
            >
              {showCoacheeTab &&
                <ToggleButton
                  sx={{ textTransform: 'none' }}
                  value={DASHBOARD_VIEW_TYPES.COACHEE}
                  size="small"
                >Coachee</ToggleButton>
              }
              {showCoachTab &&
                <ToggleButton
                  sx={{ textTransform: 'none' }}
                  value={DASHBOARD_VIEW_TYPES.COACH}
                  size="small"
                >Coach</ToggleButton>
              }
              {showSupervisorTab &&
                <ToggleButton
                  sx={{ textTransform: 'none' }}
                  value={DASHBOARD_VIEW_TYPES.SUPERVISOR}
                  size="small"
                >Supervisor</ToggleButton>
              }
            </ToggleButtonGroup>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export default DashboardHeader
