import { Box, Card, CardContent, Skeleton } from '@mui/material'
import React from 'react'

const SingleNoteSkelton = () => {
  return (
    <Card sx={{ marginBottom: '1rem' }}>
      <CardContent>
        <Box display='flex' flexDirection='column' gap={1}>
          <Box display='flex' justifyContent='space-between'>
            <Box><Skeleton animation="wave" variant="rounded" width='20rem' height='2rem' /></Box>
            <Box display='flex' flexDirection='row' gap={4}>
              <Box><Skeleton animation="wave" variant="rounded" width='2rem' height='2rem' /></Box>
              <Box><Skeleton animation="wave" variant="rounded" width='2rem' height='2rem' /></Box>
            </Box>
          </Box>
          <Box margin={'4px 0px'}>
            <Skeleton animation="wave" variant="rounded" width='100%' height='0.3rem' />
          </Box>
          <Box><Skeleton animation="wave" variant="rounded" width='100%' height='1rem' /></Box>
          <Box><Skeleton animation="wave" variant="rounded" width='100%' height='1rem' /></Box>
          <Box><Skeleton animation="wave" variant="rounded" width='100%' height='1rem' /></Box>
          <Box><Skeleton animation="wave" variant="rounded" width='100%' height='1rem' /></Box>
          <Box><Skeleton animation="wave" variant="rounded" width='100%' height='1rem' /></Box>
        </Box>
      </CardContent>
    </Card>
  )
}

export default SingleNoteSkelton
