
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { type AxiosResponse } from 'axios'
import { axiosPrivateAPI } from '.'
import {
  type MyEngagementRequestPayload,
  type EngagementCreateRequestPayload,
  type EngagementCreateResponse,
  type EngagementListResponse,
  type AllEngagementRequestPayload,
  type EngagementUpdateRequestPayload,
  type GoalListData,
  type EngagementDetails,
  type EngagementDetailsRequestParam,
  type AllGoalsByEngagementIdRequestPayload,
  type GoalListSummeryResponse
} from '../interfaces'

const createEngagement = async (payload: EngagementCreateRequestPayload):
Promise<AxiosResponse<EngagementCreateResponse>> => {
  return await axiosPrivateAPI.post('/api/v1/engagements', payload)
}
const getMyEngagementList = async (
  payload: MyEngagementRequestPayload
): Promise<AxiosResponse<EngagementListResponse>> => {
  return await axiosPrivateAPI.post(`/api/v1/users/${payload.pathParam.username}/myEngagements`, payload.requestBody)
}
const getAllEngagementList = async (
  payload: AllEngagementRequestPayload
): Promise<AxiosResponse<EngagementListResponse>> => {
  return await axiosPrivateAPI.post(`/api/v1/users/${payload.pathParam.username}/allEngagements`, payload.requestBody)
}
const updateEngagement = async (payload: EngagementUpdateRequestPayload):
Promise<AxiosResponse<EngagementUpdateRequestPayload>> => {
  return await axiosPrivateAPI.put(`/api/v1/engagements/${payload.engagementId}`, payload.requestBody)
}
const getGoalsByEngagementByIdList = async (engagementId: string):
Promise<AxiosResponse<GoalListData[]>> => {
  return await axiosPrivateAPI.get(`/api/v1/engagements/${engagementId}/goals`)
}

const engagementDetails = async (param: EngagementDetailsRequestParam): Promise<AxiosResponse<EngagementDetails>> => {
  return await axiosPrivateAPI.get(`/api/v1/engagements/${param.pathParam.id}`, { params: param.queryParam })
}

/*
  @Ishara Manage
  This service is used to retrive the All Goals (Assigned and accept both) List by engagement id
  used to retrive and set data in editEngagement screen
*/
const getAllGoalsByEngagementByIdList = async (param: AllGoalsByEngagementIdRequestPayload):
Promise<AxiosResponse<GoalListSummeryResponse>> => {
  return await axiosPrivateAPI.get(`/api/v1/engagements/${param.pathParam.id}/all-goals`, { params: param.quesryParam })
}

export const engagementService = {
  createEngagement,
  getMyEngagementList,
  getAllEngagementList,
  updateEngagement,
  getGoalsByEngagementByIdList,
  engagementDetails,
  getAllGoalsByEngagementByIdList
}
