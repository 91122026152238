import { Card, CardContent, Skeleton, Typography } from '@mui/material'
import React from 'react'

const DashboardStatCard: React.FC<{
  count: number
  title: string
  isLoading: boolean
}> = (props) => {
  return (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        {props.isLoading &&
          <div style={{ padding: '0.5rem', alignItems: 'center' }} className='layout-row'>
            <Skeleton animation="wave" variant="rounded" width='100%' height='2rem' />
          </div>
        }
        {!props.isLoading &&
          <div style={{ padding: '0.5rem', alignItems: 'center' }} className='layout-row'>
            <Typography variant="h6" fontWeight={600} display={'flex'} alignItems={'center'} mr={'1rem'}>
              {props.count}
            </Typography>
            <Typography variant="subtitle1" fontWeight={500}>{props.title}</Typography>
          </div>
        }
      </CardContent>
    </Card>
  )
}

export default DashboardStatCard
