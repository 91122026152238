import React from 'react'
import {
  Autocomplete,
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { type SessionTypeDto, type SessionCreateFormState } from '../../../interfaces/session'
import { type CoachList } from '../../../interfaces/coachList'
import { SESSION_FORM_FIELDS } from '../../../utils/constants'

const SessionDetailsForm: React.FC<{
  coachList: CoachList[]
  generalDetailForm: SessionCreateFormState
  onInputHandleForm: (property: string, value: any) => void
  handleBlur: (property: string, value: any) => void
  onInputFocus: (property: string) => void
  sessionTypeList: SessionTypeDto[]
  inputSessionDurationValue: string
}> = ({
  generalDetailForm, onInputHandleForm, handleBlur, onInputFocus,
  coachList, sessionTypeList, inputSessionDurationValue
}) => {
  return (
    <>
      <Box>
        <form>
          <FormControl sx={{ width: '100%' }} >
            <Box sx={{ marginBottom: '3.5rem' }}>
              <InputLabel
                sx={{ fontWeight: '600' }}
                variant="standard"
                htmlFor="uncontrolled-native"
              >
                <Box display='flex'><Typography fontWeight={600}>Session Name</Typography>
                  <Typography color='red'>*</Typography></Box>
              </InputLabel>
            </Box>
            <TextField
              placeholder="Enter Session Name"
              sx={{ width: '100%' }}
              id="outlined-basic"
              variant="outlined"
              value={generalDetailForm.sessionName.value}
              disabled={generalDetailForm.sessionName.disable}
              error={!(generalDetailForm.sessionName.error == null)}
              required={generalDetailForm.sessionName.isRequired}
              helperText={generalDetailForm.sessionName.error}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                onInputHandleForm(SESSION_FORM_FIELDS.NAME, event.target.value)
              }}
              onFocus={() => { onInputFocus(SESSION_FORM_FIELDS.NAME) }}
              inputProps={{ maxLength: 100 }}
            />
          </FormControl>
          <FormControl sx={{ width: '100%' }}>
            <Box sx={{ backgroundColor: 'blue', marginBottom: '3.5rem' }}>
              <InputLabel
                sx={{ fontWeight: '600' }}
                variant="standard"
                htmlFor="uncontrolled-native"
              >
                <Box display='flex'><Typography fontWeight={600}>Session Description</Typography>
                  <Typography color='red'>*</Typography></Box>
              </InputLabel>
            </Box>
            <TextField
              multiline
              rows={5}
              fullWidth
              placeholder="Enter Session Description"
              value={generalDetailForm.sessionDescription.value}
              disabled={generalDetailForm.sessionDescription.disable}
              error={!(generalDetailForm.sessionDescription.error == null)}
              required={generalDetailForm.sessionDescription.isRequired}
              helperText={generalDetailForm.sessionDescription.error}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                onInputHandleForm(SESSION_FORM_FIELDS.DESCRIPTION, event.target.value)
              }}
              onFocus={() => { onInputFocus(SESSION_FORM_FIELDS.DESCRIPTION) }}
              inputProps={{ maxLength: 255 }}
            />
          </FormControl>
          <FormControl
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              gap: '2rem'
            }}
          >
            <FormControl sx={{ width: '100%' }}>
              <Box>
                <Box sx={{ width: '100%', marginBottom: '3.5rem' }}>
                  <InputLabel
                    shrink={false}
                    sx={{ fontWeight: '600' }}
                    variant="standard"
                    htmlFor="uncontrolled-native"
                  >
                    <Box display='flex'><Typography fontWeight={600}>Session Coach</Typography>
                      <Typography color='red'>*</Typography></Box>
                  </InputLabel>
                </Box>
                <Box>
                  <Autocomplete
                    value={generalDetailForm.sessionCoach.value}
                    fullWidth
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    options={coachList.map((coach: CoachList) => {
                      return {
                        name: coach.username,
                        id: coach._id
                      }
                    })}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => {
                      onInputHandleForm(SESSION_FORM_FIELDS.COACH, value)
                    }}
                    onFocus={() => { onInputFocus(SESSION_FORM_FIELDS.COACH) }}
                    readOnly={generalDetailForm.sessionCoach.disable}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled={generalDetailForm.sessionCoach.disable}
                        error={!(generalDetailForm.sessionCoach.error == null)}
                        required={generalDetailForm.sessionCoach.isRequired}
                        helperText={generalDetailForm.sessionCoach.error}
                        placeholder="Select Coach"

                      />
                    )}
                  />
                </Box>
              </Box>
            </FormControl>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={12}>
                <FormControl sx={{ width: '100%' }}>
                  <Box>
                    <Box sx={{ width: '100%', marginBottom: '3.5rem' }}>
                      <InputLabel
                        shrink={false}
                        sx={{ fontWeight: '600' }}
                        variant="standard"
                        htmlFor="uncontrolled-native"
                      >
                        <Box display='flex'><Typography fontWeight={600}>
                          Session Type
                        </Typography>
                          <Typography color='red'>*</Typography></Box>
                      </InputLabel>
                    </Box>
                    <Box>
                      <Autocomplete
                        value={generalDetailForm.sessionTypes.value}
                        fullWidth
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        options={sessionTypeList.map((type: SessionTypeDto) => {
                          return {
                            name: type.name,
                            id: type.typeId.toString()
                          }
                        })}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) => {
                          onInputHandleForm(SESSION_FORM_FIELDS.SESSION_TYPES, value)
                        }}
                        onFocus={() => { onInputFocus(SESSION_FORM_FIELDS.SESSION_TYPES) }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            disabled={generalDetailForm.sessionTypes.disable}
                            error={!(generalDetailForm.sessionTypes.error == null)}
                            required={generalDetailForm.sessionTypes.isRequired}
                            helperText={generalDetailForm.sessionTypes.error}
                            placeholder="Select Session Type"
                          />
                        )}
                      />
                    </Box>
                  </Box>
                </FormControl>
              </Grid>
            </Grid>
          </FormControl>
          <FormControl sx={{ width: '50%' }}>
            <Box sx={{ backgroundColor: 'blue', marginBottom: '3.5rem' }}>
              <InputLabel
                sx={{ fontWeight: '600' }}
                variant="standard"
                htmlFor="uncontrolled-native"
              >
                <Box display='flex'><Typography fontWeight={600}>Session Duration</Typography>
                  <Typography color='red'>*</Typography></Box>
              </InputLabel>
            </Box>
            <Box>
              <TextField
                type="text"
                value={generalDetailForm.sessionDuration.value}
                disabled={generalDetailForm.sessionDuration.disable}
                error={!(generalDetailForm.sessionDuration.error == null)}
                required={generalDetailForm.sessionDuration.isRequired}
                helperText={generalDetailForm.sessionDuration.error}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  onInputHandleForm(SESSION_FORM_FIELDS.DURATION, event.target.value)
                  handleBlur(SESSION_FORM_FIELDS.DURATION, generalDetailForm.sessionDuration.value)
                }}
                  onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
                    handleBlur(SESSION_FORM_FIELDS.DURATION, generalDetailForm.sessionDuration.value)
                  }}
                  onFocus={() => { onInputFocus(SESSION_FORM_FIELDS.DURATION) }}
                  placeholder="3m 1w 2d 3h"
                  fullWidth
              />
              <Typography variant="caption" color="textSecondary">
                Format:3m 1w 2d 3h
              </Typography>
              <Box>
                <Typography variant="caption">
                  Converted duration: {inputSessionDurationValue}
                </Typography>
                <Tooltip
                  title={
                    <>
                      <Typography color="inherit">m=month</Typography>
                      <Typography color="inherit">w=week</Typography>
                      <Typography color="inherit">d=day</Typography>
                      <Typography color="inherit">h=hour</Typography>
                    </>
                  }
                >
                  <IconButton style={{ marginLeft: '5px' }}>
                    <HelpOutlineIcon style={{ fontSize: '16px' }} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </FormControl>
        </form>
      </Box>
    </>
  )
}

export default SessionDetailsForm
