import { type OrgAndRoleHierarchy, type Action, type GetHierarchyRequestParams } from '../../interfaces'
import { COMMON_ACTIONS, HIERARCHY_ACTIONS } from '../../utils/constants'

const getOrganizationHierarchy = (params: GetHierarchyRequestParams): Action<GetHierarchyRequestParams> => {
  return { type: HIERARCHY_ACTIONS.GET_ORGANIZATION_HIERARCHY + COMMON_ACTIONS.REQUEST, data: params }
}

const clearOrganizationHierarchy = (): Action<OrgAndRoleHierarchy[]> => {
  return { type: HIERARCHY_ACTIONS.GET_ORGANIZATION_HIERARCHY + COMMON_ACTIONS.CLEAR, data: [] }
}

const getRoleHierarchy = (params: GetHierarchyRequestParams): Action<GetHierarchyRequestParams> => {
  return { type: HIERARCHY_ACTIONS.GET_ROLE_HIERARCHY + COMMON_ACTIONS.REQUEST, data: params }
}

const clearRoleHierarchy = (): Action<OrgAndRoleHierarchy[]> => {
  return { type: HIERARCHY_ACTIONS.GET_ROLE_HIERARCHY + COMMON_ACTIONS.CLEAR, data: [] }
}

const getUserHierarchy = (params: GetHierarchyRequestParams): Action<GetHierarchyRequestParams> => {
  return { type: HIERARCHY_ACTIONS.GET_USER_HIERARCHY + COMMON_ACTIONS.REQUEST, data: params }
}

const clearUserHierarchy = (): Action<OrgAndRoleHierarchy[]> => {
  return { type: HIERARCHY_ACTIONS.GET_USER_HIERARCHY + COMMON_ACTIONS.CLEAR, data: [] }
}

export const hierarchyAction = {
  getOrganizationHierarchy,
  clearOrganizationHierarchy,
  getRoleHierarchy,
  clearRoleHierarchy,
  getUserHierarchy,
  clearUserHierarchy
}
