/* eslint-disable @typescript-eslint/restrict-plus-operands */
import {
  type ReviewQuestion,
  type Action, type AssessmentQuestion,
  type sessionRequestPayLoad,
  type CompleteSessionPayLoad,
  type SessionCoacheePayload,
  type SessionDetailsRequestParam,
  type editSessionPayLoad,
  type SessionDeleteRequestParam
} from '../../interfaces'
import { COMMON_ACTIONS, SESSION_ACTIONS } from '../../utils/constants'

const getSessionDetailsById = (param: SessionDetailsRequestParam): Action<SessionDetailsRequestParam> => {
  return { type: SESSION_ACTIONS.GET_SESSION_DETAILS_BY_ID + COMMON_ACTIONS.REQUEST, data: param }
}

const clearGetSessionDetailsById = (): Action<null> => {
  return { type: SESSION_ACTIONS.GET_SESSION_DETAILS_BY_ID + COMMON_ACTIONS.CLEAR, data: null }
}

const updateSessionStatus = (payload: CompleteSessionPayLoad): Action<CompleteSessionPayLoad> => {
  return { type: SESSION_ACTIONS.UPDATE_SESSION_STATUS_BY_ID + COMMON_ACTIONS.REQUEST, data: payload }
}

const getSessionTypeList = (): Action<null> => {
  return { type: SESSION_ACTIONS.GET_SESSION_TYPE_LIST + COMMON_ACTIONS.REQUEST, data: null }
}
const storeSessionAssessmentQuestion = (assessmentQuestion: AssessmentQuestion[]): Action<AssessmentQuestion[]> => {
  return { type: SESSION_ACTIONS.STORE_ASSESSMENT_QUESTION, data: assessmentQuestion }
}

const storeSessionFeedBackQuestion = (feedBack: ReviewQuestion[]): Action<ReviewQuestion[]> => {
  return { type: SESSION_ACTIONS.STORE_FEEDBACK_QUESTION, data: feedBack }
}

const createSession = (payload: sessionRequestPayLoad): Action<sessionRequestPayLoad> => {
  return { type: SESSION_ACTIONS.CREATE_SESSION + COMMON_ACTIONS.REQUEST, data: payload }
}

const editSession = (payload: editSessionPayLoad): Action<editSessionPayLoad> => {
  return { type: SESSION_ACTIONS.UPDATE_SESSION + COMMON_ACTIONS.REQUEST, data: payload }
}

const clearEditSession = (): Action<null> => {
  return { type: SESSION_ACTIONS.UPDATE_SESSION + COMMON_ACTIONS.CLEAR, data: null }
}

const clearCreateSession = (): Action<null> => {
  return { type: SESSION_ACTIONS.CREATE_SESSION + COMMON_ACTIONS.CLEAR, data: null }
}

const clearStoreSessionAssessmentQuestion = (): Action<null> => {
  return { type: SESSION_ACTIONS.STORE_ASSESSMENT_QUESTION_CLEAR, data: null }
}
const clearStoreSessionFeedBackQuestion = (): Action<null> => {
  return { type: SESSION_ACTIONS.STORE_FEEDBACK_QUESTION_CLEAR, data: null }
}
const getSessionCoacheeList = (payload: SessionCoacheePayload): Action<SessionCoacheePayload> => {
  return { type: SESSION_ACTIONS.GET_SESSION_COACHEE_LIST + COMMON_ACTIONS.REQUEST, data: payload }
}
const clearGetSessionCoacheeList = (): Action<null> => {
  return { type: SESSION_ACTIONS.GET_SESSION_COACHEE_LIST + COMMON_ACTIONS.CLEAR, data: null }
}

const sessionCoacheeDetails = (sessionId: string): Action<string> => {
  return { type: SESSION_ACTIONS.GET_SESSION_COACHEE_DETAILS + COMMON_ACTIONS.REQUEST, data: sessionId }
}
const clearCompleteSessionStatus = (): Action<null> => {
  return { type: SESSION_ACTIONS.UPDATE_SESSION_STATUS_BY_ID + COMMON_ACTIONS.CLEAR, data: null }
}

const sessionDelete = (params: SessionDeleteRequestParam): Action<SessionDeleteRequestParam> => {
  return { type: SESSION_ACTIONS.SESSION_DELETE + COMMON_ACTIONS.REQUEST, data: params }
}

const clearSessionDelete = (): Action<null> => {
  return { type: SESSION_ACTIONS.SESSION_DELETE + COMMON_ACTIONS.CLEAR, data: null }
}
export const sessionActions = {
  getSessionTypeList,
  storeSessionAssessmentQuestion,
  storeSessionFeedBackQuestion,
  createSession,
  getSessionDetailsById,
  updateSessionStatus,
  clearCreateSession,
  clearStoreSessionAssessmentQuestion,
  clearStoreSessionFeedBackQuestion,
  getSessionCoacheeList,
  clearGetSessionCoacheeList,
  sessionCoacheeDetails,
  clearCompleteSessionStatus,
  clearGetSessionDetailsById,
  editSession,
  clearEditSession,
  sessionDelete,
  clearSessionDelete
}
