/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { Box, Button, Card, CardContent, Checkbox, Chip, Divider, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
  type AssessmentResultPathParams,
  type AppState,
  type AssessmentQuestions,
  type SessionDetails,
  type EngagementDetailsRequestParam,
  type SessionDetailsRequestParam
} from '../../interfaces'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { assessmentActions } from '../../redux/actions/assessmentActions'
import { APP_ACTION_STATUS, getAssessmentRoute, getGiveFeedbackRoute, getViewFeedbackRoute, getViewSessionRoute } from '../../utils/constants'
import { engagementsActions, sessionActions } from '../../redux/actions'
import { isNullOrUndefinedOrEmpty } from '../../utils/helpers'
import { FeedbackViewSkelton, SomethingWentWrong } from '../../components'

const AssessmentResultView = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { engagementId, sessionId, roleType, assessmentId, user } =
    useParams<{ engagementId: string, sessionId: string, roleType: string, assessmentId: string, user: string }>()

  const [isErrorFetchData, setIsErrorFetchData] = useState<string>('')

  const assessmentResultResponse = useSelector((state: AppState) => state.assessments.assessmentResultDetails)
  const AssessmentResultDetailsfromAPI = useSelector((state: AppState) =>
    state.assessments.assessmentResultDetails.data)
  const { totalQuestions, totalCorrectAnswers } = AssessmentResultDetailsfromAPI
  const sessionDetails: SessionDetails = useSelector((state: AppState) => state.session.sessionDetails.data)
  const sessionDetailsResponse = useSelector((state: AppState) => state.session.sessionDetails)
  const engagementDetailsResponse = useSelector((state: AppState) => state.engagements.engagementDetails)

  const assessmentResultPayload: AssessmentResultPathParams = {
    id: sessionId ?? '',
    coacheeId: user ?? '',
    assessmentId: assessmentId ?? ''
  }
  useEffect(() => {
    dispatch(assessmentActions.assessmentResultDetails(assessmentResultPayload))

    // THIS CALL FOR VALIDATE PUPROPSE OF ENGAGEMENTID
    const param: EngagementDetailsRequestParam = {
      pathParam: { id: engagementId ?? '' },
      queryParam: { roleType: roleType ?? '' }
    }
    dispatch(engagementsActions.engagementDetails(param))

    // THIS CALL FOR VALIDATE PUPROPSE OF SESSIONID AND UPDATE REDUX STORE
    const sessionParam: SessionDetailsRequestParam = {
      pathParam: {
        id: sessionId ?? ''
      },
      queryParam: {
        roleType: roleType ?? ''
      }
    }
    dispatch(sessionActions.getSessionDetailsById(sessionParam))
    return () => {
      dispatch(engagementsActions.clearGetEngagementDetailById())
    }
  }, [])

  useEffect(() => {
    if (assessmentResultResponse?.status === APP_ACTION_STATUS.ERROR) {
      setIsErrorFetchData(assessmentResultResponse?.error ?? 'Something went wrong when fetching data.!')
    }
    if (engagementDetailsResponse?.status === APP_ACTION_STATUS.ERROR) {
      setIsErrorFetchData(engagementDetailsResponse?.error ?? 'Something went wrong when fetching data.!')
    }
    if (sessionDetailsResponse?.status === APP_ACTION_STATUS.ERROR) {
      setIsErrorFetchData(sessionDetailsResponse?.error ?? 'Something went wrong when fetching data.!')
    }
  }, [engagementDetailsResponse?.status, assessmentResultResponse?.status, sessionDetailsResponse?.status])

  const retakeQuestions = () => {
    const _engagementId = engagementId ?? ''
    const _sessionId = sessionId ?? ''
    const _roleType = roleType ?? ''
    const _assessment = assessmentId ?? ''
    const _user = user ?? ''
    navigate(getAssessmentRoute(_engagementId, _sessionId, _roleType, _assessment, _user), { replace: true })
  }

  const goBackToSession = () => {
    navigate(getViewSessionRoute(engagementId ?? '', sessionId ?? '', roleType ?? ''), { replace: true })
  }

  const giveSessionFeedback = () => {
    if (sessionDetails?.isReviewDone) {
      navigate(getViewFeedbackRoute(engagementId ?? '', sessionId ?? '', roleType ?? '', user ?? ''), { replace: true })
    } else {
      navigate(getGiveFeedbackRoute(engagementId ?? '', sessionId ?? '', roleType ?? ''), { replace: true })
    }
  }

  return (
    <React.Fragment>
      <section className='page-root' >
        <Card className='section-card'>
          <CardContent>
            <section className='section-card-header'>
              <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Typography fontWeight={600} fontSize='1.5rem'>Assessment Result View</Typography>
                  <Typography fontWeight={600} fontSize='1rem'>
                    {totalCorrectAnswers}/{totalQuestions} Correct Answers
                  </Typography>

              </Box>
            </section>
            <section className='section-card-body'>
              {(assessmentResultResponse?.isLoading || engagementDetailsResponse?.isLoading || sessionDetailsResponse?.isLoading) && <FeedbackViewSkelton />}
              {!(assessmentResultResponse?.isLoading || engagementDetailsResponse?.isLoading || sessionDetailsResponse?.isLoading) &&
                <>
                  {!isNullOrUndefinedOrEmpty(isErrorFetchData) && <SomethingWentWrong isShowDefault={false} subNotification={isErrorFetchData} />}
                  {isNullOrUndefinedOrEmpty(isErrorFetchData) &&
                    <>
                      {AssessmentResultDetailsfromAPI?.questions?.map((question: AssessmentQuestions) => {
                        const isCorrectQuestion = question.options.filter((selected: any) => selected.isCorrectAnswer === true)
                        const isCorrect = isCorrectQuestion.every((option: any) => option.isSelected === true)

                        const isCorrectQuestion2 = question.options.filter((selected: any) => selected.isCorrectAnswer === false)
                        const isCorrect2 = isCorrectQuestion2.every((option: any) => option.isSelected === false)

                        const finalCorrect = isCorrect && isCorrect2
                        return <Box key={question.questionOrder}>
                          <Divider />
                          <Box display='flex' justifyContent='space-between' marginTop='2rem'>
                            <Typography fontWeight={600} fontSize='1rem'>{question.questionOrder}.
                              {question.questionLabel}</Typography>
                            {finalCorrect
                              ? <Chip sx={{ borderRadius: 1, fontWeight: 600, minWidth: '5rem' }}
                                label="Correct" color='success'
                                variant="outlined" />
                              : <Chip sx={{ borderRadius: 1, fontWeight: 600, minWidth: '5rem' }}
                                label="Wrong" color='warning'
                                variant="outlined" />}
                          </Box>
                          <Box marginBottom='2rem'>
                            {question.type === 'single-select'
                              ? question?.options?.map((questionOption: any) => {
                                return <Box key={questionOption.optionOrder} sx={{ margin: '1rem 0 1rem 0' }}>
                                  <Box display='flex' justifyContent='space-between'>
                                    <FormControl sx={{ backgroundColor: questionOption.isSelected ? '#F5FAFF' : 'transparent' }}>
                                      <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue="questionOption"
                                        name="radio-buttons-group"
                                      >
                                        <FormControlLabel value={questionOption.optionOrder}
                                          control={<Radio
                                            color='primary' />}
                                          label={questionOption.optionLabel} />

                                      </RadioGroup>
                                    </FormControl>
                                  </Box>
                                </Box>
                              })
                              : (question.type === 'boolean'
                                  ? question?.options?.map((questionOption: any) => {
                                    return <Box key={questionOption.optionOrder}>
                                    <Box display='flex' justifyContent='space-between'>
                                      <FormControl sx={{ backgroundColor: questionOption.isSelected ? '#F5FAFF' : 'transparent' }}>
                                        <RadioGroup
                                          aria-labelledby="demo-radio-buttons-group-label"
                                          defaultValue="questionOption"
                                          name="radio-buttons-group"
                                        >
                                          <FormControlLabel value={questionOption.optionOrder}
                                            control={<Radio
                                              color='primary' />}
                                            label={questionOption.optionLabel} />

                                        </RadioGroup>
                                      </FormControl>
                                    </Box>
                                  </Box>
                                  })
                                  : (question.type === 'multiple-choice'
                                      ? question?.options?.map((questionOption: any) => {
                                        return <Box key={questionOption.optionOrder} sx={{ margin: '1rem 0 1rem 0' }}>
                                      <Box display='flex' alignItems='center' sx={{ backgroundColor: questionOption.isSelected ? '#F5FAFF' : 'transparent' }}>
                                        <Checkbox />
                                        <Typography>{questionOption.optionLabel}</Typography>
                                      </Box>
                                    </Box>
                                      })
                                      : '')
                                )}
                          </Box>
                          <Divider />
                        </Box>
                      })}
                    </>
                  }
                </>
              }
            </section>
            <section className='section-card-footer'>
              <Box display='flex' justifyContent='end' gap={2}>
                <Button onClick={giveSessionFeedback} sx={{ textTransform: 'none' }}
                  variant='outlined' color='primary'>{sessionDetails?.isReviewDone ? 'View Feedback' : 'Give Feedback'}</Button>
                <Button onClick={retakeQuestions} sx={{ textTransform: 'none' }}
                  variant='outlined' color='primary'>Retake</Button>
                <Button onClick={goBackToSession} sx={{ textTransform: 'none' }}
                  variant='contained' color='primary'>Go back to session</Button>
              </Box>
            </section>
          </CardContent>
        </Card>
      </section>
    </React.Fragment>
  )
}

export default AssessmentResultView
