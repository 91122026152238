import * as React from 'react'
import { Box, Card, CardContent, Divider, Drawer, IconButton, Typography } from '@mui/material'
import { type ViewCoacheeFeedBack } from '../../../interfaces/feedBack'
import CloseIcon from '@mui/icons-material/Close'
import { type AsyncStateObject } from '../../../interfaces'
import styles from './SessionFeedbackDrawer.module.scss'
import { SingleNoteSkelton } from '../../skeleton'

const SessionFeedbackDrawer: React.FC<{
  drawerOpen: boolean
  feedbackComments: AsyncStateObject<ViewCoacheeFeedBack[]>
  toggleDrawer: (open: boolean) => any

}> = ({ drawerOpen, feedbackComments, toggleDrawer }) => {
  return (
    <Box className="testCard">
      <React.Fragment key="right">
        <Drawer
          sx={{ zIndex: '1202' }}
          anchor="right"
          open={drawerOpen}
          onClose={toggleDrawer(false)}
          slotProps={{ backdrop: { invisible: true } }}

        >
          <Box
            sx={{ width: 380 }}
            role="presentation"
            onKeyDown={() => toggleDrawer(false)}
          >
            <Card sx={{ boxShadow: 'none' }}>
              <CardContent
                sx={{
                  boxShadow: 'none',
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <Typography fontWeight='700' variant="h5" sx={{ fontSize: 'medium', py: 1 }}>
                  Feedbacks
                </Typography>

                <IconButton>
                  <CloseIcon onClick={toggleDrawer(false)} />

                </IconButton>
              </CardContent>
              <Divider sx={{ marginTop: '2rem' }} />
              {feedbackComments?.isLoading &&
                <Box display='flex' flexDirection='column'>
                  <SingleNoteSkelton/><SingleNoteSkelton/><SingleNoteSkelton/>
                </Box>
              }
              {!feedbackComments?.isLoading &&
                <Card sx={{ boxShadow: 'none' }}>
                  <CardContent>
                    {feedbackComments?.data?.length !== 0
                      ? (
                          feedbackComments.data.map((comment: ViewCoacheeFeedBack, index: number) => (
                          <Box key={index}>
                            <Typography id={styles.comment_style}>
                              <Typography style={{ marginTop: '20px' }}>
                                {comment.comment}
                              </Typography>
                              <Typography id={styles.comment_date_style}>
                                {(() => {
                                  const dateString = comment.commentedAt.toString()
                                  const commentDate = new Date(dateString)
                                  return commentDate.toLocaleString()
                                })()}
                              </Typography>
                            </Typography>
                            {index < feedbackComments.data.length - 1 && <Divider />}
                          </Box>
                          ))
                        )
                      : (
                        <Typography>No feedback given.</Typography>
                        )}
                  </CardContent>

                </Card>
              }
            </Card>
          </Box>
        </Drawer>
      </React.Fragment>
    </Box >
  )
}
export default SessionFeedbackDrawer
