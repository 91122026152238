import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material'
import React from 'react'
import { ENGAGEMENT_TYPES, NOTE_TAB_TYPE } from '../../../utils/constants'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { type AppState } from '../../../interfaces'

const NotesHeader: React.FC<{
  viewType: NOTE_TAB_TYPE
  handleChangeViewType: (event: React.MouseEvent<HTMLElement>, type: NOTE_TAB_TYPE) => void
}> = ({
  viewType,
  handleChangeViewType
}) => {
  const { roleType, coachId } = useParams<{ roleType: string, coachId: string }>()
  const authorizedUser = useSelector((state: AppState) => state.authUser.authorizedUser)
  const _showMyNotesTab =
    (authorizedUser?.data?._id === coachId && roleType === ENGAGEMENT_TYPES.COACH) ||
    roleType === ENGAGEMENT_TYPES.COACHEE
  const _showCoachNotesTab =
    roleType === ENGAGEMENT_TYPES.SUPERVISOR ||
    roleType === ENGAGEMENT_TYPES.COACHEE ||
    (authorizedUser?.data?._id !== coachId && roleType === ENGAGEMENT_TYPES.COACH)
  const _showCoacheeNotesTab = roleType === ENGAGEMENT_TYPES.SUPERVISOR || roleType === ENGAGEMENT_TYPES.COACH
  return (
    <Box
      sx={{
        borderColor: 'divider',
        flexDirection: { md: 'row', xs: 'column-reverse', sm: 'column-reverse' }
      }}
    >
      <Box sx={{
        width: { md: '40%', sm: '100%' },
        marginTop: { md: 0, xs: '1rem' }
      }}>
        <ToggleButtonGroup
          value={viewType}
          onChange={handleChangeViewType}
          exclusive
        >
          {_showMyNotesTab &&
            <ToggleButton
              sx={{ textTransform: 'none' }}
              value={NOTE_TAB_TYPE.MY_NOTES}
              size="small"
            >My Notes</ToggleButton>
          }
          {_showCoachNotesTab &&
            <ToggleButton
              sx={{ textTransform: 'none' }}
              value={NOTE_TAB_TYPE.COACH_NOTE}
              size="small"
            >Coach&apos;s Notes</ToggleButton>
          }
          {_showCoacheeNotesTab &&
            <ToggleButton
              sx={{ textTransform: 'none' }}
              value={NOTE_TAB_TYPE.COACHEE_NOTES}
              size="small"
            >Coachee&apos;s Notes</ToggleButton>
          }
        </ToggleButtonGroup>
      </Box>
    </Box>
  )
}

export default NotesHeader
