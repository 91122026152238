/* eslint-disable spaced-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SessionCoacheesHeaderCard from '../../components/session/SessionCoacheesHeaderCard/SessionCoacheesHeaderCard'
import {
  AppPagination,
  SessionCoacheeViewCard,
  SessionFeedbacksToCoacheesModal,
  SomethingWentWrong
} from '../../components'
import { useDispatch, useSelector } from 'react-redux'
import {
  type SessionCoacheePayload,
  type AppState,
  type SessionDetailsRequestParam,
  type EngagementDetailsRequestParam
} from '../../interfaces'
import { APP_ACTION_STATUS, APP_TABLE_CONFIGS } from '../../utils/constants'
import { sessionActions } from '../../redux/actions/sessionActions'
import { engagementsActions, feedBackActions, statusAction } from '../../redux/actions'
import { type GiveFeedbackToCoachee, type GiveFeedbackToCoacheePayload } from '../../interfaces/feedBack'
import { useParams } from 'react-router-dom'
import { isNullOrUndefinedOrEmpty } from '../../utils/helpers'

const CoacheesView = () => {
  const dispatch = useDispatch()
  const { roleType, engagementId, sessionId } =
  useParams<{ roleType: string, engagementId: string, sessionId: string }>()
  const feedbackSubmitStatus = useSelector((state: AppState) => state.feedbacks.viewGroupFeedbacks)
  const sessionCoacheeDetailsResponse = useSelector((state: AppState) => state.session.sessionCoacheeDetails)
  const sessionCoacheeListResponse = useSelector((state: AppState) => state.session.sessionCoacheeList)
  const sessionStatusList = useSelector((state: AppState) => state.status.sessionStatusList.data)
  const sessionCoacheeSubmitFeedback = useSelector((state: AppState) => state.feedbacks.giveCoachFeedbacks)
  const engagementDetailsResponse = useSelector((state: AppState) => state.engagements.engagementDetails)
  const sessionDetailsResponse = useSelector((state: AppState) => state.session.sessionDetails)

  const [isErrorFetchData, setIsErrorFetchData] = useState<string>('')
  const [page, setPage] = React.useState(APP_TABLE_CONFIGS.DEFAULT_PAGE)
  const [pageCount, setPageCount] = React.useState(APP_TABLE_CONFIGS.DEFAULT_PAGE_COUNT)
  const [statusIds, setStatusIds] = React.useState<number[]>([])
  const [searchKey, setSearchKey] = React.useState<string>('')
  const [drawerOpen, setDrawerOpen] = React.useState(false)
  const [sessionFeedbackModalOpen, setSessionFeedbackModalOpen] = useState(false)
  const [sentimentRateValue, setSentimentRateValue] = useState<number>(0)
  const [iseSelectAll, setIsSelectAll] = useState(false)
  const [feedbackMessage, setFeedbackMessage] = useState<string>('')
  const loggedUser = useSelector((state: AppState) => state.authUser.authorizedUser?.data._id)
  const [coacheeIds, setCoacheeIds] = useState<string[]>([])
  const [isValidFeedback, setIsValidFeedback] = useState<boolean>(false)

  useEffect(() => {
    // THIS CALL FOR VALIDATE PUPROPSE OF SESSIONID AND UPDATE REDUX STORE
    const sessionParam: SessionDetailsRequestParam = {
      pathParam: {
        id: sessionId ?? ''
      },
      queryParam: {
        roleType: roleType ?? ''
      }
    }
    dispatch(sessionActions.getSessionDetailsById(sessionParam))

    // THIS CALL FOR VALIDATE PUPROPSE OF ENGAGEMENTID
    const param: EngagementDetailsRequestParam = {
      pathParam: { id: engagementId ?? '' },
      queryParam: { roleType: roleType ?? '' }
    }
    dispatch(engagementsActions.engagementDetails(param))

    void getCoacheeList(getCoacheeListRequestPayload(searchKey, page))
    void getSessionCoacheeDetails(sessionId ?? '')
    dispatch(statusAction.getSessionStatus())
    return () => {
      dispatch(engagementsActions.clearGetEngagementDetailById())
    }
  }, [])

  useEffect(() => {
    if (engagementDetailsResponse?.status === APP_ACTION_STATUS.ERROR) {
      setIsErrorFetchData(engagementDetailsResponse?.error ?? 'Something went wrong when fetching data.!')
    }
    if (sessionDetailsResponse?.status === APP_ACTION_STATUS.ERROR) {
      setIsErrorFetchData(sessionDetailsResponse?.error ?? 'Something went wrong when fetching data.!')
    }
    if (sessionCoacheeDetailsResponse?.status === APP_ACTION_STATUS.ERROR) {
      setIsErrorFetchData(sessionCoacheeDetailsResponse?.error ?? 'Something went wrong when fetching data.!')
    }
  }, [engagementDetailsResponse?.status, sessionDetailsResponse?.status, sessionCoacheeDetailsResponse?.status])

  useEffect(() => {
    if (sessionCoacheeListResponse.status === APP_ACTION_STATUS.SUCCESS) {
      const count = Math.ceil(sessionCoacheeListResponse.data.count / APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE)
      setPageCount(count)
    }
  }, [sessionCoacheeListResponse.status])

  useEffect(() => {
    if (feedbackSubmitStatus.status === APP_ACTION_STATUS.SUCCESS) {
      setIsSelectAll(false)
      setCoacheeIds([])
      setFeedbackMessage('')
      setSentimentRateValue(0)
      void getCoacheeList(getCoacheeListRequestPayload(searchKey, page))
      void getSessionCoacheeDetails(sessionId ?? '')
    }
  }, [feedbackSubmitStatus.status])

  useEffect(() => {
    if (sessionCoacheeSubmitFeedback.status === APP_ACTION_STATUS.SUCCESS) {
      setIsSelectAll(false)
      setCoacheeIds([])
      setFeedbackMessage('')
      setSentimentRateValue(0)
      void getCoacheeList(getCoacheeListRequestPayload(searchKey, page))
      void getSessionCoacheeDetails(sessionId ?? '')
    }
  }, [sessionCoacheeSubmitFeedback.status])
  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
    void getCoacheeList(getCoacheeListRequestPayload(searchKey, value))
  }

  const onSearchEngagement = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKey(event.target.value)
    setPage(APP_TABLE_CONFIGS.DEFAULT_PAGE)
    setStatusIds([])
    void getCoacheeList(getCoacheeListRequestPayload(event.target.value, page))
  }

  const getCoacheeListRequestPayload =
    (key: string, page: number): SessionCoacheePayload => {
      let _statusIds

      if (statusIds?.length === 0) {
        _statusIds = undefined
      } else {
        if (statusIds != null && statusIds.length > 0) {
          _statusIds = statusIds
        } else {
          _statusIds = undefined
        }
      }

      const myEngagementRequestPayload: SessionCoacheePayload = {
        pathParam: {
          id: sessionId ?? ''
        },
        requestBody: {
          statusIds: _statusIds,
          searchField: key,
          limit: APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE,
          page
        }
      }
      return myEngagementRequestPayload
    }

  const getCoacheeList = async (requestPayload: SessionCoacheePayload) => {
    dispatch(sessionActions.getSessionCoacheeList(requestPayload))
  }
  const getSessionCoacheeDetails = async (sessionId: string) => {
    dispatch(sessionActions.sessionCoacheeDetails(sessionId))
  }

  // open close Drawer
  const toggleDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    setDrawerOpen(open)
    setStatusIds([])
  }

  // handle filter function according statusList
  const handleFilterStatus = (event: React.ChangeEvent<HTMLInputElement>, statusId: number) => {
    if (event.target.checked) {
      setStatusIds(prev => [...prev, statusId])
    } else {
      setStatusIds(statusIds.filter(item => item !== statusId))
    }
  }

  const filterCoachessbyStatus = () => {
    void getCoacheeList(getCoacheeListRequestPayload(searchKey, page))
    setDrawerOpen(false)
  }
  const clearSearchGoal = () => {
    setSearchKey('')
    setStatusIds([])
    if (searchKey.length > 0) {
      const searchKeyRemoved = ''
      void getCoacheeList(getCoacheeListRequestPayload(searchKeyRemoved, page))
    }
  }

  const handleSessionFeedbackModalClose = () => {
    setSessionFeedbackModalOpen(false)
  }
  const handleSessionFeedbackModalOpen = () => {
    setSessionFeedbackModalOpen(true)
  }

  const handleSentimentRate = (sentimentID: number) => {
    setSentimentRateValue(sentimentID)
  }
  const hadleSelectAllCoachees = () => {
    setIsSelectAll(!iseSelectAll)
    setCoacheeIds([])
  }
  const handleFeedbackMessage = (feedbackMessage: any) => {
    setFeedbackMessage(feedbackMessage)
  }
  const submitCoacheFeedbackToCoachees = () => {
    if (feedbackMessage === '') {
      setIsValidFeedback(true)
    } else {
      const submitCoachFeedbackToCoacheesPayload: GiveFeedbackToCoacheePayload = {
        pathParam: {
          id: sessionId ?? '',
          coachId: loggedUser
        },
        requestBody: {
          content: feedbackMessage,
          isAllCoachees: !!iseSelectAll,
          rating: sentimentRateValue,
          userIds: !iseSelectAll ? coacheeIds : []
        }
      }
      dispatch(feedBackActions.giveCoachFeedbackToCoachee(submitCoachFeedbackToCoacheesPayload))
      setSessionFeedbackModalOpen(false)
    }
  }

  const handleSelectCoacheeId = (coacheeId: string) => {
    if (!coacheeIds.includes(coacheeId)) {
      setCoacheeIds([...coacheeIds, coacheeId])
    } else {
      setCoacheeIds(coacheeIds.filter(id => id !== coacheeId))
    }
  }

  return (
    <React.Fragment>
      <section className='page-root' >
        {!isNullOrUndefinedOrEmpty(isErrorFetchData) &&
          <SomethingWentWrong
            isShowDefault={true}
            subNotification={isErrorFetchData}
          />
        }
        {isNullOrUndefinedOrEmpty(isErrorFetchData) &&
        <>
          <SessionCoacheesHeaderCard sessionCoacheeDetails={sessionCoacheeDetailsResponse?.data} />
          <SessionCoacheeViewCard
            selectedCoacheeIds={coacheeIds}
            handleSelectCoacheeId={handleSelectCoacheeId}
            selectAllCoachees={hadleSelectAllCoachees}
            iseSelectAll={iseSelectAll}
            openSessionModal={handleSessionFeedbackModalOpen}
            clearSearchGoal={clearSearchGoal}
            filterCoachessbyStatus={filterCoachessbyStatus}
            sessionStatusFilter={sessionStatusList}
            statusIds={statusIds}
            handleFilterStatus={handleFilterStatus}
            drawerOpen={drawerOpen}
            toggleDrawer={toggleDrawer}
            coacheeListResponse={sessionCoacheeListResponse}
            onSearchEngagement={onSearchEngagement}
            searchKey={searchKey}
            pagination={<AppPagination
              page={page}
              handleChangePage={handleChangePage}
              count={pageCount} />}
          />
          <SessionFeedbacksToCoacheesModal
            isValidFeedback={isValidFeedback}
            submitCoacheFeedbackToCoachees={submitCoacheFeedbackToCoachees}
            feedbackMessage={feedbackMessage}
            handleFeedbackMessage={handleFeedbackMessage}
            handleSelectedRating={handleSentimentRate}
            selectedRating={sentimentRateValue}
            handleClose={handleSessionFeedbackModalClose}
            modalOpen={sessionFeedbackModalOpen}
          />
        </>
        }
      </section>
    </React.Fragment>
  )
}

export default CoacheesView
