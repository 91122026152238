import React, { useEffect } from 'react'
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Checkbox,
  Chip
} from '@mui/material'
import styles from './EngagementGoalSelectCard.module.scss'
import { type AlertMessage, type GoalListSummaryData } from '../../../interfaces'
import moment from 'moment'
import { TableSkeleton } from '../../skeleton'
import { useDispatch } from 'react-redux'
import { alertActions } from '../../../redux/actions'
import { ALERT_VARIANT } from '../../../utils/constants'

const EngagementGoalSelectCard: React.FC<{
  isLoading: boolean
  goalList: GoalListSummaryData[]
  onSelectGoals: (goalId: string, goalName: string, tags: [], index: number) => void
  selectedCheckBoxes: any[]
  isChekedSelectedGoals: (id: string) => boolean
  showErrorMessage: boolean
}> = ({ isLoading, goalList, onSelectGoals, selectedCheckBoxes, isChekedSelectedGoals, showErrorMessage }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (showErrorMessage) {
      const data: AlertMessage = {
        message: 'You have selected goals that have different tags, Do you still want to continue?',
        options: {
          variant: ALERT_VARIANT.WARNING,
          autoHideDuration: 2000
        }
      }
      dispatch(alertActions.triggerAlert(data))
    }
  }, [showErrorMessage])
  return (
        <Card
            sx={{
              minWidth: 275,
              borderRadius: '1rem',
              border: '1px solid #DAE0E6',
              boxShadow: 'none',
              marginTop: '1rem'
            }}
        >

            <Box>
                {isLoading &&
                    <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '40vh',
                          padding: '1rem'
                        }}>
                        <TableSkeleton />
                    </div>
                }
                {!isLoading && goalList.map((goal: any, index: number) => {
                  return <CardContent key={index} sx={{ borderBottom: '0.1rem solid #DAE0E6', boxShadow: 'none' }}>
                        <Grid
                            container
                            display="flex"
                            lg={12}
                            md={12}
                            xs={12} alignItems="center" >
                            <Grid display="flex" item xs={12} md={6} lg={9}>
                                <Grid xs={1} md={1} lg={1}>
                                    {/* isChekedSelectedGoals(goal._id) */}
                                    <Checkbox checked={isChekedSelectedGoals(goal._id)} sx={{ borderRadius: '3rem' }}
                                        onClick={() => { onSelectGoals(goal._id, goal.name, goal.tags, index) }}
                                    />
                                </Grid>
                                <Grid xs={11} md={11} lg={11}>
                                    <Typography sx={{ marginLeft: { xs: '1rem', md: '0.25rem' } }}
                                        fontWeight="600">{goal.name}</Typography>
                                    {goal.tags.map((tag: any, index: number) => {
                                      return <Chip color="primary" key={index} sx={{
                                        borderRadius: '0.2rem',
                                        marginTop: { xs: '1rem', md: '0.5rem' },
                                        marginRight: '0.5rem'
                                      }}
                                            id={styles.goalCardEmailSlogan} label={tag.tag} />
                                    })}

                                </Grid>
                            </Grid>
                            <Grid
                                display="flex"
                                justifyContent="space-between"
                                item
                                xs={12}
                                md={6}
                                lg={3}
                                sx={{ marginTop: { xs: '1rem' } }}
                            >
                                <Grid
                                    xs={7}
                                    md={8}
                                    lg={8}>
                                    <Typography
                                        id={styles.DateRange}
                                        fontWeight="500">{`${moment(goal.startDate).format('DD/MM/YYYY')}
                                - ${moment(goal.endDate).format('DD/MM/YYYY')}`}</Typography>
                                </Grid>
                                <Grid
                                    xs={5}
                                    md={4}
                                    lg={4}
                                >
                                    <Box sx={{ display: 'flex', justifyContent: { xs: 'end', md: 'start', lg: 'start' } }}>
                                        <Typography>
                                            {goal.priority === 'High'
                                              ? (
                                                    <Chip
                                                        id={styles.cardChipHigh}
                                                        color="error"
                                                        label="High"
                                                    />
                                                )
                                              : (goal.priority === 'Medium'
                                                  ? (
                                                        <Chip
                                                            id={styles.cardChipMedium}
                                                            color="error"
                                                            label="Medium"
                                                        />
                                                    )
                                                  : <Chip
                                                        id={styles.cardChipLow}
                                                        color="error"
                                                        label="Low"
                                                    />)}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                })}

                {!isLoading && goalList.length === 0 && (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh' }}>
                        There are no Goals to select
                    </div>
                )}
            </Box>

        </Card >
  )
}

export default EngagementGoalSelectCard
