/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { type Action, type AddToQueDto, type AddToQueState } from '../../../interfaces'
import { COMMON_ACTIONS, FILE_ACTIONS } from '../../../utils/constants'

const INITIAL_STATE: AddToQueState = {
  type: 0,
  queue: [],
  file: null
}

const saveFileToQueReducer = (state = INITIAL_STATE, action: Action<AddToQueDto>) => {
  switch (action.type) {
    case FILE_ACTIONS.SAVE_FILE_TO_QUE + COMMON_ACTIONS.REQUEST:
      return {
        ...state,
        queue: [...state.queue, action.data.file],
        type: action.data.type
      }
    case FILE_ACTIONS.SAVE_FILE_TO_S3 + COMMON_ACTIONS.CLEAR:
      return INITIAL_STATE
    default:
      return state
  }
}

export default saveFileToQueReducer
