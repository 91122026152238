/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { call, put, takeEvery } from 'redux-saga/effects'
import { ALERT_ACTIONS, ALERT_VARIANT, COMMON_ACTIONS, FEEDBACK_ACTIONS } from '../../utils/constants'
import { type AlertMessage, type Action, type ErrorResponse } from '../../interfaces'
// eslint-disable-next-line max-len
import {
  type ViewCoacheeFeedbackParam,
  type AddFeedbackResponse, type CoacheeFeedbackPayload,
  type FeedBackRequestParams, type FeedBackResponse,
  type ViewCoacheeGroupFeedback, type ViewCoacheeGroupFeedbackParam,
  type ViewCoacheeFeedBack,
  type ViewCoacheeFeedbackCommentsRequestParams,
  type GiveFeedbackToCoacheePayload
} from '../../interfaces/feedBack'
import { feedbackService } from '../../services'
import { type AxiosResponse, type AxiosError } from 'axios'

function * getFeedBackDetails (action: Action<FeedBackRequestParams>) {
  try {
    const response: Awaited<ReturnType<typeof feedbackService.getFeedBackDetails>> =
       yield call(feedbackService.getFeedBackDetails, action.data)
    yield put<Action<FeedBackResponse>>({
      type: FEEDBACK_ACTIONS.GET_FEEDBACK_DETAILS + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
  } catch (error) {
    const e = error as AxiosError
    yield put<Action<ErrorResponse>>({
      type: FEEDBACK_ACTIONS.GET_FEEDBACK_DETAILS + COMMON_ACTIONS.ERROR,
      data: { message: e?.response?.data as string },
      error: error as string
    })
  }
}
function * getFeedBack (action: Action<ViewCoacheeFeedbackParam>) {
  try {
    const response: Awaited<ReturnType<typeof feedbackService.getFeedBackDetails>> =
       yield call(feedbackService.viewFeedback, action.data)
    yield put<Action<FeedBackResponse>>({
      type: FEEDBACK_ACTIONS.VIEW_FEEDBACK + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
  } catch (error) {
    const e = error as AxiosError
    yield put<Action<ErrorResponse>>({
      type: FEEDBACK_ACTIONS.VIEW_FEEDBACK + COMMON_ACTIONS.ERROR,
      data: { message: e?.response?.data as string },
      error: error as string
    })
  }
}

function * addFeedback (action: Action<CoacheeFeedbackPayload>) {
  try {
    const response: AxiosResponse<AddFeedbackResponse> = yield call(
      feedbackService.addFeedback, action.data)
    yield put<Action<AddFeedbackResponse>>({
      type: FEEDBACK_ACTIONS.ADD_FEEDBACK + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
    const alert: AlertMessage = {
      message: response.data.message,
      options: {
        variant: ALERT_VARIANT.SUCCESS,
        autoHideDuration: 5000
      }
    }
    yield put<Action<AlertMessage>>({
      type: ALERT_ACTIONS.TRIGGER_ALERT_MESSAGE,
      data: alert
    })
  } catch (e) {
    const error = e as AxiosError
    const response: AddFeedbackResponse = error.response?.data as AddFeedbackResponse
    yield put<Action<AddFeedbackResponse>>({
      type: FEEDBACK_ACTIONS.ADD_FEEDBACK + COMMON_ACTIONS.ERROR,
      data: { message: '' },
      error: e as string
    })
    const alert: AlertMessage = {
      message: response.message,
      options: {
        variant: ALERT_VARIANT.ERROR
      }
    }
    yield put<Action<AlertMessage>>({
      type: ALERT_ACTIONS.TRIGGER_ALERT_MESSAGE,
      data: alert
    })
  }
}

function * groupFeedBack (action: Action<ViewCoacheeGroupFeedbackParam>) {
  try {
    const response: Awaited<ReturnType<typeof feedbackService.viewGroupFeedback>> =
       yield call(feedbackService.viewGroupFeedback, action.data)
    yield put<Action<ViewCoacheeGroupFeedback>>({
      type: FEEDBACK_ACTIONS.VIEW_GROUP_FEEDBACK + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
  } catch (error) {
    const e = error as AxiosError
    yield put<Action<ErrorResponse>>({
      type: FEEDBACK_ACTIONS.VIEW_GROUP_FEEDBACK + COMMON_ACTIONS.ERROR,
      data: { message: e?.response?.data as string },
      error: error as string
    })
  }
}

function * addCoachFeedBack (action: Action<GiveFeedbackToCoacheePayload>) {
  try {
    const response: Awaited<ReturnType<typeof feedbackService.giveFeedbackToCoachees>> =
       yield call(feedbackService.giveFeedbackToCoachees, action.data)
    yield put<Action<ErrorResponse>>({
      type: FEEDBACK_ACTIONS.ADD_COACH_FEEDBACK_TO_COACHEES + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
    const alert: AlertMessage = {
      message: response.data.message,
      options: {
        variant: ALERT_VARIANT.SUCCESS,
        autoHideDuration: 5000
      }
    }
    yield put<Action<AlertMessage>>({
      type: ALERT_ACTIONS.TRIGGER_ALERT_MESSAGE,
      data: alert
    })
  } catch (error) {
    const e = error as AxiosError
    yield put<Action<ErrorResponse>>({
      type: FEEDBACK_ACTIONS.ADD_COACH_FEEDBACK_TO_COACHEES + COMMON_ACTIONS.ERROR,
      data: { message: e?.response?.data as string },
      error: error as string
    })
  }
}

function * showFeedbackComments (action: Action<ViewCoacheeFeedbackCommentsRequestParams>) {
  try {
    const response:
    AxiosResponse<ViewCoacheeFeedBack[]> = yield call(feedbackService.showFeedbackComments, action.data)
    yield put<Action<ViewCoacheeFeedBack[]>>({
      type: FEEDBACK_ACTIONS.VIEW_FEEDBACK_COMMENTS + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
  } catch (error) {
    yield put<Action<ViewCoacheeFeedBack[]>>({
      type: FEEDBACK_ACTIONS.VIEW_FEEDBACK_COMMENTS + COMMON_ACTIONS.ERROR,
      data: [],
      error: error as string
    })
    const alert: AlertMessage = {
      message: error as string,
      options: {
        variant: ALERT_VARIANT.ERROR
      }
    }
    yield put<Action<AlertMessage>>({
      type: ALERT_ACTIONS.TRIGGER_ALERT_MESSAGE,
      data: alert
    })
  }
}
function * feedbackSagas () {
  yield takeEvery(FEEDBACK_ACTIONS.GET_FEEDBACK_DETAILS + COMMON_ACTIONS.REQUEST, getFeedBackDetails)
  yield takeEvery(FEEDBACK_ACTIONS.ADD_FEEDBACK + COMMON_ACTIONS.REQUEST, addFeedback)
  yield takeEvery(FEEDBACK_ACTIONS.VIEW_FEEDBACK + COMMON_ACTIONS.REQUEST, getFeedBack)
  yield takeEvery(FEEDBACK_ACTIONS.VIEW_GROUP_FEEDBACK + COMMON_ACTIONS.REQUEST, groupFeedBack)
  yield takeEvery(FEEDBACK_ACTIONS.ADD_COACH_FEEDBACK_TO_COACHEES + COMMON_ACTIONS.REQUEST, addCoachFeedBack)
  yield takeEvery(FEEDBACK_ACTIONS.VIEW_FEEDBACK_COMMENTS + COMMON_ACTIONS.REQUEST, showFeedbackComments)
}

export default feedbackSagas
