import { combineReducers } from 'redux'
import { type GoalsState } from '../../../interfaces'
import createGoalReducer from './createGoal'
import getAllGoalListReducer from './getAllGoalList'
import getGoalByUserReducer from './getGoalByUser'
import goalStatusReducer from './goalsStatus'
import getGoalByIdReducer from './goalById'
import updateGoalReducer from './updateGoal'
import getGoalListSummaryReducer from './getGoalListSummary'
import goalsByEngagementIdReducer from './goalsByEngagementId'

const goalReducer = combineReducers<GoalsState>({
  createGoal: createGoalReducer,
  updateGoal: updateGoalReducer,
  allGoalList: getAllGoalListReducer,
  goalByUser: getGoalByUserReducer,
  updateGoalState: goalStatusReducer,
  goalById: getGoalByIdReducer,
  goalListSummary: getGoalListSummaryReducer,
  goalsByEngagementId: goalsByEngagementIdReducer
})

export default goalReducer
