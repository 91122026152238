import { Grid } from '@mui/material'
import React from 'react'
import DashboardStatCard from '../DashboardStatCard/DashboardStatCard'
import {
  type DashboardStatChartDto,
  type AsyncStateObject,
  type SupervisorStatsResponse,
  type EngagementListResponse,
  type DashboardEngagementSummaryDto,
  type DropdownOptionnDto
} from '../../../interfaces'
import DashboardStatChartCard from '../DashboardStatChartCard/DashboardStatChartCard'
import DashboardEngaementSummaryCard from '../DashboardEngaementSummaryCard/DashboardEngaementSummaryCard'
import moment from 'moment'

const DashboardSupervisorView: React.FC<{
  engagementStatus: DropdownOptionnDto
  onHandleStatusChange: (value: DropdownOptionnDto) => void
  supervisorStatsResponse: AsyncStateObject<SupervisorStatsResponse | null>
  supervisorEngagementSummaryResponse: AsyncStateObject<EngagementListResponse>
}> = ({
  engagementStatus,
  onHandleStatusChange,
  supervisorStatsResponse,
  supervisorEngagementSummaryResponse
}) => {
  const engagements = supervisorStatsResponse?.data?.engagements
  const goals = supervisorStatsResponse?.data?.goals
  const chartData: DashboardStatChartDto = {
    total: goals?.totalGoals ?? 0,
    pending: goals?.pendingGoals ?? 0,
    rejected: goals?.rejectedGoals ?? 0,
    accepted: goals?.acceptedGoals ?? 0,
    assigned: goals?.assignedGoals ?? 0,
    inProgress: goals?.inProgressGoals ?? 0,
    completed: goals?.completedGoals ?? 0
  }

  const summaryEngagements = supervisorEngagementSummaryResponse?.data?.data ?? []
  const engagementList: DashboardEngagementSummaryDto[] = summaryEngagements.map((eng) => {
    return {
      _id: eng._id,
      startDate: moment(eng.startDate).format('DD/MM/YYYY'),
      endDate: moment(eng.endDate).format('DD/MM/YYYY'),
      name: eng.name,
      status: eng.status,
      statusId: eng.statusId,
      isIndividual: !(eng.coachees.length > 1)
    }
  })
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={3}>
              <DashboardStatCard
                isLoading={supervisorStatsResponse?.isLoading}
                count={engagements?.totalEngagements ?? 0}
                title={'Total Engagements'}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <DashboardStatCard
                isLoading={supervisorStatsResponse?.isLoading}
                count={engagements?.completedEngagements ?? 0}
                title={'Completed Engagements'}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <DashboardStatCard
                isLoading={supervisorStatsResponse?.isLoading}
                count={engagements?.inProgressEngagements ?? 0}
                title={'In Progress Engagements'}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <DashboardStatCard
                isLoading={supervisorStatsResponse?.isLoading}
                count={engagements?.pendingEngagements ?? 0}
                title={'Pending Engagements'}
              />
            </Grid>
            <Grid item xs={12} className='dashboard-chart'>
              <DashboardStatChartCard
                title='Total Goals Created'
                isLoading={supervisorStatsResponse?.isLoading}
                data={chartData}
                showAction={true}
              />
            </Grid>
            <Grid item xs={12}>
              <DashboardEngaementSummaryCard
                selectedStatus={engagementStatus}
                onChange={onHandleStatusChange}
                isLoading={supervisorEngagementSummaryResponse?.isLoading}
                data={engagementList}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default DashboardSupervisorView
