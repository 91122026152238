export enum APP_ACTION_STATUS {
  SUCCESS = 'success',
  ERROR = 'error',
  LOADING = 'loading',
  INITIAL = 'Initial'
}

export enum FORM_VALIDATOR_TYPES {
  TEXT = 'text',
  NUMBER = 'number',
  DATE = 'date',
  OBJECT = 'object',
  ARRAY = 'array',
  EMAIL = 'email',
  FILE = 'file',
  BOOLEAN = 'Boolean',
  CKTEXT = 'RichText',
  DURATION = 'Duration',
  LINK = 'Link'
}

export enum PRIORITY_TYPES {
  LOW = 'Low',
  HIGH = 'High',
  MEDIUM = 'Medium',
}

export enum HIERARCHY_TYPES {
  ORGANAIZATION = 1,
  ROLE = 2,
  USER = 3,
}

export enum GOALS_TYPES {
  MY_GOALS = 'MyGoals',
  ALL_GOALS = 'AllGoals',
}

export enum SESSION_TYPES {
  COMPLETED = 'Completed',
  CONDUCTED = 'Conducted',
  ASSIGNED = 'Assigned'
}

export enum ENGAGEMENT_TYPES {
  COACH = 'COACH',
  COACHEE = 'COACHEE',
  SUPERVISOR = 'SUPERVISOR',
}

export enum DASHBOARD_VIEW_TYPES {
  COACH = 'COACH',
  COACHEE = 'COACHEE',
  SUPERVISOR = 'SUPERVISOR',
}

export enum GOAL_STATUS_ACTION {
  PENDIND = 1,
  REQUEST_EDIT = 2,
  ACCEPT = 3,
  REJECT = 4,
  ASSIGNED = 5,
  INPROGRESS = 6,
  COMPLETED = 7
}

export enum FILE_UPLOAD_TYPE {
  ENGAGEMENT = 1,
  SESSION = 2
}

export enum ALERT_VARIANT {
  SUCCESS = 'success',
  DEFAULT = 'default',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info'
}

export enum ALERT_ORIGIN_VERTICAL_TYPE {
  BOTTOM = 'bottom',
  TOP = 'top'
}

export enum ALERT_ORIGIN_HORIZONTAL_TYPE {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center'
}

export enum APP_FEATURES {
  VIEW_DASHBOARD = 'viewDashboard',
  CREATE_GOAL = 'createGoal',
  VIEW_GOAL = 'viewGoal',
  EDIT_GOAL = 'editGoal',
  VIEW_ENGAGEMENT = 'viewEngagement',
  CREATE_ENGAGEMENT = 'createEngagement',
  EDIT_ENGAGEMENT = 'editEngagement',
  VIEW_SESSION = 'viewSession',
  VIEW_FEEDBACK = 'viewFeedback',
  VIEW_ASSESSMENT = 'ViewAssessment',
  VIEW_ASSESSMENT_RESULT = 'ViewAssessmentResults',
}

export enum PERMISSION {
  CREATE_GOAL_FOR_HIM_SELF = 'CreateGoalForHimself',
  CREATE_GOAL_FOR_OTHERS = 'CreateGoalForOthers',
  VIEW_GOALS_ASSIGNED_TO_HIM_SELF = 'ViewGoalsAssignedToHimself',
  VIEW_GOALS_ASSIGNED_TO_OTHERS = 'ViewGoalsAssignedToOthers',
  VIEW_GOALS_CREATED_BY_HIM_SELF = 'ViewGoalsCreatedByHimself',
  VIEW_GOALS_CREATED_BY_OTHERS = 'ViewGoalsCreatedByOthers',
  VIEW_ALL_GOALS = 'ViewAllGoals',
  VIEW_OWN_ALL_GOALS = 'ViewOwnAllGoals',
  VIEW_OTHERS_ALL_GOALS = 'ViewOthersAllGoals',

  CREATE_ENGAGEMENTS = 'CreateEngagements',
  VIEW_ENGAGEMENTS_ASSIGNED_TO_HIM_SELF = 'ViewEngagementsAssignedToHimself',
  VIEW_ENGAGEMENTS_ASSIGNED_TO_HIM_SELF_AS_COACH = 'ViewEngagementsAssignedToHimselfAsCoach',
  VIEW_ENGAGEMENTS_CEATED_BY_HIM_SELF = 'ViewEngagementsCreatedByHimself',
  VIEW_ENGAGEMENTS_ASSIGNED_TO_OTHERS = 'ViewEngagementsAssignedToOthers',
  EDIT_GOALS_CREATED_BY_HIM_SELF = 'EditGoalsCreatedByHimself',

  VIEW_HIERARCHY = 'ViewHierarchy',
  VIEW_USERS = 'ViewUsers',

  VIEW_CREATED_SESSIONS = 'ViewCreatedSessions',
  VIEW_SESSIONS_AS_COACH = 'ViewSessionsAsCoach',
  VIEW_SESSIONS_AS_COACHEE = 'ViewSessionsAsCoachee',
  VIEW_SESSION_COACHEES = 'ViewSessionCoachees',
  GIVE_FEEDBACKS = 'GiveFeedbacks',
  VIEW_FEEDBACKS = 'ViewFeedbacks',
  VIEW_ASSESSMENT = 'ViewAssessment',
  VIEW_ASSESSMENT_RESULTS = 'ViewAssessmentResults'

}

export enum FEEDBACK_SCREEN_MODE {
  VIEW = 'VIEW',
  CREATE = 'CREATE'
}

export enum ENGAGEMENT_ROLE {
  ENGAGEMENT_COACH = 1,
  COACH = 2,
  COACHEE = 3
}

export enum ROLES_TYPES {
  ENGAGEMENT_COACH = 'EngagementCoach',
  SESSION_COACH = 'SessionCoach',
  COACHEE = 'Coachee',
  SUPERVISOR = 'Supervisor'
}
// TODO : NEED To Change name -> ENGAGEMENT_fORM_FIELDS To ENGAGEMENT_FORM_FIELDS
export enum ENGAGEMENT_fORM_FIELDS {
  NAME = 'engagementName',
  DESCRIPTION = 'engagementDescription',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  COACH = 'engagementCoach',
  SBU = 'sbu',
  DEPARTMENT = 'department',
  ROLE = 'role'
}

export enum SESSION_STATUS {
  IN_PROGRESS = 'In Progress',
  COMPLETED = 'Completed',
  NEW = 'New',
  EXPIRED = 'Expired'
}

export enum SESSION_CHANGE_STATUS {
  TO_COMPLETED = 13,
  TO_START = 12
}

export enum SESSION_FORM_FIELDS {
  NAME = 'sessionName',
  DESCRIPTION = 'sessionDescription',
  COACH = 'sessionCoach',
  SESSION_TYPES = 'sessionTypes',
  DURATION = 'sessionDuration'
}

export enum SESSION_VIDEO_UPLOAD {
  VIDEO_NAME = 'title',
  VIDEO_LINK = 'url'
}
export enum ENGAGEMENT_STATUS {
  COMPLETED = 'Completed',
  PENDING = 'Pending',
  IN_PROGRESS = 'In Progress'
}

export enum COACH_VIEW_COACHEE_STATUS {
  IN_PROGRESS = 'In Progress',
  COMPLETED = 'Completed',
  NEW = 'New'
}
export enum SESSION_ASSESSMENT_TYPE {
  BOOLEAN = 'boolean',
  MULTIPLE_CHOICE = 'multiple-choice',
  SINGLE_SELECT = 'single-select',

}
export enum SESSION_ASSESSMENT_OPTION {
  MAX_QUESTION_OPTION = 5,
  MAX_SINGLE_SELECT_OPTION = 5,
  MAX_BOOLEAN_OPTION = 2

}

export enum BREAD_CRUB_TEXTS {
  HOME = 'Home',
  PROFILE = 'Profile',
  DASHBAORD = 'Dashboard',
  UNAUTHORIZED = 'Unauthorized',
  NOT_FOUND = 'Not Found',
  GOAL = 'Goals',
  GOAL_CREATE = 'Create Goal',
  GOAL_EDIT = 'Edit Goal',
  ENGAGEMENT = 'Engagements',
  ENGAGEMENT_CREATE = 'Create Engagement',
  ENGAGEMENT_EDIT = 'Edit Engagement',
  ENGAGEMENT_VIEW = 'View Engagement',
  SESSION_VIEW = 'View Session',
  SESSION_CREATE = 'Create Session',
  SESSION_EDIT = 'Edit Session',
  ASSESSMENT_VIEW = 'Assessments',
  ASSESSMENT_RESULT = 'Result',
  VIEW_FEEDBACK = 'View Feedbacks',
  GIVE_FEEDBACK = 'Give Feedbacks',
  VIEW_NOTES = 'Notes'
}

export enum NOTE_TAB_TYPE {
  MY_NOTES = 'MY_NOTES',
  COACHEE_NOTES = 'COACHEE_NOTES',
  COACH_NOTE = 'COACH_NOTE'
}

export enum SESSION_ATTACHMENT_TYPE {
  VIDEO_LINK = 'Video Links',
  MATERIAL = 'Material'
}
