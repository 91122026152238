import { type Action, type AsyncStateObject } from '../../../interfaces'
import { type AuthorizedUserPermissionDto } from '../../../interfaces/auth'
import { APP_ACTION_STATUS, AUTH_ACTION_TYPE, COMMON_ACTIONS } from '../../../utils/constants'

const INITIAL_STATE: AsyncStateObject<AuthorizedUserPermissionDto[]> = {
  data: [],
  isLoading: false,
  status: APP_ACTION_STATUS.INITIAL,
  error: null
}

const authorizedUserPermissionsReducer = (state = INITIAL_STATE, action: Action<AuthorizedUserPermissionDto[]>) => {
  switch (action.type) {
    case AUTH_ACTION_TYPE.FETCH_AUTHORIZE_USER_PERMISSION + COMMON_ACTIONS.REQUEST:
      return {
        ...state,
        isLoading: true,
        status: APP_ACTION_STATUS.LOADING
      }
    case AUTH_ACTION_TYPE.FETCH_AUTHORIZE_USER_PERMISSION + COMMON_ACTIONS.SUCCESS:
      return {
        ...state,
        isLoading: false,
        status: APP_ACTION_STATUS.SUCCESS,
        data: action.data,
        error: null
      }
    case AUTH_ACTION_TYPE.FETCH_AUTHORIZE_USER_PERMISSION + COMMON_ACTIONS.ERROR:
      return {
        ...state,
        isLoading: false,
        status: APP_ACTION_STATUS.ERROR,
        error: action.error
      }
    case AUTH_ACTION_TYPE.FETCH_AUTHORIZE_USER_PERMISSION + COMMON_ACTIONS.CLEAR:
      return INITIAL_STATE
    default:
      return state
  }
}

export default authorizedUserPermissionsReducer
