/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { Box, Button, Card, CardActions, CardContent, Divider, Grid, Skeleton, Typography } from '@mui/material'
import React from 'react'
import { Doughnut } from 'react-chartjs-2'
import styles from './DashboardStatChartCard.module.scss'
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js'
import { type DashboardStatChartDto } from '../../../interfaces'
import { APP_ROUTES, GOAL_STAT_ITEMS } from '../../../utils/constants'
import { getStatValue, isNullOrUndefinedOrEmpty } from '../../../utils/helpers'
import { useNavigate } from 'react-router-dom'
Chart.register(ArcElement, Tooltip, Legend)

const DashboardStatChartCard: React.FC<{
  data: DashboardStatChartDto
  showAction: boolean
  isLoading: boolean
  title: string
}> = ({
  data,
  showAction,
  isLoading,
  title
}) => {
  const navigate = useNavigate()
  const chartData = GOAL_STAT_ITEMS.map((item) => !isNullOrUndefinedOrEmpty(item.COLOR) ? item.NAME : '')
    .filter(item => !isNullOrUndefinedOrEmpty(item)).map((item) => getStatValue(data, item))
  const _data = {
    labels: GOAL_STAT_ITEMS.map((item) => !isNullOrUndefinedOrEmpty(item.COLOR) ? item.NAME : '')
      .filter(item => !isNullOrUndefinedOrEmpty(item)),
    datasets: [
      {
        label: 'No of Goals',
        data: chartData,
        backgroundColor: GOAL_STAT_ITEMS.map((item) => !isNullOrUndefinedOrEmpty(item.COLOR) ? item.COLOR : '')
          .filter(item => !isNullOrUndefinedOrEmpty(item)),
        borderColor: GOAL_STAT_ITEMS.map((item) => !isNullOrUndefinedOrEmpty(item.COLOR) ? item.COLOR : '')
          .filter(item => !isNullOrUndefinedOrEmpty(item)),
        borderWidth: 1
      }
    ]
  }

  const options = {
    plugins: {
      legend: {
        display: false // Hide the legend
      },
      tooltip: {
        enabled: true // Disable the tooltip
      },
      datalabels: {
        display: false // Hide the label details
      }
    },
    elements: {
      arc: {
        borderWidth: 50,
        borderRadius: (context: { dataIndex: number }) => {
          // Set the border radius based on the label index
          return context.dataIndex === 2 ? 0 : 0
        }
      }
    },
    maintainAspectRatio: true
  }

  const textCenter = {
    id: 'textCenter',
    beforeDatasetsDraw (chart: any, args: any, pluginOptions: any) {
      const { ctx } = chart

      ctx.save()
      ctx.font = 'bolder 2rem sans-serif'
      ctx.fillStyle = 'black'
      ctx.textAlign = 'center'
      ctx.fillText(data.total, chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y)
    }
  }
  const textBottom = {
    id: 'textCenter',
    beforeDatasetsDraw (chart: any, args: any, pluginOptions: any) {
      const { ctx } = chart
      ctx.save()
      ctx.font = 'bolder 0.75rem sans-serif'
      ctx.fillStyle = 'black'
      ctx.textAlign = 'center'
      ctx.fillText(title, chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y + 30)
    }
  }
  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          {showAction &&
            <Grid item xs={12} borderBottom={'1px solid rgba(0, 0, 0, 0.12)'}>
              <Typography variant="h5" fontWeight={400} padding={'0.5rem'} display={'flex'} justifyContent={'start'}>
                Goals Summary
              </Typography>
            </Grid>
          }
          <Grid item xs={12} xl={4} sx={{ display: 'flex', justifyContent: 'center' }}>
            {isLoading &&
              <Box display={'flex'} justifyContent={'center'} alignItems={'center'} width={330} height={330}>
                <Skeleton animation="wave" variant="circular" width='100%' height='100%' />
              </Box>
            }
            {!isLoading && chartData.some((data) => data > 0) &&
              <Doughnut data={_data} options={options} plugins={[textCenter, textBottom]}/>
            }
            {!isLoading && !chartData.some((data) => data > 0) &&
              <Box display={'flex'} justifyContent={'center'} alignItems={'center'} width={330} height={330}>
                <Typography variant="h6" fontWeight={600}>
                  No data to preview
                </Typography>
              </Box>
            }
          </Grid>
          <Grid item xs={12} xl={8} className={styles.dataGrid}>
            <Grid container spacing={2} alignItems={'center'}>
              {GOAL_STAT_ITEMS.map((item, index) => (
                <Grid item sm={4} key={index}>
                  {isLoading &&
                    <Box display='flex' flexDirection='column' gap={1}>
                      <Box><Skeleton animation="wave" variant="rounded" width='80%' height='2rem' /></Box>
                      <Box display={'flex'} justifyContent={'center'}>
                        <Skeleton animation="wave" variant="rounded" width='50%' height='2rem' />
                      </Box>
                    </Box>
                  }
                  {!isLoading &&
                    <>
                      <Typography
                        variant="h6"
                        fontWeight={500}
                        display={'flex'}
                        justifyContent={'center'}
                        borderBottom={`3px solid ${!isNullOrUndefinedOrEmpty(item.COLOR) ? item.COLOR : 'white'}`}
                      >{item.NAME}</Typography>
                      <Typography variant="subtitle1" fontWeight={600} display={'flex'} justifyContent={'center'}>
                          {getStatValue(data, item.NAME)}
                      </Typography>
                    </>
                  }
                </Grid>

              ))}
            </Grid>
          </Grid>
        </Grid>
        {showAction && <Divider sx={{ marginTop: '1rem' }} />}
      </CardContent>
      {showAction &&
        <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button size="large" onClick={() => { navigate(APP_ROUTES.GOALS) }}>See All Goals</Button>
        </CardActions>
      }
    </Card>
  )
}

export default DashboardStatChartCard
