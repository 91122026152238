import { APP_FEATURES, PERMISSION } from './index'

export const FEATURE_PERMISSION = {

  [APP_FEATURES.VIEW_DASHBOARD]: [
    PERMISSION.VIEW_ENGAGEMENTS_ASSIGNED_TO_HIM_SELF,
    PERMISSION.VIEW_GOALS_ASSIGNED_TO_HIM_SELF,
    PERMISSION.VIEW_GOALS_CREATED_BY_HIM_SELF,
    PERMISSION.VIEW_ENGAGEMENTS_ASSIGNED_TO_HIM_SELF_AS_COACH,
    PERMISSION.VIEW_GOALS_CREATED_BY_HIM_SELF,
    PERMISSION.VIEW_ENGAGEMENTS_CEATED_BY_HIM_SELF
  ],

  [APP_FEATURES.CREATE_GOAL]: [PERMISSION.CREATE_GOAL_FOR_HIM_SELF,
    PERMISSION.CREATE_GOAL_FOR_OTHERS],

  [APP_FEATURES.VIEW_GOAL]: [PERMISSION.VIEW_HIERARCHY,
    PERMISSION.VIEW_USERS],

  [APP_FEATURES.VIEW_ENGAGEMENT]: [PERMISSION.VIEW_ENGAGEMENTS_ASSIGNED_TO_HIM_SELF,
    PERMISSION.VIEW_ENGAGEMENTS_ASSIGNED_TO_OTHERS],

  [APP_FEATURES.CREATE_ENGAGEMENT]: [PERMISSION.CREATE_ENGAGEMENTS],

  [APP_FEATURES.EDIT_ENGAGEMENT]: [PERMISSION.EDIT_GOALS_CREATED_BY_HIM_SELF],

  [APP_FEATURES.VIEW_SESSION]: [PERMISSION.VIEW_CREATED_SESSIONS, PERMISSION.VIEW_SESSIONS_AS_COACH,
    PERMISSION.VIEW_SESSIONS_AS_COACHEE],
  [APP_FEATURES.VIEW_FEEDBACK]: [PERMISSION.VIEW_FEEDBACKS],
  [APP_FEATURES.VIEW_ASSESSMENT]: [PERMISSION.VIEW_ASSESSMENT],
  [APP_FEATURES.VIEW_ASSESSMENT_RESULT]: [PERMISSION.VIEW_ASSESSMENT_RESULTS]

}
