/* eslint-disable @typescript-eslint/restrict-template-expressions */
import {
  Box,
  Button,
  CardContent,
  Grid,
  Typography
} from '@mui/material'
import {
  EngagementViewHeaderDescription,
  GoalEngagementsCard,
  SomethingWentWrong,
  ViewEngagementSkelton
} from '../../components'
import {
  type GoalListData,
  type AppState,
  type CompleteSessionPayLoad,
  type EngagementDetailsRequestParam,
  type SessionDeleteRequestParam,
  type Attachments
} from '../../interfaces'
import Card from '@mui/material/Card'
import React, { useEffect, useState } from 'react'
import { SessionCard, StartSessionModal } from '../../components/session'
import styles from './CustomScrollbar.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { sessionActions } from '../../redux/actions/sessionActions'
import GoalModal from '../../components/goal/GoalModal/GoalModal'
import { engagementsActions, goalActions } from '../../redux/actions'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import {
  APP_ACTION_STATUS,
  APP_ROUTES,
  ROLES_TYPES,
  SESSION_CHANGE_STATUS,
  getCreateSessionRoute,
  getViewSessionRoute
} from '../../utils/constants'
import SessionDeletePopup from '../../components/session/SessionDeletePopup/SessionDeletePopup'
import { isNullOrUndefinedOrEmpty } from '../../utils/helpers'
import EngagementMaterialView from '../../components/engagement/EngagementMaterialView/EngagementMaterialView'
import { fileActions } from '../../redux/actions/fileActions'
import attachmentImageHandler from '../../utils/helpers/attachmentImageHandler'

const EngagementViewById = () => {
  const INIT_ATTACHMENT: Attachments = {
    attachmentTitle: '',
    attachmentUrl: '',
    attachmentId: ''
  }
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { engagementId, roleType } = useParams<{ engagementId: string, roleType: string }>()

  const [isErrorFetchData, setIsErrorFetchData] = useState<string>('')
  const [modalOpen, setModalOpen] = React.useState(false)
  const [sessionID, setSessionId] = useState<string>('')
  const [sessionStatus, setSessionStatus] = useState<string>('')
  const [goalModalOpen, setGoalModalOpen] = React.useState(false)
  const [openDeletePopup, setOpenDeletePopup] = React.useState(false)
  const [deleteSessionId, setDeleteSessionId] = React.useState('')
  const [myFiles, setMyFiles] = useState<Attachments[]>([])
  const [selectedAttachment, setSelectedAttachment] = React.useState<Attachments>(INIT_ATTACHMENT)

  const engagementDetails = useSelector((state: AppState) => state.engagements?.engagementDetails?.data)
  const engagementDetailsResponse = useSelector((state: AppState) => state.engagements.engagementDetails)
  const goalDetails = useSelector((state: AppState) => state.goals.goalById.data)
  const goalsResponse = useSelector((state: AppState) => state.goals?.goalsByEngagementId)
  const downloadFilesResponse = useSelector((state: AppState) => state.files.downloadFile)
  const downloadFiles = useSelector((state: AppState) => state.files.downloadFile.data)
  const authorizedUser = useSelector((state: AppState) => state.authUser.authorizedUser)

  const progressBar = engagementDetails.sessions.length
  const completedSession = engagementDetails.sessions.filter((session: any, index: number) =>
    session.status === 'Completed').length
  const engagementRoles = engagementDetails.roles
  const isValidToEditDeleteSession: boolean =
    engagementRoles?.some(item => item === ROLES_TYPES.ENGAGEMENT_COACH || item === ROLES_TYPES.SESSION_COACH)
  const isValidToCreateSession: boolean = engagementRoles?.some(item => item === ROLES_TYPES.ENGAGEMENT_COACH)
  const sessionDeleteResponse = useSelector((state: AppState) => state.session.sessionDelete)
  const engagementStatus = engagementDetails?.status

  useEffect(() => {
    onInit()
    return () => {
      dispatch(engagementsActions.clearGetEngagementDetailById())
      dispatch(goalActions.clearGoalsByEngagementId())
      dispatch(sessionActions.clearSessionDelete())
    }
  }, [])

  const onInit = () => {
    const param: EngagementDetailsRequestParam = {
      pathParam: { id: engagementId ?? '' },
      queryParam: { roleType: roleType ?? '' }
    }
    dispatch(engagementsActions.engagementDetails(param))
    dispatch(goalActions.goalsByEngagementId(engagementId ?? ''))
    dispatch(sessionActions.clearStoreSessionAssessmentQuestion())
    dispatch(sessionActions.clearStoreSessionFeedBackQuestion())
  }

  useEffect(() => {
    if (sessionDeleteResponse?.status === APP_ACTION_STATUS.SUCCESS) {
      dispatch(sessionActions.clearSessionDelete())
      const sessions = engagementDetails?.sessions.map((session) => {
        if (session.coaches.find((coach) => coach.id === authorizedUser?.data?._id) != null) {
          return session
        }
        return undefined
      })
      const isEngagementCoach = engagementDetails?.coaches.find((coach) => coach.id === authorizedUser?.data?._id)
      if (isEngagementCoach === undefined && sessions.length === 1) {
        navigate(APP_ROUTES.ENGAGEMENT_VIEW)
      } else {
        onInit()
      }
    }
  }, [sessionDeleteResponse?.status])

  useEffect(() => {
    if (engagementDetailsResponse?.status === APP_ACTION_STATUS.ERROR) {
      setIsErrorFetchData(engagementDetailsResponse?.error ?? 'Something went wrong when fetching data.!')
    }
  }, [engagementDetailsResponse?.status])

  useEffect(() => {
    if (goalsResponse?.status === APP_ACTION_STATUS.ERROR) {
      setIsErrorFetchData(goalsResponse?.error ?? 'Something went wrong when fetching data.!')
    }
  }, [goalsResponse?.status])

  useEffect(() => {
    if (engagementDetailsResponse.status === APP_ACTION_STATUS.SUCCESS) {
      const attArray: any[] = []
      for (let i = 0; i < engagementDetailsResponse?.data?.attachments?.length; i++) {
        attArray.push({
          attachmentId: engagementDetailsResponse?.data?.attachments[i].attachmentId,
          attachmentTitle: engagementDetailsResponse?.data?.attachments[i].attachmentTitle,
          attachmentUrl: engagementDetailsResponse?.data?.attachments[i].attachmentUrl
        })
      }
      setMyFiles(attArray)
    }
  }, [engagementDetailsResponse.status])

  useEffect(() => {
    if (downloadFilesResponse.status === APP_ACTION_STATUS.SUCCESS) {
      const blob = new Blob([downloadFiles])
      const fileURL = URL.createObjectURL(blob)
      const downloadLink = document.createElement('a')
      downloadLink.href = fileURL
      downloadLink.download = selectedAttachment.attachmentTitle
      downloadLink.textContent = 'Download File'
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
      dispatch(fileActions.clearDownloadFile())
    }
  }, [downloadFilesResponse.status])

  const progressBarPercentage = progressBar > 0 ? (completedSession / progressBar) * 100 : 0

  const handleClose = () => {
    setModalOpen(false)
    setOpenDeletePopup(false)
  }
  const openModal = (sessionId: string, sessionStatus: string) => {
    setSessionId(sessionId)
    setSessionStatus(sessionStatus)
    setModalOpen(true)
  }
  const goalModalClose = () => {
    setGoalModalOpen(false)
  }
  const openGoalModal = (goalId: string) => {
    dispatch(goalActions.getGoalById(goalId))
    setGoalModalOpen(true)
  }

  const viewSessionNotCoachee = (sessionID: string) => {
    navigate(getViewSessionRoute(engagementId ?? '', sessionID, roleType ?? ''))
  }

  const viewSession = (sessionID: string) => {
    setModalOpen(false)
    const updateSessionPayload: CompleteSessionPayLoad = {
      sessionId: sessionID,
      statusId: SESSION_CHANGE_STATUS.TO_START // StatusID=12 for start the session
    }
    dispatch(sessionActions.updateSessionStatus(updateSessionPayload))
    navigate(getViewSessionRoute(engagementId ?? '', sessionID, roleType ?? ''))
  }

  const sessionDeletePopup = (sessionId: string) => {
    setOpenDeletePopup(true)
    setDeleteSessionId(sessionId)
  }
  const sessionDeleteHandle = () => {
    const sessionDeletePayload: SessionDeleteRequestParam = {
      id: deleteSessionId
    }
    dispatch(sessionActions.sessionDelete(sessionDeletePayload))
    setOpenDeletePopup(false)
  }

  const createSessionHandle = () => {
    navigate(getCreateSessionRoute(engagementId ?? '', roleType ?? ''))
  }

  const fileDownload = (id: string) => {
    const attachment = myFiles.find((file) => file.attachmentUrl === id)
    setSelectedAttachment(attachment ?? INIT_ATTACHMENT)
    dispatch(fileActions.downloadFile(attachment?.attachmentUrl ?? ''))
  }

  return (
    <React.Fragment>
      <section className='page-root' >
        {!isNullOrUndefinedOrEmpty(isErrorFetchData) &&
          <SomethingWentWrong
            subNotification={isErrorFetchData}
            isShowDefault={true}
          />
        }
        {isNullOrUndefinedOrEmpty(isErrorFetchData) &&
          <>
            {engagementDetailsResponse?.isLoading && <ViewEngagementSkelton />}
            {!engagementDetailsResponse?.isLoading &&
              <Grid container spacing={2}>
                <Grid item md={9} >
                  <Card key={engagementDetails?._id} sx={{ overflowY: 'auto', height: 'calc(100vh - 8rem)' }}
                    className={styles.myScrollbarContainer} >
                    <CardContent>
                      <EngagementViewHeaderDescription
                        progressBarPercentage={progressBarPercentage}
                        viewEngagementHeader={engagementDetails?.name}
                        engagementCoachName={engagementDetails?.coaches[0]?.name}
                        engagementStartDate={engagementDetails?.startDate?.toString()}
                        engagementEndDate={engagementDetails?.endDate?.toString()}
                        engagementDescription={engagementDetails?.description}
                      />

                      <EngagementMaterialView
                        downloadFile={fileDownload}
                        myFiles={myFiles}
                        attachmentImageHandler={attachmentImageHandler}/>
                      <Box my='1rem' display='flex' justifyContent='space-between'>
                        <Grid>
                          <Typography fontWeight={600} fontSize='1rem'>Sessions</Typography>
                        </Grid>
                        <Grid>
                          {isValidToCreateSession
                            ? <Button
                              sx={{ textTransform: 'none' }}
                              variant="outlined"
                              color='primary'
                              disabled={engagementDetails.status === 'Completed'}
                              onClick={createSessionHandle}
                            >
                              Create session
                            </Button>
                            : ''}

                        </Grid>
                      </Box>
                      {/* Session Cards */}
                      <Box>
                        {engagementDetails?.sessions?.map((session: any) => {
                          return <SessionCard
                            engagementStatus={engagementStatus}
                            viewSession={viewSessionNotCoachee}
                            sessionEditDelete={isValidToEditDeleteSession}
                            openModal={openModal}
                            key={session._id}
                            id={session._id}
                            sessionName={session?.name}
                            sessionDescription={session?.description}
                            sessionStatus={session?.status}
                            sessionDuration={session?.sessionDuration}
                            noOfUncompletedAssessments={session?.noOfUncompletedAssessments}
                            coaches={session?.coaches}
                            sessionDeleteHandle={sessionDeletePopup}

                          />
                        })}
                      </Box>

                      {/* Session Cards */}
                    </CardContent>
                    {engagementDetails?.sessions?.length === 0
                      ? <Box sx={{ padding: '1rem' }}>
                        <Card sx={{ border: '1px solid #648EF7', marginTop: '1rem' }}>
                          <Box display='flex' gap={2} sx={{ padding: '1rem', backgroundColor: '#F5FAFF' }}>
                            <ErrorOutlineIcon fontSize='medium' color='primary' />
                            <Typography color='#5F6D7E' >There are no sessions</Typography>
                          </Box>
                        </Card>
                      </Box>
                      : null}
                  </Card>
                </Grid>
                <Grid item md={3}>
                  <Card
                    sx={{ height: 'calc(100vh - 8rem)', overflowY: 'auto' }}
                    className={styles.myScrollbarContainer}
                  >
                    <CardContent>
                      <Typography mb='1rem' fontWeight={600} variant="h5">Goals</Typography>
                      {goalsResponse?.data?.map((goal: GoalListData) => {
                        return <GoalEngagementsCard
                          goalId={goal._id}
                          openGoalModal={openGoalModal}
                          key={goal._id}
                          goalName={goal?.name}
                          goalDescription={goal?.description}
                          goalTags={goal?.tags}
                          goalStartDate={goal?.startDate.toString()}
                          goalEndDate={goal?.endDate.toString()}
                        />
                      })}
                    </CardContent>
                  </Card>
                </Grid>
                <StartSessionModal
                  sessionStatus={sessionStatus}
                  sessionID={sessionID}
                  modalOpen={modalOpen}
                  handleClose={handleClose}
                  viewSession={viewSession}
                />

                <GoalModal goalDetails={goalDetails} handleClose={goalModalClose} modalOpen={goalModalOpen} />
                <SessionDeletePopup
                  openDeletePopup={openDeletePopup}
                  sessionDeleteHandle={sessionDeleteHandle}
                  handleClose={handleClose} />
              </Grid>
            }
          </>
        }
      </section>
    </React.Fragment>
  )
}

export default EngagementViewById
