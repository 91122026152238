/* eslint-disable multiline-ternary */

import {
  Card, CardActions, CardContent, Checkbox,
  Divider, FormControl, MenuItem, Select, TextField, Typography, Button, Box, type SelectChangeEvent
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import styles from './SessionAssessment.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import {
  type SessionAssessments,
  type AppState,
  type AssessmentQuestion,
  type AssessmentDetails
} from '../../../interfaces'
import { sessionActions } from '../../../redux/actions/sessionActions'
import { APP_ACTION_STATUS, SESSION_ASSESSMENT_OPTION, SESSION_ASSESSMENT_TYPE } from '../../../utils/constants'
import { isNullOrUndefinedOrEmpty } from '../../../utils/helpers'

const SessionAssessment: React.FC<{
  setAssessment: (assessment: SessionAssessments) => void
}> = ({ setAssessment }) => {
  const questionOptions = [
    { value: 'multiple-choice', label: 'Multiple Choice' },
    { value: 'single-select', label: 'Single Select' },
    { value: 'boolean', label: 'Boolean' }
  ]

  const dispatch = useDispatch()

  const assessmentQuestions = useSelector((state: AppState) => state.session.assessmentQuestions.data)
  const assessmentDetailsResponse = useSelector((state: AppState) => state.assessments.assessmentDetails)
  const [compulsory, setCompulsory] = React.useState(true)
  const [isEdit, setIsEdit] = React.useState(false)
  const [isAddNew, setIsAddNew] = React.useState(true)
  const [questionType, setQuestionType] = useState('multiple-choice')
  const [questions, setQuestions] = useState<AssessmentQuestion>(
    {
      type: questionType,
      questionLabel: '',
      questionOrder: 0,
      options: [{ optionLabel: '', optionOrder: 0, isCorrectAnswer: false }]
    }

  )
  const [showQuestions, setShowQuestions] = useState(false)

  useEffect(() => {
    const updatedAssessment: SessionAssessments = {
      isCompulsory: compulsory,
      assessmentQuestions
    }
    setAssessment(updatedAssessment)
    setShowQuestions(true)
  }, [assessmentQuestions])

  const assessmentCompulsory = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isCompulsory = event.target.checked
    setCompulsory(isCompulsory)
    // Create a new Assessment object with updated values
    const updatedAssessment: SessionAssessments = {
      isCompulsory,
      assessmentQuestions // Use the updated questions state
    }
    setAssessment(updatedAssessment)
  }
  useEffect(() => {
    if (assessmentDetailsResponse.status === APP_ACTION_STATUS.SUCCESS) {
      if (!isNullOrUndefinedOrEmpty(assessmentDetailsResponse?.data)) {
        const assessmentDetails: AssessmentDetails = assessmentDetailsResponse?.data
        setIsAddNew(!(assessmentDetails?.questions.length > 0))

        setCompulsory(assessmentDetails.isCompulsory)
        const updatedAssessment: SessionAssessments = {
          isCompulsory: assessmentDetails.isCompulsory,
          assessmentQuestions // Use the updated questions state
        }
        setAssessment(updatedAssessment)
      }
    }
  }, [assessmentDetailsResponse.status])

  const handleQuestionType = (event: SelectChangeEvent) => {
    setQuestionType(event.target.value)

    if (event.target.value === SESSION_ASSESSMENT_TYPE.BOOLEAN) {
      setQuestions((prevQuestions) => ({
        ...prevQuestions,
        type: event.target.value,
        questionLabel: '',
        questionOrder: 0,
        options: [
          {
            optionLabel: 'True',
            optionOrder: 1,
            isCorrectAnswer: true
          },
          {
            optionLabel: 'False',
            optionOrder: 2,
            isCorrectAnswer: false
          }
        ]
      }))
    } else {
      setQuestions({
        type: event.target.value,
        questionLabel: '',
        questionOrder: 0,
        options: [
          {
            optionLabel: '',
            optionOrder: 0,
            isCorrectAnswer: false
          }
        ]
      })
    }
  }

  const addingQuestions = (value: string) => {
    const updatedQuestions = { ...questions }
    updatedQuestions.questionLabel = value
    setQuestions(updatedQuestions)
  }

  const addingOptions = (optionIndex: number, value: string) => {
    setQuestions((prevQuestions) => {
      const updatedQuestions = { ...prevQuestions }
      const options = [...updatedQuestions.options]
      options[optionIndex].optionLabel = value
      updatedQuestions.options = options
      return updatedQuestions
    })
  }

  const handleCheckboxChange = (optionIndex: number) => {
    setQuestions((prevQuestions) => {
      const updatedOptions = prevQuestions.options.map((option, oIndex) => {
        if (questionType !== SESSION_ASSESSMENT_TYPE.MULTIPLE_CHOICE) {
          if (oIndex === optionIndex) {
            return { ...option, isCorrectAnswer: !option.isCorrectAnswer } // Toggle the checkbox state
          } else {
            return { ...option, isCorrectAnswer: false } // Unselect other options
          }
        } else {
          if (oIndex === optionIndex) {
            return { ...option, isCorrectAnswer: !option.isCorrectAnswer } // Toggle the checkbox state
          } else {
            return option
          }
        }
      })

      // Return the entire questions object with updated options
      return { ...prevQuestions, options: updatedOptions }
    })
  }

  const handleAddOption = () => {
    let maxOptions = 5

    if (questionType === SESSION_ASSESSMENT_TYPE.SINGLE_SELECT) {
      maxOptions = SESSION_ASSESSMENT_OPTION.MAX_SINGLE_SELECT_OPTION
    } else if (questionType === SESSION_ASSESSMENT_TYPE.BOOLEAN) {
      maxOptions = SESSION_ASSESSMENT_OPTION.MAX_BOOLEAN_OPTION
    }

    if (questions.options.length < maxOptions) {
      const updatedQuestions = { ...questions }
      updatedQuestions.options.push({
        optionLabel: '',
        optionOrder: questions.options.length + 1,
        isCorrectAnswer: false
      })
      setQuestions(updatedQuestions)
    }
  }

  const handleRemoveOption = (optionIndex: number) => {
    if (optionIndex > 0) {
      const updatedQuestions = { ...questions }
      updatedQuestions.options.splice(optionIndex, 1)
      setQuestions(updatedQuestions)
    }
  }

  const addQuestionsAnswers = () => {
    setCompulsory(true)
    dispatch(sessionActions.storeSessionAssessmentQuestion([{
      ...questions,
      questionOrder: assessmentQuestions.length + 1
    }]))
    setShowQuestions(true)
    setIsAddNew(false)
    setQuestions({
      type: questionType,
      questionLabel: '',
      questionOrder: 0,
      options: [{ optionLabel: '', optionOrder: 0, isCorrectAnswer: false }]
    })
  }

  const deleteQuestions = (questionOder: number) => {
    const updatedQuestions = [...assessmentQuestions]
    dispatch(sessionActions.clearStoreSessionAssessmentQuestion())
    const _withoutDeletedOne = updatedQuestions.filter((q) => q.questionOrder !== questionOder)
    if (_withoutDeletedOne.length === 0) setIsAddNew(true)
    dispatch(sessionActions.storeSessionAssessmentQuestion(
      _withoutDeletedOne.map((q, index) => {
        return {
          ...q,
          questionOrder: index + 1
        }
      })
    ))
    setQuestions({
      type: questionType,
      questionLabel: '',
      questionOrder: 0,
      options: [{ optionLabel: '', optionOrder: 0, isCorrectAnswer: false }]
    })
  }

  const editQuestions = (question: AssessmentQuestion) => {
    setQuestionType(question.type)
    setQuestions(question)
    setIsEdit(true)
    setIsAddNew(false)
  }

  const saveEditedQuestion = () => {
    const updatedQuestions = [...assessmentQuestions]
    dispatch(sessionActions.clearStoreSessionAssessmentQuestion())
    const _withoutUpdatedOne = updatedQuestions.filter((q) => q.questionOrder !== questions.questionOrder)
    dispatch(sessionActions.storeSessionAssessmentQuestion([..._withoutUpdatedOne, questions]))
    setIsEdit(false)
    setQuestions({
      type: questionType,
      questionLabel: '',
      questionOrder: 0,
      options: [{ optionLabel: '', optionOrder: 0, isCorrectAnswer: false }]
    })
  }

  const addButtonValidation = () => {
    if (questions.questionLabel === '') {
      return true
    } else if (questionType === SESSION_ASSESSMENT_TYPE.MULTIPLE_CHOICE) {
      let correctOptionsCount = 0
      correctOptionsCount += questions.options.filter(option => option.isCorrectAnswer).length
      let minimumQuestions = 0
      minimumQuestions += questions.options.length
      if (minimumQuestions < 3 || correctOptionsCount < 2 ||
        questions.options.some(option => option.optionLabel === '')) {
        return true
      }
    } else if (questionType === SESSION_ASSESSMENT_TYPE.SINGLE_SELECT) {
      let minimumQuestions = 0
      minimumQuestions += questions.options.length
      if (minimumQuestions < 2 ||
        questions.options.every(options => !options.isCorrectAnswer) ||
        questions.options.some(option => option.optionLabel === '')) {
        return true
      }
    }
    return false
  }

  const onClose = () => {
    setIsEdit(false)
    setIsAddNew(false)
    setQuestions({
      type: questionType,
      questionLabel: '',
      questionOrder: 0,
      options: [{ optionLabel: '', optionOrder: 0, isCorrectAnswer: false }]
    })
  }

  return (
    <Box sx={{ minWidth: 275 }}>
      <Card variant="outlined">
        <React.Fragment>
          <CardActions>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.2rem' }}>
              <Checkbox
                checked={compulsory}
                onChange={assessmentCompulsory}
                inputProps={{ 'aria-label': 'controlled' }}
                disabled={assessmentQuestions.every(question => question.questionLabel === '')}
              />
              <Typography sx={{ fontSize: 14, fontWeight: 600, pt: 1 }}
                color="optionLabel.secondary" gutterBottom>
                Compulsory
              </Typography>
            </Box>
          </CardActions>
          <CardActions>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.15rem' }}>
              <ErrorOutlineOutlinedIcon sx={{ pb: 1 }} />
              <Typography sx={{ mt: 0.1, pb: 1 }} color="optionLabel.secondary">
                The coachee must do the Assessment in order to finish the session
              </Typography>
            </Box>
          </CardActions>
        </React.Fragment>
      </Card>
      {
        !isAddNew &&
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', pt: 1 }}>
          <Button
            variant="contained"
            size="small"
            color="secondary"
            onClick={() => { setIsAddNew(true) }}
          >
            Add New Question
          </Button>
        </Box>
      }
      {
        isAddNew &&
        <>
          <Typography sx={{ fontSize: 13, pl: 2, py: 2 }}>Question type:</Typography>
          <FormControl sx={{ width: '99%', pl: 2 }}>
            <Select
              sx={{ paddingTop: 0 }}
              value={questionType}
              onChange={handleQuestionType}
              size="small"
            >
              {questionOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <CardContent sx={{ width: '100%' }}>
            <Card sx={{ pt: 1, pl: 2 }}>
              <TextField
                variant="outlined"
                value={questions.questionLabel}
                size="small"
                sx={{ width: '94.3%' }}
                onChange={(e) => { addingQuestions(e.target.value) }}
              />
              <Divider sx={{ py: 2 }} />
              {questions.options.map((options, optionIndex) => (
                <CardContent key={optionIndex} sx={{ display: 'flex', alignItems: 'center', py: 1, width: '100%' }}>
                  <Checkbox
                    size="small"
                    checked={options.isCorrectAnswer}
                    onChange={(e) => {
                      handleCheckboxChange(optionIndex)
                    }}
                  />
                  <Typography variant="body1" sx={{ mr: 1 }}>
                    {String.fromCharCode(65 + optionIndex)}.
                  </Typography>
                     <TextField
                     variant="outlined"
                     value={options.optionLabel}
                     size="small"
                     disabled={questionType === SESSION_ASSESSMENT_TYPE.BOOLEAN}
                     sx={{ width: '90%' }}
                     onChange={(e) => { addingOptions(optionIndex, e.target.value) }}
                   />

                  <ClearOutlinedIcon
                    sx={{ pl: 1, fontSize: 30 }}
                    onClick={() => { handleRemoveOption(optionIndex) }}
                    id={styles.removeIcon}
                  />
                </CardContent>
              ))}

              <br />
              <Typography
                id={styles.addOutlinedIconText}
                sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', pr: 4 }}
              >
                {questions.options.length < SESSION_ASSESSMENT_OPTION.MAX_QUESTION_OPTION && (
                  <Box style={{ display: 'flex', alignItems: 'center' }}>
                    <AddOutlinedIcon onClick={() => { handleAddOption() }} id={styles.addOutlinedIcon} />

                    <span onClick={() => { handleAddOption() }}>
                      Add Another Answer
                    </span>
                  </Box>
                )}
              </Typography>
              <Divider />
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', py: 1, pr: 4 }}>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ color: 'black', borderColor: 'black' }}
                  onClick={addQuestionsAnswers}
                  disabled={addButtonValidation()}
                >
                  Add
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ color: 'black', borderColor: 'black', marginLeft: '5px' }}
                  onClick={onClose}
                >
                  Close
                </Button>

              </Box>
            </Card>
          </CardContent>
        </>
      }
        {showQuestions && (
        <Box>
          {assessmentQuestions.sort((a, b) => a.questionOrder - b.questionOrder)
            .map((question: AssessmentQuestion, questionIndex: number) =>
              ((isEdit && question.questionOrder === questions.questionOrder)
                ? <Card key={questionIndex} sx={{ marginTop: '10px' }}>
                <CardContent sx={{ width: '100%' }}>
                  <Typography sx={{ fontSize: 13, pb: 1 }}>{questionType}</Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={questions.questionLabel}
                    size="small"
                    onChange={(e) => { addingQuestions(e.target.value) }}
                  />
                  <Divider sx={{ py: 1 }} />
                  {questions.options.map((options, optionIndex) => (
                    <CardContent
                      key={optionIndex}
                      sx={{ display: 'flex', alignItems: 'center', py: 1, width: '100%' }}
                    >
                      <Checkbox
                        size="small"
                        checked={options.isCorrectAnswer}
                        onChange={(e) => {
                          handleCheckboxChange(optionIndex)
                        }}
                      />
                      <Typography variant="body1" sx={{ mr: 1 }}>
                        {String.fromCharCode(65 + optionIndex)}.
                      </Typography>
                      <TextField
                        variant="outlined"
                        value={options.optionLabel}
                        size="small"
                        fullWidth
                        disabled={questions.type === SESSION_ASSESSMENT_TYPE.BOOLEAN}
                        onChange={(e) => { addingOptions(optionIndex, e.target.value) }}
                      />
                    </CardContent>
                  ))}

                  <br />
                  <Divider />
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', py: 1 }}>
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{ color: 'black', borderColor: 'black' }}
                      onClick={saveEditedQuestion}
                      disabled={addButtonValidation()}
                    >
                      Save
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{ color: 'black', borderColor: 'black', marginLeft: '5px' }}
                      onClick={() => { deleteQuestions(question.questionOrder) }}
                    >
                      Delete
                    </Button>

                  </Box>
                </CardContent>
              </Card>
                : <Card key={questionIndex} sx={{ marginTop: '10px' }}>
                <CardContent sx={{ width: '100%' }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 600, marginBottom: '5px' }}>
                    {questionIndex + 1}. {question.questionLabel}
                  </Typography>
                  <Divider />
                  {question.options.map((option, optionIndex) => (
                    <Box key={optionIndex} sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                      <Checkbox
                        size="small"
                        checked={option.isCorrectAnswer}
                      />
                      <Typography variant="body1" sx={{ mr: 1 }}>
                        {String.fromCharCode(65 + optionIndex)}.
                      </Typography>
                      <Typography>{option.optionLabel}</Typography>
                    </Box>
                  ))}

                  <Divider />
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', pt: 1 }}>
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{ color: 'black', borderColor: 'black' }}
                      onClick={() => { editQuestions(question) }}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{ color: 'black', borderColor: 'black', marginLeft: '5px' }}
                      onClick={() => { deleteQuestions(question.questionOrder) }}
                    >
                      Delete
                    </Button>
                  </Box>
                </CardContent>
              </Card>
              ))}
        </Box>
        )
      }
    </Box >
  )
}
export default SessionAssessment
