export const GOAL_STATUS = {
  PENDING: 'Pending',
  ACCEPTED: 'Accepted',
  REJECTED: 'Rejected',
  ASSIGNED: 'Assigned',
  INPROGRESS: 'In progress',
  COMPLETED: 'Completed'
}

export const GOAL_STAT_ITEMS = [
  {
    NAME: GOAL_STATUS.PENDING,
    COLOR: 'rgba(252,191,73,255)'
  },
  {
    NAME: GOAL_STATUS.ACCEPTED,
    COLOR: 'rgba(36,123,160,255)'
  },
  {
    NAME: GOAL_STATUS.REJECTED,
    COLOR: 'rgba(214,40,40,255)'
  },
  {
    NAME: GOAL_STATUS.ASSIGNED,
    COLOR: ''
  },
  {
    NAME: GOAL_STATUS.INPROGRESS,
    COLOR: ''
  },
  {
    NAME: GOAL_STATUS.COMPLETED,
    COLOR: ''
  }
]
