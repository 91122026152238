import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  LinearProgress,
  Skeleton,
  Typography,
  linearProgressClasses,
  styled
} from '@mui/material'
import React from 'react'
import { type DashboardRecentEngagementDto } from '../../../interfaces'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { getEngagementViewByTypeRoute } from '../../../utils/constants'
import { isNullOrUndefinedOrEmpty } from '../../../utils/helpers'

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#F5F8FE'
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#308fe8'
  }
}))

const DashboardEngagementCard: React.FC<{
  data: DashboardRecentEngagementDto
  isLoading: boolean
  type: string
}> = ({
  data,
  isLoading,
  type
}) => {
  const navigate = useNavigate()
  const navigatTO = () => {
    navigate(getEngagementViewByTypeRoute(data._id, type))
  }
  return (
    <Card>
      <CardContent>
        <Typography variant="h5" fontWeight={400} padding={'0.5rem'} display={'flex'} justifyContent={'center'}>
          Recently Assigned Engagement
        </Typography>
        <Divider />
        {!isLoading && isNullOrUndefinedOrEmpty(data._id) &&
          <div style={{ padding: '2rem', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
            <Typography variant="subtitle1" fontWeight={500} color={'gray'}>
              No Engagement to preview
            </Typography>
          </div>
        }
        {!isLoading && !isNullOrUndefinedOrEmpty(data._id) &&
          <>
            <Typography variant="h6" fontWeight={600} display={'flex'} alignItems={'center'} mt={'1rem'}>
              {data.name}
            </Typography>
            <Typography variant="subtitle1" fontWeight={500} color={'gray'} mb={'1rem'}>
              Coach: {data.coaches.map((coach) => coach.name).join(', ')}
            </Typography>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <Card sx={{ height: '100%' }}>
                  <CardContent sx={{ padding: '0.5rem', alignItems: 'center' }} className='layout-row'>
                      <div>
                        <Typography variant="h6" fontWeight={600} display={'flex'} alignItems={'center'} mt={'1rem'}>
                          {moment(data.endDate).format('MMM D, YYYY')}
                        </Typography>
                        <Typography variant="subtitle1" fontWeight={500} color={'gray'}>End Date</Typography>
                      </div>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={6}>
                <Card sx={{ height: '100%' }}>
                  <CardContent sx={{ padding: '0.5rem', alignItems: 'center' }} className='layout-row'>
                    <div>
                      <Typography variant="h6" fontWeight={600} display={'flex'} alignItems={'center'} mt={'1rem'}>
                      {data.sessionsLeft}
                      </Typography>
                      <Typography variant="subtitle1" fontWeight={500} color={'gray'}>Session left</Typography>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', alignItems: 'center', my: '1rem' }}>
              <Box sx={{ width: '100%', mr: 1 }}>
                <BorderLinearProgress
                  variant="determinate"
                  value={data.sessionCompletedPercentage}
                />
              </Box>
              <Box>
                <Typography variant="body2" color="text.secondary">{data.sessionCompletedPercentage}%</Typography>
              </Box>
            </Box>
          </>
        }
        {isLoading &&
          <Box display='flex' flexDirection='column' gap={1} mt={'1rem'} mb={'1rem'}>
            <Box><Skeleton animation="wave" variant="rounded" width='100%' height='2rem' /></Box>
            <Box><Skeleton animation="wave" variant="rounded" width='60%' height='2rem' /></Box>
            <Grid container spacing={2} mt={'0.3rem'}>
              <Grid item md={6}>
                <Box display='flex' flexDirection='column' gap={1}>
                  <Box><Skeleton animation="wave" variant="rounded" width='100%' height='1.5rem' /></Box>
                  <Box><Skeleton animation="wave" variant="rounded" width='60%' height='1.5rem' /></Box>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box display='flex' flexDirection='column' gap={1}>
                  <Box><Skeleton animation="wave" variant="rounded" width='20%' height='1.5rem' /></Box>
                  <Box><Skeleton animation="wave" variant="rounded" width='60%' height='1.5rem' /></Box>
                </Box>
              </Grid>
            </Grid>
            <Box mt={'0.6rem'}><Skeleton animation="wave" variant="rounded" width='100%' height='2rem' /></Box>
          </Box>
        }
        <Divider />
      </CardContent>
      <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button size="medium" onClick={navigatTO}>See All</Button>
      </CardActions>
    </Card>
  )
}

export default DashboardEngagementCard
