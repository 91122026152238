import Grid from '@mui/material/Grid'
import React from 'react'
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined'
import { Box, Card, CardActions, CardContent, Chip, Divider, LinearProgress, Typography } from '@mui/material'
import styles from './EngagementSummery.module.scss'
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined'
import { type Attachments, type EngementCreteFormState } from '../../../interfaces'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import attachmentImageHandler from '../../../utils/helpers/attachmentImageHandler'

const EngagementSummery: React.FC<{
  isDoawnloading: boolean
  myFiles: any
  myUploadedFiles: Attachments[]
  selectedAttachment: Attachments
  formData: EngementCreteFormState
  openFileInNewTab: (index: number) => void
  fileDownload: (id: string) => void
  selectedGoals: any[]
}> = ({
  isDoawnloading, formData, selectedAttachment, selectedGoals, myFiles, fileDownload, myUploadedFiles, openFileInNewTab
}) => {
  const ConvertToHTML = ({ htmlString }: any) => {
    return (
        <div dangerouslySetInnerHTML={{ __html: htmlString }} />
    )
  }
  return (
      <Grid container display='flex' sx={{ flexDirection: 'column' }}>
        <Grid xs={12} sm={12} md={12} lg={12} margin='1rem 0 1rem 0'>
          <Typography variant='subtitle1' fontWeight={600}>Engagement Name</Typography>
          <Typography color='#5F6D7E'>{formData.engagementName.value}</Typography>
        </Grid>
        <Grid xs={12} sm={12} md={12} lg={12} margin='1rem 0 1rem 0'>
          <Typography variant='subtitle1' fontWeight={600}>Engagement Description</Typography>
          <Typography color='#5F6D7E'>
            <ConvertToHTML htmlString={formData.engagementDescription.value} />
          </Typography>
        </Grid>
        <Divider sx={{ margin: '1.5rem 0 1.5rem 0' }} />
        <Grid item display='flex' gap="1rem">
          <CheckBoxOutlinedIcon color='primary' />
          <Typography fontWeight={600}>Goals</Typography>
        </Grid>
        <Grid item display='flex' sx={{ flexDirection: 'column' }}>

          {selectedGoals.length > 0 && selectedGoals.map((goal: any, index: number) => {
            return <Grid display='flex' xs={12} sm={12} md={12} lg={12} key={index}>
              <Grid display='flex' sx={{ marginTop: '1rem', marginLeft: '2.5rem' }}
                key={index} xs={11} sm={11} md={11} lg={11}>
                <Grid display='flex' sx={{ flexDirection: 'column' }}
                  md={11} lg={11}>
                  <Box display='flex' justifyContent='space-between' alignItems='center'>
                    <Typography color='#5F6D7E' fontWeight={600}>{goal.goalName}</Typography>
                  </Box>
                  <Box>
                    {goal.tags.map((tag: any, index: number) => {
                      return <Chip key={index} sx={{
                        backgroundColor: '#F5FAFF',
                        color: '#437EF7',
                        borderRadius: '0.2rem',
                        marginTop: { xs: '1rem', md: '0.5rem' },
                        marginRight: '0.5rem'
                      }}
                        id={styles.goalCardEmailSlogan} label={tag.tag} />
                    })}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          })}
        </Grid>
        <Divider sx={{ margin: '1.5rem 0 1.5rem 0' }} />
        <Grid item display='flex' gap="1rem" sx={{ paddingTop: '2rem' }}>
          <AttachFileOutlinedIcon color='primary' />
          <Typography fontWeight={600}>Attachments</Typography>
          <Typography fontWeight={400}>
            {myFiles.length === 0 && myUploadedFiles.length === 0 ? '-No attachement for this engagement-' : ''}
          </Typography>
        </Grid>
        <Grid item display='flex' gap="1rem" sm={12}>
          {myFiles.length > 0 && myFiles.map((file: any, index: number) => {
            return (
              <Grid key={index} item sm={4}>
                <Card id={styles.attchementCardInfo}>
                  <CardContent sx={{ minHeight: '5rem' }}>
                    <Box display='flex' justifyContent='center'>
                      <Grid container>
                        <Grid item xs={2}>
                        <img alt="Attachment" src={attachmentImageHandler(file.name)} />
                        </Grid>
                        <Grid item xs={10}>
                          <Box display='flex' flexDirection='column' alignItems='start'>
                            <Typography
                              className={styles.fileName}
                              justifyContent='center'
                              fontWeight={500} fontSize='1rem'>
                              {file.name}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </CardContent>
                  <Divider />
                  <CardActions disableSpacing>
                    <Grid container>
                      <Grid item xs={10} />
                      <Grid item xs={2} display='flex' justifyContent='end'>
                        <Box style={{ cursor: 'pointer' }}>
                          <FileDownloadOutlinedIcon
                            fontSize='medium' color='primary'
                            onClick={() => { openFileInNewTab(index) }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </CardActions>
                </Card>
              </Grid>
            )
          })}
          {myUploadedFiles.length > 0 && myUploadedFiles.map((file: Attachments, index: number) => {
            return (
              <Grid key={index} item sm={4}>
                <Card id={styles.attchementCardInfo}>
                  <CardContent sx={{ minHeight: '5rem' }}>
                    <Box display='flex' justifyContent='center'>
                      <Grid container>
                        <Grid item xs={2}>
                          <img alt="Attachment" src={attachmentImageHandler(file.attachmentTitle)} />
                        </Grid>
                        <Grid item xs={10}>
                          <Box display='flex' flexDirection='column' alignItems='start'>
                            <Typography
                              className={styles.fileName}
                              justifyContent='center'
                              fontWeight={500} fontSize='1rem'>
                              {file.attachmentTitle}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </CardContent>
                  <Divider />
                  {isDoawnloading && selectedAttachment.attachmentId === file.attachmentId &&
                    <Box sx={{ width: '100%' }}>
                      <LinearProgress />
                    </Box>
                  }
                  <CardActions disableSpacing>
                    <Grid container>
                      <Grid item xs={10} />
                      <Grid item xs={2} display='flex' justifyContent='end'>
                        <Box style={{ cursor: 'pointer' }}>
                          <FileDownloadOutlinedIcon
                            fontSize='medium' color='primary'
                            onClick={() => { fileDownload(file.attachmentId ?? '') }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </CardActions>
                </Card>
              </Grid>
            )
          })}

        </Grid>

        <Box>
          <Card id={styles.bottomCardInfo}>
            <Box display='flex' alignItems='center' gap='1rem' padding='0.5rem'>
              <ErrorOutlineOutlinedIcon fontSize='large' color='primary' />
              <Typography>Please note that you will only be able to create a
                session once you complete creating the engagement</Typography>
            </Box>
          </Card>
        </Box>
      </Grid>
  )
}

export default EngagementSummery
