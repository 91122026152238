import { AUTH_ACTION_TYPE, COMMON_ACTIONS } from '../../utils/constants'

const authorizedUser = () => {
  return { type: AUTH_ACTION_TYPE.AUTHORIZE_USER + COMMON_ACTIONS.REQUEST }
}

const fetchAuthorizedUser = () => {
  return { type: AUTH_ACTION_TYPE.FETCH_AUTHORIZE_USER + COMMON_ACTIONS.REQUEST }
}

const fetchAuthorizedUserPermission = () => {
  return { type: AUTH_ACTION_TYPE.FETCH_AUTHORIZE_USER_PERMISSION + COMMON_ACTIONS.REQUEST }
}

const logout = () => {
  return { type: AUTH_ACTION_TYPE.LOGOUT + COMMON_ACTIONS.REQUEST }
}

export const authActions = {
  authorizedUser,
  fetchAuthorizedUser,
  fetchAuthorizedUserPermission,
  logout
}
