import { type Action, type RecentEngagementAsCoachResponse, type AsyncStateObject } from '../../../interfaces'
import { APP_ACTION_STATUS, DASHBOARD_ACTION_TYPE, COMMON_ACTIONS } from '../../../utils/constants'

const INITIAL_STATE: AsyncStateObject<RecentEngagementAsCoachResponse | null> = {
  data: null,
  isLoading: false,
  status: APP_ACTION_STATUS.INITIAL,
  error: undefined
}

const coachRecentEngagementReducer = (
  state = INITIAL_STATE,
  action: Action<RecentEngagementAsCoachResponse>
): AsyncStateObject<RecentEngagementAsCoachResponse | null> => {
  switch (action.type) {
    case DASHBOARD_ACTION_TYPE.GET_RECENT_ENGAGEMENTS_AS_COACH + COMMON_ACTIONS.REQUEST:
      return {
        ...state,
        isLoading: true,
        status: APP_ACTION_STATUS.LOADING,
        error: undefined
      }
    case DASHBOARD_ACTION_TYPE.GET_RECENT_ENGAGEMENTS_AS_COACH + COMMON_ACTIONS.SUCCESS:
      return {
        ...state,
        isLoading: false,
        status: APP_ACTION_STATUS.SUCCESS,
        data: action.data
      }
    case DASHBOARD_ACTION_TYPE.GET_RECENT_ENGAGEMENTS_AS_COACH + COMMON_ACTIONS.ERROR:
      return {
        ...state,
        isLoading: false,
        data: null,
        status: APP_ACTION_STATUS.ERROR,
        error: action.error
      }
    case DASHBOARD_ACTION_TYPE.GET_RECENT_ENGAGEMENTS_AS_COACH + COMMON_ACTIONS.CLEAR:
      return INITIAL_STATE
    default:
      return state
  }
}

export default coachRecentEngagementReducer
