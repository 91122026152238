import { type AsyncStateObject, type Action } from '../../../interfaces'
import { type FileUploadURLResponse } from '../../../interfaces/fileUpload'
import { APP_ACTION_STATUS, COMMON_ACTIONS, FILE_ACTIONS } from '../../../utils/constants'

const INITIAL_STATE: AsyncStateObject<FileUploadURLResponse[]> = {
  data: [],
  isLoading: false,
  status: APP_ACTION_STATUS.INITIAL,
  error: undefined
}

const fileUploadURLReducer = (state = INITIAL_STATE, action: Action<FileUploadURLResponse[]>
): AsyncStateObject<FileUploadURLResponse[]> => {
  switch (action.type) {
    case FILE_ACTIONS.GET_FILE_UPLOAD_URL +
     COMMON_ACTIONS.REQUEST:
      return {
        ...state,
        isLoading: true,
        status: APP_ACTION_STATUS.LOADING,
        error: undefined
      }
    case FILE_ACTIONS.GET_FILE_UPLOAD_URL + COMMON_ACTIONS.SUCCESS:
      return {
        ...state,
        isLoading: false,
        status: APP_ACTION_STATUS.SUCCESS,
        data: [...state.data].concat(action.data)
      }
    case FILE_ACTIONS.GET_FILE_UPLOAD_URL + COMMON_ACTIONS.ERROR:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        status: APP_ACTION_STATUS.ERROR,
        error: action.error
      }
    case FILE_ACTIONS.GET_FILE_UPLOAD_URL + COMMON_ACTIONS.CLEAR:
      return INITIAL_STATE
    default:
      return state
  }
}

export default fileUploadURLReducer
