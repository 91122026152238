import { combineReducers } from 'redux'
import { type SessionState } from '../../../interfaces'
import sessionTypeListReducer from './sessionTypeList'
import assessmentQuestionReducer from './assessmentQuestion'
import ReviewQuestionReducer from './feedBackQuestion'
import createSessionReducer from './createSession'
import sessionDetailsReducer from './sessionDetails'
import startAndCompleteReducer from './startAndComplete'
import sessionCoacheeListReducer from './sessionCoacheeList'
import sessionCoacheeDetailsReducer from './sessionCoacheeDetails'
import editSessionReducer from './editSession'
import sessionDeleteReducer from './sessionDelete'

const sessionReducer = combineReducers<SessionState>({
  sessionTypes: sessionTypeListReducer,
  assessmentQuestions: assessmentQuestionReducer,
  feedBackQuestion: ReviewQuestionReducer,
  sessionCreate: createSessionReducer,
  sessionDetails: sessionDetailsReducer,
  startAndComplete: startAndCompleteReducer,
  sessionCoacheeList: sessionCoacheeListReducer,
  sessionCoacheeDetails: sessionCoacheeDetailsReducer,
  sessionEdit: editSessionReducer,
  sessionDelete: sessionDeleteReducer
})

export default sessionReducer
