import { combineReducers } from 'redux'
import { type FeedbacksState } from '../../../interfaces/feedBack'
import getFeedBackReducer from './feedBackDetails'
import addFeedbackReducer from './addFeedback'
import viewFeedbackReducer from './viewFeedback'
import viewGroupFeedbacksReducer from './viewGroupFeedbacks'
import giveCoachFeedbacksReducer from './giveCoachFeedbacks'
import feedbackCommentsReducer from './showFeedbackComments'

const feedBackReducer = combineReducers<FeedbacksState>({
  feedbackDetails: getFeedBackReducer,
  addFeedback: addFeedbackReducer,
  viewFeedback: viewFeedbackReducer,
  viewGroupFeedbacks: viewGroupFeedbacksReducer,
  giveCoachFeedbacks: giveCoachFeedbacksReducer,
  ViewCoacheeFeedbackComments: feedbackCommentsReducer
})

export default feedBackReducer
