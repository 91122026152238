import { type AsyncStateObject, type Action, type GoalStatusList } from '../../../interfaces'
import { APP_ACTION_STATUS, COMMON_ACTIONS, STATUS_ACTIONS } from '../../../utils/constants'

const INITIAL_STATE: AsyncStateObject<GoalStatusList[]> = {
  data: [],
  isLoading: false,
  status: APP_ACTION_STATUS.INITIAL,
  error: undefined
}

const goalStatusListReducer = (
  state = INITIAL_STATE,
  action: Action<GoalStatusList[]>
): AsyncStateObject<GoalStatusList[]> => {
  switch (action.type) {
    case STATUS_ACTIONS.GET_GOAL_STATUS_LIST + COMMON_ACTIONS.REQUEST:
      return {
        ...state,
        isLoading: true,
        status: APP_ACTION_STATUS.LOADING,
        error: undefined
      }
    case STATUS_ACTIONS.GET_GOAL_STATUS_LIST + COMMON_ACTIONS.SUCCESS:
      return {
        ...state,
        isLoading: false,
        status: APP_ACTION_STATUS.SUCCESS,
        data: action.data
      }
    case STATUS_ACTIONS.GET_GOAL_STATUS_LIST + COMMON_ACTIONS.ERROR:
      return {
        ...state,
        isLoading: false,
        data: [],
        status: APP_ACTION_STATUS.ERROR,
        error: action.error
      }
    case STATUS_ACTIONS.GET_GOAL_STATUS_LIST + COMMON_ACTIONS.CLEAR:
      return INITIAL_STATE
    default:
      return state
  }
}

export default goalStatusListReducer
