/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { call, put, select, takeEvery } from 'redux-saga/effects'
import { ALERT_ACTIONS, ALERT_VARIANT, COMMON_ACTIONS, FILE_ACTIONS } from '../../utils/constants'
import { type AxiosResponse } from 'axios'
import { fileService } from '../../services/fileService'
import {
  type FileUploadURLParams,
  type Action,
  type FileUploadURLResponse,
  type SaveFileRequestPayload,
  type AppState,
  type DownloadFileResponse,
  type AlertMessage,
  type AddToQueState
} from '../../interfaces'
import { type DeleteFileRequestParam, type DeleteFileResponse } from '../../interfaces/fileDelete'

function * getUploadFileURL (action: Action<FileUploadURLParams>) {
  try {
    const response: AxiosResponse<FileUploadURLResponse> = yield call(fileService.getFileUploadURL, action.data)
    yield put<Action<FileUploadURLResponse>>({
      type: FILE_ACTIONS.GET_FILE_UPLOAD_URL + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
  } catch (error) {
    yield put<Action<FileUploadURLResponse>>({
      type: FILE_ACTIONS.GET_FILE_UPLOAD_URL + COMMON_ACTIONS.ERROR,
      data: {
        url: '',
        fields: {
          key: '',
          bucket: '',
          username: '',
          'X-Amz-Algorithm': '',
          'X-Amz-Credential': '',
          'X-Amz-Signature': '',
          'X-Amz-Date': '',
          Policy: ''
        }
      },
      error: error as string
    })
  }
}

function * saveFileToS3 (action: Action<SaveFileRequestPayload>) {
  try {
    const response: AxiosResponse<any> = yield call(fileService.saveFileToS3, action.data)
    yield put<Action<any>>({
      type: FILE_ACTIONS.SAVE_FILE_TO_S3 + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
  } catch (error) {
    yield put<Action<any>>({
      type: FILE_ACTIONS.SAVE_FILE_TO_S3 + COMMON_ACTIONS.ERROR,
      data: '',
      error: error as string
    })
  }
}

function * fileUpload (): any {
  try {
    const currentState: AddToQueState = yield select((state: AppState) => state.files.addToQue)
    const currentLength = currentState.queue.length
    const getURLPayload = {
      filename: currentState.queue[currentLength - 1].name,
      type: currentState.type
    }
    const response: AxiosResponse<FileUploadURLResponse> = yield call(
      fileService.getFileUploadURL,
      getURLPayload
    )
    const s3Payload = {
      key: response.data.fields.key,
      username: response.data.fields.username,
      bucket: response.data.fields.bucket,
      'X-Amz-Algorithm': response.data.fields['X-Amz-Algorithm'],
      'X-Amz-Credential': response.data.fields['X-Amz-Credential'],
      'X-Amz-Date': response.data.fields['X-Amz-Date'],
      Policy: response.data.fields.Policy,
      'X-Amz-Signature': response.data.fields['X-Amz-Signature'],
      file: currentState.queue[currentLength - 1]
    }
    yield put<Action<any>>({
      type: FILE_ACTIONS.SAVE_FILE_TO_S3 + COMMON_ACTIONS.REQUEST,
      data: s3Payload
    })
    yield put<Action<FileUploadURLResponse>>({
      type: FILE_ACTIONS.GET_FILE_UPLOAD_URL + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
  } catch (error) {
    console.log(error)
  }
}

function * downloadFile (action: Action<string>) {
  try {
    const response: AxiosResponse<DownloadFileResponse> = yield call(fileService.downloadFile, action.data)
    const res: AxiosResponse<Blob> = yield call(fileService.downloadFileAs, response.data.url)
    yield put<Action<string | Blob>>({
      type: FILE_ACTIONS.DOWNLOAD_FILE + COMMON_ACTIONS.SUCCESS,
      data: res.data
    })
  } catch (error) {
    yield put<Action<string | Blob>>({
      type: FILE_ACTIONS.DOWNLOAD_FILE + COMMON_ACTIONS.ERROR,
      data: '',
      error: error as string
    })
  }
}
function * deleteFile (action: Action<DeleteFileRequestParam>) {
  try {
    const response: AxiosResponse<DeleteFileResponse> = yield call(fileService.deleteFiles, action.data)
    yield put<Action<DeleteFileResponse>>({
      type: FILE_ACTIONS.DELETE_FILE + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
    const alert: AlertMessage = {
      message: response.data.message,
      options: {
        variant: ALERT_VARIANT.SUCCESS,
        autoHideDuration: 5000
      }
    }
    yield put<Action<AlertMessage>>({
      type: ALERT_ACTIONS.TRIGGER_ALERT_MESSAGE,
      data: alert
    })
  } catch (error) {
    yield put<Action<DeleteFileResponse>>({
      type: FILE_ACTIONS.DELETE_FILE + COMMON_ACTIONS.ERROR,
      data: {
        message: ''
      },
      error: error as string
    })
    const alert: AlertMessage = {
      message: error as string,
      options: {
        variant: ALERT_VARIANT.ERROR
      }
    }
    yield put<Action<AlertMessage>>({
      type: ALERT_ACTIONS.TRIGGER_ALERT_MESSAGE,
      data: alert
    })
  }
}

function * fileSagas () {
  yield takeEvery(FILE_ACTIONS.GET_FILE_UPLOAD_URL + COMMON_ACTIONS.REQUEST, getUploadFileURL)
  yield takeEvery(FILE_ACTIONS.SAVE_FILE_TO_S3 + COMMON_ACTIONS.REQUEST, saveFileToS3)
  yield takeEvery(FILE_ACTIONS.SAVE_FILE_TO_QUE + COMMON_ACTIONS.REQUEST, fileUpload)
  yield takeEvery(FILE_ACTIONS.DOWNLOAD_FILE + COMMON_ACTIONS.REQUEST, downloadFile)
  yield takeEvery(FILE_ACTIONS.DELETE_FILE + COMMON_ACTIONS.REQUEST, deleteFile)
}

export default fileSagas
