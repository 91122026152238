import {
  Card,
  CardContent,
  Typography,
  Divider,
  Skeleton,
  Chip,
  Grid,
  Box,
  CardActions,
  Button,
  Autocomplete,
  TextField
} from '@mui/material'
import React from 'react'
import {
  type DropdownOptionnDto,
  type DashboardGoalSummaryDto,
  type AppState,
  type GoalStatusList
} from '../../../interfaces'
import { APP_ROUTES, GOAL_SUMMARY_ROWS_COUNT, PRIORITY_TYPES } from '../../../utils/constants'
import styles from './DashboardGoalSummaryCard.module.scss'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

const DashboardGoalSummaryCard: React.FC<{
  data: DashboardGoalSummaryDto[]
  isLoading: boolean
  selectedStatus: DropdownOptionnDto
  onChange: (value: DropdownOptionnDto) => void
}> = ({
  data,
  isLoading,
  onChange,
  selectedStatus
}) => {
  const navigate = useNavigate()
  const goalStatusResponse = useSelector((state: AppState) => state.status.goalStatusList)
  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography variant="h5" fontWeight={400} padding={'0.5rem'} display={'flex'} justifyContent={'start'}>
              Goal Summary
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              value={selectedStatus}
              fullWidth
              size='small'
              options={goalStatusResponse?.data?.map((status: GoalStatusList) => {
                return { id: status.statusId, name: status.name }
              })}
              getOptionLabel={(option) => option.name}
              onChange={(event, value) => { onChange(value ?? { id: -1, name: '' }) }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Status"
                />
              )}
            />
          </Grid>
        </Grid>
        <Divider />
        {isLoading && Array.from({ length: GOAL_SUMMARY_ROWS_COUNT }).map((_, index) => (
          <div key={index}>
            <Grid container spacing={2} mt={'1rem'} mb={'1rem'}>
              <Grid item xs={7}>
                <Box display='flex' flexDirection='column' gap={1}>
                  <Box><Skeleton animation="wave" variant="rounded" width='100%' height='2rem' /></Box>
                  <Box><Skeleton animation="wave" variant="rounded" width='40%' height='2rem' /></Box>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box><Skeleton animation="wave" variant="rounded" width='100%' height='2rem' /></Box>
              </Grid>
              <Grid item xs={2}>
                <Box><Skeleton animation="wave" variant="rounded" width='100%' height='2rem' /></Box>
              </Grid>
            </Grid>
            {data.length - 1 !== index && <Divider />}
          </div>
        ))}
        {!isLoading && data.length > 0 && data.map((goal, index) => (
          <div key={index}>
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <Typography variant="h6" fontWeight={500} mt={'1rem'} className={styles.sessionName}>
                  {goal.name}
                </Typography>
                <Typography variant="subtitle1" fontWeight={500} color={'gray'} mb={'1rem'}>
                  {goal.status}
                </Typography>
              </Grid>
              <Grid item xs={3} display={'flex'} alignItems={'end'}>
                <Typography variant="subtitle1" fontWeight={500} color={'gray'} mb={'1rem'}>
                  {moment(goal.startDate).format('DD/MM/YYYY')} - {moment(goal.endDate).format('DD/MM/YYYY')}
                </Typography>
              </Grid>
              <Grid item xs={2} display={'flex'} alignItems={'end'} mb={'1rem'}>
                {goal.priority === PRIORITY_TYPES.HIGH && <Chip label={goal.priority} className='chip-danger' />}
                {goal.priority === PRIORITY_TYPES.MEDIUM && <Chip label={goal.priority} className='chip-warning' />}
                {goal.priority === PRIORITY_TYPES.LOW && <Chip label={goal.priority} className='chip-success' />}
              </Grid>
            </Grid>
            {data.length - 1 !== index && <Divider />}
          </div>
        ))}
        {!isLoading && data.length === 0 && (
          <div style={{ padding: '2rem', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
            <Typography variant="subtitle1" fontWeight={500} color={'gray'}>
              No goals to preview
            </Typography>
          </div>
        )}
        <Divider />
      </CardContent>
      <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button size="medium" onClick={() => { navigate(APP_ROUTES.GOALS) }}>See All Goals</Button>
      </CardActions>
    </Card>
  )
}

export default DashboardGoalSummaryCard
