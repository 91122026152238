/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'

import { useTheme } from '@mui/material/styles'
import SpaceDashboardOutlinedIcon from '@mui/icons-material/SpaceDashboardOutlined'
import PieChartOutlineIcon from '@mui/icons-material/PieChartOutline'
import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined'
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined'
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import { logo } from '../../assets'
import { useDispatch, useSelector } from 'react-redux'
import {
  Avatar,
  Box,
  CssBaseline,
  useMediaQuery
} from '@mui/material'

import AppLayoutHeader from '../AppLayoutHeader/AppLayoutHeader'
import { type BreadCrumb, type AppState } from '../../interfaces'
import { NavLink, useNavigate } from 'react-router-dom'
import { type IPublicClientApplication } from '@azure/msal-browser'
import { authActions } from '../../redux/actions'
import { useMsal } from '@azure/msal-react'
import { APP_ROUTES } from '../../utils/constants'
const AppLayout: React.FC<{
  children?: React.ReactNode
  breadCrumb: BreadCrumb[]
}> = ({ breadCrumb, children }) => {
  const { instance } = useMsal()
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(true)
  const [navClass, setNavClass] = useState('')
  const SideBarUpperIconWithNames = [
    {
      name: 'Dashboard',
      id: 1,
      icon: <SpaceDashboardOutlinedIcon />,
      url: '/dashboard'
    },
    {
      name: 'Goals',
      id: 2,
      icon: <PieChartOutlineIcon />,
      url: '/goals'
    },
    {
      name: 'Engagements',
      id: 3,
      icon: <LeaderboardOutlinedIcon />,
      url: '/engagements'
    }
    // {
    //   name: 'Explore Events',
    //   id: 4,
    //   icon: <PublicOutlinedIcon />,
    //   url: '/events'
    // }
  ]
  // const SideBarBottomIconWithNames = [
  //   {
  //     name: 'Notifications',
  //     id: 5,
  //     icon: <NotificationsActiveOutlinedIcon />,
  //     url: '/notifications'
  //   },
  //   {
  //     name: 'Support',
  //     id: 6,
  //     icon: <HelpOutlineOutlinedIcon />,
  //     url: 'support'
  //   },
  //   {
  //     name: 'Sign Out',
  //     id: 7,
  //     icon: <LogoutOutlinedIcon />,
  //     url: 'signout'
  //   }
  // ]

  const toggleSideNav = () => {
    setOpen(!open)
    if (!open) setNavClass('collapsed')
    else setNavClass('')
  }

  const matches = useMediaQuery('(max-width:900px)')

  useEffect(() => {
    if (matches) setNavClass('collapsed')
    else setNavClass('')
  }, [matches])

  const handleSignOut = (instance: IPublicClientApplication) => {
    dispatch(authActions.logout())
    instance.logoutRedirect().catch(e => {
      // eslint-disable-next-line no-console
      console.error('Sign-out Error', e)
    })
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <div className={'layout-row authorizedContainer'}>
        <aside className={`layout-row sideNavigation ${navClass}`}>
          <aside className={'navBar'}>
            <aside>
              <aside className={'logo-section'}>
                <div className={`menuBox ${navClass}`}>
                  <a className="menuIcon" onClick={toggleSideNav}>
                    <span></span>
                  </a>
                </div>
                <div className="contentGroup ">
                  <img className="logo" src={logo} />
                </div>
              </aside>
              {SideBarUpperIconWithNames.map((item: any) => (
                <aside key={item.id} className={'links'}>
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? 'layout-row is-active navLink' : 'layout-row navLink'
                    }
                    to={item.url}
                  >
                    <div className={'appLinkIcon'}>
                      {item.icon}
                    </div>
                    <div className={`navBarContent layout-row ${navClass}`}>
                      <span>{item.name}</span>
                    </div>
                  </NavLink>
                </aside>
              ))}
            </aside>
            <aside className={'sidebar-down'}>
              <aside>
                  {/* <aside className={'links'}>
                    <NavLink to={'/profile'} className={'layout-row'}>
                      <div className={'appLinkIcon'}>
                        <Avatar
                          alt="Remy Sharp"
                          variant="circular"
                          src="https://images.unsplash.com/photo-1619946794135-5bc917a27793?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9"
                        />
                      </div>
                      <div className={`navBarContent layout-row ${navClass}`}>
                        <span>
                          <Box>Brian Ford</Box>
                          <Box>brianford@lok.com</Box>
                        </span>
                      </div>
                    </NavLink>
                  </aside> */}
                  {/* {SideBarBottomIconWithNames.map((item: any) => (
                    <aside key={item.id} className={'links'}>
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? 'layout-row is-active navLink' : 'layout-row navLink'
                        }
                        to={item.url}
                      >
                        <div className={'appLinkIcon'}>
                          {item.icon}
                        </div>
                        <div className={`navBarContent layout-row ${navClass}`}>
                          <span>{item.name}</span>
                        </div>
                      </NavLink>
                    </aside>
                  ))} */}
                  <aside className={'links'}>
                    <a onClick={() => { handleSignOut(instance) }} className='layout-row navLink'>
                      <div className={'appLinkIcon'}>
                        <LogoutOutlinedIcon />
                      </div>
                      <div className={`navBarContent layout-row ${navClass}`}>
                        <span>Sign out</span>
                      </div>
                    </a>
                  </aside>
              </aside>
            </aside>
          </aside>
        </aside>
        <aside className={'content'}>
          <aside className="content2">
            <AppLayoutHeader breadCrumb={breadCrumb} />
          </aside>
          <aside className={'content3'}>{children}</aside>
        </aside>
      </div>
    </React.Fragment>
  )
}

export default AppLayout
