import { call, put, takeEvery } from 'redux-saga/effects'
import { ALERT_ACTIONS, ALERT_VARIANT, COMMON_ACTIONS, ENGAGEMENT_ACTIONS } from '../../utils/constants'
import {
  type EngagementCreateRequestPayload,
  type Action,
  type EngagementCreateResponse,
  type AlertMessage,
  type EngagementListResponse,
  type MyEngagementRequestPayload,
  type AllEngagementRequestPayload,
  type EngagementUpdateResponse,
  type EngagementUpdateRequestPayload,
  type GoalListData,
  type EngagementDetails,
  type EngagementDetailsRequestParam,
  type AllGoalsByEngagementIdRequestPayload,
  type GoalListSummeryResponse
} from '../../interfaces'
import { type AxiosResponse } from 'axios'
import { engagementService } from '../../services'

function * createEngagement (action: Action<EngagementCreateRequestPayload>) {
  try {
    const response: AxiosResponse<EngagementCreateResponse> = yield call(
      engagementService.createEngagement, action.data)
    yield put<Action<EngagementCreateResponse>>({
      type: ENGAGEMENT_ACTIONS.CREATE_ENGAGEMENT + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
    const alert: AlertMessage = {
      message: response.data.message,
      options: {
        variant: ALERT_VARIANT.SUCCESS,
        autoHideDuration: 5000
      }
    }
    yield put<Action<AlertMessage>>({
      type: ALERT_ACTIONS.TRIGGER_ALERT_MESSAGE,
      data: alert
    })
  } catch (e) {
    yield put<Action<EngagementCreateResponse>>({
      type: ENGAGEMENT_ACTIONS.CREATE_ENGAGEMENT + COMMON_ACTIONS.ERROR,
      data: { message: '' },
      error: e as string
    })
    const alert: AlertMessage = {
      message: e as string,
      options: {
        variant: ALERT_VARIANT.ERROR
      }
    }
    yield put<Action<AlertMessage>>({
      type: ALERT_ACTIONS.TRIGGER_ALERT_MESSAGE,
      data: alert
    })
  }
}
function * updateEngagement (action: Action<EngagementUpdateRequestPayload>) {
  try {
    const response: AxiosResponse<EngagementUpdateResponse> =
     yield call(engagementService.updateEngagement, action.data)
    yield put<Action<EngagementUpdateResponse>>({
      type: ENGAGEMENT_ACTIONS.UPDATE_ENGAGEMENT + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
    const alert: AlertMessage = {
      message: response.data.message,
      options: {
        variant: ALERT_VARIANT.SUCCESS,
        autoHideDuration: 5000
      }
    }
    yield put<Action<AlertMessage>>({
      type: ALERT_ACTIONS.TRIGGER_ALERT_MESSAGE,
      data: alert
    })
  } catch (e) {
    yield put<Action<EngagementUpdateResponse>>({
      type: ENGAGEMENT_ACTIONS.UPDATE_ENGAGEMENT + COMMON_ACTIONS.ERROR,
      data: { message: '' },
      error: e as string
    })
    const alert: AlertMessage = {
      message: e as string,
      options: {
        variant: ALERT_VARIANT.ERROR
      }
    }
    yield put<Action<AlertMessage>>({
      type: ALERT_ACTIONS.TRIGGER_ALERT_MESSAGE,
      data: alert
    })
  }
}

function * getMyEngagementListAsCoach (action: Action<MyEngagementRequestPayload>) {
  try {
    const response: AxiosResponse<EngagementListResponse> =
     yield call(engagementService.getMyEngagementList, action.data)
    yield put<Action<EngagementListResponse>>({
      type: ENGAGEMENT_ACTIONS.GET_MY_ENGAGEMENT_LIST_AS_COACH + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
  } catch (error) {
    yield put<Action<EngagementListResponse>>({
      type: ENGAGEMENT_ACTIONS.GET_MY_ENGAGEMENT_LIST_AS_COACH + COMMON_ACTIONS.ERROR,
      data: { count: 0, data: [] },
      error: error as string
    })
  }
}

function * getMyEngagementListAsCoachee (action: Action<MyEngagementRequestPayload>) {
  try {
    const response: AxiosResponse<EngagementListResponse> =
     yield call(engagementService.getMyEngagementList, action.data)
    yield put<Action<EngagementListResponse>>({
      type: ENGAGEMENT_ACTIONS.GET_MY_ENGAGEMENT_LIST_AS_COACHEE + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
  } catch (error) {
    yield put<Action<EngagementListResponse>>({
      type: ENGAGEMENT_ACTIONS.GET_MY_ENGAGEMENT_LIST_AS_COACHEE + COMMON_ACTIONS.ERROR,
      data: { count: 0, data: [] },
      error: error as string
    })
  }
}
function * getAllEngagementList (action: Action<AllEngagementRequestPayload>) {
  try {
    const response: AxiosResponse<EngagementListResponse> =
     yield call(engagementService.getAllEngagementList, action.data)
    yield put<Action<EngagementListResponse>>({
      type: ENGAGEMENT_ACTIONS.GET_ALL_ENGAGEMENT_LIST + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
  } catch (error) {
    yield put<Action<EngagementListResponse>>({
      type: ENGAGEMENT_ACTIONS.GET_ALL_ENGAGEMENT_LIST + COMMON_ACTIONS.ERROR,
      data: { count: 0, data: [] },
      error: error as string
    })
  }
}

function * getGoalsByEngagementById (action: Action<string>) {
  try {
    const response: Awaited<ReturnType<typeof engagementService.getGoalsByEngagementByIdList>> =
     yield call(engagementService.getGoalsByEngagementByIdList, action.data)
    yield put<Action<GoalListData[]>>({
      type: ENGAGEMENT_ACTIONS.GET_GOALS_BY_ENGAGEMENT_ID + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
  } catch (error) {
    yield put<Action<GoalListData[]>>({
      type: ENGAGEMENT_ACTIONS.GET_GOALS_BY_ENGAGEMENT_ID + COMMON_ACTIONS.ERROR,
      data: [],
      error: error as string
    })
  }
}

/*
  @Ishara Manage
  This function is used to retrive the All Goals (Assigned and accept both) List by engagement id
  used to retrive and set data in editEngagement screen
*/
function * getAllGoalsByEngagementById (action: Action<AllGoalsByEngagementIdRequestPayload>) {
  try {
    const response: Awaited<ReturnType<typeof engagementService.getAllGoalsByEngagementByIdList>> =
     yield call(engagementService.getAllGoalsByEngagementByIdList, action.data)
    yield put<Action<GoalListSummeryResponse>>({
      type: ENGAGEMENT_ACTIONS.GET_ALL_GOALS_BY_ENGAGEMENT_ID + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
  } catch (error) {
    yield put<Action<GoalListSummeryResponse>>({
      type: ENGAGEMENT_ACTIONS.GET_ALL_GOALS_BY_ENGAGEMENT_ID + COMMON_ACTIONS.ERROR,
      data: {
        count: -1,
        data: []
      },
      error: error as string
    })
  }
}

function * engagementDetails (action: Action<EngagementDetailsRequestParam>) {
  try {
    const response: Awaited<ReturnType<typeof engagementService.engagementDetails>> =
      yield call(engagementService.engagementDetails, action.data)
    yield put<Action<EngagementDetails>>({
      type: ENGAGEMENT_ACTIONS.GET_ENGAGEMENT_DETAILS + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
  } catch (error) {
    yield put<Action<EngagementDetails>>({
      type: ENGAGEMENT_ACTIONS.GET_ENGAGEMENT_DETAILS + COMMON_ACTIONS.ERROR,
      data: {
        _id: '',
        name: '',
        description: '',
        status: '',
        statusId: 0,
        startDate: '',
        endDate: '',
        createdBy: '',
        coaches: [],
        coachees: [],
        attachments: [],
        sessions: [],
        roles: []
      },
      error: error as string
    })
  }
}

function * engagementSagas () {
  yield takeEvery(ENGAGEMENT_ACTIONS.CREATE_ENGAGEMENT + COMMON_ACTIONS.REQUEST, createEngagement)
  yield takeEvery(
    ENGAGEMENT_ACTIONS.GET_MY_ENGAGEMENT_LIST_AS_COACH + COMMON_ACTIONS.REQUEST, getMyEngagementListAsCoach)
  yield takeEvery(
    ENGAGEMENT_ACTIONS.GET_MY_ENGAGEMENT_LIST_AS_COACHEE + COMMON_ACTIONS.REQUEST, getMyEngagementListAsCoachee)
  yield takeEvery(ENGAGEMENT_ACTIONS.GET_ALL_ENGAGEMENT_LIST + COMMON_ACTIONS.REQUEST, getAllEngagementList)
  yield takeEvery(ENGAGEMENT_ACTIONS.UPDATE_ENGAGEMENT + COMMON_ACTIONS.REQUEST, updateEngagement)
  yield takeEvery(ENGAGEMENT_ACTIONS.GET_GOALS_BY_ENGAGEMENT_ID + COMMON_ACTIONS.REQUEST, getGoalsByEngagementById)
  yield takeEvery(
    ENGAGEMENT_ACTIONS.GET_ALL_GOALS_BY_ENGAGEMENT_ID + COMMON_ACTIONS.REQUEST, getAllGoalsByEngagementById)
  yield takeEvery(ENGAGEMENT_ACTIONS.GET_ENGAGEMENT_DETAILS + COMMON_ACTIONS.REQUEST, engagementDetails)
}

export default engagementSagas
