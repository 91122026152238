/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import {
  Grid,
  IconButton,
  Typography
} from '@mui/material'
import { Box } from '@mui/system'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import SupervisedUserCircleRoundedIcon from '@mui/icons-material/SupervisedUserCircleRounded'
import { type EngagementListData, type AsyncStateObject, type EngagementListResponse } from '../../../interfaces'
import moment from 'moment'
import { ENGAGEMENT_TYPES } from '../../../utils/constants'
import { TableSkeleton } from '../../skeleton'
import { useParams } from 'react-router-dom'

const EngagementViewCard: React.FC<{
  engagementList: AsyncStateObject<EngagementListResponse>
  sessionStatus: (session: any) => any
  engagementType: ENGAGEMENT_TYPES
  pagination: React.ReactNode
  EditOptionEnable: (loggedUser: EngagementListData) => boolean
  handleEditClick: (engagementGoals: EngagementListData) => void
  viewEngagementById: (id: string) => void
  searchKey: string
}> = ({
  engagementList,
  sessionStatus,
  engagementType,
  pagination,
  EditOptionEnable,
  handleEditClick,
  viewEngagementById,
  searchKey
}) => {
  const { engagementId } = useParams<{ engagementId: string }>()
  const engagements = engagementList.data.data

  const engagementIsLoading = engagementList.isLoading

  return (
      <Card
        sx={{
          minWidth: 275,
          borderRadius: '1rem',
          border: '1px solid #DAE0E6',
          boxShadow: 'none'
        }}
      >
        {engagementIsLoading && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
            {/* <CircularProgress /> */}
            <TableSkeleton/>
          </div>
        )}
        {!engagementIsLoading && engagements.length > 0 &&
          engagements.map((engagement: EngagementListData, index: number) => (
            <CardContent key={index}
              sx={{
                borderBottom: '0.1rem solid #DAE0E6',
                boxShadow: 'none',
                marginBottom: '0rem',
                backgroundColor: engagementId === engagement._id ? '#F3F9FE' : 'white'
              }}
            >
              <Grid lg={12} md={12} xs={12} container display="flex" alignItems="center" >
                <Grid display="flex" item xs={12} md={4} lg={6}>
                  <Grid xs={12}>
                    <Typography
                      fontWeight="600"
                      variant="body1"
                      align="left"
                      color={engagementId === engagement._id ? '#437EF7' : 'black'}
                      style={{ wordWrap: 'break-word' }}
                    >
                      {engagement.name}
                    </Typography>
                    <Typography
                      fontWeight="400"
                      variant="body1"
                      align="left"
                      color={engagementId === engagement._id ? '#437EF7' : 'black'}
                      paddingTop="0.2rem"
                      style={{ wordWrap: 'break-word' }}
                    >
                      {(engagementType === ENGAGEMENT_TYPES.COACHEE || engagementType === ENGAGEMENT_TYPES.COACH)
                        ? sessionStatus(engagement.session ?? [])
                        : ''}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid xs={12} md={4} lg={3}
                  display="flex" justifyContent="space-between" item
                  sx={{ marginTop: { xs: '1rem', md: '1rem' } }} >
                  <Grid xs={12}>
                    <Typography
                      fontWeight="500"
                      color={engagementId === engagement._id ? '#437EF7' : 'black'}
                      sx={{ whiteSpace: 'nowrap' }}
                    >
                      {`${moment(engagement.startDate).format('DD/MM/YYYY')}
                    - ${moment(engagement.endDate).format('DD/MM/YYYY')}`}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid xs={10} md={4} lg={3}
                  display="flex" justifyContent="space-between"
                  alignItems={'center'} item sx={{ marginTop: { xs: '1rem' } }} >
                  <Grid xs={5} md={5} lg={3}>
                    <Typography
                      fontWeight="500"
                      sx={{ minWidth: '5rem' }}
                      color={engagementId === engagement._id ? '#437EF7' : 'black'}
                    >
                      {engagement.status}
                    </Typography>
                  </Grid>

                  <Grid xs={3} md={3} lg={2}>
                    <Box sx={{ display: 'flex', justifyContent: { xs: 'end', md: 'start', lg: 'start' } }}>
                      <Typography>
                        {engagement.coachees.length > 1
                          ? (
                            <SupervisedUserCircleRoundedIcon fontSize='large' />
                            )
                          : (
                            <AccountCircleIcon color='primary' fontSize='large' />
                            )}
                      </Typography>
                    </Box>
                  </Grid>
                  {ENGAGEMENT_TYPES.SUPERVISOR === engagementType &&
                    <Grid xs={1} md={2} lg={1} sx={{ marginRight: '-2rem' }}>
                      {EditOptionEnable(engagement)
                        ? (
                        <Box display='flex'>
                          <IconButton
                           onClick={() => { handleEditClick(engagement) }}>
                            <EditOutlinedIcon fontSize='small' />
                          </IconButton>
                        </Box>
                          )
                        : null}

                    </Grid>
                  }

                  <Grid xs={1} md={2} lg={1} sx={{ marginRight: '2rem' }}>
                    <Box display='flex'>
                      <IconButton onClick={() => { viewEngagementById(engagement._id) }}>
                        <VisibilityOutlinedIcon
                          fontSize='small'
                          sx={{ color: engagementId === engagement._id ? '#437EF7' : 'black' }}
                        />
                      </IconButton>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>

          ))}

        {!engagementIsLoading && engagements.length === 0 && (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
            <Card sx={{ border: '1px solid #648EF7', marginTop: '1rem', width: '50rem' }}>
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                gap={2}
                sx={{ padding: '1rem', backgroundColor: '#F5FAFF' }}>

                <Typography
                color='#5F6D7E'
                 >{searchKey.length > 0
                   ? 'No results found. Please try a different keyword.'
                   : 'There are no matching engagements'}
                 </Typography>
              </Box>
          </Card>
        </Box>
        )}
        <Box
          sx={{ height: '5rem' }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {pagination}
        </Box>
      </Card>
  )
}

export default EngagementViewCard
