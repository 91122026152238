/* eslint-disable @typescript-eslint/restrict-plus-operands */
import {
  type EngagementCreateRequestPayload,
  type Action,
  type MyEngagementRequestPayload,
  type AllEngagementRequestPayload,
  type EngagementUpdateRequestPayload,
  type EngagementDetailsRequestParam,
  type AllGoalsByEngagementIdRequestPayload
} from '../../interfaces'
import { COMMON_ACTIONS, ENGAGEMENT_ACTIONS } from '../../utils/constants'

const createEngagement = (payload: EngagementCreateRequestPayload): Action<EngagementCreateRequestPayload> => {
  return { type: ENGAGEMENT_ACTIONS.CREATE_ENGAGEMENT + COMMON_ACTIONS.REQUEST, data: payload }
}
const getMyEngagementListAsCoach = (payload: MyEngagementRequestPayload): Action<MyEngagementRequestPayload> => {
  return { type: ENGAGEMENT_ACTIONS.GET_MY_ENGAGEMENT_LIST_AS_COACH + COMMON_ACTIONS.REQUEST, data: payload }
}
const getMyEngagementListAsCoachee = (payload: MyEngagementRequestPayload): Action<MyEngagementRequestPayload> => {
  return { type: ENGAGEMENT_ACTIONS.GET_MY_ENGAGEMENT_LIST_AS_COACHEE + COMMON_ACTIONS.REQUEST, data: payload }
}
const getAllEngagementList = (payload: AllEngagementRequestPayload): Action<AllEngagementRequestPayload> => {
  return { type: ENGAGEMENT_ACTIONS.GET_ALL_ENGAGEMENT_LIST + COMMON_ACTIONS.REQUEST, data: payload }
}
const clearEngagementStatus = (): Action<null> => {
  return { type: ENGAGEMENT_ACTIONS.CREATE_ENGAGEMENT + COMMON_ACTIONS.CLEAR, data: null }
}
const clearCreateEngagement = (): Action<null> => {
  return { type: ENGAGEMENT_ACTIONS.CREATE_ENGAGEMENT + COMMON_ACTIONS.CLEAR, data: null }
}

const clearUpdateEngagement = (): Action<null> => {
  return { type: ENGAGEMENT_ACTIONS.UPDATE_ENGAGEMENT + COMMON_ACTIONS.CLEAR, data: null }
}
const updateEngagement = (payload: EngagementUpdateRequestPayload): Action<EngagementUpdateRequestPayload> => {
  return { type: ENGAGEMENT_ACTIONS.UPDATE_ENGAGEMENT + COMMON_ACTIONS.REQUEST, data: payload }
}

const clearGetEngagementDetailById = (): Action<null> => {
  return { type: ENGAGEMENT_ACTIONS.GET_ENGAGEMENT_DETAILS + COMMON_ACTIONS.CLEAR, data: null }
}

const getGoalsByEngagementId = (engagementId: string): Action<string> => {
  return { type: ENGAGEMENT_ACTIONS.GET_GOALS_BY_ENGAGEMENT_ID + COMMON_ACTIONS.REQUEST, data: engagementId }
}
const clearGetGoalsByEngagementId = (): Action<null> => {
  return { type: ENGAGEMENT_ACTIONS.GET_GOALS_BY_ENGAGEMENT_ID + COMMON_ACTIONS.CLEAR, data: null }
}
const engagementDetails = (param: EngagementDetailsRequestParam): Action<EngagementDetailsRequestParam> => {
  return { type: ENGAGEMENT_ACTIONS.GET_ENGAGEMENT_DETAILS + COMMON_ACTIONS.REQUEST, data: param }
}

/*
  @Ishara Manage
  This actions is used to retrive the All Goals (Assigned and accept both) List by engagement id
  used to retrive and set data in editEngagement screen
*/
const getAllGoalsByEngagementId = (param: AllGoalsByEngagementIdRequestPayload):
Action<AllGoalsByEngagementIdRequestPayload> => {
  return { type: ENGAGEMENT_ACTIONS.GET_ALL_GOALS_BY_ENGAGEMENT_ID + COMMON_ACTIONS.REQUEST, data: param }
}
const clearAllGetGoalsByEngagementId = (): Action<null> => {
  return { type: ENGAGEMENT_ACTIONS.GET_ALL_GOALS_BY_ENGAGEMENT_ID + COMMON_ACTIONS.CLEAR, data: null }
}

export const engagementsActions = {
  createEngagement,
  getMyEngagementListAsCoach,
  getMyEngagementListAsCoachee,
  getAllEngagementList,
  clearEngagementStatus,
  updateEngagement,
  clearGetEngagementDetailById,
  getGoalsByEngagementId,
  clearCreateEngagement,
  clearUpdateEngagement,
  clearGetGoalsByEngagementId,
  engagementDetails,
  getAllGoalsByEngagementId,
  clearAllGetGoalsByEngagementId
}
