import {
  Box,
  InputAdornment,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import React from 'react'

const EngagementGoalSelect: React.FC<{
  pagination: React.ReactNode
  onToggleButton: (event: React.MouseEvent<HTMLElement>, newAlignment: number) => void
  activeTab: number
  organizationLevelGoal?: React.ReactNode
  roleLevelGoal?: React.ReactNode
  userLevelGoal?: React.ReactNode
  hierarchyLevelGoals?: React.ReactNode
  selectedGoal: any[]
  searchGoalListSummary: (event: React.ChangeEvent<HTMLInputElement>) => void
  searchResult: string
  clearSearchResult: () => void

}> = ({
  selectedGoal,
  onToggleButton,
  activeTab,
  organizationLevelGoal,
  roleLevelGoal,
  userLevelGoal,
  hierarchyLevelGoals,
  pagination,
  searchGoalListSummary,
  searchResult,
  clearSearchResult
}) => {
  return (
      <Box sx={{ width: '100%' }}>
        <Box
          sx={{
            borderColor: 'divider',
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Box sx={{ width: '75%' }}>
            <ToggleButtonGroup
              value={activeTab}
              exclusive
              onChange={onToggleButton}
              aria-label="text alignment"
            >

              <ToggleButton
                value={1}
                aria-label="left aligned"
                size="medium"
                sx={{ textTransform: 'none' }}
                disabled={activeTab !== 1 && selectedGoal.length > 0}
              >
                Organization
              </ToggleButton>
              <ToggleButton
                value={2}
                aria-label="centered"
                size="medium"
                sx={{ textTransform: 'none', minWidth: '4rem' }}
                disabled={activeTab !== 2 && selectedGoal.length > 0}
              >
                Role
              </ToggleButton>
              <ToggleButton
                size="medium"
                sx={{ textTransform: 'none', minWidth: '4rem' }}
                value={3}
                aria-label="right aligned"
                disabled={activeTab !== 3 && selectedGoal.length > 0}
              >
                User
              </ToggleButton>
            </ToggleButtonGroup>

          </Box>
          <Box sx={{ width: '25%' }}>
            <TextField
              value={searchResult}
              onChange={(event: any) => { searchGoalListSummary(event) }}
              placeholder="Search"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    {(searchResult.length > 0)
                      ? (
                      <ClearIcon onClick={clearSearchResult} style={{ cursor: 'pointer' }} />
                        )
                      : (
                      <SearchIcon />
                        )}
                  </InputAdornment>
                )
              }}
              size="medium"
              sx={{ width: '100%' }}
            />
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <CheckBoxIcon color="disabled" sx={{ marginLeft: '1.5rem' }} />
          <Typography color="#919BA7" fontWeight="600">{selectedGoal.length} Item Selected</Typography>
        </Box>
        {hierarchyLevelGoals}
        <Box
          sx={{ height: '5rem' }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {pagination}
        </Box>
      </Box>

  )
}

export default EngagementGoalSelect
