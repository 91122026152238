/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Card, Grid, Typography, Divider, LinearProgress, TextField, Button, CardContent } from '@mui/material'
import React from 'react'
import Link from '@mui/material/Link'
import styles from './SessionAddMaterial.module.scss'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { type Videos, type Attachments, type SessionCreateFormState } from '../../../interfaces'
import { SESSION_ATTACHMENT_TYPE, SESSION_VIDEO_UPLOAD } from '../../../utils/constants'
import attachmentImageHandler from '../../../utils/helpers/attachmentImageHandler'

const SessionAddMaterial: React.FC<{
  isLoading: boolean
  myFiles: any
  updateFiles: Attachments[]
  openFileInNewTab: (index: number) => void
  removeFile: (file: any) => void
  removeFileFromUploaded: (file: Attachments) => void
  fileDownload: (id: string) => void
  getRootProps: any
  getInputProps: any
  open: any
  errorMessage?: string
  sessionGeneralDetailForm: SessionCreateFormState
  selectedAttachment: Attachments
  videoLinkHandleForm: (property: string, value: string) => void
  sessionVideo: Videos
  videoLinkHandleSubmit: () => void
  finalSessionVideo: any[]
  handleVideoDelete: (video: number) => void

}> = ({
  myFiles, updateFiles, openFileInNewTab, selectedAttachment,
  removeFile, getRootProps, getInputProps, open, errorMessage, isLoading,
  sessionGeneralDetailForm, fileDownload, removeFileFromUploaded,
  videoLinkHandleForm, sessionVideo,
  videoLinkHandleSubmit, finalSessionVideo, handleVideoDelete
}) => {
  const _myFiles = myFiles.map((file: any, index: number) => (
    <Grid key={file.path} item xs={12} md={4}>
      <Card>
        <CardContent sx={{ minHeight: '5rem' }}>
          <Box display='flex' flexDirection='column' gap='1rem'>
            <Box display='flex' justifyContent='center'>
              <Grid container display={'flex'} alignItems={'center'}>
                <Grid item xs={2}>
                  <img alt="Attachment" src={attachmentImageHandler(file.name)} />
                </Grid>
                <Grid item xs={10}>
                  <Box display='flex' flexDirection='column' alignItems='start'>
                    <Typography
                      className={styles.fileName}
                      justifyContent='center'
                      fontWeight={500}
                      fontSize='1rem'
                      sx={{ maxWidth: '350px' }}>
                      {file.name}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Divider sx={{ padding: 0, margin: 0 }} />
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
              <Grid container>
                <Grid item xs={10} />
                <Grid item xs={2} display='flex' justifyContent='space-between'>
                  <FileDownloadOutlinedIcon
                    id={styles.Icon}
                    onClick={() => { openFileInNewTab(index) }} />
                  <DeleteOutlineOutlinedIcon
                    id={styles.Icon}
                    color='action' onClick={() => { removeFile(file) }} />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  ))
  const _myUploadedFiles = updateFiles.map((file: Attachments, index: number) => (
    <Grid key={index} item xs={12} md={4}>
      <Card>
        <CardContent sx={{ minHeight: '5rem' }}>
          <Box display='flex' flexDirection='column' gap='1rem'>
            <Box display='flex' justifyContent='center'>
              <Grid container display={'flex'} alignItems={'center'}>
                <Grid item xs={2}>
                  <img alt="Attachment" src={attachmentImageHandler(file.attachmentTitle)} />
                </Grid>
                <Grid item xs={10}>
                  <Box display='flex' flexDirection='column' alignItems='start'>
                    <Typography
                      className={styles.fileName}
                      justifyContent='center'
                      fontWeight={500}
                      fontSize='1rem'
                      sx={{ maxWidth: '350px' }}>
                      {file.attachmentTitle}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            {!(isLoading && selectedAttachment.attachmentId === file.attachmentId) && <Divider />}
            {isLoading && selectedAttachment.attachmentId === file.attachmentId &&
              <Box sx={{ width: '100%' }}> <LinearProgress /> </Box>
            }
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
              <Grid container>
                <Grid item xs={10} />
                <Grid item xs={2} display='flex' justifyContent='space-between'>
                  <FileDownloadOutlinedIcon
                    id={styles.Icon}
                    onClick={() => { fileDownload(file.attachmentId ?? '') }} />
                  <DeleteOutlineOutlinedIcon
                    id={styles.Icon}
                    color='action' onClick={() => { removeFileFromUploaded(file) }} />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  ))

  return (
      <>
        <Typography fontWeight={500}
          sx={{ pb: 3 }} align="left">{sessionGeneralDetailForm.sessionTypes.value?.name}</Typography>
        <Box
          sx={{
            border: '1px dashed #DAE0E6',
            minHeight: '10rem',
            width: '100%'
          }}
        >
          {(sessionGeneralDetailForm.sessionTypes.value?.name === SESSION_ATTACHMENT_TYPE.VIDEO_LINK)
            ? <Box display='flex'
              justifyContent='center'
              {...getRootProps({
                sx: {
                  border: 'none',
                  backgroundColor: '#fff'
                }
              })}
            >
              <Grid container spacing={4} alignItems="center" sx={{ p: 2 }}>
                <Grid item xs={12} sm={5}>
                  <TextField placeholder="Enter Video title" variant="outlined" size="small" fullWidth
                    value={sessionVideo.title.value}
                    error={!(sessionVideo.title.error == null)}
                    helperText={sessionVideo.title.error}
                    required={sessionVideo.title.isRequired}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      videoLinkHandleForm(SESSION_VIDEO_UPLOAD.VIDEO_NAME, event?.target.value)
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <TextField placeholder="Add Video link" variant="outlined" size="small" fullWidth
                    value={sessionVideo.url.value}
                    error={!(sessionVideo.url.error == null)}
                    helperText={sessionVideo.url.error}
                    required={sessionVideo.url.isRequired}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      videoLinkHandleForm(SESSION_VIDEO_UPLOAD.VIDEO_LINK, event?.target.value)
                    }}
                  />
                </Grid>
                <Grid item>
                  <Button variant="contained"
                    onClick={videoLinkHandleSubmit} sx={{ textTransform: 'none' }} size="small" fullWidth>
                    Add Link
                  </Button>
                </Grid>
              </Grid>

            </Box>
            : <Box display='flex'
              justifyContent='center'
              {...getRootProps({
                sx: {
                  border: 'none',
                  backgroundColor: '#fff'
                }
              })}
            >
              <Box><input {...getInputProps()} /></Box>

              <Box display='flex' flexDirection='column'>
                <Box display='flex' justifyContent='center' marginTop='2rem'>
                  <AddCircleOutlineOutlinedIcon
                    onClick={open}
                    fontSize='large'
                    color='primary'
                    id={styles.addCircleIcon} // Apply the external SCSS class for hover styles
                  />
                </Box>
                <Typography display='flex' justifyContent='center' fontWeight={600} variant="h6"
                  component="p">
                  Drop your files here
                </Typography>
                <Box display='flex' justifyContent='center' alignItems={'center'}>
                  <Typography variant="h6" component="p">
                    <Link underline="hover" onClick={open} sx={{ cursor: 'pointer' }}>
                      Browse Files
                    </Link>
                  </Typography>
                  <Typography marginLeft='1rem'>
                    from your computer
                  </Typography>
                </Box>
                <Box display='flex' justifyContent='center' marginBottom='2rem'>
                  <Typography fontWeight={600} color='text.secondary'>
                    Supported file formats are .docx, .pdf, .xlsx, .pptx, .csv, .zip, .rar and maximum file size is 20MB
                  </Typography>
                </Box>
              </Box>
            </Box>
          }

        </Box>
        {(sessionGeneralDetailForm.sessionTypes.value?.name === SESSION_ATTACHMENT_TYPE.VIDEO_LINK)
          ? (<Grid container spacing={2} sx={{ p: 3 }}>
            {finalSessionVideo.map((value, index) => (
              <Grid container item spacing={2} key={index}>
                <Grid item xs={4}>
                  {value.title}
                </Grid>
                <Grid item xs={4}>
                  <a href={value.url} target="_blank" rel="noopener noreferrer">
                    {value.url}
                  </a>
                </Grid>
                <Grid item xs={2}>
                  <Button variant="contained" size="small"
                    onClick={() => { handleVideoDelete(index) }}>Delete</Button>
                </Grid>
              </Grid>
            ))}
          </Grid>)
          : <Grid container spacing={2} mt={2}>
            {_myUploadedFiles}{_myFiles}
          </Grid>
        }

        {(errorMessage != null)
          ? <Typography display='flex' color='red'
            justifyContent='center' fontWeight={600} variant="h6" component="p">
            {errorMessage}
          </Typography>
          : ''}
      </>
  )
}

export default SessionAddMaterial
