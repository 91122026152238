/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { Box, Card, CardContent, Grid, Link, Typography } from '@mui/material'
import React from 'react'
import { Doughnut } from 'react-chartjs-2'
import styles from './FeedbackGroupViewCard.module.scss'
import { GROUP_FEEDBACK } from '../../../utils/constants/feedback'
import { type ViewGroupFeedbackQnA } from '../../../interfaces/feedBack'

const FeedbackGroupViewCard: React.FC<{
  question: ViewGroupFeedbackQnA
  feedbackCommentViewer: (index: number) => void
}> = ({
  question,
  feedbackCommentViewer
}) => {
  const getColorList = () => {
    return question.options.sort((a, b) => a.optionOrder - b.optionOrder)
      .map((option) => colorByRate(option.optionOrder))
  }
  const getLabelList = () => {
    return question.options.sort((a, b) => a.optionOrder - b.optionOrder)
      .map((option) => nameByRate(option.optionOrder))
  }

  const colorByRate = (order: number) => {
    return GROUP_FEEDBACK.find((rate) => rate.ID === order)?.COLOR ?? ''
  }

  const nameByRate = (order: number) => {
    return GROUP_FEEDBACK.find((rate) => rate.ID === order)?.NAME ?? ''
  }

  const _data = {
    labels: getLabelList(),
    datasets: [
      {
        label: 'No of Picks',
        data: question.options.sort((a, b) => a.optionOrder - b.optionOrder).map((option) => option.totalPicks),
        backgroundColor: getColorList(),
        borderColor: getColorList(),
        borderWidth: 1
      }
    ]
  }

  const options = {
    plugins: {
      legend: {
        display: false // Hide the legend
      },
      tooltip: {
        enabled: true // Disable the tooltip
      },
      datalabels: {
        display: false // Hide the label details
      }
    },
    elements: {
      arc: {
        borderWidth: 50,
        borderRadius: (context: { dataIndex: number }) => {
          // Set the border radius based on the label index
          return context.dataIndex === 2 ? 0 : 0
        }
      }
    },
    maintainAspectRatio: true
  }

  const textCenter = {
    id: 'textCenter',
    beforeDatasetsDraw (chart: any, args: any, pluginOptions: any) {
      const { ctx } = chart

      ctx.save()
      ctx.font = 'bolder 2rem sans-serif'
      ctx.fillStyle = 'black'
      ctx.textAlign = 'center'
      ctx.fillText('Total', chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y)
    }
  }
  const textBottom = {
    id: 'textCenter',
    beforeDatasetsDraw (chart: any, args: any, pluginOptions: any) {
      const { ctx } = chart
      ctx.save()
      ctx.font = 'bolder 1rem sans-serif'
      ctx.fillStyle = 'black'
      ctx.textAlign = 'center'
      ctx.fillText(question.total, chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y + 30)
    }
  }

  return (
    <Card>
      <CardContent>
        <Box borderBottom={'1px solid rgba(0, 0, 0, 0.12)'} display={'flex'} alignItems={'center'}>
          <Typography fontWeight="600" variant='subtitle1'>
              {question.questionOrder}.
          </Typography>
          <Typography fontWeight="500" variant='subtitle1'>
              {question.questionLabel}
          </Typography>
        </Box>
        <CardContent className={styles.chartRoot}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={12} lg={5}>
              <Doughnut data={_data} options={options} plugins={[textCenter, textBottom]}/>
            </Grid>
            <Grid item xs={12} md={12} lg={3} display={'flex'} flexDirection={'column'}>
              {question.options.sort((a, b) => a.optionOrder - b.optionOrder).map((option, index) =>
                <Grid
                  item
                  key={option.optionOrder}
                  xs={12}
                  display={'flex'}
                  alignItems={'center'}
                >
                    <Box
                      padding={'1rem'}
                      borderBottom={'1px solid rgba(0, 0, 0, 0.12)'}
                      width={'100%'}
                      display={'flex'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                    >
                      <Box
                        paddingLeft={'1rem'}
                        borderLeft={`4.5px solid ${colorByRate(option.optionOrder)}`}
                      > {GROUP_FEEDBACK.find((rate) => rate.ID === option.optionOrder)?.NAME} </Box>
                      <Box> {option.totalPicks} </Box>
                    </Box>
                </Grid>
              )}
            </Grid>
          </Grid>
        </CardContent>
        <Box
          borderTop={'1px solid rgba(0, 0, 0, 0.12)'}
          pt={'1rem'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography fontWeight="600" variant='subtitle1'>
              All Feedbacks for the question
          </Typography>
          <Link onClick={() => { feedbackCommentViewer(question.questionOrder) }} sx={{ cursor: 'pointer' }}>
            <Typography
              color='primary'
              fontSize='1rem'
              align="right"
              fontWeight={600}>View</Typography>
          </Link>
        </Box>
      </CardContent>
    </Card>
  )
}

export default FeedbackGroupViewCard
