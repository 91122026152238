import React from 'react'
import {
  Autocomplete,
  Box,
  FormControl,
  Grid,
  InputLabel,
  TextField,
  Typography
} from '@mui/material'
import { type PriorityList, type GoalCreteFormState } from '../../../interfaces'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import moment from 'moment'

const GoalGeneralDetailsForm: React.FC<{
  priorityList: PriorityList[]
  formData: GoalCreteFormState
  onInputHandleForm: (property: string, value: any) => void
}> = (props) => {
  const {
    priorityList,
    formData,
    onInputHandleForm
  } = props

  const onAnyTextFieldChanged = (e: any) => {
    if (e?.preventDefault === true) {
      e?.preventDefault()
      e?.stopPropagation()
    }
  }
  return (
    <>
      <Box sx={{ width: '100%' }}>
        <form>
          <FormControl sx={{ width: '100%' }}>
            <Box sx={{ marginBottom: '3.5rem' }}>
              <InputLabel
                sx={{ fontWeight: '600' }}
                variant="standard"
                htmlFor="uncontrolled-native"
              >
                <Box display='flex'><Typography fontWeight={600}>Goal Name</Typography>
                 <Typography color='red'>*</Typography></Box>
              </InputLabel>
            </Box>
            <TextField
              placeholder="Enter Goal Name"
              sx={{ width: '100%' }}
              id="outlined-basic"
              variant="outlined"
              value={formData.goalName.value}
              disabled={formData.goalName.disable}
              error={!(formData.goalName.error == null)}
              required={formData.goalName.isRequired}
              helperText={formData.goalName.error}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                onInputHandleForm('goalName', event.target.value)
              }}
              inputProps={{ maxLength: 100 }}
            />
          </FormControl>
          <FormControl sx={{ width: '100%' }}>
            <Box sx={{ backgroundColor: 'blue', marginBottom: '3.5rem' }}>
              <InputLabel
                sx={{ fontWeight: '600' }}
                variant="standard"
                htmlFor="uncontrolled-native"
              >
                <Box display='flex'><Typography fontWeight={600}>Goal Description</Typography>
                  <Typography color='red'>*</Typography></Box>
              </InputLabel>
            </Box>
            <TextField
              multiline
              rows={5}
              fullWidth
              placeholder="Enter Goal Description"
              value={formData.goalDescription.value}
              disabled={formData.goalDescription.disable}
              error={!(formData.goalDescription.error == null)}
              required={formData.goalDescription.isRequired}
              helperText={formData.goalDescription.error}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                onInputHandleForm('goalDescription', event.target.value)
              }}
              inputProps={{ maxLength: 255 }}
            />
          </FormControl>
          <FormControl
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              gap: '2rem'
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <FormControl sx={{ width: '100%' }}>
                  <Box>
                    <Box sx={{ width: '100%', marginBottom: '3.5rem' }}>
                      <InputLabel
                        shrink={false}
                        sx={{ fontWeight: '600' }}
                        variant="standard"
                        htmlFor="uncontrolled-native"
                      >
                        <Box display='flex'><Typography fontWeight={600}>
                          Priority
                        </Typography>
                        <Typography color='red'>*</Typography></Box>
                      </InputLabel>
                    </Box>
                    <Box>
                      <Autocomplete
                        value={formData.priority.value}
                        fullWidth
                        options={priorityList.map((priority: PriorityList) => {
                          return { name: priority.name, id: priority.priorityId }
                        })}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) => { onInputHandleForm('priority', value) }
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            disabled={formData.priority.disable}
                            error={!(formData.priority.error == null)}
                            required={formData.priority.isRequired}
                            helperText={formData.priority.error}
                            placeholder="Select Priority"
                          />
                        )}
                      />
                    </Box>
                  </Box>
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={3}>
                <FormControl sx={{ width: '100%' }}>
                  <Box sx={{ width: '100%', marginBottom: '3.5rem' }}>
                    <InputLabel shrink={false} sx={{ fontWeight: '600' }} variant="standard"
                      htmlFor="uncontrolled-native">
                      <Box display='flex'>
                        <Typography fontWeight={600}>Start Date</Typography>
                        <Typography color='red'>*</Typography>
                      </Box>
                    </InputLabel>
                  </Box>
                  <Box sx={{ width: '100%' }} >
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DemoContainer sx={{ paddingTop: 0 }} components={['DatePicker']}>
                        <DatePicker
                          sx={{ width: '100%' }}
                          value={formData.startDate.value}
                          minDate={moment()}
                          maxDate={formData.endDate.value}
                          onChange={(newValue) => { onInputHandleForm('startDate', newValue) }
                          }
                          slotProps={{
                            textField: {
                              error: formData.startDate.error !== null,
                              helperText: formData.startDate.error,
                              onBeforeInput: onAnyTextFieldChanged,
                              InputProps: {
                                readOnly: true
                              }
                            }
                          }}

                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Box>
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={3}>
                <FormControl sx={{ width: '100%' }}>
                  <Box sx={{ width: '100%', marginBottom: '3.5rem' }}>
                    <InputLabel shrink={false} sx={{ fontWeight: '600' }}
                      variant="standard" htmlFor="uncontrolled-native">
                      <Box display='flex'>
                        <Typography fontWeight={600}>End Date</Typography>
                        <Typography color='red'>*</Typography>
                      </Box>
                    </InputLabel>
                  </Box>
                  <Box sx={{ width: '100%' }} >
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DemoContainer sx={{ paddingTop: 0 }} components={['DatePicker']}>
                        <DatePicker
                          sx={{ width: '100%' }}
                          value={formData.endDate.value}
                          minDate={formData.startDate.value}
                          onChange={(newValue) => { onInputHandleForm('endDate', newValue) }
                          }
                          slotProps={{
                            textField: {
                              error: formData.endDate.error !== null,
                              helperText: formData.endDate.error,
                              onBeforeInput: onAnyTextFieldChanged,
                              InputProps: {
                                readOnly: true
                              }
                            }
                          }}

                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Box>
                </FormControl>
              </Grid>
            </Grid>

          </FormControl>
        </form>
      </Box>
    </>
  )
}

export default GoalGeneralDetailsForm
