import { Pagination, PaginationItem, Typography } from '@mui/material'
import React from 'react'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

const AppPagination: React.FC<{
  page: number
  count: number
  handleChangePage: (event: React.ChangeEvent<unknown>, value: number) => void
}> = ({
  page,
  count,
  handleChangePage
}) => {
  return (
        <React.Fragment>
            <Pagination
                count={count}
                onChange={handleChangePage}
                page={page}
                renderItem={(item) => (
                    <PaginationItem
                        slots={{
                          previous: () => (
                                <>
                                    <ArrowBackIcon
                                        style={{
                                          fontSize: '1rem',
                                          margin: '0 0.2rem 0 0.2rem'
                                        }}
                                    />
                                    <Typography fontSize="1rem">Prev</Typography>
                                </>
                          ),
                          next: () => (
                                <>
                                    <Typography
                                        style={{
                                          fontSize: '1rem',
                                          margin: '0 0.2rem 0 0.2rem'
                                        }}
                                    >
                                        Next
                                    </Typography>
                                    <ArrowForwardIcon style={{ fontSize: '1rem' }} />
                                </>
                          )
                        }}
                        {...item}
                    />
                )}
            />
        </React.Fragment>
  )
}

export default AppPagination
