import { combineReducers } from 'redux'
import { type AuthState } from '../../../interfaces/auth'
import userAuthorizingReducer from './userAuthorizing.reducer'
import authorizedUserReducer from './authorizedUser.reducer'
import authorizedUserPermissionsReducer from './authorizedUserPermission.reducer'

const authReducer = combineReducers<AuthState>({
  userAuthorizing: userAuthorizingReducer,
  authorizedUser: authorizedUserReducer,
  authorizedUserPermission: authorizedUserPermissionsReducer
})

export default authReducer
