/* eslint-disable max-len */
import { type AxiosResponse } from 'axios'
import {
  type AssessmentDetailsParams,
  type AssessmentDetails,
  type AssessmentSubmitPayload,
  type AssessmentResultDetails,
  type AssessmentResultPathParams
} from '../interfaces/assessment'
import { axiosPrivateAPI } from '.'
import { type ErrorResponse } from '../interfaces'

/*
Load Assessment API
*/
const assessmentDetails = async (assessmentDetailsParams: AssessmentDetailsParams):
Promise<AxiosResponse<AssessmentDetails>> => {
  return await axiosPrivateAPI.get(`/api/v1/sessions/${assessmentDetailsParams.id}/assessments/${assessmentDetailsParams.assessmentId}`)
}

/*
Submit Assessment API
*/

const assessmentSubmit = async (payload: AssessmentSubmitPayload): Promise<AxiosResponse<ErrorResponse>> => {
  return await axiosPrivateAPI.post(`/api/v1/sessions/${payload.sessionId}/sessionCoachee/${payload.coacheeId}/assessments/${payload.assessmentId}`, payload.requestBody)
}

/*
Load Assessment Results API
*/

const assessmentResultDetails = async (params: AssessmentResultPathParams): Promise<AxiosResponse<AssessmentResultDetails>> => {
  return await axiosPrivateAPI.get(`/api/v1/sessions/${params.id}/sessionCoachee/${params.coacheeId}/assessments/${params.assessmentId}`)
}
export const assessmentService = {
  assessmentDetails,
  assessmentSubmit,
  assessmentResultDetails
}
