/* eslint-disable @typescript-eslint/restrict-plus-operands */

/*
  @Ishara Manage
  This reducer is used to store the All Goals (Assigned and accept both) List by engagement id
  used to retrive and set data in editEngagement screen
*/

import {
  type GoalListSummeryResponse,
  type Action, type AsyncStateObject
} from '../../../interfaces'
import { APP_ACTION_STATUS, COMMON_ACTIONS, ENGAGEMENT_ACTIONS } from '../../../utils/constants'

const INITIAL_STATE: AsyncStateObject<GoalListSummeryResponse> = {
  data: { count: 0, data: [] },
  isLoading: false,
  status: APP_ACTION_STATUS.INITIAL,
  error: undefined
}

const getAllGoalsByEngagementByIdReducer = (state = INITIAL_STATE, action: Action<GoalListSummeryResponse>) => {
  switch (action.type) {
    case ENGAGEMENT_ACTIONS.GET_ALL_GOALS_BY_ENGAGEMENT_ID + COMMON_ACTIONS.REQUEST:
      return {
        ...state,
        isLoading: true,
        status: APP_ACTION_STATUS.LOADING,
        error: undefined
      }
    case ENGAGEMENT_ACTIONS.GET_ALL_GOALS_BY_ENGAGEMENT_ID + COMMON_ACTIONS.SUCCESS:
      return {
        ...state,
        isLoading: false,
        status: APP_ACTION_STATUS.SUCCESS,
        data: action.data
      }
    case ENGAGEMENT_ACTIONS.GET_ALL_GOALS_BY_ENGAGEMENT_ID + COMMON_ACTIONS.ERROR:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        status: APP_ACTION_STATUS.ERROR,
        error: action.error
      }
    case ENGAGEMENT_ACTIONS.GET_ALL_GOALS_BY_ENGAGEMENT_ID + COMMON_ACTIONS.CLEAR:
      return INITIAL_STATE
    default:
      return state
  }
}

export default getAllGoalsByEngagementByIdReducer
