import { type AxiosResponse } from 'axios'
import { call, put, takeEvery } from 'redux-saga/effects'
import { usersService } from '../../services'
import { COMMON_ACTIONS, USERS_ACTION_TYPE } from '../../utils/constants'
import { type Action } from '../../interfaces'
import { type FilteredCoacheListDto, type GetAllCoachesParam } from '../../interfaces/users'

function * getAllCoachesList (action: Action<GetAllCoachesParam>) {
  try {
    const response: AxiosResponse<FilteredCoacheListDto> = yield call(
      usersService.getAllCoachesList, action.data)
    yield put<Action<FilteredCoacheListDto>>({
      type: USERS_ACTION_TYPE.GET_ALL_COACHES + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
  } catch (e) {
    yield put<Action<FilteredCoacheListDto | null>>({
      type: USERS_ACTION_TYPE.GET_ALL_COACHES + COMMON_ACTIONS.ERROR,
      error: e as string,
      data: null
    })
  }
}

function * userSagas () {
  yield takeEvery(USERS_ACTION_TYPE.GET_ALL_COACHES + COMMON_ACTIONS.REQUEST, getAllCoachesList)
}

export default userSagas
