import * as React from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import Button from '@mui/material/Button'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  Typography
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CloseIcon from '@mui/icons-material/Close'
import { type PriorityList, type GoalStatusList } from '../../../interfaces'

const GoalViewDrawer: React.FC<{
  toggleDrawer: (open: boolean) => any
  drawerState: boolean
  goalStatus: GoalStatusList[]
  priorityList: PriorityList[]
  statusIds: number[]
  priorityIds: number[]
  handleFilterStatus: (event: React.ChangeEvent<HTMLInputElement>, statusId: number) => void
  handleFilterPriority: (event: React.ChangeEvent<HTMLInputElement>, priorityId: number) => void
  disableContinueButton: boolean
  resetSelectedFilters: () => void
  filterGoalsByStatusOrPriority: () => void
}> = ({
  toggleDrawer,
  drawerState,
  goalStatus,
  priorityList,
  statusIds,
  priorityIds,
  handleFilterStatus,
  handleFilterPriority,
  disableContinueButton,
  resetSelectedFilters,
  filterGoalsByStatusOrPriority
}) => {
  return (
      <Box className="testCard">
        <React.Fragment key="right">
          <Drawer
            sx={{ zIndex: '1202' }}
            anchor="right"
            open={drawerState}
            onClose={toggleDrawer(false)}
          >
            <Box
              sx={{ width: 350 }}
              role="presentation"
              onKeyDown={() => toggleDrawer(false)}
            >
              <Card sx={{ boxShadow: 'none' }} onKeyDown={toggleDrawer(false)}>
                <CardContent
                  sx={{
                    boxShadow: 'none',
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <Typography fontWeight='700' variant="h5" sx={{ fontSize: 'medium', py: 1 }}>
                    User Filters
                  </Typography>

                  <IconButton>
                    <CloseIcon onClick={resetSelectedFilters} />
                  </IconButton>
                </CardContent>
                <Divider />
                <Card sx={{ boxShadow: 'none' }}>
                  <CardContent>
                    <Accordion
                      defaultExpanded
                      sx={{
                        border: '1px solid #EAEBF0',
                        boxShadow: 'none'
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Box>
                          <Typography fontWeight='600'>Status</Typography>
                        </Box>
                      </AccordionSummary>
                      <Divider />
                      <AccordionDetails >
                        {goalStatus.length > 0 && goalStatus.map((status: GoalStatusList, index: number) => (
                          < FormGroup key={index} >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={statusIds.length > 0 &&
                                    statusIds.some((statusId: number) => statusId === status.statusId)}
                                  onChange={(event) => { handleFilterStatus(event, status.statusId) }}
                                />
                              }
                              label={status.name}
                            />
                          </FormGroup>
                        ))
                        }
                      </AccordionDetails>
                    </Accordion>
                  </CardContent>
                </Card>
                <Card>
                  <CardContent>
                    <Accordion
                      defaultExpanded
                      sx={{
                        border: '1px solid #EAEBF0',
                        boxShadow: 'none',
                        py: 0
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Box display="flex" justifyContent="space-between" gap="0rem">
                          <Typography fontWeight='600'>Priority</Typography>
                        </Box>
                      </AccordionSummary>
                      <Divider />
                      <AccordionDetails>
                        {priorityList.length > 0 && priorityList.map((priority: PriorityList, index: number) => (
                          <FormGroup key={index}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={priorityIds.length > 0 &&
                                    priorityIds.some((priorityId: number) => priorityId === priority.priorityId)}
                                  onChange={(event) => { handleFilterPriority(event, priority.priorityId) }}
                                />
                              }
                              label={priority.name} />
                          </FormGroup>
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  </CardContent>
                </Card>
              </Card>
              <Divider sx={{ marginTop: '2rem' }} />
              <Box
              sx={{
                position: 'sticky',
                bottom: 0,
                left: 0,
                right: 0,
                display: 'flex',
                justifyContent: 'space-between',
                padding: '1rem',
                backgroundColor: '#FFF'
              }}
              >
                <Button
                  color="secondary"
                  size="medium"
                  variant="contained"
                  sx={{ textTransform: 'none' }}
                  onClick={resetSelectedFilters}
                >
                  Cancel
                </Button>
                <Button
                  sx={{ textTransform: 'none' }}
                  variant="contained"
                  onClick={filterGoalsByStatusOrPriority}
                >
                  Continue
                </Button>
              </Box>
            </Box>
          </Drawer>
        </React.Fragment>
      </Box >
  )
}

export default GoalViewDrawer
