import { Box, Card, CardContent, Chip, Divider, Grid, Skeleton, Typography } from '@mui/material'
import React from 'react'
import styles from './DashboardSessionCard.module.scss'
import { type DashboardSessionSummaryDto } from '../../../interfaces'
import { SESSION_STATUS, SESSION_SUMMARY_ROWS_COUNT, getEngagementViewBySessionRoute } from '../../../utils/constants'
import { useNavigate } from 'react-router-dom'

const DashboardSessionCard: React.FC<{
  data: DashboardSessionSummaryDto[]
  isLoading: boolean
  type: string
}> = ({
  data,
  isLoading,
  type
}) => {
  const navigate = useNavigate()
  const navigatTO = (session: DashboardSessionSummaryDto) => {
    navigate(getEngagementViewBySessionRoute(session.engagementId, type, session.sessionId))
  }
  return (
    <Card>
      <CardContent>
        <Typography variant="h5" fontWeight={400} padding={'0.5rem'} display={'flex'} justifyContent={'center'}>
          Recent Sessions
        </Typography>
        <Divider />
        {isLoading && Array.from({ length: SESSION_SUMMARY_ROWS_COUNT }).map((item, index) => (
          <Box key={index} display='flex' flexDirection='column' gap={1} mt={'0.5rem'}>
            <Box><Skeleton animation="wave" variant="rounded" width='100%' height='2rem' /></Box>
            <Grid container spacing={2}>
              <Grid item md={8}>
                <Box><Skeleton animation="wave" variant="rounded" width='100%' height='1.5rem' /></Box>
              </Grid>
              <Grid item md={2}></Grid>
              <Grid item md={2}>
                <Box><Skeleton animation="wave" variant="rounded" width='100%' height='1.5rem' /></Box>
              </Grid>
            </Grid>
            {data.length - 1 !== index && <Divider />}
          </Box>
        ))}
        {!isLoading && data.length > 0 && data.map((session, index) => (
          <div key={index}>
            <Typography
              onClick={() => { navigatTO(session) }}
              variant="h6"
              fontWeight={500}
              mt={'1rem'}
              className={styles.sessionName}
            >
              {session.name}
            </Typography>
            <div
              style={{ paddingBottom: '5px', alignItems: 'center', justifyContent: 'space-between' }}
              className='layout-row'
            >
              <Typography variant="subtitle1" fontWeight={500} color={'gray'}>
                {session.sessionType} * {session.sessionDuration}
              </Typography>
              {session.status === SESSION_STATUS.IN_PROGRESS &&
                <Chip label={session.status} className='chip-warning' />
              }
              {session.status === SESSION_STATUS.COMPLETED &&
                <Chip label={session.status} className='chip-success' />
              }
              {session.status === SESSION_STATUS.NEW &&
                <Chip label={session.status} className='chip-primary' />
              }
              {session.status === SESSION_STATUS.EXPIRED &&
                <Chip label={session.status} className='chip-danger' />
              }
            </div>
            {data.length - 1 !== index && <Divider />}
          </div>
        ))}
        {!isLoading && data.length === 0 && (
          <div style={{ padding: '2rem', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
            <Typography variant="subtitle1" fontWeight={500} color={'gray'}>
              No sessions to preview
            </Typography>
          </div>
        )}
      </CardContent>
    </Card>
  )
}

export default DashboardSessionCard
