import { type Action, type AsyncStateObject, type FeedBackCreateResponse } from '../../../interfaces'
import { APP_ACTION_STATUS, COMMON_ACTIONS, SESSION_LIST_ACTION } from '../../../utils/constants'

const INITIAL_STATE: AsyncStateObject<FeedBackCreateResponse> = {
  data: { message: '' },
  isLoading: false,
  status: APP_ACTION_STATUS.INITIAL,
  error: undefined

}

const createSessionReducer = (state = INITIAL_STATE, action: Action<FeedBackCreateResponse>) => {
  switch (action.type) {
    case SESSION_LIST_ACTION.CREATE_SESSION + COMMON_ACTIONS.REQUEST:
      return {
        ...state,
        isLoading: true,
        status: APP_ACTION_STATUS.LOADING,
        error: undefined
      }
    case SESSION_LIST_ACTION.CREATE_SESSION + COMMON_ACTIONS.SUCCESS:
      return {
        ...state,
        isLoading: false,
        status: APP_ACTION_STATUS.SUCCESS,
        data: action.data
      }
    case SESSION_LIST_ACTION.CREATE_SESSION + COMMON_ACTIONS.ERROR:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        status: APP_ACTION_STATUS.ERROR,
        error: action.error
      }
    case SESSION_LIST_ACTION.CREATE_SESSION + COMMON_ACTIONS.CLEAR:
      return INITIAL_STATE
    default:
      return state
  }
}

export default createSessionReducer
