import { call, put, takeEvery } from 'redux-saga/effects'
import { ALERT_ACTIONS, ALERT_VARIANT, COMMON_ACTIONS, GOAL_ACTIONS } from '../../utils/constants'
import {
  type Action,
  type GoalCreateRequestPayload,
  type GoalListResponse,
  type GoalStatusResponse,
  type StatusUpdatePayLoad,
  type GoalListData, type GoalUpdateRequestPayload,
  type GoalCreatAndUpdateResponse, type MyGoalsRequestPayload,
  type AllGoalRequestPayload, type AlertMessage,
  type GoalListSummeryRequestParams, type GoalListSummeryResponse
} from '../../interfaces'
import { goalService } from '../../services'
import { type AxiosError, type AxiosResponse } from 'axios'

function * createGoal (action: Action<GoalCreateRequestPayload>) {
  try {
    const response: AxiosResponse<GoalCreatAndUpdateResponse> = yield call(goalService.createGoal, action.data)
    yield put<Action<GoalCreatAndUpdateResponse>>({
      type: GOAL_ACTIONS.CREATE_GOAL + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
    const alert: AlertMessage = {
      message: response.data.message,
      options: {
        variant: ALERT_VARIANT.SUCCESS,
        autoHideDuration: 5000
      }
    }
    yield put<Action<AlertMessage>>({
      type: ALERT_ACTIONS.TRIGGER_ALERT_MESSAGE,
      data: alert
    })
  } catch (e) {
    yield put<Action<GoalCreatAndUpdateResponse>>({
      type: GOAL_ACTIONS.CREATE_GOAL + COMMON_ACTIONS.ERROR,
      data: { message: '' },
      error: e as string
    })
    const alert: AlertMessage = {
      message: e as string,
      options: {
        variant: ALERT_VARIANT.ERROR
      }
    }
    yield put<Action<AlertMessage>>({
      type: ALERT_ACTIONS.TRIGGER_ALERT_MESSAGE,
      data: alert
    })
  }
}

function * updateGoal (action: Action<GoalUpdateRequestPayload>) {
  try {
    const response: AxiosResponse<GoalCreatAndUpdateResponse> = yield call(goalService.updateGoal, action.data)
    yield put<Action<GoalCreatAndUpdateResponse>>({
      type: GOAL_ACTIONS.UPDATE_GOAL + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
    const alert: AlertMessage = {
      message: response.data.message,
      options: {
        variant: ALERT_VARIANT.SUCCESS,
        autoHideDuration: 5000
      }
    }
    yield put<Action<AlertMessage>>({
      type: ALERT_ACTIONS.TRIGGER_ALERT_MESSAGE,
      data: alert
    })
  } catch (e) {
    const error = e as AxiosError
    const response: GoalCreatAndUpdateResponse = error.response?.data as GoalCreatAndUpdateResponse
    yield put<Action<GoalCreatAndUpdateResponse>>({
      type: GOAL_ACTIONS.UPDATE_GOAL + COMMON_ACTIONS.ERROR,
      data: { message: '' },
      error: response.message
    })
    const alert: AlertMessage = {
      message: response.message,
      options: {
        variant: ALERT_VARIANT.ERROR
      }
    }
    yield put<Action<AlertMessage>>({
      type: ALERT_ACTIONS.TRIGGER_ALERT_MESSAGE,
      data: alert
    })
  }
}

function * GoalStatusChange (action: Action<StatusUpdatePayLoad>) {
  try {
    const response: AxiosResponse<GoalStatusResponse> = yield call(goalService.GoalStatusChange, action.data)
    yield put<Action<GoalStatusResponse>>({
      type: GOAL_ACTIONS.GOAL_STATUS_UPDATE + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
    const alert: AlertMessage = {
      message: response.data.message,
      options: {
        variant: ALERT_VARIANT.INFO,
        autoHideDuration: 5000
      }
    }
    yield put<Action<AlertMessage>>({
      type: ALERT_ACTIONS.TRIGGER_ALERT_MESSAGE,
      data: alert
    })
  } catch (e) {
    const error = e as AxiosError
    const response: GoalStatusResponse = error.response?.data as GoalStatusResponse
    yield put<Action<GoalStatusResponse>>({
      type: GOAL_ACTIONS.GOAL_STATUS_UPDATE + COMMON_ACTIONS.ERROR,
      data: { message: '' },
      error: response.message
    })
    const alert: AlertMessage = {
      message: response.message,
      options: {
        variant: ALERT_VARIANT.ERROR
      }
    }
    yield put<Action<AlertMessage>>({
      type: ALERT_ACTIONS.TRIGGER_ALERT_MESSAGE,
      data: alert
    })
  }
}

function * getAllGoalList (action: Action<AllGoalRequestPayload>) {
  try {
    const response: AxiosResponse<GoalListResponse> = yield call(goalService.getAllGoalList, action.data)
    yield put<Action<GoalListResponse>>({
      type: GOAL_ACTIONS.GET_ALL_GOAL_LIST + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
  } catch (error) {
    yield put<Action<GoalListResponse>>({
      type: GOAL_ACTIONS.GET_ALL_GOAL_LIST + COMMON_ACTIONS.ERROR,
      data: { count: 0, data: [] },
      error: error as string
    })
  }
}

/*
  This Saga is used to fetch the My Goals List by passing the assignee as username
  @Kalana Elapatha
*/

function * getGoalByUser (action: Action<MyGoalsRequestPayload>) {
  try {
    const response: AxiosResponse<GoalListResponse> = yield call(goalService.getGoalByUser, action.data)
    yield put<Action<GoalListResponse>>({
      type: GOAL_ACTIONS.GET_GOAL_BY_USER + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
  } catch (error) {
    yield put<Action<GoalListResponse>>({
      type: GOAL_ACTIONS.GET_GOAL_BY_USER + COMMON_ACTIONS.ERROR,
      data: { count: 0, data: [] },
      error: error as string
    })
  }
}

function * getGoalById (action: Action<string>) {
  try {
    const response: AxiosResponse<GoalListData> = yield call(goalService.getGoalById, action.data)
    yield put<Action<GoalListData>>({
      type: GOAL_ACTIONS.GET_GOAL_BY_ID + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
  } catch (error) {
    yield put<Action<GoalListData | null>>({
      type: GOAL_ACTIONS.GET_GOAL_BY_ID + COMMON_ACTIONS.ERROR,
      data: null,
      error: error as string
    })
  }
}

/*
  This Saga is used to fetch the Goals List Summary
  This is reletae to get data to use in createEngagement goal select scrren intially.
  @Kalana Elapatha
*/
function * getGoalListSummary (action: Action<GoalListSummeryRequestParams>) {
  try {
    const response: AxiosResponse<GoalListSummeryResponse> = yield call(goalService.getGoalListSummary, action.data)
    yield put<Action<GoalListSummeryResponse>>({
      type: GOAL_ACTIONS.GET_GOAL_LIST_SUMMARY + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
  } catch (error) {
    yield put<Action<GoalListSummeryResponse>>({
      type: GOAL_ACTIONS.GET_GOAL_LIST_SUMMARY + COMMON_ACTIONS.ERROR,
      data: { count: 0, data: [] },
      error: error as string
    })
  }
}

function * goalsByEngagementId (action: Action<string>) {
  try {
    const response: AxiosResponse<GoalListData[]> = yield call(goalService.goalsByEngagement, action.data)
    yield put<Action<GoalListData[]>>({
      type: GOAL_ACTIONS.GET_GOALS_BY_ENGAGEMENT_ID + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
  } catch (error) {
    yield put<Action<GoalListData[] | null>>({
      type: GOAL_ACTIONS.GET_GOALS_BY_ENGAGEMENT_ID + COMMON_ACTIONS.ERROR,
      data: null,
      error: error as string
    })
  }
}
function * goalSagas () {
  yield takeEvery(GOAL_ACTIONS.CREATE_GOAL + COMMON_ACTIONS.REQUEST, createGoal)
  yield takeEvery(GOAL_ACTIONS.UPDATE_GOAL + COMMON_ACTIONS.REQUEST, updateGoal)
  yield takeEvery(GOAL_ACTIONS.GOAL_STATUS_UPDATE + COMMON_ACTIONS.REQUEST, GoalStatusChange)
  yield takeEvery(GOAL_ACTIONS.GET_ALL_GOAL_LIST + COMMON_ACTIONS.REQUEST, getAllGoalList)
  yield takeEvery(GOAL_ACTIONS.GET_GOAL_BY_USER + COMMON_ACTIONS.REQUEST, getGoalByUser)
  yield takeEvery(GOAL_ACTIONS.GET_GOAL_BY_ID + COMMON_ACTIONS.REQUEST, getGoalById)
  yield takeEvery(GOAL_ACTIONS.GET_GOAL_LIST_SUMMARY + COMMON_ACTIONS.REQUEST, getGoalListSummary)
  yield takeEvery(GOAL_ACTIONS.GET_GOALS_BY_ENGAGEMENT_ID + COMMON_ACTIONS.REQUEST, goalsByEngagementId)
}

export default goalSagas
