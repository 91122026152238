import {
  type Action, type AsyncStateObject, type AssessmentResultDetails
} from '../../../interfaces'
import { APP_ACTION_STATUS, ASSESSMENT_ACTIONS, COMMON_ACTIONS } from '../../../utils/constants'

const INITIAL_STATE: AsyncStateObject<AssessmentResultDetails> = {
  data: {
    _id: '',
    sessionId: '',
    engagamentId: '',
    userId: '',
    assessmentId: '',
    status: '',
    takesCount: 0,
    questions: [],
    totalQuestions: 0,
    totalCorrectAnswers: 0
  },
  isLoading: false,
  status: APP_ACTION_STATUS.INITIAL,
  error: undefined

}

const assessmentResultDetailsReducer = (state = INITIAL_STATE, action: Action<AssessmentResultDetails>) => {
  switch (action.type) {
    case ASSESSMENT_ACTIONS.GET_ASSESSMENT_RESULT_DETAILS + COMMON_ACTIONS.REQUEST:
      return {
        ...state,
        isLoading: true,
        status: APP_ACTION_STATUS.LOADING,
        error: undefined
      }
    case ASSESSMENT_ACTIONS.GET_ASSESSMENT_RESULT_DETAILS + COMMON_ACTIONS.SUCCESS:
      return {
        ...state,
        isLoading: false,
        status: APP_ACTION_STATUS.SUCCESS,
        data: action.data
      }
    case ASSESSMENT_ACTIONS.GET_ASSESSMENT_RESULT_DETAILS + COMMON_ACTIONS.ERROR:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        status: APP_ACTION_STATUS.ERROR,
        error: action.error
      }
    case ASSESSMENT_ACTIONS.GET_ASSESSMENT_RESULT_DETAILS + COMMON_ACTIONS.CLEAR:
      return INITIAL_STATE
    default:
      return state
  }
}

export default assessmentResultDetailsReducer
