import React from 'react'
import { Box, Chip, Divider, Grid, IconButton, Typography } from '@mui/material'
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import EventNoteIcon from '@mui/icons-material/EventNote'
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded'
import { type OrgAndRoleHierarchy, type GoalCreteFormState, type UserHierarchy } from '../../../interfaces'
import moment from 'moment'
import { HIERARCHY_TYPES } from '../../../utils/constants'

const GoalSummary: React.FC<{
  formData: GoalCreteFormState
  isEditable: boolean
  tags: OrgAndRoleHierarchy[] | UserHierarchy[]
  hierarchyType: HIERARCHY_TYPES
}> = (props) => {
  const {
    formData,
    isEditable,
    tags,
    hierarchyType
  } = props
  return (
    <>
      <Box sx={{ my: '2rem' }}>
        <Grid container sx={{ padding: '0rem', margin: '0rem' }} gap={5}>
          <Grid item display="flex" sx={{ flexDirection: { xs: 'column' } }} xs={12} sm={12} md={6} lg={3}>
            <Box display="flex" justifyContent="space-between">
              <Box display="flex" gap="1rem">
                <Box>
                  <IconButton color="primary" sx={{ backgroundColor: '#F5FAFF' }} aria-label="edit">
                    <ModeEditOutlinedIcon />
                  </IconButton>
                </Box>
                <Box>
                  <Typography>Editable</Typography>
                  <Typography fontWeight="600">{isEditable ? 'Yes' : 'No'}</Typography>
                </Box>
              </Box>
              <Box display="flex" gap="1rem">
                <Box>
                  <IconButton color="primary" sx={{ backgroundColor: '#F5FAFF' }} aria-label="edit">
                    <FileUploadOutlinedIcon />
                  </IconButton>
                </Box>
                <Box>
                  <Typography>Priority</Typography>
                  <Chip
                    sx={{ fontWeight: '600', borderRadius: '0.25rem', border: 'none', backgroundColor: '#FFF2F0 ' }}
                    color="error"
                    variant="outlined"
                    label={formData.priority.value?.name}
                    size="small" />
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item display="flex" sx={{
            flexDirection: { xs: 'column' }
          }} xs={12} md={6} lg={2}>
            <Box display="flex" justifyContent="start" gap="1rem">
              <Box>
                <IconButton color="primary" sx={{ backgroundColor: '#F5FAFF' }} aria-label="edit">
                  <EventNoteIcon />
                </IconButton>
              </Box>
              <Box>
                <Typography>Time Duration</Typography>
                <Typography fontWeight="600">
                  {`${moment(formData.startDate.value).format('DD/MM/YYYY')}
                   - ${moment(formData.endDate.value).format('DD/MM/YYYY')}`}
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item sx={{ flexDirection: { xs: 'column' } }} xs={12} sm={12} md={12} lg={6}>
            <Grid display='flex'>
              <Grid>
                <Box >
                  <IconButton color="primary" sx={{ backgroundColor: '#F5FAFF' }} aria-label="edit">
                    <StarBorderRoundedIcon />
                  </IconButton>
                </Box>
              </Grid>
              <Grid sx={{ paddingLeft: '1rem' }}>
                <Grid>Tags</Grid>
                <Grid>
                  <Box gap={1}>
                    {hierarchyType !== HIERARCHY_TYPES.USER &&
                      tags.length > 0 && tags.map((tag: any) => {
                      return <>
                          <Chip
                            sx={{
                              fontWeight: '600',
                              borderRadius: '0.25rem',
                              border: 'none',
                              backgroundColor: '#F5FAFF',
                              marginRight: '1rem',
                              marginTop: '0.5rem'
                            }}
                            color="primary"
                            variant="outlined"
                            label={tag.levelName}
                            size="small"
                          />
                        </>
                    })}
                    {hierarchyType === HIERARCHY_TYPES.USER &&
                      tags.length > 0 && tags.map((tag: any) => {
                      return <>
                          <Chip
                            sx={{
                              fontWeight: '600',
                              borderRadius: '0.25rem',
                              border: 'none',
                              backgroundColor: '#F5FAFF',
                              marginTop: '0.5rem'
                            }}
                            color="primary"
                            variant="outlined"
                            label={tag.username}
                            size="small"
                          />
                        </>
                    })}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Divider sx={{ margin: '3rem 0 3rem 0' }} />
        <Typography fontWeight="600" variant="subtitle2">
          Goal Name
        </Typography>
        <Typography
          variant="body1"
          align="left"
          style={{ wordWrap: 'break-word' }}
        >
          {formData.goalName.value}
        </Typography>
      </Box>
      <Grid lg={12}>
        <Typography fontWeight="600" variant="subtitle2">
          Goal Description
        </Typography>
        <Typography
          variant="body1"
          align="left"
          style={{ wordWrap: 'break-word' }}
        >
          {formData.goalDescription.value}
        </Typography>
      </Grid>
    </>
  )
}

export default GoalSummary
