import { type AxiosResponse } from 'axios'
import { type GoalStatusList, type Action, type EngagementStatusList, type SessionStatusList } from '../../interfaces'
import { COMMON_ACTIONS, STATUS_ACTIONS } from '../../utils/constants'
import { call, put, takeEvery } from 'redux-saga/effects'
import { statusService } from '../../services'

function * getGoalStatusList (action: Action<null>) {
  try {
    const response: AxiosResponse<EngagementStatusList[]> = yield call(statusService.getGoalStatusList)
    yield put<Action<GoalStatusList[]>>({
      type: STATUS_ACTIONS.GET_GOAL_STATUS_LIST + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
  } catch (error) {
    yield put<Action<EngagementStatusList[]>>({
      type: STATUS_ACTIONS.GET_GOAL_STATUS_LIST + COMMON_ACTIONS.ERROR,
      data: [],
      error: error as string
    })
  }
}
function * getEngagementStatusList (action: Action<null>) {
  try {
    const response: AxiosResponse<EngagementStatusList[]> = yield call(statusService.getEngagementList)
    yield put<Action<EngagementStatusList[]>>({
      type: STATUS_ACTIONS.GET_ENGAGEMENT_STATUS_LIST + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
  } catch (error) {
    yield put<Action<EngagementStatusList[]>>({
      type: STATUS_ACTIONS.GET_ENGAGEMENT_STATUS_LIST + COMMON_ACTIONS.ERROR,
      data: [],
      error: error as string
    })
  }
}

function * getSessionStatusList (action: Action<null>) {
  try {
    const response: Awaited<ReturnType<typeof statusService.getSessionList>> =
      yield call(statusService.getSessionList)
    yield put<Action<SessionStatusList[]>>({
      type: STATUS_ACTIONS.GET_SESSION_STATUS_LIST + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
  } catch (error) {
    yield put<Action<SessionStatusList[]>>({
      type: STATUS_ACTIONS.GET_SESSION_STATUS_LIST + COMMON_ACTIONS.ERROR,
      data: [],
      error: error as string
    })
  }
}
function * statusSagas () {
  yield takeEvery(STATUS_ACTIONS.GET_GOAL_STATUS_LIST + COMMON_ACTIONS.REQUEST, getGoalStatusList)
  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
  yield takeEvery(STATUS_ACTIONS.GET_ENGAGEMENT_STATUS_LIST + COMMON_ACTIONS.REQUEST, getEngagementStatusList)
  yield takeEvery(STATUS_ACTIONS.GET_SESSION_STATUS_LIST + COMMON_ACTIONS.REQUEST, getSessionStatusList)
}

export default statusSagas
