import { combineReducers } from 'redux'
import { type FileState } from '../../../interfaces'
import fileUploadURLReducer from './getFileUploadURL'
import saveFileToS3Reducer from './saveFileToS3'
import saveFileToQueReducer from './saveFileToQue'
import downloadFileReducer from './downloadFile'
import deleteFileReducer from './deleteFiles'

const fileReducer = combineReducers<FileState>({
  fileUploadURL: fileUploadURLReducer,
  saveFileToS3: saveFileToS3Reducer,
  addToQue: saveFileToQueReducer,
  downloadFile: downloadFileReducer,
  deleteFile: deleteFileReducer
})

export default fileReducer
