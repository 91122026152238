import { Box, Button, Chip, Grid, IconButton, Typography } from '@mui/material'
import Card from '@mui/material/Card'
import React from 'react'
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined'
import { type Coach } from '../../../interfaces'
import { ENGAGEMENT_STATUS, ENGAGEMENT_TYPES, SESSION_STATUS, getEditSessionRoute } from '../../../utils/constants'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'

const SessionCard: React.FC<{
  id: string
  sessionName?: string
  sessionDescription?: string
  sessionStatus: string
  sessionDuration?: string
  noOfUncompletedAssessments: number
  coaches: Coach[]
  openModal: (sessionId: string, sessionStatus: string) => void
  sessionEditDelete: boolean
  viewSession: (sessionId: string, sessionStatus: string) => void
  engagementStatus: string
  sessionDeleteHandle: (sessionId: string) => void
}> = ({
  id,
  sessionName,
  sessionDescription,
  sessionStatus,
  sessionDuration,
  noOfUncompletedAssessments,
  coaches,
  openModal,
  sessionEditDelete,
  viewSession,
  engagementStatus,
  sessionDeleteHandle
}) => {
  const navigate = useNavigate()
  const { engagementId, roleType, sessionId } =
  useParams<{ engagementId: string, roleType: string, sessionId: string }>()
  const _coachName = coaches[0]?.name?.split('@')[0].split('.')[0].replace(/"/g, '')
  const isCoachee = roleType === ENGAGEMENT_TYPES.COACHEE

  // This is method attached due to sujjection bug Requested by QA side
  const formatTime = (input: any) => {
    const months: string = (input.match(/\d+m/) != null) ? input.match(/\d+m/)[0] : ''
    const weeks: string = (input.match(/\d+w/) != null) ? input.match(/\d+w/)[0] : ''
    const days: string = (input.match(/\d+d/) != null) ? input.match(/\d+d/)[0] : ''
    const hours: string = (input.match(/\d+h/) != null) ? input.match(/\d+h/)[0] : ''

    return `${months} ${weeks} ${days} ${hours}`.trim()
  }

  const sessionDurationConverted = formatTime(sessionDuration)
  return (
      <Card sx={{ p: '1rem', my: '1rem', backgroundColor: id === sessionId ? '#F5FAFF' : '' }}>
        <Box display='flex' justifyContent='space-between'>
          <Typography fontWeight={600} fontSize='1rem'>{sessionName}</Typography>
          {sessionEditDelete
            ? <Box>
              <IconButton
              onClick={() => { navigate(getEditSessionRoute(engagementId ?? '', id, roleType ?? '')) }}>
                <CreateOutlinedIcon sx={{ color: '#5F6D7E' }} />
              </IconButton>
              <IconButton
              onClick={() => { sessionDeleteHandle(id) }}>
                <DeleteOutlinedIcon sx={{ color: '#5F6D7E', ml: '0.25rem' }} />
              </IconButton>
            </Box>
            : null}

        </Box>
        <Grid container md={12} mt='1rem'>
          <Grid item md={2}>
            <Chip
              sx={{
                backgroundColor: '#F5FAFF',
                borderRadius: '0.5rem',
                color: '#437EF7',
                fontWeight: '600',
                fontSize: '1rem'
              }}
              variant='filled'
              label={sessionStatus}
            />
          </Grid>
          <Grid item md={2} display='flex'>
            <Box display='flex' alignItems='center'>
              <AccessTimeOutlinedIcon sx={{ color: '#5F6D7E' }} />
              <Typography ml='0.5rem'
                fontWeight={600}
                fontSize='1rem'>{sessionDurationConverted}</Typography>
            </Box>
          </Grid>
          <Grid item md={8} display='flex' alignItems='center'>
            <Box display='flex'>
              <PersonOutlineOutlinedIcon color='primary' />
              <Typography display='flex' fontWeight={500} fontSize='1rem'>
                <Typography
                  fontWeight={500}
                  fontSize='1rem'
                  color='primary'
                  marginRight='0.5rem'>Coach:</Typography>
                {_coachName?.charAt(0).toUpperCase() + _coachName?.slice(1)}</Typography>
            </Box>

          </Grid>
        </Grid>
        <Typography
          my='1rem'
          fontWeight={500}
          color='#A1A1AA'
          variant="body2">{sessionDescription}</Typography>
        <Box my='1rem' display='flex' justifyContent='space-between'>
          <Grid>
            {isCoachee &&
              <Typography
                fontWeight={500}
                variant='body2'
                color='#A1A1AA'>
                {noOfUncompletedAssessments === null
                  ? 'No Assignment for this Session'
                  : noOfUncompletedAssessments === 0
                    ? 'Assignment Completed'
                    : 'Assignment Not Completed'}
              </Typography>
            }
          </Grid>
          <Grid>
            {(isCoachee && (sessionStatus === SESSION_STATUS.NEW)) &&
              <Button
                disabled={engagementStatus !== ENGAGEMENT_STATUS.IN_PROGRESS}
                onClick={() => { openModal(id, sessionStatus) }}
                sx={{
                  textTransform: 'none',
                  background: 'white',
                  color: '#437EF7',
                  boxShadow: 'none',
                  '&:hover': {
                    background: 'white'

                  }
                }}
                variant="contained"
                endIcon={<ArrowForwardIosOutlinedIcon />}>Start session</Button>}

            {((isCoachee && (sessionStatus === SESSION_STATUS.IN_PROGRESS ||
              sessionStatus === SESSION_STATUS.COMPLETED)) || (!isCoachee)) &&
              <Button
                onClick={() => { viewSession(id, sessionStatus) }}
                sx={{
                  textTransform: 'none',
                  background: 'white',
                  color: '#437EF7',
                  boxShadow: 'none',
                  '&:hover': {
                    background: 'white'

                  }
                }}
                variant="contained"
                endIcon={<ArrowForwardIosOutlinedIcon />}>View session</Button>}

          </Grid>
        </Box>

      </Card>
  )
}

export default SessionCard
