import { type DashboardStatChartDto } from '../../interfaces'
import { GOAL_STATUS } from '../constants'

const getStatValue = (data: DashboardStatChartDto, stat: string): number => {
  switch (stat) {
    case GOAL_STATUS.PENDING:
      return data.pending
    case GOAL_STATUS.ACCEPTED:
      return data.accepted
    case GOAL_STATUS.REJECTED:
      return data.rejected
    case GOAL_STATUS.ASSIGNED:
      return data.assigned
    case GOAL_STATUS.INPROGRESS:
      return data.inProgress
    case GOAL_STATUS.COMPLETED:
      return data.completed
    default:
      return 0
  }
}

export default getStatValue
