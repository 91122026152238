import * as React from 'react'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { Box, Button, Card, CardContent, Divider, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import styles from './StartSessionModal.module.scss'

const StartSessionModal: React.FC<{
  modalOpen: boolean
  handleClose: () => void
  viewSession: (sessionID: string) => void
  sessionID: string
  sessionStatus?: string
}> = ({
  modalOpen,
  handleClose,
  sessionID,
  sessionStatus,
  viewSession
}) => {
  return (
      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card className={styles.sessionStartOuterCard}>
          <Box sx={{ padding: '1rem' }} display='flex' justifyContent='space-between'>
            <Typography id="modal-modal-title" fontWeight={600} fontSize='1rem'>
              Start Session
            </Typography>
            <IconButton onClick={handleClose} aria-label="delete" size="small">
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider variant="fullWidth" />
          <CardContent>
            <Card sx={{ border: '1px solid #EEA23E', padding: '1rem', backgroundColor: '#FFF8EB' }}>
              <Box display='flex' alignItems='center'>
                <Typography ml='0.5rem' id="modal-modal-title" variant="body1" component="h2">
                  Once you click start, the session will be marked as
                  started and the duration will start to count.
                </Typography>
              </Box>
            </Card>
          </CardContent>
          <Divider variant="fullWidth" />
          <Box sx={{ padding: '1rem' }} display='flex' justifyContent='end' gap={2}>
            <Button
              onClick={handleClose}
              sx={{
                textTransform: 'none'
              }}
              color='secondary'
              variant="contained">Cancel</Button>
            <Button
              onClick={() => { viewSession(sessionID) }}
              sx={{
                textTransform: 'none'
              }}
              color='primary'
              variant="contained">Start</Button>
          </Box>

        </Card>
      </Modal>
  )
}

export default StartSessionModal
