import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'

import LinearProgress, {
  linearProgressClasses
} from '@mui/material/LinearProgress'
import { styled } from '@mui/material/styles'
import moment from 'moment'

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#F5F8FE'
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#308fe8'
  }
}))

const EngagementViewHeaderDescription: React.FC<{
  viewEngagementHeader: string
  engagementStartDate?: string
  engagementEndDate?: string
  engagementCoachName: string
  engagementDescription: string
  progressBarPercentage: number
}> = ({
  viewEngagementHeader,
  engagementStartDate,
  engagementEndDate,
  engagementCoachName,
  engagementDescription,
  progressBarPercentage
}) => {
  const _coachName = engagementCoachName?.split('@')[0].split('.')[0].replace(/"/g, '')
  return (
    <Box borderBottom={'1px solid rgba(0, 0, 0, 0.12)'} pb={'1rem'}>
      <Typography fontWeight={600} variant="h4">
        {viewEngagementHeader}
      </Typography>
      <Grid container gap={2}>
        <Grid item md={4}>
          <Box display="flex" alignItems="center" my="1rem">
            <CalendarTodayIcon color="primary" />
            <Typography fontWeight={500} fontSize="1rem" ml="1rem">
              {`${moment(engagementStartDate).format('DD/MM/YYYY')} - 
                            ${moment(engagementEndDate).format('DD/MM/YYYY')}`}
            </Typography>
          </Box>
        </Grid>
        <Grid item md={7}>
          <Box display="flex" alignItems="center" my="1rem">
            <PersonOutlineOutlinedIcon color="primary" />
            <Typography display="flex" fontWeight={500} fontSize="1rem">
              <Typography
                fontWeight={500}
                fontSize="1rem"
                color="primary"
                marginRight="0.5rem"
              >
                Coach:
              </Typography>
              {_coachName?.charAt(0).toUpperCase() + _coachName?.slice(1)}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', alignItems: 'center', my: '1rem' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <BorderLinearProgress
            variant="determinate"
            value={progressBarPercentage}
          />
        </Box>
        <Box>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            progressBarPercentage
          )}%`}</Typography>
        </Box>
      </Box>
      <Box>
        <Box sx={{ mb: '1rem' }}>
          <Typography fontWeight={600} fontSize="1rem">
            Description
          </Typography>
        </Box>
        <Box>
          <Typography variant="body2" color="text.secondary">
            {engagementDescription}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default EngagementViewHeaderDescription
