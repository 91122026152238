/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { Box, Button, Grid, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { APP_ROUTES, PERMISSION } from '../../../utils/constants'
import { hasPermission } from '../../../utils/helpers'
import { useSelector } from 'react-redux'
import { type AppState } from '../../../interfaces'

const GoalViewHeader = () => {
  const navigate = useNavigate()
  const authorizedUser = useSelector(
    (state: AppState) => state.authUser.authorizedUser
  )
  const hasPermissionToCreateGoal =
        hasPermission([PERMISSION.CREATE_GOAL_FOR_HIM_SELF, PERMISSION.CREATE_GOAL_FOR_OTHERS])
  return (
    <Grid container sm={12} md={12} display="flex" justifyContent="space-between" marginBottom="2rem">
      <Grid item sm={12} md={7}>
        <Box>
          <Typography textTransform="none" fontWeight="600"
            sx={{
              fontSize: {
                xs: '1rem',
                sm: '1.25rem',
                md: '1.5rem',
                lg: '2rem'
              }
            }}>Hey there, {authorizedUser?.data?.name}!</Typography>
          <Typography textTransform="none" fontWeight="600" sx={{
            fontSize: {
              xs: '0.75rem',
              sm: '0.75rem',
              md: '1rem',
              lg: '1rem'
            }
          }}
            variant='subtitle1' color="#5F6D7E">Welcome back, we are happy to have you here!</Typography>
        </Box>
      </Grid>
      <Grid item sm={12} md={5} marginTop={'1rem'}>
        <Box display="flex" justifyContent={{ sm: 'flex-start', md: 'flex-end' }}>
          {hasPermissionToCreateGoal && <Button
            sx={{ textTransform: 'none' }}
            size='medium'
            variant='contained'
            color='primary'
            onClick={() => { navigate(APP_ROUTES.GOAL_CREATE) }}
          >
            Create Goal
          </Button>}

        </Box>
      </Grid>
    </Grid>
  )
}

export default GoalViewHeader
