/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { type AxiosResponse } from 'axios'
import { call, put, takeEvery } from 'redux-saga/effects'
import {
  type Action,
  type AlertMessage
} from '../../interfaces'
import {
  type GetOwnRequestParamDto,
  type AddNoteRequestParamDto,
  type AddNoteResponseDto,
  type NoteDto,
  type GetOthersRequestParamDto,
  type DeleteNoteResponseDto,
  type DeleteNoteRequestParamDto,
  type EditNoteRequestParamDto,
  type EditNoteResponseDto
} from '../../interfaces/notes'
import { notesService } from '../../services'
import { ALERT_ACTIONS, ALERT_VARIANT, COMMON_ACTIONS, NOTES_ACTION_TYPE } from '../../utils/constants'

function * addNote (action: Action<AddNoteRequestParamDto>) {
  try {
    const response: AxiosResponse<AddNoteResponseDto> = yield call(
      notesService.addNote, action.data)
    yield put<Action<AddNoteResponseDto>>({
      type: NOTES_ACTION_TYPE.ADD_NOTE + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
    const alert: AlertMessage = {
      message: response.data.message,
      options: {
        variant: ALERT_VARIANT.SUCCESS,
        autoHideDuration: 5000
      }
    }
    yield put<Action<AlertMessage>>({
      type: ALERT_ACTIONS.TRIGGER_ALERT_MESSAGE,
      data: alert
    })
  } catch (e) {
    yield put<Action<AddNoteResponseDto>>({
      type: NOTES_ACTION_TYPE.ADD_NOTE + COMMON_ACTIONS.ERROR,
      data: { message: '' },
      error: e as string
    })
    const alert: AlertMessage = {
      message: e as string,
      options: {
        variant: ALERT_VARIANT.ERROR
      }
    }
    yield put<Action<AlertMessage>>({
      type: ALERT_ACTIONS.TRIGGER_ALERT_MESSAGE,
      data: alert
    })
  }
}

function * editNote (action: Action<EditNoteRequestParamDto>) {
  try {
    const response: AxiosResponse<EditNoteResponseDto> = yield call(
      notesService.editNote, action.data)
    yield put<Action<AddNoteResponseDto>>({
      type: NOTES_ACTION_TYPE.EDIT_NOTE + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
    const alert: AlertMessage = {
      message: response.data.message,
      options: {
        variant: ALERT_VARIANT.SUCCESS,
        autoHideDuration: 5000
      }
    }
    yield put<Action<AlertMessage>>({
      type: ALERT_ACTIONS.TRIGGER_ALERT_MESSAGE,
      data: alert
    })
  } catch (e) {
    yield put<Action<EditNoteResponseDto>>({
      type: NOTES_ACTION_TYPE.EDIT_NOTE + COMMON_ACTIONS.ERROR,
      data: { message: '' },
      error: e as string
    })
    const alert: AlertMessage = {
      message: e as string,
      options: {
        variant: ALERT_VARIANT.ERROR
      }
    }
    yield put<Action<AlertMessage>>({
      type: ALERT_ACTIONS.TRIGGER_ALERT_MESSAGE,
      data: alert
    })
  }
}

function * deleteNote (action: Action<DeleteNoteRequestParamDto>) {
  try {
    const response: AxiosResponse<DeleteNoteResponseDto> = yield call(
      notesService.deleteNote, action.data)
    yield put<Action<DeleteNoteResponseDto>>({
      type: NOTES_ACTION_TYPE.DELETE_NOTE + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
    const alert: AlertMessage = {
      message: response.data.message,
      options: {
        variant: ALERT_VARIANT.SUCCESS,
        autoHideDuration: 5000
      }
    }
    yield put<Action<AlertMessage>>({
      type: ALERT_ACTIONS.TRIGGER_ALERT_MESSAGE,
      data: alert
    })
  } catch (e) {
    yield put<Action<DeleteNoteResponseDto>>({
      type: NOTES_ACTION_TYPE.DELETE_NOTE + COMMON_ACTIONS.ERROR,
      data: { message: '' },
      error: e as string
    })
    const alert: AlertMessage = {
      message: e as string,
      options: {
        variant: ALERT_VARIANT.ERROR
      }
    }
    yield put<Action<AlertMessage>>({
      type: ALERT_ACTIONS.TRIGGER_ALERT_MESSAGE,
      data: alert
    })
  }
}

function * viewOwnNotes (action: Action<GetOwnRequestParamDto>) {
  try {
    const response: AxiosResponse<NoteDto[]> =
     yield call(notesService.viewOwnNotes, action.data)
    yield put<Action<NoteDto[]>>({
      type: NOTES_ACTION_TYPE.VIEW_OWN_NOTES + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
  } catch (error) {
    yield put<Action<NoteDto[]>>({
      type: NOTES_ACTION_TYPE.VIEW_OWN_NOTES + COMMON_ACTIONS.ERROR,
      data: [],
      error: error as string
    })
  }
}

function * viewOthersNotes (action: Action<GetOthersRequestParamDto>) {
  try {
    const response: AxiosResponse<NoteDto[]> =
     yield call(notesService.viewOthersNotes, action.data)
    yield put<Action<NoteDto[]>>({
      type: NOTES_ACTION_TYPE.VIEW_OTHERS_NOTES + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
  } catch (error) {
    yield put<Action<NoteDto[]>>({
      type: NOTES_ACTION_TYPE.VIEW_OTHERS_NOTES + COMMON_ACTIONS.ERROR,
      data: [],
      error: error as string
    })
  }
}

function * noteSaga () {
  yield takeEvery(NOTES_ACTION_TYPE.ADD_NOTE + COMMON_ACTIONS.REQUEST, addNote)
  yield takeEvery(NOTES_ACTION_TYPE.EDIT_NOTE + COMMON_ACTIONS.REQUEST, editNote)
  yield takeEvery(NOTES_ACTION_TYPE.DELETE_NOTE + COMMON_ACTIONS.REQUEST, deleteNote)
  yield takeEvery(NOTES_ACTION_TYPE.VIEW_OWN_NOTES + COMMON_ACTIONS.REQUEST, viewOwnNotes)
  yield takeEvery(NOTES_ACTION_TYPE.VIEW_OTHERS_NOTES + COMMON_ACTIONS.REQUEST, viewOthersNotes)
}

export default noteSaga
