import React from 'react'
import {
  type EngagementStatusList,
  type AppState,
  type DashboardEngagementSummaryDto,
  type DropdownOptionnDto
} from '../../../interfaces'
import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux'
import {
  Card,
  CardContent,
  Typography,
  Divider,
  Skeleton,
  Grid,
  Box,
  CardActions,
  Button,
  Autocomplete,
  TextField
} from '@mui/material'
import { GOAL_SUMMARY_ROWS_COUNT, APP_ROUTES } from '../../../utils/constants'
import styles from './DashboardEngaementSummaryCard.module.scss'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import SupervisedUserCircleRoundedIcon from '@mui/icons-material/SupervisedUserCircleRounded'

const DashboardEngaementSummaryCard: React.FC<{
  data: DashboardEngagementSummaryDto[]
  isLoading: boolean
  selectedStatus: DropdownOptionnDto
  onChange: (value: DropdownOptionnDto) => void
}> = ({
  data,
  isLoading,
  onChange,
  selectedStatus
}) => {
  const navigate = useNavigate()
  const engagmentStatusResponse = useSelector((state: AppState) => state.status.engagementStatusList)
  return (
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Typography variant="h5" fontWeight={400} padding={'0.5rem'} display={'flex'} justifyContent={'start'}>
                Engagements Summary
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                value={selectedStatus}
                fullWidth
                size='small'
                options={engagmentStatusResponse?.data?.map((status: EngagementStatusList) => {
                  return { id: status.statusId, name: status.name }
                })}
                getOptionLabel={(option) => option.name}
                onChange={(event, value) => { onChange(value ?? { id: -1, name: '' }) }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Status"
                  />
                )}
              />
            </Grid>
          </Grid>
          <Divider />
          {isLoading && Array.from({ length: GOAL_SUMMARY_ROWS_COUNT }).map((_, index) => (
            <div key={index}>
              <Grid container spacing={2} mt={'1rem'} mb={'1rem'}>
                <Grid item xs={8}>
                  <Box display='flex' flexDirection='column' gap={1}>
                    <Box><Skeleton animation="wave" variant="rounded" width='100%' height='2rem' /></Box>
                    <Box><Skeleton animation="wave" variant="rounded" width='40%' height='2rem' /></Box>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box><Skeleton animation="wave" variant="rounded" width='100%' height='2rem' /></Box>
                </Grid>
                <Grid item xs={1}>
                  <Box><Skeleton animation="wave" variant="rounded" width='100%' height='2rem' /></Box>
                </Grid>
              </Grid>
              {data.length - 1 !== index && <Divider />}
            </div>
          ))}
          {!isLoading && data.length > 0 && data.map((eng: DashboardEngagementSummaryDto, index) => (
            <div key={index}>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Typography variant="h6" fontWeight={600} mt={'1rem'} className={styles.engagementName}>
                    {eng.name}
                  </Typography>
                  <Typography variant="subtitle1" fontWeight={500} color={'gray'} mb={'1rem'}>
                    {eng.status}
                  </Typography>
                </Grid>
                <Grid item xs={3} display={'flex'} alignItems={'end'}>
                  <Typography variant="subtitle1" fontWeight={500} color={'gray'} mb={'1rem'}>
                    {eng.startDate} - {eng.endDate}
                  </Typography>
                </Grid>
                <Grid item xs={1} display={'flex'} alignItems={'end'} mb={'1rem'}>
                  {!eng.isIndividual && <SupervisedUserCircleRoundedIcon fontSize='large' />}
                  {(eng.isIndividual) && <AccountCircleIcon color='primary' fontSize='large' />}
                </Grid>
              </Grid>
              {data.length - 1 !== index && <Divider />}
            </div>
          ))}
          {!isLoading && data.length === 0 && (
            <div style={{ padding: '2rem', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
              <Typography variant="subtitle1" fontWeight={500} color={'gray'}>
                No Engagements to preview
              </Typography>
            </div>
          )}
          <Divider />
        </CardContent>
        <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button size="medium" onClick={() => { navigate(APP_ROUTES.ENGAGEMENT_VIEW) }}>SEE ALL ENGAGEMENTS</Button>
        </CardActions>
      </Card>
  )
}

export default DashboardEngaementSummaryCard
