import { APP_CONFIGS, PERMISSION_TYPES } from '../constants'

const hasPermission = (permissionKeys: string[], flag: string = PERMISSION_TYPES.PARTIAL_PERMISSION): boolean => {
  const allPermission: any = localStorage.getItem(APP_CONFIGS.USER_ENTITLEMENT)
  const lowercasedAllArray = JSON.parse(allPermission)?.map((permission: string) => permission?.toLowerCase())
  const check = (element: string) => lowercasedAllArray?.includes(element?.toLowerCase())
  if (flag === PERMISSION_TYPES.FULL_PERMISSION) return permissionKeys?.every(check)
  else return permissionKeys?.some(check)
}

export default hasPermission
