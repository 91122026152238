/* eslint-disable @typescript-eslint/restrict-plus-operands */
import {
  type GetOthersRequestParamDto,
  type Action,
  type AddNoteRequestParamDto,
  type GetOwnRequestParamDto,
  type DeleteNoteRequestParamDto,
  type EditNoteRequestParamDto
} from '../../interfaces'
import { NOTES_ACTION_TYPE, COMMON_ACTIONS } from '../../utils/constants'

const addNote = (payload: AddNoteRequestParamDto): Action<AddNoteRequestParamDto> => {
  return { type: NOTES_ACTION_TYPE.ADD_NOTE + COMMON_ACTIONS.REQUEST, data: payload }
}
const clearAddNote = (): Action<null> => {
  return { type: NOTES_ACTION_TYPE.ADD_NOTE + COMMON_ACTIONS.CLEAR, data: null }
}
const editNote = (payload: EditNoteRequestParamDto): Action<EditNoteRequestParamDto> => {
  return { type: NOTES_ACTION_TYPE.EDIT_NOTE + COMMON_ACTIONS.REQUEST, data: payload }
}
const clearEditNote = (): Action<null> => {
  return { type: NOTES_ACTION_TYPE.EDIT_NOTE + COMMON_ACTIONS.CLEAR, data: null }
}
const deleteNote = (payload: DeleteNoteRequestParamDto): Action<DeleteNoteRequestParamDto> => {
  return { type: NOTES_ACTION_TYPE.DELETE_NOTE + COMMON_ACTIONS.REQUEST, data: payload }
}
const clearDeleteNote = (): Action<null> => {
  return { type: NOTES_ACTION_TYPE.DELETE_NOTE + COMMON_ACTIONS.CLEAR, data: null }
}
const viewOwnNotes = (payload: GetOwnRequestParamDto): Action<GetOwnRequestParamDto> => {
  return { type: NOTES_ACTION_TYPE.VIEW_OWN_NOTES + COMMON_ACTIONS.REQUEST, data: payload }
}
const clearViewOwnNotes = (): Action<null> => {
  return { type: NOTES_ACTION_TYPE.VIEW_OWN_NOTES + COMMON_ACTIONS.CLEAR, data: null }
}
const viewOthersNotes = (payload: GetOthersRequestParamDto): Action<GetOthersRequestParamDto> => {
  return { type: NOTES_ACTION_TYPE.VIEW_OTHERS_NOTES + COMMON_ACTIONS.REQUEST, data: payload }
}
const clearViewOthersNotes = (): Action<null> => {
  return { type: NOTES_ACTION_TYPE.VIEW_OTHERS_NOTES + COMMON_ACTIONS.CLEAR, data: null }
}

export const noteAction = {
  addNote,
  clearAddNote,
  editNote,
  clearEditNote,
  deleteNote,
  clearDeleteNote,
  viewOwnNotes,
  clearViewOwnNotes,
  viewOthersNotes,
  clearViewOthersNotes
}
