import React from 'react'
import {
  Autocomplete,
  Box,
  FormControl,
  InputLabel,
  Switch,
  TextField,
  Typography
} from '@mui/material'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { type AsyncStateObject, type CoachListDropDownObject, type EngementCreteFormState } from '../../../interfaces'
import styles from './EngagementGeneralDetailsForm.module.scss'
import { type FilteredCoacheListDto } from '../../../interfaces/users'
import moment from 'moment'
import { ENGAGEMENT_fORM_FIELDS } from '../../../utils/constants'

const GeneralDetailsForm: React.FC<{
  isFilter: boolean
  coachList: any[]
  generalDetailForm: EngementCreteFormState
  getCoachesListResponse: AsyncStateObject<FilteredCoacheListDto | null>
  onInputHandleForm: (event: any, editer: any) => void
  onSetIsFilter: () => void
}> = ({
  generalDetailForm,
  isFilter, onInputHandleForm, coachList, getCoachesListResponse, onSetIsFilter
}) => {
  const _sbuList = getCoachesListResponse.data !== null
    ? getCoachesListResponse.data.organization.map((s) => { return { id: s.sbuId, name: s.sbuName } })
    : []
  const _rolesList = getCoachesListResponse.data !== null
    ? getCoachesListResponse.data.roles.map((r) => { return { id: r.id, name: r.name } })
    : []

  const _sbuId = generalDetailForm.sbu.value.id
  const _depaList = getCoachesListResponse.data !== null && _sbuId !== ''
    ? getCoachesListResponse.data.organization.find((o) => o.sbuId === _sbuId)?.departments
    : []
  return (
    <>
      <Box>
        <form>
          <FormControl id={styles.formControlEngName}>
            <Box sx={{ marginBottom: '3.5rem' }}>
              <InputLabel
                sx={{ fontWeight: '600' }}
                variant="standard"
                htmlFor="uncontrolled-native"
              >
                <Box display='flex'><Typography fontWeight={600}>Engagement Name</Typography>
                  <Typography color='red'>*</Typography></Box>
              </InputLabel>
            </Box>
            <TextField
              placeholder="Enter Engagement Name"
              sx={{ width: '100%' }}
              id="outlined-basic"
              variant="outlined"
              value={generalDetailForm.engagementName.value}
              disabled={generalDetailForm.engagementName.disable}
              error={!(generalDetailForm.engagementName.error == null)}
              required={generalDetailForm.engagementName.isRequired}
              helperText={generalDetailForm.engagementName.error}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                onInputHandleForm(ENGAGEMENT_fORM_FIELDS.NAME, event.target.value)
              }}
              inputProps={{ maxLength: 100 }}
            />
          </FormControl>
          <FormControl sx={{ width: '100%' }}>
            <Box sx={{ backgroundColor: 'blue', marginBottom: '3.5rem' }}>
              <InputLabel
                sx={{ fontWeight: '600' }}
                variant="standard"
                htmlFor="uncontrolled-native"
              >
                <Box display='flex'><Typography fontWeight={600}>Engagement Description</Typography>
                  <Typography color='red'>*</Typography></Box>
              </InputLabel>
            </Box>
            <TextField
              multiline
              rows={5}
              fullWidth
              placeholder="Enter Engagement Description"
              value={generalDetailForm.engagementDescription.value}
              disabled={generalDetailForm.engagementDescription.disable}
              error={!(generalDetailForm.engagementDescription.error == null)}
              required={generalDetailForm.engagementDescription.isRequired}
              helperText={generalDetailForm.engagementDescription.error}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                onInputHandleForm(ENGAGEMENT_fORM_FIELDS.DESCRIPTION, event.target.value)
              }}
              inputProps={{ maxLength: 255 }}
            />
          </FormControl>
          <Box display='flex' sx={{ alignItems: 'center', padding: '1rem 0rem' }}>
            <Typography fontWeight={600}>Advance Filter</Typography>
            <Switch value={isFilter} onChange={onSetIsFilter} />
          </Box>
          {isFilter &&
            <FormControl
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                gap: '2rem'
              }}
            >
              <FormControl sx={{ width: '33%' }}>
                <Box>
                  <Box sx={{ width: '100%', marginBottom: '3.5rem' }}>
                    <InputLabel
                      shrink={false}
                      sx={{ fontWeight: '600' }}
                      variant="standard"
                      htmlFor="uncontrolled-native"
                    >
                      <Box display='flex'><Typography fontWeight={600}>SBU</Typography></Box>
                    </InputLabel>
                  </Box>
                  <Box>
                    <Autocomplete
                      placeholder='Select SBU'
                      value={generalDetailForm.sbu.value}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      fullWidth
                      options={_sbuList}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) => { onInputHandleForm(ENGAGEMENT_fORM_FIELDS.SBU, value) }
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          disabled={generalDetailForm.sbu.disable}
                          error={!(generalDetailForm.sbu.error == null)}
                          required={generalDetailForm.sbu.isRequired}
                          helperText={generalDetailForm.sbu.error}
                          placeholder="Select SBU"
                        />
                      )}
                    />
                  </Box>
                </Box>
              </FormControl>
              <FormControl sx={{ width: '33%' }}>
                <Box>
                  <Box sx={{ width: '100%', marginBottom: '3.5rem' }}>
                    <InputLabel
                      shrink={false}
                      sx={{ fontWeight: '600' }}
                      variant="standard"
                      htmlFor="uncontrolled-native"
                    >
                      <Box display='flex'><Typography fontWeight={600}>Department</Typography></Box>
                    </InputLabel>
                  </Box>
                  <Box>
                    <Autocomplete
                      placeholder='Select Department'
                      value={generalDetailForm.department.value}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      fullWidth
                      options={_depaList ?? []}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) => { onInputHandleForm(ENGAGEMENT_fORM_FIELDS.DEPARTMENT, value) }
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          disabled={generalDetailForm.department.disable}
                          error={!(generalDetailForm.department.error == null)}
                          required={generalDetailForm.department.isRequired}
                          helperText={generalDetailForm.department.error}
                          placeholder="Select Department"
                        />
                      )}
                    />
                  </Box>
                </Box>
              </FormControl>
              <FormControl sx={{ width: '33%' }}>
                <Box>
                  <Box sx={{ width: '100%', marginBottom: '3.5rem' }}>
                    <InputLabel
                      shrink={false}
                      sx={{ fontWeight: '600' }}
                      variant="standard"
                      htmlFor="uncontrolled-native"
                    >
                      <Box display='flex'><Typography fontWeight={600}>Role</Typography></Box>
                    </InputLabel>
                  </Box>
                  <Box>
                    <Autocomplete
                      placeholder='Select Role'
                      value={generalDetailForm.role.value}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      fullWidth
                      options={_rolesList}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) => { onInputHandleForm(ENGAGEMENT_fORM_FIELDS.ROLE, value) }
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          disabled={generalDetailForm.role.disable}
                          error={!(generalDetailForm.role.error == null)}
                          required={generalDetailForm.role.isRequired}
                          helperText={generalDetailForm.role.error}
                          placeholder="Select Role"
                        />
                      )}
                    />
                  </Box>
                </Box>
              </FormControl>
            </FormControl>
          }

          <FormControl
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              gap: '2rem'
            }}
          >
            <FormControl sx={{ width: '50%' }}>
              <Box>
                <Box sx={{ width: '100%', marginBottom: '3.5rem' }}>
                  <InputLabel
                    shrink={false}
                    sx={{ fontWeight: '600' }}
                    variant="standard"
                    htmlFor="uncontrolled-native"
                  >
                    <Box display='flex'><Typography fontWeight={600}>Engagement owner / Coach</Typography>
                      <Typography color='red'>*</Typography></Box>
                  </InputLabel>
                </Box>
                <Box>
                  <Autocomplete
                    value={generalDetailForm.engagementCoach.value}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    fullWidth
                    options={coachList.map((priority: CoachListDropDownObject) => {
                      return { name: priority.name, id: priority.id }
                    })}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => { onInputHandleForm(ENGAGEMENT_fORM_FIELDS.COACH, value) }
                    }
                    disabled={generalDetailForm.engagementCoach.disable}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled={generalDetailForm.engagementCoach.disable}
                        error={!(generalDetailForm.engagementCoach.error == null)}
                        required={generalDetailForm.engagementCoach.isRequired}
                        helperText={generalDetailForm.engagementCoach.error}
                        placeholder="Select Coach"
                      />
                    )}
                  />
                </Box>
              </Box>
            </FormControl>
            <FormControl sx={{ width: '25%' }}>
              <Box sx={{ width: '100%', marginBottom: '3.5rem' }}>
                <InputLabel shrink={false} sx={{ fontWeight: '600' }} variant="standard" htmlFor="uncontrolled-native">
                  <Box display='flex'>
                    <Typography fontWeight={600}>Start Date</Typography>
                    <Typography color='red'>*</Typography>
                  </Box>
                </InputLabel>
              </Box>
              <Box sx={{ width: '100%' }} >
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DemoContainer sx={{ paddingTop: 0 }} components={['DatePicker']}>
                    <DatePicker
                      sx={{ width: '100%' }}
                      value={generalDetailForm.startDate.value}
                      onChange={(newValue) => { onInputHandleForm(ENGAGEMENT_fORM_FIELDS.START_DATE, newValue) }}
                      minDate={moment()}
                      maxDate={generalDetailForm.endDate.value}
                      disabled={generalDetailForm.startDate.disable}
                      slotProps={{
                        textField: {
                          error: generalDetailForm.startDate.error !== null,
                          helperText: generalDetailForm.startDate.error,
                          InputProps: {
                            readOnly: true
                          }
                        }
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Box>

            </FormControl>
            <FormControl sx={{ width: '25%' }}>
              <Box sx={{ width: '100%', marginBottom: '3.5rem' }}>
                <InputLabel shrink={false} sx={{ fontWeight: '600' }} variant="standard" htmlFor="uncontrolled-native">
                  <Box display='flex'>
                    <Typography fontWeight={600}>End Date</Typography>
                    <Typography color='red'>*</Typography>
                  </Box>
                </InputLabel>
              </Box>
              <Box sx={{ width: '100%' }} >
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DemoContainer sx={{ paddingTop: 0 }} components={['DatePicker']}>
                    <DatePicker
                      sx={{ width: '100%' }}
                      value={generalDetailForm.endDate.value}
                      minDate={generalDetailForm.startDate.value}
                      onChange={(newValue) => { onInputHandleForm(ENGAGEMENT_fORM_FIELDS.END_DATE, newValue) }}
                      slotProps={{
                        textField: {
                          error: generalDetailForm.endDate.error !== null,
                          helperText: generalDetailForm.endDate.error,
                          InputProps: {
                            readOnly: true
                          }
                        }
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Box>
            </FormControl>
          </FormControl>
        </form>
      </Box>
    </>
  )
}

export default GeneralDetailsForm
