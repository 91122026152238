/* eslint-disable max-len */
import { Box, Button, Card, CardContent, CircularProgress, TextField } from '@mui/material'
import React from 'react'
import { type NoteDto, type NoteFormState } from '../../../interfaces/notes'
import SingleNote from '../SingleNote/SingleNote'
import styles from './MyNotes.module.scss'
import { SingleNoteSkelton } from '../../skeleton'
import { type AsyncStateObject } from '../../../interfaces'
import { isNullOrUndefinedOrEmpty } from '../../../utils/helpers'
import { SomethingWentWrong } from '../../common'

const MyNotes: React.FC<{
  isLoading: boolean
  isEditNote: boolean
  isAddEditLoading: boolean
  isErrorFetchData: string
  viewMyNoteResponse: AsyncStateObject<NoteDto[]>
  noteForm: NoteFormState
  onSelectNoteForDelete: (note: NoteDto) => void
  onSelectNoteForEdit: (note: NoteDto) => void
  addMyNote: () => void
  editMyNote: () => void
  onInputHandleForm: (value: any) => void
}> = ({
  isLoading,
  isEditNote,
  isAddEditLoading,
  isErrorFetchData,
  noteForm,
  addMyNote,
  editMyNote,
  onSelectNoteForDelete,
  onSelectNoteForEdit,
  viewMyNoteResponse,
  onInputHandleForm
}) => {
  return (
    <>
      <Card sx={{ marginTop: '2rem' }}>
        <CardContent>
          <TextField
            multiline
            rows={8}
            fullWidth
            placeholder="Enter Session Note"
            value={noteForm.note.value}
            disabled={noteForm.note.disable}
            error={!(noteForm.note.error === null)}
            required={noteForm.note.isRequired}
            helperText={noteForm.note.error}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              onInputHandleForm(event.target.value)
            }}
            inputProps={{ maxLength: 1000 }}
          />
        </CardContent>
        <Box display={'flex'} justifyContent={'end'} p={'1rem'} borderTop={'1px solid rgba(0, 0, 0, 0.12)'}>
          <Button
            sx={{ textTransform: 'none' }}
            variant='contained'
            color='primary'
            disabled={isAddEditLoading}
            onClick={isEditNote ? editMyNote : addMyNote}
          >
            {isAddEditLoading && <CircularProgress size={12} color='inherit' sx={{ marginRight: '8px' }} /> }
            {isEditNote ? 'Update Note' : 'Add Note'}
          </Button>
        </Box>
      </Card>
      <Card sx={{ marginTop: '2rem' }}>
        <CardContent className={styles.notesCard}>
          {isLoading &&
            <Box display='flex' flexDirection='column'>
              <SingleNoteSkelton/><SingleNoteSkelton/>
            </Box>
          }
          {!isLoading &&
            <>
              {!isNullOrUndefinedOrEmpty(isErrorFetchData) &&
                <SomethingWentWrong
                  height='100%'
                  isShowDefault={false}
                  subNotification={isErrorFetchData}
                />
              }
              {isNullOrUndefinedOrEmpty(isErrorFetchData) &&
                <>
                  {viewMyNoteResponse?.data?.map((note: NoteDto, index: number) =>
                    <Box key={index}>
                      <SingleNote
                        index={index}
                        note={note}
                        onDelete={() => { onSelectNoteForDelete(note) }}
                        onEdit={() => { onSelectNoteForEdit(note) }}
                        isShowAction={true}
                      />
                    </Box>
                  )}
                  {viewMyNoteResponse?.data?.length === 0 && (
                    <div className={styles.noDataNotification}>
                      There are no notes to preview.!
                    </div>
                  )}
                </>
              }
            </>
          }
        </CardContent>
      </Card>
    </>
  )
}

export default MyNotes
