import { type Action } from '../../interfaces'
import { type GetAllCoachesParam } from '../../interfaces/users'
import { COMMON_ACTIONS, USERS_ACTION_TYPE } from '../../utils/constants'

const getAllCoachesList = (param: GetAllCoachesParam): Action<GetAllCoachesParam> => {
  return { type: USERS_ACTION_TYPE.GET_ALL_COACHES + COMMON_ACTIONS.REQUEST, data: param }
}

export const userAction = {
  getAllCoachesList
}
