import { Alert, Button, Card, CardContent, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { engagementsActions, feedBackActions } from '../../redux/actions'
import {
  type EngagementDetailsRequestParam,
  type AppState
} from '../../interfaces'
import {
  type ViewGroupFeedbackQnA,
  type ViewCoacheeFeedbackCommentsRequestParams,
  type ViewCoacheeGroupFeedbackParam
} from '../../interfaces/feedBack'
import SessionFeedbackDrawer from '../../components/session/SessionFeedbackDrawer/SessionFeedbackDrawer'
import { useNavigate, useParams } from 'react-router-dom'
import { APP_ACTION_STATUS, getViewSessionRoute } from '../../utils/constants'
import { isNullOrUndefinedOrEmpty } from '../../utils/helpers'
import { FeedbackViewSkelton, SomethingWentWrong } from '../../components'
import FeedbackGroupViewCard from '../../components/feedback/FeedbackGroupViewCard/FeedbackGroupViewCard'

const FeedBackGroupView = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { roleType, engagementId, sessionId } =
    useParams<{ roleType: string, engagementId: string, sessionId: string }>()
  const feedBackComments = useSelector((state: AppState) => state.feedbacks.ViewCoacheeFeedbackComments)
  const feedbackData = useSelector((state: AppState) =>
    state.feedbacks.viewGroupFeedbacks.data)
  const engagementDetailsResponse = useSelector((state: AppState) => state.engagements.engagementDetails)
  const groupFeedbackDetailsResponse = useSelector((state: AppState) => state.feedbacks.viewGroupFeedbacks)
  const [drawerOpen, setDrawerOpen] = React.useState(false)
  const [isErrorFetchData, setIsErrorFetchData] = useState<string>('')

  const feedbackResponse = useSelector((state: AppState) => state.feedbacks.viewGroupFeedbacks)

  useEffect(() => {
    const params: ViewCoacheeGroupFeedbackParam = {
      id: sessionId ?? ''
    }
    dispatch(feedBackActions.viewGroupFeedback(params))

    // THIS CALL FOR VALIDATE PUPROPSE OF ENGAGEMENTID
    const param: EngagementDetailsRequestParam = {
      pathParam: { id: engagementId ?? '' },
      queryParam: { roleType: roleType ?? '' }
    }
    dispatch(engagementsActions.engagementDetails(param))
  }, [])

  useEffect(() => {
    if (engagementDetailsResponse?.status === APP_ACTION_STATUS.ERROR) {
      setIsErrorFetchData(engagementDetailsResponse?.error ?? 'Something went wrong when fetching data.!')
    }
    if (groupFeedbackDetailsResponse?.status === APP_ACTION_STATUS.ERROR) {
      setIsErrorFetchData(groupFeedbackDetailsResponse?.error ?? 'Something went wrong when fetching data.!')
    }
  }, [engagementDetailsResponse?.status, groupFeedbackDetailsResponse?.status])

  const feedbackCommentViewer = (questionIndex: number) => {
    const sessionFeedbackComments: ViewCoacheeFeedbackCommentsRequestParams = {
      id: sessionId ?? '',
      feedbackId: feedbackData._id,
      questionIndex
    }
    dispatch(feedBackActions.ViewCoacheeFeedbackComments(sessionFeedbackComments))
    setDrawerOpen(true)
  }

  const toggleDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    setDrawerOpen(open)
    if (!open) dispatch(feedBackActions.clearViewCoacheeFeedbackComments())
  }
  const onBack = () => {
    navigate(getViewSessionRoute(engagementId ?? '', sessionId ?? '', roleType ?? ''), { replace: true })
  }
  return (
    <React.Fragment>
      <section className='page-root' >
        <Card className='section-card'>
          <CardContent>
            <section className='section-card-header'>
              <Typography fontWeight="600" variant='h6'>
                Feedback : {feedbackResponse?.data?.sessionName}
              </Typography>
            </section>
            <section className='section-card-body'>
              <Alert severity="info" sx={{ marginBottom: '1rem' }}>
                1 stands for &apos;Poor&lsquo; and 5 stands for &apos;Excellent&lsquo;
              </Alert>
              <>
                  {feedbackResponse?.isLoading && <FeedbackViewSkelton />}
                  {!feedbackResponse?.isLoading &&
                    <>
                      {!isNullOrUndefinedOrEmpty(isErrorFetchData) &&
                        <SomethingWentWrong
                          isShowDefault={false}
                          subNotification={isErrorFetchData}
                        />
                      }
                      {isNullOrUndefinedOrEmpty(isErrorFetchData) &&
                        <>
                          {(feedbackResponse?.data.questionsAndAnswers.length > 0 &&
                            feedbackResponse?.data.questionsAndAnswers?.map(
                              (question: ViewGroupFeedbackQnA, index: number) => (
                                <Box key={index} mt={index !== 0 ? '1rem' : ''}>
                                  <FeedbackGroupViewCard
                                    question={question}
                                    feedbackCommentViewer={feedbackCommentViewer}
                                  />
                                </Box>
                              )))}
                          {feedbackResponse?.data.questionsAndAnswers?.length === 0 &&
                            <SomethingWentWrong
                              isShowDefault={false}
                              subNotification={'No data to preview.!'}
                            />
                          }
                          <SessionFeedbackDrawer
                            drawerOpen={drawerOpen}
                            feedbackComments={feedBackComments}
                            toggleDrawer={toggleDrawer}
                          />
                        </>
                      }
                    </>
                  }
              </>
            </section>
            <section className='section-card-footer'>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={onBack}
                >
                  Back to Session
                </Button>
              </Box>
            </section>
          </CardContent>
        </Card>
      </section>
    </React.Fragment>
  )
}

export default FeedBackGroupView
