/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import {
  type GetOwnRequestParamDto,
  type AddNoteRequestParamDto,
  type NoteFormState,
  type GetOthersRequestParamDto,
  type NoteDto,
  type DeleteNoteRequestParamDto,
  type EditNoteRequestParamDto
} from '../../interfaces/notes'
import {
  type SessionDetailsRequestParam,
  type AppState,
  type EngagementDetailsRequestParam,
  type SessionCoacheePayload,
  type SessionCoacheeDataDto
} from '../../interfaces'
import {
  APP_ACTION_STATUS,
  APP_TABLE_CONFIGS,
  ENGAGEMENT_TYPES,
  FORM_VALIDATOR_TYPES,
  NOTE_TAB_TYPE
} from '../../utils/constants'
import { CoachNotes, CoacheeNotes, CoacheeNotesDrawer, MyNotes, NotesHeader } from '../../components/notes'
import { formValidator, isNullOrUndefinedOrEmpty } from '../../utils/helpers'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { engagementsActions, noteAction, sessionActions } from '../../redux/actions'
import { AppPagination } from '../../components'
import ConfirmationPopup from '../../components/common/ConfirmationPopup/ConfirmationPopup'

const Note = () => {
  const INITIAL_STATE: NoteFormState = {
    note: {
      value: '',
      isRequired: true,
      disable: false,
      validator: FORM_VALIDATOR_TYPES.TEXT,
      error: null
    }
  }
  const INITIAL_COACHEE: SessionCoacheeDataDto = {
    id: '',
    name: '',
    status: '',
    sessionStartDate: '',
    sessionEndDate: '',
    isFeedbackReceived: false,
    assessments: []
  }
  const INITIAL_NOTE: NoteDto = {
    noteId: '',
    createdAt: '',
    content: ''
  }

  const dispatch = useDispatch()
  const { roleType, engagementId, sessionId, coachId } =
    useParams<{ roleType: string, engagementId: string, sessionId: string, coachId: string }>()

  const [isErrorFetchData, setIsErrorFetchData] = useState<string>('')
  const [viewType, setViewType] = useState<NOTE_TAB_TYPE>(NOTE_TAB_TYPE.MY_NOTES)
  const [noteForm, setNoteForm] = useState<NoteFormState>(INITIAL_STATE)
  const [page, setPage] = React.useState(APP_TABLE_CONFIGS.DEFAULT_PAGE)
  const [pageCount, setPageCount] = React.useState(APP_TABLE_CONFIGS.DEFAULT_PAGE_COUNT)
  const [searchKey, setSearchKey] = React.useState<string>('')
  const [drawerOpen, setDrawerOpen] = React.useState(false)
  const [selectedCoachee, setSelectedCoachee] = React.useState<SessionCoacheeDataDto>(INITIAL_COACHEE)
  const [selectedNote, setSelectedNote] = React.useState<NoteDto>(INITIAL_NOTE)
  const [openDeletePopup, setOpenDeletePopup] = React.useState<boolean>(false)
  const [isEditNote, setIsEditNote] = React.useState<boolean>(false)

  const addNoteResponse = useSelector((state: AppState) => state.notes.addNote)
  const editNoteResponse = useSelector((state: AppState) => state.notes.editNote)
  const deleteNoteResponse = useSelector((state: AppState) => state.notes.deleteNote)
  const viewMyNoteResponse = useSelector((state: AppState) => state.notes.viewOwnNotes)
  const viewOthersNoteResponse = useSelector((state: AppState) => state.notes.viewOtherNotes)
  const engagementDetailsResponse = useSelector((state: AppState) => state.engagements.engagementDetails)
  const sessionDetailsResponse = useSelector((state: AppState) => state.session.sessionDetails)
  const sessionCoacheeListResponse = useSelector((state: AppState) => state.session.sessionCoacheeList)
  const authorizedUser = useSelector((state: AppState) => state.authUser.authorizedUser)

  useEffect(() => {
    onInit()
    return () => {
      setAsInitial()
    }
  }, [])

  const onInit = () => {
    if (roleType === ENGAGEMENT_TYPES.SUPERVISOR) {
      setViewType(NOTE_TAB_TYPE.COACH_NOTE)
      getOthersNotes(NOTE_TAB_TYPE.COACH_NOTE, coachId ?? '')
    }
    if (roleType === ENGAGEMENT_TYPES.COACH) {
      if (authorizedUser?.data?._id === coachId) {
        getMyNotes()
      } else {
        setViewType(NOTE_TAB_TYPE.COACH_NOTE)
        getOthersNotes(NOTE_TAB_TYPE.COACH_NOTE, coachId ?? '')
      }
    }
    if (roleType === ENGAGEMENT_TYPES.COACHEE) {
      getMyNotes()
    }
    // THIS CALL FOR VALIDATE PUPROPSE OF ENGAGEMENTID
    const param: EngagementDetailsRequestParam = {
      pathParam: { id: engagementId ?? '' },
      queryParam: { roleType: roleType ?? '' }
    }
    dispatch(engagementsActions.engagementDetails(param))
    // THIS CALL FOR UPDATE SESSION DETAILS
    const sessionParam: SessionDetailsRequestParam = {
      pathParam: {
        id: sessionId ?? ''
      },
      queryParam: {
        roleType: roleType ?? ''
      }
    }
    dispatch(sessionActions.getSessionDetailsById(sessionParam))
  }

  const setAsInitial = () => {
    setIsErrorFetchData('')
    dispatch(noteAction.clearAddNote())
    dispatch(noteAction.clearViewOthersNotes())
    dispatch(noteAction.clearViewOwnNotes())
    dispatch(engagementsActions.clearGetEngagementDetailById())
  }

  const getMyNotes = () => {
    const payload: GetOwnRequestParamDto = {
      roleType: roleType ?? '',
      pathParam: {
        id: sessionId ?? ''
      }
    }
    dispatch(noteAction.viewOwnNotes(payload))
  }

  const getOthersNotes = (viewType: NOTE_TAB_TYPE, id: string) => {
    const payload: GetOthersRequestParamDto = {
      requestViewType: viewType,
      pathParam: {
        id: sessionId ?? ''
      },
      queryParam: {
        coachId: viewType === NOTE_TAB_TYPE.COACH_NOTE ? id : undefined,
        coacheeId: viewType === NOTE_TAB_TYPE.COACHEE_NOTES ? id : undefined,
        roleType: roleType ?? ''
      }
    }
    dispatch(noteAction.viewOthersNotes(payload))
  }

  const getSessionCoacheeList = (searchKey: string, page: number) => {
    const sessionCoacheeList: SessionCoacheePayload = {
      pathParam: {
        id: sessionId ?? ''
      },
      requestBody: {
        searchField: searchKey,
        limit: APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE,
        page
      }
    }
    dispatch(sessionActions.getSessionCoacheeList(sessionCoacheeList))
  }

  useEffect(() => {
    if (viewMyNoteResponse?.status === APP_ACTION_STATUS.ERROR) {
      setIsErrorFetchData(viewMyNoteResponse?.error ?? 'Something went wrong when fetching data.!')
    }
    if (engagementDetailsResponse?.status === APP_ACTION_STATUS.ERROR) {
      setIsErrorFetchData(engagementDetailsResponse?.error ?? 'Something went wrong when fetching data.!')
    }
    if (sessionDetailsResponse?.status === APP_ACTION_STATUS.ERROR) {
      setIsErrorFetchData(sessionDetailsResponse?.error ?? 'Something went wrong when fetching data.!')
    }
    if (viewOthersNoteResponse?.status === APP_ACTION_STATUS.ERROR) {
      setIsErrorFetchData(viewOthersNoteResponse?.error ?? 'Something went wrong when fetching data.!')
    }
    if (sessionCoacheeListResponse?.status === APP_ACTION_STATUS.ERROR) {
      setIsErrorFetchData(sessionCoacheeListResponse?.error ?? 'Something went wrong when fetching data.!')
    }
  }, [
    viewMyNoteResponse?.status,
    engagementDetailsResponse?.status,
    sessionDetailsResponse?.status,
    sessionCoacheeListResponse?.status,
    viewOthersNoteResponse?.status
  ])

  useEffect(() => {
    if (sessionCoacheeListResponse.status === APP_ACTION_STATUS.SUCCESS) {
      const count = Math.ceil(sessionCoacheeListResponse.data.count / APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE)
      setPageCount(count)
    }
  }, [sessionCoacheeListResponse.status])

  useEffect(() => {
    if (addNoteResponse?.status === APP_ACTION_STATUS.SUCCESS) {
      getMyNotes()
      setNoteForm(INITIAL_STATE)
      dispatch(noteAction.clearAddNote())
    }
  }, [addNoteResponse?.status])

  useEffect(() => {
    if (editNoteResponse?.status === APP_ACTION_STATUS.SUCCESS) {
      getMyNotes()
      setNoteForm(INITIAL_STATE)
      setIsEditNote(false)
      setSelectedNote(INITIAL_NOTE)
      dispatch(noteAction.clearEditNote())
    }
  }, [editNoteResponse?.status])

  useEffect(() => {
    if (deleteNoteResponse?.status === APP_ACTION_STATUS.SUCCESS) {
      getMyNotes()
      setSelectedNote(INITIAL_NOTE)
      dispatch(noteAction.clearDeleteNote())
    }
  }, [deleteNoteResponse?.status])

  const handleChangeViewType = (event: React.MouseEvent<HTMLElement>, type: NOTE_TAB_TYPE) => {
    setIsErrorFetchData('')
    dispatch(noteAction.clearAddNote())
    dispatch(noteAction.clearViewOthersNotes())
    dispatch(noteAction.clearViewOwnNotes())
    dispatch(sessionActions.clearGetSessionCoacheeList())
    if (!isNullOrUndefinedOrEmpty(type)) {
      setViewType(type)
      if (type === NOTE_TAB_TYPE.MY_NOTES) {
        getMyNotes()
      }
      if (type === NOTE_TAB_TYPE.COACH_NOTE) {
        getOthersNotes(type, coachId ?? '')
      }
      if (type === NOTE_TAB_TYPE.COACHEE_NOTES) {
        getSessionCoacheeList(searchKey, page)
      }
    }
  }

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
    getSessionCoacheeList(searchKey, value)
  }

  const toggleDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    setDrawerOpen(open)
  }

  const viewCoacheeNotes = (coachee: SessionCoacheeDataDto) => {
    setSelectedCoachee(coachee)
    setDrawerOpen(true)
    getOthersNotes(viewType, coachee.id)
  }

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKey(event.target.value)
    setPage(APP_TABLE_CONFIGS.DEFAULT_PAGE)
    getSessionCoacheeList(event.target.value, APP_TABLE_CONFIGS.DEFAULT_PAGE)
  }

  const onClearSearch = () => {
    setSearchKey('')
    setPage(APP_TABLE_CONFIGS.DEFAULT_PAGE)
    getSessionCoacheeList('', APP_TABLE_CONFIGS.DEFAULT_PAGE)
  }

  const onInputHandleForm = (value: string) => {
    // Update the form state
    setNoteForm({
      ...noteForm,
      note: {
        ...noteForm.note,
        value,
        error: null
      }
    })
  }

  const addMyNote = () => {
    formValidator(noteForm)
      .then(([formValidateData, formIsValid]) => {
        setNoteForm(formValidateData)
        if (formIsValid) {
          const payload: AddNoteRequestParamDto = {
            roleType: roleType ?? '',
            pathParam: {
              id: sessionId ?? ''
            },
            bodyParam: {
              content: noteForm.note.value
            }
          }
          dispatch(noteAction.addNote(payload))
        }
      })
      .catch(e => {

      })
  }

  const editMyNote = () => {
    formValidator(noteForm)
      .then(([formValidateData, formIsValid]) => {
        setNoteForm(formValidateData)
        if (formIsValid) {
          const payload: EditNoteRequestParamDto = {
            roleType: roleType ?? '',
            pathParam: {
              id: sessionId ?? '',
              noteId: selectedNote.noteId
            },
            bodyParam: {
              content: noteForm.note.value
            }
          }
          dispatch(noteAction.editNote(payload))
        }
      })
      .catch(e => {

      })
  }

  const onDeleteNote = (con: boolean) => {
    setOpenDeletePopup(false)
    if (con) {
      const payload: DeleteNoteRequestParamDto = {
        roleType: roleType ?? '',
        pathParam: {
          id: sessionId ?? '',
          noteId: selectedNote.noteId
        }
      }
      dispatch(noteAction.deleteNote(payload))
    }
  }

  const onSelectNoteForDelete = (note: NoteDto) => {
    setOpenDeletePopup(true)
    setSelectedNote(note)
  }

  const onSelectNoteForEdit = (note: NoteDto) => {
    setNoteForm({
      ...noteForm,
      note: {
        ...noteForm.note,
        value: note.content
      }
    })
    setIsEditNote(true)
    setSelectedNote(note)
  }

  return (
    <React.Fragment>
      <section className='page-root' >
        <NotesHeader
          viewType={viewType}
          handleChangeViewType={handleChangeViewType}
        />
        {viewType === NOTE_TAB_TYPE.MY_NOTES &&
          <MyNotes
            noteForm={noteForm}
            isEditNote={isEditNote}
            addMyNote={addMyNote}
            editMyNote={editMyNote}
            isAddEditLoading={(Boolean((addNoteResponse?.isLoading))) || editNoteResponse?.isLoading}
            isLoading={(Boolean((viewMyNoteResponse?.isLoading))) || engagementDetailsResponse?.isLoading}
            viewMyNoteResponse={viewMyNoteResponse}
            onInputHandleForm={onInputHandleForm}
            isErrorFetchData={isErrorFetchData}
            onSelectNoteForDelete={onSelectNoteForDelete}
            onSelectNoteForEdit={onSelectNoteForEdit}
          />
        }
        {viewType === NOTE_TAB_TYPE.COACH_NOTE &&
          <CoachNotes
            viewOthersNoteResponse={viewOthersNoteResponse}
            isLoading={viewOthersNoteResponse?.isLoading}
            isErrorFetchData={isErrorFetchData}
          />
        }
        {viewType === NOTE_TAB_TYPE.COACHEE_NOTES &&
          <CoacheeNotes
            onSearch={onSearch}
            onClearSearch={onClearSearch}
            searchKey={searchKey}
            coacheeListResponse={sessionCoacheeListResponse}
            isErrorFetchData={isErrorFetchData}
            viewCoacheeNotes={viewCoacheeNotes}
            pagination={<AppPagination
              page={page}
              handleChangePage={handleChangePage}
              count={pageCount} />}
          />
        }
        <CoacheeNotesDrawer
          drawerOpen={drawerOpen}
          toggleDrawer={toggleDrawer}
          selectedCoachee={selectedCoachee}
          viewOthersNoteResponse={viewOthersNoteResponse}
        />
        <ConfirmationPopup
          isOpenConfirmationDialog={openDeletePopup}
          content='Are you sure you want to delete the note?'
          onCallback={onDeleteNote}
          title='Delete Note'
          cancelButtonTitle='Cancel'
          confirmButtonTitle='Confirm'
        />
      </section>
    </React.Fragment>
  )
}

export default Note
