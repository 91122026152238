import { combineReducers } from 'redux'
import { type AssessmentsState } from '../../../interfaces/assessment'
import assessmentsDetailsByIdReducer from './assessmentsDetailsById'
import assessmentSubmitReducer from './assessmentSubmit'
import assessmentResultDetailsReducer from './assessmentResultDetails'

const assessmentReducer = combineReducers<AssessmentsState>({
  assessmentDetails: assessmentsDetailsByIdReducer,
  assessmentSubmit: assessmentSubmitReducer,
  assessmentResultDetails: assessmentResultDetailsReducer
})

export default assessmentReducer
