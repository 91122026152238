import { Box, Card, CardContent, Grid, InputAdornment, Link, TextField, Typography } from '@mui/material'
import styles from './CoacheeNotes.module.scss'
import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import React from 'react'
import { TableSkeleton } from '../../skeleton'
import {
  type SessionCoacheeDataDto,
  type AsyncStateObject,
  type SessionCoacheeListDto
} from '../../../interfaces'
import { isNullOrUndefinedOrEmpty } from '../../../utils/helpers'
import { SomethingWentWrong } from '../../common'

const CoacheeNotes: React.FC<{
  coacheeListResponse: AsyncStateObject<SessionCoacheeListDto>
  pagination: React.ReactNode
  searchKey: string
  isErrorFetchData: string
  onSearch: (event: React.ChangeEvent<HTMLInputElement>) => void
  onClearSearch: () => void
  viewCoacheeNotes: (coachee: SessionCoacheeDataDto) => void
}> = ({
  coacheeListResponse,
  pagination,
  searchKey,
  onSearch,
  viewCoacheeNotes,
  isErrorFetchData,
  onClearSearch
}) => {
  return (
    <Card sx={{ marginTop: '2rem' }}>
      <CardContent className={styles.rootCard}>
        <Grid container>
          <Grid
            item
            xs={12} md={7}
            alignItems={'center'}
            sx={{ marginTop: { xs: '1rem', lg: 'center' } }}
          />
          <Grid
            item
            xs={12} md={5}
            alignItems={'center'}
            sx={{ marginTop: { xs: '1rem', lg: 'center' } }}
          >
            <TextField
              value={searchKey}
              onChange={onSearch}
              placeholder="Search"
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    {searchKey.length > 0
                      ? (
                        <ClearIcon onClick={onClearSearch} style={{ cursor: 'pointer' }} />
                        )
                      : (
                        <SearchIcon />
                        )}
                  </InputAdornment>
                )
              }}
              sx={{ width: '100%' }}
            />
          </Grid>
        </Grid>
        {coacheeListResponse?.isLoading &&
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
            <TableSkeleton />
          </div>
        }
        {!coacheeListResponse?.isLoading &&
          <>
            {!isNullOrUndefinedOrEmpty(isErrorFetchData) &&
              <SomethingWentWrong
                height='calc(100% - 5rem)'
                isShowDefault={false}
                subNotification={isErrorFetchData}
              />
            }
            {isNullOrUndefinedOrEmpty(isErrorFetchData) &&
              <>
                <Card sx={{
                  minWidth: 275,
                  borderRadius: '1rem',
                  border: '1px solid #DAE0E6',
                  boxShadow: 'none',
                  marginTop: '3rem'
                }}>
                  {coacheeListResponse?.data?.data.length > 0 &&
                    coacheeListResponse?.data?.data.map((coachee: SessionCoacheeDataDto, index: number) => (
                      <CardContent key={index}
                        sx={{
                          borderBottom: '0.1rem solid #DAE0E6',
                          boxShadow: 'none',
                          marginBottom: '0rem',
                          padding: '1.5rem'
                        }}
                      >
                        <Grid container alignItems="center">
                          <Grid item xs={11} md={10}>
                            <Typography
                              fontWeight="600"
                              variant="subtitle1"
                              align="left"
                              style={{ wordWrap: 'break-word' }}
                            >
                              {coachee.name}
                            </Typography>
                          </Grid>
                          <Grid xs={1} md={2} item>
                            <Link onClick={() => { viewCoacheeNotes(coachee) }} sx={{ cursor: 'pointer' }}>
                              <Typography
                                color='primary'
                                fontSize='1rem'
                                align="right"
                                fontWeight={600}>View Note</Typography>
                            </Link>
                          </Grid>
                        </Grid>
                      </CardContent>
                    ))}
                    {coacheeListResponse?.data?.data.length === 0 && (
                      <div className={styles.noDataNotification}>
                        There are no coachee to preview.!
                      </div>
                    )}
                </Card>
                <Box
                  sx={{ height: '5rem', marginTop: '2rem' }}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  {pagination}
                </Box>
              </>
            }
          </>
        }
      </CardContent>
    </Card>
  )
}

export default CoacheeNotes
