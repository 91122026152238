import { type Action } from '../../interfaces'
import { COMMON_ACTIONS, PRIORITY_ACTIONS } from '../../utils/constants'

const getPriorityList = (): Action<null> => {
  return { type: PRIORITY_ACTIONS.GET_PRIORITY_LIST + COMMON_ACTIONS.REQUEST, data: null }
}

export const priorityAction = {
  getPriorityList
}
