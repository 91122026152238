/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { type AxiosResponse } from 'axios'
import {
  type AlertMessage,
  type Action,
  type SessionTypeDto,
  type FeedBackCreateResponse,
  type sessionRequestPayLoad,
  type CompleteSessionResponse,
  type CompleteSessionPayLoad,
  type SessionDetails,
  type SessionCoacheePayload,
  type SessionCoacheeListDto,
  type SessionCoacheeDetails,
  type SessionDetailsRequestParam,
  type editSessionPayLoad,
  type SessionDeleteResponse,
  type SessionDeleteRequestParam
} from '../../interfaces'
import { ALERT_ACTIONS, ALERT_VARIANT, COMMON_ACTIONS, SESSION_ACTIONS } from '../../utils/constants'
import { call, put, takeEvery } from 'redux-saga/effects'
import { sessionService } from '../../services'

function * sessionDetails (action: Action<SessionDetailsRequestParam>) {
  try {
    const response: Awaited<ReturnType<typeof sessionService.sessionDetails>> =
      yield call(sessionService.sessionDetails, action.data)
    yield put<Action<SessionDetails>>({
      type: SESSION_ACTIONS.GET_SESSION_DETAILS_BY_ID + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
  } catch (error) {
    yield put<Action<SessionDetails | null>>({
      type: SESSION_ACTIONS.GET_SESSION_DETAILS_BY_ID + COMMON_ACTIONS.ERROR,
      data: null,
      error: error as string
    })
  }
}

function * startAndCompleteSession (action: Action<CompleteSessionPayLoad>) {
  try {
    const response: Awaited<ReturnType<typeof sessionService.startAndCompleteSession>> =
      yield call(sessionService.startAndCompleteSession, action.data)
    yield put<Action<CompleteSessionResponse>>({
      type: SESSION_ACTIONS.UPDATE_SESSION_STATUS_BY_ID + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
    const alert: AlertMessage = {
      message: response.data.message,
      options: {
        variant: ALERT_VARIANT.SUCCESS,
        autoHideDuration: 5000
      }
    }
    yield put<Action<AlertMessage>>({
      type: ALERT_ACTIONS.TRIGGER_ALERT_MESSAGE,
      data: alert
    })
  } catch (error) {
    const e = error as any
    yield put<Action<CompleteSessionResponse>>({
      type: SESSION_ACTIONS.UPDATE_SESSION_STATUS_BY_ID + COMMON_ACTIONS.ERROR,
      data: {
        message: e.response?.data?.message
      },
      error: e.response?.data?.message
    })
  }
}

function * getSessionTypeList (action: Action<null>) {
  try {
    const response: AxiosResponse<SessionTypeDto[]> = yield call(sessionService.getSessionTypeList)
    yield put<Action<SessionTypeDto[]>>({
      type: SESSION_ACTIONS.GET_SESSION_TYPE_LIST + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
  } catch (error) {
    yield put<Action<SessionTypeDto[]>>({
      type: SESSION_ACTIONS.GET_SESSION_TYPE_LIST + COMMON_ACTIONS.ERROR,
      data: [],
      error: error as string
    })
  }
}
function * createSession (action: Action<sessionRequestPayLoad>) {
  try {
    const response: AxiosResponse<FeedBackCreateResponse> = yield call(
      sessionService.createSession, action.data)
    yield put<Action<FeedBackCreateResponse>>({
      type: SESSION_ACTIONS.CREATE_SESSION + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
    const alert: AlertMessage = {
      message: response.data.message,
      options: {
        variant: ALERT_VARIANT.SUCCESS,
        autoHideDuration: 5000
      }
    }
    yield put<Action<AlertMessage>>({
      type: ALERT_ACTIONS.TRIGGER_ALERT_MESSAGE,
      data: alert
    })
  } catch (e) {
    yield put<Action<FeedBackCreateResponse>>({
      type: SESSION_ACTIONS.CREATE_SESSION + COMMON_ACTIONS.ERROR,
      data: { message: '' },
      error: e as string
    })
    const alert: AlertMessage = {
      message: e as string,
      options: {
        variant: ALERT_VARIANT.ERROR
      }
    }
    yield put<Action<AlertMessage>>({
      type: ALERT_ACTIONS.TRIGGER_ALERT_MESSAGE,
      data: alert
    })
  }
}
function * editSession (action: Action<editSessionPayLoad>) {
  try {
    const response: AxiosResponse<FeedBackCreateResponse> = yield call(
      sessionService.editSession, action.data)
    yield put<Action<FeedBackCreateResponse>>({
      type: SESSION_ACTIONS.UPDATE_SESSION + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
    const alert: AlertMessage = {
      message: response.data.message,
      options: {
        variant: ALERT_VARIANT.SUCCESS,
        autoHideDuration: 5000
      }
    }
    yield put<Action<AlertMessage>>({
      type: ALERT_ACTIONS.TRIGGER_ALERT_MESSAGE,
      data: alert
    })
  } catch (e) {
    yield put<Action<FeedBackCreateResponse>>({
      type: SESSION_ACTIONS.UPDATE_SESSION + COMMON_ACTIONS.ERROR,
      data: { message: '' },
      error: e as string
    })
    const alert: AlertMessage = {
      message: e as string,
      options: {
        variant: ALERT_VARIANT.ERROR
      }
    }
    yield put<Action<AlertMessage>>({
      type: ALERT_ACTIONS.TRIGGER_ALERT_MESSAGE,
      data: alert
    })
  }
}
function * getSessionCoacheeList (action: Action<SessionCoacheePayload>) {
  try {
    const response: Awaited<ReturnType<typeof sessionService.getSessionCoacheeList>> =
     yield call(sessionService.getSessionCoacheeList, action.data)
    yield put<Action<SessionCoacheeListDto>>({
      type: SESSION_ACTIONS.GET_SESSION_COACHEE_LIST + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
  } catch (error) {
    yield put<Action<SessionCoacheeListDto>>({
      type: SESSION_ACTIONS.GET_SESSION_COACHEE_LIST + COMMON_ACTIONS.ERROR,
      data: {
        count: 0,
        data: []
      },
      error: error as string
    })
  }
}

function * sessionCoacheeDetails (action: Action<string>) {
  try {
    const response: Awaited<ReturnType<typeof sessionService.sessionCoacheeDetails>> =
     yield call(sessionService.sessionCoacheeDetails, action.data)
    yield put<Action<any>>({
      type: SESSION_ACTIONS.GET_SESSION_COACHEE_DETAILS + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
  } catch (error) {
    yield put<Action<SessionCoacheeDetails>>({
      type: SESSION_ACTIONS.GET_SESSION_COACHEE_DETAILS + COMMON_ACTIONS.ERROR,
      data: {
        totalCoachees: 0,
        sessionCompleted: 0,
        sessionNotStarted: 0,
        assessmentCompleted: 0
      },
      error: error as string
    })
  }
}

function * sessionDelete (action: Action<SessionDeleteRequestParam>) {
  try {
    const response: AxiosResponse<SessionDeleteResponse> = yield call(sessionService.sessionDelete, action.data)
    yield put<Action<SessionDeleteResponse>>({
      type: SESSION_ACTIONS.SESSION_DELETE + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
    const alert: AlertMessage = {
      message: response.data.message,
      options: {
        variant: ALERT_VARIANT.SUCCESS,
        autoHideDuration: 5000
      }
    }
    yield put<Action<AlertMessage>>({
      type: ALERT_ACTIONS.TRIGGER_ALERT_MESSAGE,
      data: alert
    })
  } catch (error) {
    yield put<Action<SessionDeleteResponse>>({
      type: SESSION_ACTIONS.SESSION_DELETE + COMMON_ACTIONS.ERROR,
      data: {
        message: ''
      },
      error: error as string
    })
    const alert: AlertMessage = {
      message: error as string,
      options: {
        variant: ALERT_VARIANT.ERROR
      }
    }
    yield put<Action<AlertMessage>>({
      type: ALERT_ACTIONS.TRIGGER_ALERT_MESSAGE,
      data: alert
    })
  }
}

function * sessionSagas () {
  yield takeEvery(SESSION_ACTIONS.GET_SESSION_DETAILS_BY_ID + COMMON_ACTIONS.REQUEST, sessionDetails)
  yield takeEvery(SESSION_ACTIONS.UPDATE_SESSION_STATUS_BY_ID + COMMON_ACTIONS.REQUEST, startAndCompleteSession)
  yield takeEvery(SESSION_ACTIONS.GET_SESSION_TYPE_LIST + COMMON_ACTIONS.REQUEST, getSessionTypeList)
  yield takeEvery(SESSION_ACTIONS.CREATE_SESSION + COMMON_ACTIONS.REQUEST, createSession)
  yield takeEvery(SESSION_ACTIONS.UPDATE_SESSION + COMMON_ACTIONS.REQUEST, editSession)
  yield takeEvery(SESSION_ACTIONS.GET_SESSION_COACHEE_LIST + COMMON_ACTIONS.REQUEST, getSessionCoacheeList)
  yield takeEvery(SESSION_ACTIONS.GET_SESSION_COACHEE_DETAILS + COMMON_ACTIONS.REQUEST, sessionCoacheeDetails)
  yield takeEvery(SESSION_ACTIONS.SESSION_DELETE + COMMON_ACTIONS.REQUEST, sessionDelete)
}
export default sessionSagas
