import { type Action } from '../../interfaces'
import { type DeleteFileRequestParam } from '../../interfaces/fileDelete'
import {
  type AddToQueDto,
  type FileUploadURLFields, type FileUploadURLParams
} from '../../interfaces/fileUpload'
import { COMMON_ACTIONS, FILE_ACTIONS } from '../../utils/constants'

const fileUploadURL = (params: FileUploadURLParams): Action<FileUploadURLParams> => {
  return { type: FILE_ACTIONS.GET_FILE_UPLOAD_URL + COMMON_ACTIONS.REQUEST, data: params }
}

const saveFileToS3 = (payload: FileUploadURLFields): Action<FileUploadURLFields> => {
  return { type: FILE_ACTIONS.SAVE_FILE_TO_S3 + COMMON_ACTIONS.REQUEST, data: payload }
}

const addToQue = (payload: AddToQueDto) => {
  return { type: FILE_ACTIONS.SAVE_FILE_TO_QUE + COMMON_ACTIONS.REQUEST, data: payload }
}

const clearfileUploadURL = (): Action<null> => {
  return { type: FILE_ACTIONS.GET_FILE_UPLOAD_URL + COMMON_ACTIONS.CLEAR, data: null }
}

const downloadFile = (params: string): Action<string> => {
  return { type: FILE_ACTIONS.DOWNLOAD_FILE + COMMON_ACTIONS.REQUEST, data: params }
}

const clearDownloadFile = (): Action<null> => {
  return { type: FILE_ACTIONS.DOWNLOAD_FILE + COMMON_ACTIONS.CLEAR, data: null }
}

const fileDelete = (params: DeleteFileRequestParam): Action<DeleteFileRequestParam> => {
  return { type: FILE_ACTIONS.DELETE_FILE + COMMON_ACTIONS.REQUEST, data: params }
}

const clearFileDelete = (): Action<null> => {
  return { type: FILE_ACTIONS.DELETE_FILE + COMMON_ACTIONS.CLEAR, data: null }
}

export const fileActions = {
  fileUploadURL,
  saveFileToS3,
  addToQue,
  clearfileUploadURL,
  downloadFile,
  clearDownloadFile,
  clearFileDelete,
  fileDelete
}
