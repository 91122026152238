import { type AxiosResponse } from 'axios'
import { axiosPrivateAPI } from '.'
import {
  type CoacheeGoalSummaryDto,
  type CoacheeSessionSummaryDto,
  type CoacheeStatsResponse,
  type CoachGoalSummaryDto,
  type CoachSessionSummaryDto,
  type CoachStatsResponse,
  type RecentEngagementAsCoacheeResponse,
  type RecentEngagementAsCoachResponse,
  type SupervisorStatsResponse,
  type CoacheeGoalSummaryRequestParam,
  type CoacheeSessionSummaryRequestParam,
  type CoachGoalSummaryRequestParam,
  type CoachSessionSummaryRequestParam
} from '../interfaces'

const getCoacheeStats = async ():
Promise<AxiosResponse<CoacheeStatsResponse>> => {
  return await axiosPrivateAPI.get('/api/v1/dashboard/coachee-stats')
}

const getCoacheeGoalSummary = async (params: CoacheeGoalSummaryRequestParam):
Promise<AxiosResponse<CoacheeGoalSummaryDto[]>> => {
  return await axiosPrivateAPI.get('/api/v1/dashboard/coachee-goal-summary', { params })
}

const getCoacheeSessionSummary = async (params: CoacheeSessionSummaryRequestParam):
Promise<AxiosResponse<CoacheeSessionSummaryDto[]>> => {
  return await axiosPrivateAPI.get('/api/v1/dashboard/coachee-session-summary', { params })
}

const getCoacheeRecentEngagement = async ():
Promise<AxiosResponse<RecentEngagementAsCoacheeResponse>> => {
  return await axiosPrivateAPI.get('/api/v1/dashboard/coachee-recent-engagement')
}

const getCoachStats = async ():
Promise<AxiosResponse<CoachStatsResponse>> => {
  return await axiosPrivateAPI.get('/api/v1/dashboard/coach-stats')
}

const getCoachGoalSummary = async (params: CoachGoalSummaryRequestParam):
Promise<AxiosResponse<CoachGoalSummaryDto[]>> => {
  return await axiosPrivateAPI.get('/api/v1/dashboard/coach-goal-summary', { params })
}

const getCoachSessoinSummary = async (params: CoachSessionSummaryRequestParam):
Promise<AxiosResponse<CoachSessionSummaryDto[]>> => {
  return await axiosPrivateAPI.get('/api/v1/dashboard/coach-session-summary', { params })
}

const getCoachRecentEngagement = async ():
Promise<AxiosResponse<RecentEngagementAsCoachResponse>> => {
  return await axiosPrivateAPI.get('/api/v1/dashboard/coach-recent-engagement')
}

const getSupervisorStats = async ():
Promise<AxiosResponse<SupervisorStatsResponse>> => {
  return await axiosPrivateAPI.get('/api/v1/dashboard/supervisor-stats')
}

export const dashboardService = {
  getCoacheeStats,
  getCoacheeGoalSummary,
  getCoacheeSessionSummary,
  getCoacheeRecentEngagement,
  getCoachStats,
  getCoachGoalSummary,
  getCoachSessoinSummary,
  getCoachRecentEngagement,
  getSupervisorStats
}
