import { type Action, type AsyncStateObject, type GoalListSummeryResponse } from '../../../interfaces'
import { APP_ACTION_STATUS, COMMON_ACTIONS, GOAL_ACTIONS } from '../../../utils/constants'

const INITIAL_STATE: AsyncStateObject<any> = {
  data: { count: 0, data: [] },
  isLoading: false,
  status: APP_ACTION_STATUS.INITIAL,
  error: undefined
}

const getGoalListSummaryReducer = (
  state = INITIAL_STATE,
  action: Action<GoalListSummeryResponse>
): AsyncStateObject<GoalListSummeryResponse> => {
  switch (action.type) {
    case GOAL_ACTIONS.GET_GOAL_LIST_SUMMARY +
     COMMON_ACTIONS.REQUEST:
      return {
        ...state,
        isLoading: true,
        status: APP_ACTION_STATUS.LOADING,
        error: undefined
      }
    case GOAL_ACTIONS.GET_GOAL_LIST_SUMMARY + COMMON_ACTIONS.SUCCESS:
      return {
        ...state,
        isLoading: false,
        status: APP_ACTION_STATUS.SUCCESS,
        data: action.data
      }
    case GOAL_ACTIONS.GET_GOAL_LIST_SUMMARY + COMMON_ACTIONS.ERROR:
      return {
        ...state,
        isLoading: false,
        data: { count: 0, data: [] },
        status: APP_ACTION_STATUS.ERROR,
        error: action.error
      }
    case GOAL_ACTIONS.GET_GOAL_LIST_SUMMARY + COMMON_ACTIONS.CLEAR:
      return INITIAL_STATE
    default:
      return state
  }
}

export default getGoalListSummaryReducer
