import { type Action, type AsyncStateObject, type SessionDetails } from '../../../interfaces'
import { APP_ACTION_STATUS, COMMON_ACTIONS, SESSION_ACTIONS } from '../../../utils/constants'

const INITIAL_STATE: AsyncStateObject<SessionDetails> = {
  data: {
    userId: '',
    engagementId: '',
    sessionId: '',
    name: '',
    description: '',
    type: '',
    isAssesmentTaken: false,
    isAssessmentCompulsory: false,
    sessionAssessmentId: '',
    startDate: '',
    sessionDuration: '',
    status: '',
    coacheeCount: 0,
    isReviewDone: false,
    roles: [],
    coachList: [],
    attachments: [],
    videos: [],
    feedback: [],
    assessments: []
  },
  isLoading: false,
  status: APP_ACTION_STATUS.INITIAL,
  error: undefined
}

const sessionDetailsReducer = (state = INITIAL_STATE, action: Action<SessionDetails>) => {
  switch (action.type) {
    case SESSION_ACTIONS.GET_SESSION_DETAILS_BY_ID + COMMON_ACTIONS.REQUEST:
      return {
        ...state,
        isLoading: true,
        status: APP_ACTION_STATUS.LOADING,
        error: undefined
      }
    case SESSION_ACTIONS.GET_SESSION_DETAILS_BY_ID + COMMON_ACTIONS.SUCCESS:
      return {
        ...state,
        isLoading: false,
        status: APP_ACTION_STATUS.SUCCESS,
        data: action.data
      }
    case SESSION_ACTIONS.GET_SESSION_DETAILS_BY_ID + COMMON_ACTIONS.ERROR:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        status: APP_ACTION_STATUS.ERROR,
        error: action.error
      }
    case SESSION_ACTIONS.GET_SESSION_DETAILS_BY_ID + COMMON_ACTIONS.CLEAR:
      return INITIAL_STATE
    default:
      return state
  }
}
export default sessionDetailsReducer
