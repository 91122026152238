import React, { useEffect, useState } from 'react'
import { AppPagination, EngagementViewCard, EngagementViewHeader } from '../../components'
import EngagementViewDrawer from '../../components/engagement/EngagementViewDrawer/EngagementViewDrawer'
import EngagementTabPanel from '../../components/engagement/EngagementTabPanel/EngagementTabPanel'
import {
  APP_ACTION_STATUS,
  ENGAGEMENT_TYPES,
  SESSION_TYPES,
  APP_TABLE_CONFIGS,
  APP_CONFIGS,
  APP_ROUTES,
  getEngagementEditRoute,
  getEngagementViewRoute,
  ENGAGEMENT_STATUS
} from '../../utils/constants'
import { useSelector, useDispatch } from 'react-redux'
import {
  type EngagementListData,
  type AllEngagementRequestPayload,
  type AppState,
  type MyEngagementRequestPayload
} from '../../interfaces'
import { engagementsActions, statusAction } from '../../redux/actions'
import { useNavigate, useParams } from 'react-router-dom'
import { isNullOrUndefinedOrEmpty } from '../../utils/helpers'

const EngagementView = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { roleType } = useParams<{ roleType: string }>()
  const myEngagementAsCoacheeCount =
  useSelector((state: AppState) => state.engagements.getMyEngagementListAsCoachee.data.count)
  const myEngagementAsCoachCount =
  useSelector((state: AppState) => state.engagements.getMyEngagementListAsCoach.data.count)
  const allEngagementCount = useSelector((state: AppState) => state.engagements.getAllEngagementList)
  const engagementStatusResponse = useSelector((state: AppState) => state.status.engagementStatusList)
  const myEngagementListAsCoacheeResponse = useSelector(
    (state: AppState) => state.engagements.getMyEngagementListAsCoachee)
  const myEngagementListAsCoachResposne = useSelector(
    (state: AppState) => state.engagements.getMyEngagementListAsCoach)
  const allEngagementListResponse = useSelector((state: AppState) => state.engagements.getAllEngagementList)

  const [drawerOpen, setDrawerOpen] = useState(false)
  const [engagementType, setEngagementType] = React.useState<ENGAGEMENT_TYPES>(ENGAGEMENT_TYPES.COACHEE)
  const [statusIds, setStatusIds] = React.useState<number[]>([])

  const [page, setPage] = React.useState(APP_TABLE_CONFIGS.DEFAULT_PAGE)
  const [pageCount, setPageCount] = React.useState(APP_TABLE_CONFIGS.DEFAULT_PAGE_COUNT)
  const [searchKey, setSearchKey] = React.useState<string>('')
  const loggedUserFromAPI: any = localStorage.getItem(APP_CONFIGS.LOGGED_USER)
  const loggedInUser = loggedUserFromAPI.substring(1, loggedUserFromAPI.length - 1)

  const clearSearch = () => {
    setSearchKey('')
    setPage(APP_TABLE_CONFIGS.DEFAULT_PAGE)
    const getAllEngagementRequestPayload: AllEngagementRequestPayload = {
      pathParam: {
        username: loggedInUser
      },
      requestBody: {
        searchField: '',
        limit: APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE,
        page: APP_TABLE_CONFIGS.DEFAULT_PAGE
      }
    }
    void getAllEngagement(getAllEngagementRequestPayloadObjectCreate(getAllEngagementRequestPayload))
  }

  //  Search Goal by Goal Name or Tag
  const onSearchEngagement = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event?.target?.value
    setSearchKey(inputValue)
    setPage(APP_TABLE_CONFIGS.DEFAULT_PAGE)

    // Check if the input value is empty and clear the search if needed
    if (inputValue.trim().length === 0) {
      clearSearch()
    }

    if (engagementType === ENGAGEMENT_TYPES.COACHEE) {
      void getMyEngagementAsCoachee(getMyEngagementAsCoacheeRequestPayloadObjectCreate(inputValue, page))
    } else if (engagementType === ENGAGEMENT_TYPES.COACH) {
      void getMyEngagementAsCoach(getMyEngagementAsCoachRequestPayloadObjectCreate(inputValue, page))
    } else {
      const getAllEngagementRequestPayload: AllEngagementRequestPayload = {
        pathParam: {
          username: loggedInUser
        },
        requestBody: {
          searchField: inputValue,
          limit: APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE,
          page: APP_TABLE_CONFIGS.DEFAULT_PAGE
        }
      }
      void getAllEngagement(getAllEngagementRequestPayloadObjectCreate(getAllEngagementRequestPayload))
    }
  }

  useEffect(() => {
    // void getGoalByUser(getGoalByUserRequestParamsObjectCreate())
    if (!isNullOrUndefinedOrEmpty(roleType)) {
      if (roleType === ENGAGEMENT_TYPES.SUPERVISOR) {
        setEngagementType(ENGAGEMENT_TYPES.SUPERVISOR)
      } else if (roleType === ENGAGEMENT_TYPES.COACH) {
        setEngagementType(ENGAGEMENT_TYPES.COACH)
      } else {
        setEngagementType(ENGAGEMENT_TYPES.COACHEE)
      }
    }
    void getAllEngagement(getAllEngagementRequestPayloadObjectCreate())
    void getMyEngagementAsCoachee(getMyEngagementAsCoacheeRequestPayloadObjectCreate(searchKey, page))
    void getMyEngagementAsCoach(getMyEngagementAsCoachRequestPayloadObjectCreate(searchKey, page))
    void getEngagementStatus()
  }, [])

  // for find how many pages displays
  useEffect(() => {
    if (allEngagementListResponse.status === APP_ACTION_STATUS.SUCCESS ||
      myEngagementListAsCoachResposne.status === APP_ACTION_STATUS.SUCCESS ||
      myEngagementListAsCoacheeResponse.status === APP_ACTION_STATUS.SUCCESS) {
      if (engagementType === ENGAGEMENT_TYPES.COACHEE) {
        const count = Math.ceil(myEngagementListAsCoacheeResponse.data.count / APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE)
        setPageCount(count)
      } else if (engagementType === ENGAGEMENT_TYPES.COACH) {
        const count = Math.ceil(myEngagementListAsCoachResposne.data.count / APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE)
        setPageCount(count)
      } else {
        const count = Math.ceil(allEngagementListResponse.data.count / APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE)
        setPageCount(count)
      }
    }
  }, [allEngagementListResponse.status, myEngagementListAsCoachResposne.status,
    myEngagementListAsCoacheeResponse.status, engagementType])

  // open close Drawer
  const toggleDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    setDrawerOpen(open)
    setStatusIds([])
  }

  // handle filter function according statusList
  const handleFilterStatus = (event: React.ChangeEvent<HTMLInputElement>, statusId: number) => {
    if (event.target.checked) {
      // statusArray.push(statusId)
      setStatusIds(prev => [...prev, statusId])
    } else {
      setStatusIds(statusIds.filter(item => item !== statusId))
    }
  }

  const resetSelectedFilters = () => {
    setDrawerOpen(false)
    setStatusIds([])
  }

  // Click Continue button in Drawer
  const filterEngagementsByStatusOrBySessionCoachType = () => {
    if (engagementType === ENGAGEMENT_TYPES.COACHEE) {
      void getMyEngagementAsCoachee(getMyEngagementAsCoacheeRequestPayloadObjectCreate(searchKey, page))
    } else if (engagementType === ENGAGEMENT_TYPES.COACH) {
      void getMyEngagementAsCoach(getMyEngagementAsCoachRequestPayloadObjectCreate(searchKey, page))
    } else {
      const getAllEngagementList: AllEngagementRequestPayload = {
        requestBody: {
          limit: APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE,
          page: APP_TABLE_CONFIGS.DEFAULT_PAGE
        },
        pathParam: {
          username: loggedInUser
        }
      }
      void getAllEngagement(getAllEngagementRequestPayloadObjectCreate(getAllEngagementList))
    }
    setDrawerOpen(false)
    setPage(APP_TABLE_CONFIGS.DEFAULT_PAGE)
  }
  // Navigate between My Engagement and All Engagement
  const handleChangeEngagementType = (event: React.MouseEvent<HTMLElement>, newType: ENGAGEMENT_TYPES) => {
    if (newType !== null) {
      setStatusIds([])
      setEngagementType(newType)
      setPage(APP_TABLE_CONFIGS.DEFAULT_PAGE)
      if (newType === ENGAGEMENT_TYPES.COACHEE) {
        void getMyEngagementAsCoachee(getMyEngagementAsCoacheeRequestPayloadObjectCreate(searchKey, page))
      } else if (newType === ENGAGEMENT_TYPES.COACH) {
        void getMyEngagementAsCoach(getMyEngagementAsCoachRequestPayloadObjectCreate(searchKey, page))
      } else {
        setStatusIds([])
        const getAllEngagementRequestPayload: AllEngagementRequestPayload = {
          pathParam: {
            username: loggedInUser
          },
          requestBody: {
            statusIds: undefined,
            searchField: undefined,
            page: APP_TABLE_CONFIGS.DEFAULT_PAGE,
            limit: APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE
          }
        }
        void getAllEngagement(getAllEngagementRequestPayloadObjectCreate(getAllEngagementRequestPayload))
      }
    }
  }

  const getMyEngagementAsCoachRequestPayloadObjectCreate =
  (key: string, page: number): MyEngagementRequestPayload => {
    let _statusIds

    // Assuming statusIds is present in data?.requestBody object
    // const statusIds = data?.requestBody.statusIds

    if (statusIds?.length === 0) {
      _statusIds = undefined
    } else {
      if (statusIds != null && statusIds.length > 0) {
        _statusIds = statusIds
      } else {
        _statusIds = undefined
      }
    }

    // // Assuming roleChecked is present in the scope of the function

    const myEngagementRequestPayload: MyEngagementRequestPayload = {
      pathParam: {
        username: loggedInUser
      },
      requestBody: {
        statusIds: _statusIds,
        isEngagementCoach: true,
        isSessionCoach: true,
        isCoachee: false,
        searchField: key,
        limit: APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE,
        page
      }
    }
    return myEngagementRequestPayload
  }

  const getMyEngagementAsCoacheeRequestPayloadObjectCreate =
  (key: string, page: number): MyEngagementRequestPayload => {
    let _statusIds
    // Assuming statusIds is present in data?.requestBody object
    // const statusIds = data?.requestBody.statusIds

    if (statusIds?.length === 0) {
      _statusIds = undefined
    } else {
      if (statusIds != null && statusIds.length > 0) {
        _statusIds = statusIds
      } else {
        _statusIds = undefined
      }
    }

    // // Assuming roleChecked is present in the scope of the function

    const myEngagementRequestPayload: MyEngagementRequestPayload = {
      pathParam: {
        username: loggedInUser
      },
      requestBody: {
        statusIds: _statusIds,
        isEngagementCoach: false,
        isSessionCoach: false,
        isCoachee: true,
        searchField: key,
        limit: APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE,
        page
      }
    }
    return myEngagementRequestPayload
  }

  const getAllEngagementRequestPayloadObjectCreate =
    (data?: AllEngagementRequestPayload): AllEngagementRequestPayload => {
      let _statusIds

      if (data?.requestBody.statusIds?.length === 0) {
        _statusIds = undefined
      } else {
        if (statusIds.length > 0) {
          _statusIds = statusIds
        } else {
          _statusIds = undefined
        }
      }

      // engagement by assignee (for my engagements)
      const allEngagementRequestPayload: AllEngagementRequestPayload = {
        pathParam: {
          username: loggedInUser
        },
        requestBody: {
          statusIds: _statusIds,
          searchField: data?.requestBody.searchField !== ''
            ? data?.requestBody.searchField
            : '',
          limit: APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE,
          page: data?.requestBody.page === undefined ? page : data?.requestBody.page
        }

      }
      return allEngagementRequestPayload
    }

  // Taking myEngagements
  const getMyEngagementAsCoachee = async (requestPayload: MyEngagementRequestPayload) => {
    dispatch(engagementsActions.getMyEngagementListAsCoachee(requestPayload))
    dispatch(engagementsActions.clearUpdateEngagement())
  }
  const getMyEngagementAsCoach = async (requestPayload: MyEngagementRequestPayload) => {
    dispatch(engagementsActions.getMyEngagementListAsCoach(requestPayload))
    dispatch(engagementsActions.clearUpdateEngagement())
  }
  // Taking AllEngagements
  const getAllEngagement = async (requestPayload: AllEngagementRequestPayload) => {
    dispatch(engagementsActions.getAllEngagementList(requestPayload))
  }
  // Get Engagement Status
  const getEngagementStatus = async () => {
    dispatch(statusAction.getEngagementStatus())
  }
  // page handle in engagement pages
  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)

    if (engagementType === ENGAGEMENT_TYPES.COACHEE) {
      void getMyEngagementAsCoachee(getMyEngagementAsCoacheeRequestPayloadObjectCreate(searchKey, value))
    } else if (engagementType === ENGAGEMENT_TYPES.COACH) {
      void getMyEngagementAsCoach(getMyEngagementAsCoachRequestPayloadObjectCreate(searchKey, value))
    } else {
      const allEngagementRequestPayload: AllEngagementRequestPayload = {
        requestBody: {
          page: value,
          limit: APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE
        },
        pathParam: {
          username: loggedInUser
        }
      }
      void getAllEngagement(getAllEngagementRequestPayloadObjectCreate(allEngagementRequestPayload))
    }
  }

  // Engagement session status preview
  const sessionStatus = (session: any) => {
    const completedSessions: string = session.noOfCompletedSessions.toString()
    const sessions: string = session.noOfSessions.toString()
    if (session.type === SESSION_TYPES.COMPLETED) {
      return `${completedSessions} / ${sessions} Sessions completed`
    } else if (session.type === SESSION_TYPES.ASSIGNED) {
      return `${completedSessions} Session Assigned`
    } else {
      return `${completedSessions} / ${sessions} Sessions Conducted`
    }
  }
  // const loggedUser = 'vimukthi.ranaweera@acentura.com'

  const EditOptionEnable = (engagement: EngagementListData): boolean => {
    if (engagement.createdBy === loggedInUser) {
      return engagement.status !== ENGAGEMENT_STATUS.COMPLETED
    } else {
      return false
    }
  }

  const handleEditClick = (engagement: EngagementListData) => {
    navigate(getEngagementEditRoute(engagement._id, engagementType))
  }

  const viewEngagementById = (engagementId: string) => {
    navigate(getEngagementViewRoute(engagementId, engagementType))
  }

  const onClickCreate = () => {
    navigate(APP_ROUTES.ENGAGEMENT_CREATE)
  }
  return (
    <React.Fragment>
      <section className='page-root' >
        <EngagementViewHeader
          onClickCreate={onClickCreate}
        />
        <EngagementTabPanel
          onSearchEngagement={onSearchEngagement}
          clearSearch={clearSearch}
          searchKey={searchKey}
          toggleDrawer={toggleDrawer}
          engagementType={engagementType}
          handleChangeEngagementType={handleChangeEngagementType}
          myEngagementCountAsCoachee={myEngagementAsCoacheeCount}
          myEngagementCountAsCoach={myEngagementAsCoachCount}
          allEngagementCount={allEngagementCount.data.count}
        />
        <EngagementViewCard
          searchKey={searchKey}
          viewEngagementById={viewEngagementById}
          engagementList={engagementType === ENGAGEMENT_TYPES.COACHEE
            ? myEngagementListAsCoacheeResponse
            : engagementType === ENGAGEMENT_TYPES.COACH
              ? myEngagementListAsCoachResposne
              : allEngagementListResponse}
          sessionStatus={sessionStatus}
          engagementType={engagementType}
          pagination={<AppPagination
            page={page}
            handleChangePage={handleChangePage}
            count={pageCount} />}
          EditOptionEnable={EditOptionEnable}
          handleEditClick={handleEditClick}
        />
        <EngagementViewDrawer
          drawerState={drawerOpen}
          toggleDrawer={toggleDrawer}
          engagementStatus={engagementStatusResponse.data}
          handleFilterStatus={handleFilterStatus}
          resetSelectedFilters={resetSelectedFilters}
          statusIds={statusIds}
          filterEngagementsByStatusOrBySessionCoachType={filterEngagementsByStatusOrBySessionCoachType}

        />
      </section>
    </React.Fragment>

  )
}

export default EngagementView
