import { type ApplicationBreadCrumbs } from '../../interfaces'
import { BREAD_CRUB_TEXTS } from './enums'
import { APP_ROUTES } from './routes'

export const ENGAGEMENT_CREATE_STEPS = ['Select Goals', 'General Details', 'Add Content', 'Summary']
export const GOAL_CREATE_STEPS = ['General Details', 'Target Group', 'Summary']
export const SESSION_CREATE_STEPS = ['General Details', 'Add Material', 'Assessment', 'Feedbacks', 'Summary']

export const APP_TABLE_CONFIGS = {
  DEFAULT_ROWS_PER_PAGE: 5,
  DEFAULT_PAGE: 1,
  DEFAULT_PAGE_COUNT: 1
}

export const SESSION_SUMMARY_ROWS_COUNT = 5
export const GOAL_SUMMARY_ROWS_COUNT = 5

export const PERMISSION_TYPES = {
  FULL_PERMISSION: 'EVERY',
  PARTIAL_PERMISSION: 'SOME'
}

export const FEEDBACK_OPTIONS =
[{
  optionLabel: '1',
  optionOrder: 1

},
{
  optionLabel: '2',
  optionOrder: 2

},
{
  optionLabel: '3',
  optionOrder: 3

},
{
  optionLabel: '4',
  optionOrder: 4

},
{
  optionLabel: '5',
  optionOrder: 5

}]

export const BREAD_CRUMB: ApplicationBreadCrumbs = {
  UNAUTHORIZED: [
    {
      id: 1,
      title: BREAD_CRUB_TEXTS.HOME,
      path: APP_ROUTES.DASHBOARD,
      isActive: false
    },
    {
      id: 2,
      title: BREAD_CRUB_TEXTS.UNAUTHORIZED,
      path: '#',
      isActive: true
    }
  ],
  NOTFOUND: [
    {
      id: 1,
      title: BREAD_CRUB_TEXTS.HOME,
      path: APP_ROUTES.DASHBOARD,
      isActive: false
    },
    {
      id: 2,
      title: BREAD_CRUB_TEXTS.NOT_FOUND,
      path: '#',
      isActive: true
    }
  ],
  PROFILE: [
    {
      id: 1,
      title: BREAD_CRUB_TEXTS.HOME,
      path: APP_ROUTES.DASHBOARD,
      isActive: false
    },
    {
      id: 2,
      title: BREAD_CRUB_TEXTS.PROFILE,
      path: APP_ROUTES.PROFILE,
      isActive: true
    }
  ],
  NOTIFICATIONS: [
    {
      id: 1,
      title: BREAD_CRUB_TEXTS.HOME,
      path: APP_ROUTES.DASHBOARD,
      isActive: false
    },
    {
      id: 2,
      title: 'Notifications',
      path: APP_ROUTES.NOTIFICATIONS,
      isActive: true
    }
  ],
  EVENTS: [
    {
      id: 1,
      title: BREAD_CRUB_TEXTS.HOME,
      path: APP_ROUTES.DASHBOARD,
      isActive: false
    },
    {
      id: 2,
      title: 'Explore Events',
      path: APP_ROUTES.EVENTS,
      isActive: true
    }
  ],
  DASHBOARD: [
    {
      id: 1,
      title: BREAD_CRUB_TEXTS.DASHBAORD,
      path: APP_ROUTES.DASHBOARD,
      isActive: true
    }
  ],
  GOAL: [
    {
      id: 1,
      title: BREAD_CRUB_TEXTS.HOME,
      path: APP_ROUTES.DASHBOARD,
      isActive: false
    },
    {
      id: 2,
      title: BREAD_CRUB_TEXTS.GOAL,
      path: '#',
      isActive: true
    }
  ],
  GOAL_CREATE: [
    {
      id: 1,
      title: BREAD_CRUB_TEXTS.HOME,
      path: APP_ROUTES.DASHBOARD,
      isActive: false
    },
    {
      id: 2,
      title: BREAD_CRUB_TEXTS.GOAL,
      path: '#',
      isActive: false
    },
    {
      id: 3,
      title: BREAD_CRUB_TEXTS.GOAL_CREATE,
      path: '#',
      isActive: true
    }
  ],
  ENGAGEMENT_CREATE: [
    {
      id: 1,
      title: BREAD_CRUB_TEXTS.HOME,
      path: APP_ROUTES.DASHBOARD,
      isActive: false
    },
    {
      id: 2,
      title: BREAD_CRUB_TEXTS.ENGAGEMENT,
      path: '#',
      isActive: false
    },
    {
      id: 3,
      title: BREAD_CRUB_TEXTS.ENGAGEMENT_CREATE,
      path: '#',
      isActive: true
    }
  ],
  ENGAGEMENT_VIEW: [
    {
      id: 1,
      title: BREAD_CRUB_TEXTS.HOME,
      path: APP_ROUTES.DASHBOARD,
      isActive: false
    },
    {
      id: 2,
      title: BREAD_CRUB_TEXTS.ENGAGEMENT,
      path: '#',
      isActive: true
    }
  ],
  GOAL_EDIT: [
    {
      id: 1,
      title: BREAD_CRUB_TEXTS.HOME,
      path: APP_ROUTES.DASHBOARD,
      isActive: false
    },
    {
      id: 2,
      title: BREAD_CRUB_TEXTS.GOAL,
      path: '#',
      isActive: false
    },
    {
      id: 3,
      title: BREAD_CRUB_TEXTS.GOAL_EDIT,
      path: '#',
      isActive: true
    }
  ],
  ENGAGEMENT_VIEW_BY_ID: [
    {
      id: 1,
      title: BREAD_CRUB_TEXTS.HOME,
      path: APP_ROUTES.DASHBOARD,
      isActive: false
    },
    {
      id: 2,
      title: BREAD_CRUB_TEXTS.ENGAGEMENT,
      path: '#',
      isActive: false
    },
    {
      id: 3,
      title: BREAD_CRUB_TEXTS.ENGAGEMENT_VIEW,
      path: '#',
      isActive: true
    }
  ],
  ENGAGEMENT_EDIT: [
    {
      id: 1,
      title: BREAD_CRUB_TEXTS.HOME,
      path: APP_ROUTES.DASHBOARD,
      isActive: false
    },
    {
      id: 2,
      title: BREAD_CRUB_TEXTS.ENGAGEMENT,
      path: '#',
      isActive: false
    },
    {
      id: 3,
      title: BREAD_CRUB_TEXTS.ENGAGEMENT_EDIT,
      path: '#',
      isActive: true
    }
  ],
  SESSION_CREATE: [
    {
      id: 1,
      title: BREAD_CRUB_TEXTS.HOME,
      path: APP_ROUTES.DASHBOARD,
      isActive: false
    },
    {
      id: 2,
      title: BREAD_CRUB_TEXTS.ENGAGEMENT,
      path: '#',
      isActive: false
    },
    {
      id: 3,
      title: BREAD_CRUB_TEXTS.ENGAGEMENT_VIEW,
      path: '#',
      isActive: false
    },
    {
      id: 4,
      title: BREAD_CRUB_TEXTS.SESSION_CREATE,
      path: '#',
      isActive: true
    }
  ],
  SESSION_EDIT: [
    {
      id: 1,
      title: BREAD_CRUB_TEXTS.HOME,
      path: APP_ROUTES.DASHBOARD,
      isActive: false
    },
    {
      id: 2,
      title: BREAD_CRUB_TEXTS.ENGAGEMENT,
      path: '#',
      isActive: false
    },
    {
      id: 3,
      title: BREAD_CRUB_TEXTS.ENGAGEMENT_VIEW,
      path: '#',
      isActive: false
    },
    {
      id: 4,
      title: BREAD_CRUB_TEXTS.SESSION_EDIT,
      path: '#',
      isActive: true
    }
  ],
  VIEW_SESSION: [
    {
      id: 1,
      title: BREAD_CRUB_TEXTS.HOME,
      path: APP_ROUTES.DASHBOARD,
      isActive: false
    },
    {
      id: 2,
      title: BREAD_CRUB_TEXTS.ENGAGEMENT,
      path: '#',
      isActive: false
    },
    {
      id: 3,
      title: BREAD_CRUB_TEXTS.ENGAGEMENT_VIEW,
      path: '#',
      isActive: false
    },
    {
      id: 4,
      title: BREAD_CRUB_TEXTS.SESSION_VIEW,
      path: '#',
      isActive: true
    }
  ],
  VIEW_NOTES: [
    {
      id: 1,
      title: BREAD_CRUB_TEXTS.HOME,
      path: APP_ROUTES.DASHBOARD,
      isActive: false
    },
    {
      id: 2,
      title: BREAD_CRUB_TEXTS.ENGAGEMENT,
      path: '#',
      isActive: false
    },
    {
      id: 3,
      title: BREAD_CRUB_TEXTS.ENGAGEMENT_VIEW,
      path: '#',
      isActive: false
    },
    {
      id: 4,
      title: BREAD_CRUB_TEXTS.SESSION_VIEW,
      path: '#',
      isActive: false
    },
    {
      id: 5,
      title: BREAD_CRUB_TEXTS.VIEW_NOTES,
      path: '#',
      isActive: true
    }
  ],
  VIEW_FEEDBACK: [
    {
      id: 1,
      title: BREAD_CRUB_TEXTS.HOME,
      path: APP_ROUTES.DASHBOARD,
      isActive: false
    },
    {
      id: 2,
      title: BREAD_CRUB_TEXTS.ENGAGEMENT,
      path: '#',
      isActive: false
    },
    {
      id: 3,
      title: BREAD_CRUB_TEXTS.ENGAGEMENT_VIEW,
      path: '#',
      isActive: false
    },
    {
      id: 4,
      title: BREAD_CRUB_TEXTS.SESSION_VIEW,
      path: '#',
      isActive: false
    },
    {
      id: 5,
      title: BREAD_CRUB_TEXTS.VIEW_FEEDBACK,
      path: '#',
      isActive: true
    }
  ],
  GIVE_FEEDBACK: [
    {
      id: 1,
      title: BREAD_CRUB_TEXTS.HOME,
      path: APP_ROUTES.DASHBOARD,
      isActive: false
    },
    {
      id: 2,
      title: BREAD_CRUB_TEXTS.ENGAGEMENT,
      path: '#',
      isActive: false
    },
    {
      id: 3,
      title: BREAD_CRUB_TEXTS.ENGAGEMENT_VIEW,
      path: '#',
      isActive: false
    },
    {
      id: 4,
      title: BREAD_CRUB_TEXTS.SESSION_VIEW,
      path: '#',
      isActive: false
    },
    {
      id: 5,
      title: BREAD_CRUB_TEXTS.GIVE_FEEDBACK,
      path: '#',
      isActive: true
    }
  ],
  VIEW_ASSESSMENT: [
    {
      id: 1,
      title: BREAD_CRUB_TEXTS.HOME,
      path: APP_ROUTES.DASHBOARD,
      isActive: false
    },
    {
      id: 2,
      title: BREAD_CRUB_TEXTS.ENGAGEMENT,
      path: '#',
      isActive: false
    },
    {
      id: 3,
      title: BREAD_CRUB_TEXTS.ENGAGEMENT_VIEW,
      path: '#',
      isActive: false
    },
    {
      id: 4,
      title: BREAD_CRUB_TEXTS.SESSION_VIEW,
      path: '#',
      isActive: false
    },
    {
      id: 5,
      title: BREAD_CRUB_TEXTS.ASSESSMENT_VIEW,
      path: '#',
      isActive: true
    }
  ],
  VIEW_ASSESSMENT_RESULT: [
    {
      id: 1,
      title: BREAD_CRUB_TEXTS.HOME,
      path: APP_ROUTES.DASHBOARD,
      isActive: false
    },
    {
      id: 2,
      title: BREAD_CRUB_TEXTS.ENGAGEMENT,
      path: '#',
      isActive: false
    },
    {
      id: 3,
      title: BREAD_CRUB_TEXTS.ENGAGEMENT_VIEW,
      path: '#',
      isActive: false
    },
    {
      id: 4,
      title: BREAD_CRUB_TEXTS.SESSION_VIEW,
      path: '#',
      isActive: false
    },
    {
      id: 5,
      title: BREAD_CRUB_TEXTS.ASSESSMENT_VIEW,
      path: '#',
      isActive: false
    },
    {
      id: 6,
      title: BREAD_CRUB_TEXTS.ASSESSMENT_RESULT,
      path: '#',
      isActive: true
    }
  ],
  VIEW_FEEDBACK_GROUP: [
    {
      id: 1,
      title: BREAD_CRUB_TEXTS.HOME,
      path: APP_ROUTES.DASHBOARD,
      isActive: false
    },
    {
      id: 2,
      title: BREAD_CRUB_TEXTS.ENGAGEMENT,
      path: '#',
      isActive: false
    },
    {
      id: 3,
      title: BREAD_CRUB_TEXTS.ENGAGEMENT_VIEW,
      path: '#',
      isActive: false
    },
    {
      id: 4,
      title: BREAD_CRUB_TEXTS.SESSION_VIEW,
      path: '#',
      isActive: false
    },
    {
      id: 5,
      title: 'Feedbacks',
      path: '#',
      isActive: true
    }
  ],
  VIEW_SESSION_COACHEE_STATS: [
    {
      id: 1,
      title: BREAD_CRUB_TEXTS.HOME,
      path: APP_ROUTES.DASHBOARD,
      isActive: false
    },
    {
      id: 2,
      title: BREAD_CRUB_TEXTS.ENGAGEMENT,
      path: '#',
      isActive: false
    },
    {
      id: 3,
      title: BREAD_CRUB_TEXTS.ENGAGEMENT_VIEW,
      path: '#',
      isActive: false
    },
    {
      id: 4,
      title: BREAD_CRUB_TEXTS.SESSION_VIEW,
      path: '#',
      isActive: false
    },
    {
      id: 5,
      title: 'Coachees',
      path: '#',
      isActive: true
    }
  ]
}
