/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { type AxiosResponse } from 'axios'
import {
  type ViewCoacheeFeedbackParam,
  type AddFeedbackResponse, type CoacheeFeedbackPayload,
  type FeedBackRequestParams, type FeedBackResponse,
  type ViewCoacheeFeedback,
  type ViewCoacheeGroupFeedbackParam,
  type ViewCoacheeGroupFeedback,
  type GiveFeedbackToCoacheePayload,
  type ViewCoacheeFeedbackCommentsRequestParams,
  type ViewCoacheeFeedBack
} from '../interfaces/feedBack'
import { axiosPrivateAPI } from '.'
import { type ErrorResponse } from '../interfaces'

const getFeedBackDetails = async (params: FeedBackRequestParams):
Promise<AxiosResponse<FeedBackResponse>> => {
  return await axiosPrivateAPI.get(`/api/v1/sessions/${params.id}/feedbackform`)
}
const addFeedback = async (payload: CoacheeFeedbackPayload):
Promise<AxiosResponse<AddFeedbackResponse>> => {
  return await axiosPrivateAPI.post(`/api/v1/sessions/${payload.pathParam.id}/sessionCoachee/${payload.pathParam.coachee}/reviews/${payload.pathParam.review}`, payload.requestBody)
}

const viewFeedback = async (params: ViewCoacheeFeedbackParam):
Promise<AxiosResponse<ViewCoacheeFeedback>> => {
  return await axiosPrivateAPI.get(`/api/v1/sessions/${params.id}/sessionCoachee/${params.coachee}/reviews`)
}

const viewGroupFeedback = async (params: ViewCoacheeGroupFeedbackParam): Promise<AxiosResponse<ViewCoacheeGroupFeedback>> => {
  return await axiosPrivateAPI.get(`/api/v1/sessions/${params.id}/reviews`)
}

const giveFeedbackToCoachees = async (payload: GiveFeedbackToCoacheePayload): Promise<AxiosResponse<ErrorResponse>> => {
  const id = payload.pathParam.id
  const coachId = payload.pathParam.coachId
  return await axiosPrivateAPI.post(`/api/v1/sessions/${id}/coach/${coachId}/feedback`, payload.requestBody)
}

const showFeedbackComments = async (params: ViewCoacheeFeedbackCommentsRequestParams):
Promise<AxiosResponse<ViewCoacheeFeedBack[]>> => {
  return await axiosPrivateAPI.get(`/api/v1/sessions/${params.id}/feedback/${params.feedbackId}/questions/${params.questionIndex}/comments`)
}
export const feedbackService = {
  getFeedBackDetails,
  addFeedback,
  viewFeedback,
  viewGroupFeedback,
  giveFeedbackToCoachees,
  showFeedbackComments
}
