/* eslint-disable @typescript-eslint/restrict-plus-operands */
import {
  type AsyncStateObject, type Action
} from '../../../interfaces'
import { type ViewCoacheeFeedBack } from '../../../interfaces/feedBack'
import { APP_ACTION_STATUS, COMMON_ACTIONS, FEEDBACK_ACTIONS } from '../../../utils/constants'

const INITIAL_STATE: AsyncStateObject<ViewCoacheeFeedBack[]> = {
  data: [],
  isLoading: false,
  status: APP_ACTION_STATUS.INITIAL,
  error: undefined

}

const feedbackCommentsReducer = (state = INITIAL_STATE, action: Action<ViewCoacheeFeedBack[]>) => {
  switch (action.type) {
    case FEEDBACK_ACTIONS.VIEW_FEEDBACK_COMMENTS + COMMON_ACTIONS.REQUEST:
      return {
        ...state,
        isLoading: true,
        status: APP_ACTION_STATUS.LOADING,
        error: undefined
      }
    case FEEDBACK_ACTIONS.VIEW_FEEDBACK_COMMENTS + COMMON_ACTIONS.SUCCESS:
      return {
        ...state,
        isLoading: false,
        status: APP_ACTION_STATUS.SUCCESS,
        data: action.data
      }
    case FEEDBACK_ACTIONS.VIEW_FEEDBACK_COMMENTS + COMMON_ACTIONS.ERROR:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        status: APP_ACTION_STATUS.ERROR,
        error: action.error
      }
    case FEEDBACK_ACTIONS.VIEW_FEEDBACK_COMMENTS + COMMON_ACTIONS.CLEAR:
      return INITIAL_STATE
    default:
      return state
  }
}

export default feedbackCommentsReducer
