/* eslint-disable max-len */
import React from 'react'
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { Box, Card, CardContent, IconButton, Typography } from '@mui/material'
import { type NoteDto } from '../../../interfaces/notes'
import styles from './SingleNote.module.scss'
import moment from 'moment'

const SingleNote: React.FC<{
  index: number
  isShowAction: boolean
  note: NoteDto
  onEdit?: (note: NoteDto) => void
  onDelete?: (note: NoteDto) => void
}> = ({
  index,
  isShowAction,
  note,
  onEdit,
  onDelete
}) => {
  const formatedDate = () => {
    const today = moment()
    const date = moment(note.createdAt)

    if (date.isSame(today, 'day')) {
      return `Today ${date.format('hh:mm A')}`
    } else if (date.isSame(today.clone().subtract(1, 'day'), 'day')) {
      return `Yesterday ${date.format('hh:mm A')}`
    } else {
      return date.format('MMMM DD YYYY hh:mm A')
    }
  }
  return (
    <Card sx={{ marginTop: index !== 0 ? '2rem' : '0rem' }}>
      <CardContent>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          borderBottom={'1px solid rgba(0, 0, 0, 0.12)'}
        >
          <Typography>{formatedDate()}</Typography>
          {isShowAction &&
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} gap={2}>
              <IconButton onClick={onEdit !== undefined ? () => { onEdit(note) } : () => {}}>
                <CreateOutlinedIcon sx={{ color: '#5F6D7E' }} />
              </IconButton>
              <IconButton onClick={onDelete !== undefined ? () => { onDelete(note) } : () => {}}>
                <DeleteOutlinedIcon sx={{ color: '#5F6D7E' }} />
              </IconButton>
            </Box>
          }
        </Box>
        <Box display={'flex'} justifyContent={'space-between'} marginTop={'1rem'}>
          <Typography className={styles.note} variant="subtitle1" fontWeight={500}>{note.content}</Typography>
        </Box>
      </CardContent>
    </Card>
  )
}

export default SingleNote
