import { type UserAuthorizingStateDto } from '../../../interfaces/auth'
import { APP_ACTION_STATUS, AUTH_ACTION_TYPE, COMMON_ACTIONS } from '../../../utils/constants'

const INITIAL_STATE: UserAuthorizingStateDto = {
  isAuthorized: false,
  isLoading: false,
  status: APP_ACTION_STATUS.INITIAL,
  error: null
}

const userAuthorizingReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case AUTH_ACTION_TYPE.AUTHORIZE_USER + COMMON_ACTIONS.REQUEST:
      return {
        ...state,
        isLoading: true,
        status: APP_ACTION_STATUS.LOADING,
        error: null
      }
    case AUTH_ACTION_TYPE.AUTHORIZE_USER + COMMON_ACTIONS.SUCCESS:
      return {
        ...state,
        isAuthorized: true,
        isLoading: false,
        status: APP_ACTION_STATUS.SUCCESS
      }
    case AUTH_ACTION_TYPE.AUTHORIZE_USER + COMMON_ACTIONS.ERROR:
      return {
        ...state,
        isAuthorized: false,
        isLoading: false,
        status: APP_ACTION_STATUS.ERROR,
        error: action.error
      }
    case AUTH_ACTION_TYPE.AUTHORIZE_USER + COMMON_ACTIONS.CLEAR:
      return INITIAL_STATE
    default:
      return state
  }
}

export default userAuthorizingReducer
