import { Box, Button, Card, CardContent, CircularProgress, Grid, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import {
  APP_ACTION_STATUS,
  FORM_VALIDATOR_TYPES,
  SESSION_CREATE_STEPS,
  SESSION_FORM_FIELDS,
  FEEDBACK_OPTIONS
} from '../../utils/constants'
import {
  type AssessmentQuestion,
  type AssessmentFeedback,
  type SessionAssessments,
  type SessionCreateFormState,
  type sessionRequestPayLoad,
  type ReviewQuestion,
  type SessionDetailsRequestParam,
  type editSessionPayLoad
} from '../../interfaces/session'
import { formValidator, isNullOrUndefinedOrEmpty } from '../../utils/helpers'
import SessionStepper from '../../components/session/SessionStepper/SessionStepper'
import SessionDetailsForm from '../../components/session/SessionGeneralDetailsForm/SessionGeneralDetailsForm'
import { useDispatch, useSelector } from 'react-redux'
import {
  type Attachments,
  type AppState,
  type AssessmentDetails,
  type AssessmentQuestions,
  type AddToQueDto,
  type DropdownObjectIdAsString,
  type Videos
} from '../../interfaces'
import SessionAddMaterial from '../../components/session/SessionAddMaterial/SessionAddMaterial'
import { useDropzone } from 'react-dropzone'
import { fileActions } from '../../redux/actions/fileActions'
import SessionAssessment from '../../components/session/SessionAssessment/SessionAssessment'
import SessionFeedBack from '../../components/session/SessionFeedBack/SessionFeedBack'
import Summary from '../../components/session/Summary/Summary'
import { useNavigate, useParams } from 'react-router-dom'
import { sessionActions } from '../../redux/actions/sessionActions'
import { type DeleteFileRequestParam } from '../../interfaces/fileDelete'
import { coachListAction, feedBackActions } from '../../redux/actions'
import { type FeedBackResponse, type FeedBackRequestParams } from '../../interfaces/feedBack'
import { assessmentActions } from '../../redux/actions/assessmentActions'
import { FormFeildViewSkelton, SomethingWentWrong } from '../../components'

const SessionCreate = () => {
  const INITIAL_STATE: SessionCreateFormState = {
    sessionName: {
      value: '',
      validator: FORM_VALIDATOR_TYPES.TEXT,
      isRequired: true,
      disable: false,
      error: null
    },
    sessionDescription: {
      value: '',
      validator: FORM_VALIDATOR_TYPES.TEXT,
      isRequired: true,
      disable: false,
      error: null
    },
    sessionCoach: {
      value: null,
      validator: FORM_VALIDATOR_TYPES.OBJECT,
      isRequired: true,
      disable: false,
      error: null
    },
    sessionTypes: {
      value: null,
      validator: FORM_VALIDATOR_TYPES.OBJECT,
      isRequired: true,
      disable: false,
      error: null
    },
    sessionDuration: {
      value: '',
      validator: FORM_VALIDATOR_TYPES.DURATION,
      isRequired: true,
      disable: false,
      error: null
    }

  }
  const initialFeedbackState: AssessmentFeedback = {
    configs: {
      isCoachToCoachee: false,
      isCoacheeToCoach: false
    },
    reviewQuestions: []
  }

  const INIT_ATTACHEMENT: Attachments = {
    attachmentTitle: '',
    attachmentUrl: '',
    attachmentId: ''
  }

  const INIT_SESSION_VIDEO: Videos = {
    title: {
      value: '',
      validator: FORM_VALIDATOR_TYPES.TEXT,
      isRequired: true,
      disable: false,
      error: null
    },
    url: {
      value: '',
      validator: FORM_VALIDATOR_TYPES.LINK,
      disable: false,
      isRequired: true,
      error: null
    }
  }

  const INIT_FINAL_SESSION_VIDEO: Videos[] = []
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { roleType, engagementId, sessionId } =
    useParams<{ roleType: string, engagementId: string, sessionId: string }>()

  const sessionTypesResponse = useSelector((state: AppState) => state.session.sessionTypes)
  const coachList = useSelector((state: AppState) => state.coachList.coachList)
  const fileUploadURLs = useSelector((state: AppState) => state.files.fileUploadURL)
  const createSessionState = useSelector((state: AppState) => state.session.sessionCreate)
  const editSessionState = useSelector((state: AppState) => state.session.sessionEdit)
  const sessionDetailsResponse = useSelector((state: AppState) => state.session.sessionDetails)
  const assessmentDetailsResponse = useSelector((state: AppState) => state.assessments.assessmentDetails)
  const feedbackDetailsResponse = useSelector((state: AppState) => state.feedbacks.feedbackDetails)
  const downloadFiles = useSelector((state: AppState) => state.files.downloadFile.data)
  const downloadFilesResponse = useSelector((state: AppState) => state.files.downloadFile)
  const deleteFileResponse = useSelector((state: AppState) => state.files.deleteFile)
  const authorizedUser = useSelector((state: AppState) => state.authUser.authorizedUser)

  const [isErrorFetchData, setIsErrorFetchData] = useState<string>('')
  const [activeStep, setActiveStep] = React.useState(0)
  const [sessionGeneralDetailForm, setSessionGeneralDetailForm] = useState<SessionCreateFormState>(INITIAL_STATE)
  const [inputSessionDurationValue, setInputSessionDurationValue] = useState('')
  const [myFiles, setMyFiles] = useState<File[]>([])
  const [myUploadedFiles, setMyUploadedFiles] = useState<Attachments[]>([])
  const [selectedAttachment, setSelectedAttachment] = React.useState<Attachments>(INIT_ATTACHEMENT)
  const [errorMessage, setErrorMessage] = useState('')
  const [assessment, setAssessment] = React.useState<SessionAssessments>({
    isCompulsory: true,
    assessmentQuestions: []
  })

  const [feedback, setFeedBack] = useState<AssessmentFeedback>(initialFeedbackState)
  const [sessionVideo, setSessionVideo] = useState<Videos>(INIT_SESSION_VIDEO)
  const [finalSessionVideo, setFinalSessionVideo] = useState<Videos[]>(INIT_FINAL_SESSION_VIDEO)
  useEffect(() => {
    onInit()
    return () => {
      dispatch(sessionActions.clearGetSessionDetailsById())
      dispatch(feedBackActions.clearGetFeedBackDetails())
      dispatch(assessmentActions.clearAssessmentDetails())
      dispatch(sessionActions.clearCreateSession())
      dispatch(sessionActions.clearEditSession())
      dispatch(sessionActions.clearStoreSessionAssessmentQuestion())
      dispatch(sessionActions.clearStoreSessionFeedBackQuestion())
      dispatch(fileActions.clearfileUploadURL())
      setMyFiles([])
      setAssessment({
        isCompulsory: true,
        assessmentQuestions: []
      })
    }
  }, [])

  const onInit = () => {
    dispatch(sessionActions.getSessionTypeList())
    dispatch(coachListAction.getSessionCoachList(engagementId ?? ''))
    if (!isNullOrUndefinedOrEmpty(sessionId)) {
      const param: SessionDetailsRequestParam = {
        pathParam: {
          id: sessionId ?? ''
        },
        queryParam: {
          roleType: roleType ?? ''
        }
      }
      dispatch(sessionActions.getSessionDetailsById(param))
      // GET FEEDBACK DETAILS BY SESSION ID FOR EDIT SESSION
      const feedbackParam: FeedBackRequestParams = {
        id: sessionId ?? ''
      }
      dispatch(feedBackActions.getFeedBackDetails(feedbackParam))
    } else {
      dispatch(sessionActions.clearGetSessionDetailsById())
    }
  }

  useEffect(() => {
    if (createSessionState.status === APP_ACTION_STATUS.SUCCESS) {
      navigate(-1)
    }
  }, [createSessionState.status])

  useEffect(() => {
    if (editSessionState.status === APP_ACTION_STATUS.SUCCESS) {
      navigate(-1)
    }
  }, [editSessionState.status])

  useEffect(() => {
    if (sessionDetailsResponse.status === APP_ACTION_STATUS.SUCCESS && !isNullOrUndefinedOrEmpty(sessionId)) {
      if (!isNullOrUndefinedOrEmpty(sessionDetailsResponse?.data)) {
        const _session = sessionDetailsResponse?.data
        let coachVal: DropdownObjectIdAsString | null
        if (_session?.coachList[0].id === authorizedUser?.data?._id) {
          coachVal = { id: authorizedUser?.data?._id, name: authorizedUser?.data?.username }
        } else {
          const coach = coachList?.data.find((coach) => coach._id === _session?.coachList[0].id)
          if (coach !== undefined) {
            coachVal = { id: coach._id, name: coach.username }
          } else {
            coachVal = null
          }
        }
        handleBlur(SESSION_FORM_FIELDS.DURATION, _session.sessionDuration)

        if (!isNullOrUndefinedOrEmpty(sessionDetailsResponse?.data.assessments)) {
          // GET ASSESSMENT DETAILS BY SESSION ID FOR EDIT SESSION
          const assessmentDetailsParams: any = {
            id: _session?.sessionId,
            assessmentId: _session?.sessionAssessmentId // ASSUME ONE ASSESSMENT FOR EACH SESSION
          }
          dispatch(assessmentActions.assessmentDetails(assessmentDetailsParams))
        }

        const sessionType = sessionTypesResponse?.data.find((type) => type.name === _session.type)

        setSessionGeneralDetailForm({
          ...sessionGeneralDetailForm,
          sessionName: {
            ...sessionGeneralDetailForm.sessionName,
            value: _session.name
          },
          sessionDescription: {
            ...sessionGeneralDetailForm.sessionDescription,
            value: _session.description
          },
          sessionCoach: {
            ...sessionGeneralDetailForm.sessionCoach,
            value: coachVal,
            disable: true
          },
          sessionTypes: {
            ...sessionGeneralDetailForm.sessionTypes,
            value: { id: sessionType?.typeId.toString() ?? '', name: _session.type }
          },
          sessionDuration: {
            ...sessionGeneralDetailForm.sessionDuration,
            value: _session.sessionDuration
          }
        })

        const attArray: Attachments[] = []
        for (let i = 0; i < _session.attachments.length; i++) {
          attArray.push({
            attachmentId: _session.attachments[i].attachmentId,
            attachmentTitle: _session.attachments[i].attachmentTitle,
            attachmentUrl: _session.attachments[i].attachmentUrl
          })
        }
        setMyUploadedFiles(attArray)
        setFinalSessionVideo(() => [
          ..._session.videos.map((video) => {
            return {
              title: video.title,
              url: video.url
            }
          })
        ])
      }
    }
    if (sessionDetailsResponse?.status === APP_ACTION_STATUS.ERROR) {
      setIsErrorFetchData(sessionDetailsResponse?.error ?? 'Something went wrong when fetching data.!')
    }
  }, [sessionDetailsResponse.status, coachList?.status])

  useEffect(() => {
    if (assessmentDetailsResponse.status === APP_ACTION_STATUS.SUCCESS) {
      if (!isNullOrUndefinedOrEmpty(assessmentDetailsResponse?.data)) {
        dispatch(sessionActions.clearStoreSessionAssessmentQuestion())
        const assessmentDetails: AssessmentDetails = assessmentDetailsResponse?.data
        const questions: AssessmentQuestions[] = assessmentDetails.questions

        questions.forEach((question: AssessmentQuestions) => {
          const payload = {
            type: question.type,
            questionLabel: question.questionLabel,
            questionOrder: question.questionOrder,
            options: question.options.map((option) => {
              return {
                optionLabel: option.optionLabel,
                optionOrder: option.optionOrder,
                isCorrectAnswer: option.isCorrectAnswer
              }
            })
          }
          dispatch(sessionActions.storeSessionAssessmentQuestion([payload]))
        })
      }
    }
    if (assessmentDetailsResponse?.status === APP_ACTION_STATUS.ERROR) {
      setIsErrorFetchData(assessmentDetailsResponse?.error ?? 'Something went wrong when fetching data.!')
    }
  }, [assessmentDetailsResponse.status])

  useEffect(() => {
    if (feedbackDetailsResponse.status === APP_ACTION_STATUS.SUCCESS) {
      if (!isNullOrUndefinedOrEmpty(feedbackDetailsResponse?.data)) {
        const feedbackDetails: FeedBackResponse = feedbackDetailsResponse?.data
        const questions: AssessmentQuestions[] = feedbackDetails.reviewQuestions

        questions.forEach((question: ReviewQuestion) => {
          const payload = {
            questionLabel: question.questionLabel,
            questionOrder: question.questionOrder,
            options: [{ optionLabel: '', optionOrder: 0 }]
          }
          dispatch(sessionActions.storeSessionFeedBackQuestion([payload]))
        })
      }
    }
    if (feedbackDetailsResponse?.status === APP_ACTION_STATUS.ERROR) {
      setIsErrorFetchData(feedbackDetailsResponse?.error ?? 'Something went wrong when fetching data.!')
    }
  }, [feedbackDetailsResponse.status])

  useEffect(() => {
    if (deleteFileResponse.status === APP_ACTION_STATUS.SUCCESS) {
      const newUploadedFiles = [...myUploadedFiles]
      newUploadedFiles.splice(newUploadedFiles.indexOf(selectedAttachment), 1)
      setMyUploadedFiles(newUploadedFiles)
      dispatch(fileActions.clearFileDelete())
    }
  }, [deleteFileResponse.status])

  const openFileInNewTab = (index: number) => {
    const fileURL = URL.createObjectURL(myFiles[index])
    const downloadLink = document.createElement('a')
    downloadLink.href = fileURL
    downloadLink.download = myFiles[index].name
    downloadLink.textContent = 'Download File'
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
    dispatch(fileActions.clearDownloadFile())
  }
  useEffect(() => {
    if (downloadFilesResponse.status === APP_ACTION_STATUS.SUCCESS) {
      const blob = new Blob([downloadFiles])
      const fileURL = URL.createObjectURL(blob)
      const downloadLink = document.createElement('a')
      downloadLink.href = fileURL
      downloadLink.download = selectedAttachment.attachmentTitle
      downloadLink.textContent = 'Download File'
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
      dispatch(fileActions.clearDownloadFile())
      setSelectedAttachment(INIT_ATTACHEMENT)
    }
  }, [downloadFilesResponse.status])

  // Session stepper - Next button
  const handleNext = (): void => {
    if (activeStep === 0) {
      formValidator(sessionGeneralDetailForm)
        .then(([formValidateData, formIsValid]) => {
          setSessionGeneralDetailForm(formValidateData)
          if (formIsValid) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1)
          }
        })
        .catch(e => {

        })
    } else if (activeStep === SESSION_CREATE_STEPS.length - 1) {
      if (!isNullOrUndefinedOrEmpty(sessionId)) void editSession()
      else void createSession()
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
  }
  // SessionStepper - Back button
  const handleBack = (): void => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }
  // convert user enter sessionDuration vallue to user friendly format
  const handleBlur = (property: string, value: string) => {
    if (property === SESSION_FORM_FIELDS.DURATION && value.trim() !== '') {
      const hoursInDay = 8
      const daysInWeek = 5
      const weeksInMonth = 4
      // Remove spaces between numeric values and unit identifiers
      const cleanedText = value.replace(/(\d+)\s*([mwdh])\s*(?=\d|$)/g, '$1$2')
      // Split the input string into individual components
      const components = cleanedText.split(/(\d+|\D| )/).filter((item: string) => item !== '')
      // Initialize variables to store the converted values
      let months = 0
      let weeks = 0
      let days = 0
      let hours = 0

      // Loop through the components and convert them to the appropriate units
      for (let i = components.length - 1; i >= 0; i -= 2) {
        const value = parseInt(components[i - 1], 10)
        const unit = components[i].toLowerCase()

        switch (unit) {
          case 'h':
            hours += value
            if (hours >= hoursInDay) {
              days += Math.floor(hours / hoursInDay)
              if (days >= daysInWeek) {
                weeks += Math.floor(days / daysInWeek)
                if (weeks >= weeksInMonth) {
                  months += Math.floor(weeks / weeksInMonth)
                  weeks = weeks % weeksInMonth
                }
                days = days % daysInWeek
              }
              hours = hours % hoursInDay
            }
            break
          case 'd':
            days += value
            if (days >= daysInWeek) {
              weeks += Math.floor(days / daysInWeek)
              if (weeks >= weeksInMonth) {
                months += Math.floor(weeks / weeksInMonth)
                weeks = weeks % weeksInMonth
              }
              days = days % daysInWeek
            }
            break
          case 'w':
            weeks += value
            if (weeks >= weeksInMonth) {
              months += Math.floor(weeks / weeksInMonth)
              weeks = weeks % weeksInMonth
            }
            break
          case 'm':
            months += value
            break
          default:
            // console.error(`Invalid unit: ${daysInMonth}`)
            break
        }
      }
      // Construct the user-friendly format
      const result = []
      if (months > 0) result.push(`${months}m`)
      if (weeks > 0) result.push(`${weeks}w`)
      if (days > 0) result.push(`${days}d`)
      if (hours > 0) result.push(`${hours}h`)

      const durationValue = result.join(' ')

      setInputSessionDurationValue(durationValue)
    }
  }
  const onInputFocus = (property: string) => {
    // setSessionGeneralDetailForm({
    //   ...sessionGeneralDetailForm,
    //   [property]: {
    //     ...sessionGeneralDetailForm[property as keyof typeof sessionGeneralDetailForm],
    //     error: null
    //   }
    // })
  }
  // general detail form element change
  const onInputHandleForm = (property: string, value: string) => {
    setSessionGeneralDetailForm({
      ...sessionGeneralDetailForm,
      [property]: {
        ...sessionGeneralDetailForm[property as keyof typeof sessionGeneralDetailForm],
        value,
        error: null
      }
    })
  }

  const videoLinkHandleForm = (property: string, value: string) => {
    setSessionVideo({
      ...sessionVideo,
      [property]: {
        ...sessionVideo[property as keyof typeof sessionVideo],
        value,
        error: null
      }
    })
  }

  const videoLinkHandleSubmit = () => {
    formValidator(sessionVideo)
      .then(([formValidateData, formIsValid]) => {
        setSessionVideo(formValidateData)
        if (formIsValid) {
          setFinalSessionVideo((prevFinalSessionVideo) => [
            ...prevFinalSessionVideo,
            {
              title: formValidateData.title.value,
              url: formValidateData.url.value

            }
          ])
          setSessionVideo(INIT_SESSION_VIDEO)
        }
      })
      .catch((e) => {
        // Handle errors here
      })
  }

  const handleVideoLinkDelete = (indexToDelete: number) => {
    setFinalSessionVideo((prevFinalVideo) => [
      ...prevFinalVideo.filter((_, index) => index !== indexToDelete)
    ])
  }
  const onDrop = useCallback((acceptedFiles: File[]) => {
    const fileValidity = acceptedFiles.map((file: File) => {
      const fileSizeInMB = file.size / (1024 * 1024)
      if (fileSizeInMB > 20) {
        return false
      } else {
        setErrorMessage('')
        return true
      }
    })

    const fullValidityStatus = fileValidity.every((valid: boolean) => valid)
    const validFileTypes = ['doc', 'docx', 'pdf', 'txt', 'rft', 'json', 'csv', 'xls',
      'xlsx', '.ppt', 'pptx', 'jpg', 'jpeg', 'png', 'svg', 'zip', 'rar']

    const fullFileTypeValidity = acceptedFiles.map((file: any) => {
      const lastDotPosition = file.path.lastIndexOf('.')
      if (lastDotPosition === -1) return file.path
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      else return file.path.substr(lastDotPosition + 1)
    })

    const isFileTypeValid = fullFileTypeValidity.every((filesType) => validFileTypes.includes(filesType))

    if (!fullValidityStatus) {
      setErrorMessage(
        `File Too Large - The file you're trying to upload exceeds the maximum size limit. 
        Please choose a file smaller than 20MB`
      )
    }
    if (!isFileTypeValid) {
      setErrorMessage('File type is not supported.')
    }
    if (acceptedFiles.length > 0 && fullValidityStatus && isFileTypeValid) {
      if (myFiles.length + acceptedFiles.length < 4) {
        acceptedFiles.forEach((acceptedFile: any) => {
          const param: AddToQueDto = {
            file: acceptedFile,
            type: 2
          }
          dispatch(fileActions.addToQue(param))
        })
        setMyFiles([...myFiles, ...acceptedFiles])
      } else {
        setErrorMessage('Maximum file count (3) exceeded. Please select up to 3 files.!')
      }
    } else {
      setMyFiles([...myFiles])
    }
  }, [myFiles, dispatch])

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop
  })

  const fileDownload = (id: string) => {
    const attachment = myUploadedFiles.find((file) => file.attachmentId === id)
    setSelectedAttachment(attachment ?? INIT_ATTACHEMENT)
    dispatch(fileActions.downloadFile(attachment?.attachmentUrl ?? ''))
  }

  const removeFile = (file: any) => {
    const newFiles = [...myFiles]
    newFiles.splice(newFiles.indexOf(file), 1)
    setMyFiles(newFiles)
  }

  const removeFileFromUploaded = (file: Attachments) => {
    const _param: DeleteFileRequestParam = {
      key: file.attachmentUrl,
      id: sessionId ?? ''
    }
    dispatch(fileActions.fileDelete(_param))
    setSelectedAttachment(file)
  }

  const createSession = async () => {
    const attArray: Attachments[] = []
    for (let i = 0; i < fileUploadURLs.data.length; i++) {
      if (myFiles.some((file: File) => file.name === fileUploadURLs.data[i].fields.key.split('~')[1])) {
        attArray.push({
          attachmentTitle: fileUploadURLs.data[i].fields.key.split('~')[1],
          attachmentUrl: fileUploadURLs.data[i].fields.key
        })
      }
    }

    const assessmentPayload: SessionAssessments[] = []

    if (assessment.assessmentQuestions.length > 0) {
      assessmentPayload.push({
        isCompulsory: assessment.isCompulsory,
        assessmentQuestions: assessment.assessmentQuestions.map((question: AssessmentQuestion, index: number) => {
          const optionsPayLoad = question.options.map((option, index: number) => ({
            optionLabel: option.optionLabel,
            optionOrder: index + 1,
            isCorrectAnswer: option.isCorrectAnswer
          }))
          return {
            type: question.type,
            description: question.description,
            questionLabel: question.questionLabel,
            questionOrder: index + 1,
            options: optionsPayLoad
          }
        })
      })
    }
    // feedback payload setting

    const feedbackPayload: AssessmentFeedback[] = [
      {
        configs: {
          isCoachToCoachee: false,
          isCoacheeToCoach: feedback.configs.isCoacheeToCoach
        },
        reviewQuestions: feedback.reviewQuestions.map((question, index) => ({
          type: 'multiple-choice',
          description: '',
          questionOrder: index + 1,
          questionLabel: question.questionLabel,
          options: FEEDBACK_OPTIONS
        }))
      }
      // You can add more Feedback objects here if needed
    ]

    const createSessionPayload: sessionRequestPayLoad = {
      engagementId: engagementId ?? '',
      name: sessionGeneralDetailForm.sessionName.value,
      description: sessionGeneralDetailForm.sessionDescription.value,
      type: sessionGeneralDetailForm.sessionTypes.value?.name,
      sessionDuration: inputSessionDurationValue,
      coachList: [{
        id: sessionGeneralDetailForm.sessionCoach.value?.id ?? '',
        name: sessionGeneralDetailForm.sessionCoach.value?.name ?? '',
        type: ''
      }],
      attachments: attArray,
      feedback: feedbackPayload,
      assessment: assessmentPayload,
      videos: finalSessionVideo.map((video) => {
        return {
          title: video.title,
          url: video.url
        }
      })
    }
    dispatch(sessionActions.createSession(createSessionPayload))
  }

  const editSession = async () => {
    const attArray: Attachments[] = []
    for (let i = 0; i < fileUploadURLs.data.length; i++) {
      if (myFiles.some((file: File) => file.name === fileUploadURLs.data[i].fields.key.split('~')[1])) {
        attArray.push({
          attachmentTitle: fileUploadURLs.data[i].fields.key.split('~')[1],
          attachmentUrl: fileUploadURLs.data[i].fields.key
        })
      }
    }

    const isCompulsory = assessment.isCompulsory
    const assessmentPayload: SessionAssessments[] = [
      {
        isCompulsory,
        assessmentQuestions: assessment.assessmentQuestions.map((question: AssessmentQuestion, index: number) => {
          const optionsPayLoad = question.options.map((option, index: number) => ({
            optionLabel: option.optionLabel,
            optionOrder: index + 1,
            isCorrectAnswer: option.isCorrectAnswer
          }))
          return {
            type: question.type,
            description: question.description,
            questionLabel: question.questionLabel,
            questionOrder: index + 1,
            options: optionsPayLoad
          }
        })
      }
    ]
    // feedback payload setting

    const feedbackPayload: AssessmentFeedback[] = [
      {
        configs: {
          isCoachToCoachee: false,
          isCoacheeToCoach: feedback.configs.isCoacheeToCoach
        },
        reviewQuestions: feedback.reviewQuestions.map((question, index) => ({
          type: 'multiple-choice',
          description: '',
          questionOrder: index + 1,
          questionLabel: question.questionLabel,
          options: FEEDBACK_OPTIONS
        }))
      }
      // You can add more Feedback objects here if needed
    ]

    const editSessionPayload: editSessionPayLoad = {
      _id: sessionId ?? '',
      name: sessionGeneralDetailForm.sessionName.value,
      description: sessionGeneralDetailForm.sessionDescription.value,
      type: sessionGeneralDetailForm.sessionTypes.value?.name ?? '',
      sessionDuration: inputSessionDurationValue,
      coachList: [{
        id: sessionGeneralDetailForm.sessionCoach.value?.id ?? '',
        name: sessionGeneralDetailForm.sessionCoach.value?.name ?? '',
        type: ''
      }],
      attachments: attArray,
      videos: finalSessionVideo.map((video) => {
        return {
          title: video.title,
          url: video.url
        }
      }),
      feedback: feedbackPayload,
      assessment: assessmentPayload
    }
    dispatch(sessionActions.editSession(editSessionPayload))
  }

  return (
    <React.Fragment>
      <section className='page-root' >
        {!isNullOrUndefinedOrEmpty(isErrorFetchData) &&
          <SomethingWentWrong isShowDefault={true} subNotification={isErrorFetchData}
          />}
        {isNullOrUndefinedOrEmpty(isErrorFetchData) &&
          <Grid lg={12}>
            <Card className='section-card'>
              <CardContent>
                <section className='section-card-header'>
                  <Typography fontWeight="600" fontSize="1rem">
                    {!isNullOrUndefinedOrEmpty(sessionId) ? 'Update Session' : 'Create Session'}
                  </Typography>
                </section>
                <section className='section-card-body'>
                  {
                    (
                      sessionDetailsResponse?.isLoading ||
                      assessmentDetailsResponse?.isLoading ||
                      feedbackDetailsResponse?.isLoading
                    ) &&
                    <FormFeildViewSkelton />
                  }
                  {!(
                    sessionDetailsResponse?.isLoading ||
                    assessmentDetailsResponse?.isLoading ||
                    feedbackDetailsResponse?.isLoading) &&
                    <SessionStepper
                      activeStep={activeStep}
                      generalDetailForm={
                        <SessionDetailsForm
                          generalDetailForm={sessionGeneralDetailForm}
                          inputSessionDurationValue={inputSessionDurationValue}
                          onInputFocus={onInputFocus}
                          onInputHandleForm={onInputHandleForm}
                          handleBlur={handleBlur}
                          coachList={coachList.data}
                          sessionTypeList={sessionTypesResponse?.data}
                        />

                      }
                      steps={SESSION_CREATE_STEPS}
                      addMaterials={
                        <SessionAddMaterial
                          sessionGeneralDetailForm={sessionGeneralDetailForm}
                          myFiles={myFiles}
                          updateFiles={myUploadedFiles}
                          openFileInNewTab={openFileInNewTab}
                          getRootProps={getRootProps}
                          getInputProps={getInputProps}
                          open={open}
                          fileDownload={fileDownload}
                          removeFile={removeFile}
                          errorMessage={errorMessage}
                          removeFileFromUploaded={removeFileFromUploaded}
                          selectedAttachment={selectedAttachment}
                          isLoading={downloadFilesResponse.isLoading || deleteFileResponse.isLoading}
                          videoLinkHandleForm={videoLinkHandleForm}
                          sessionVideo={sessionVideo}
                          videoLinkHandleSubmit={videoLinkHandleSubmit}
                          finalSessionVideo={finalSessionVideo}
                          handleVideoDelete={handleVideoLinkDelete}
                        />
                      }
                      assessment={<SessionAssessment
                        setAssessment={setAssessment}
                      />}
                      feedbacks={<SessionFeedBack
                        setFeedBack={setFeedBack} />}
                      summary={<Summary
                        sessionGeneralDetailForm={sessionGeneralDetailForm}
                        myFiles={myFiles}
                        updateFiles={myUploadedFiles}
                        assessment={assessment}
                        feedback={feedback}
                        inputSessionDurationValue={inputSessionDurationValue}
                        finalSessionVideo={finalSessionVideo}
                      />}
                    />
                  }
                </section>
                <section className='section-card-footer'>
                  <Box sx={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                    <Box sx={{ flex: '1 1 auto' }} />
                    {activeStep !== 0
                      ? (
                        <Button variant="contained" onClick={handleBack}
                          color="secondary" sx={{ textTransform: 'none' }}>Back</Button>
                        )
                      : <></>
                    }
                    {activeStep !== SESSION_CREATE_STEPS.length - 1
                      ? <Button
                        variant="contained"
                        onClick={handleNext}
                        color="primary"
                        sx={{ textTransform: 'none' }}>
                        Next</Button>
                      : <Button
                        variant="contained"
                        onClick={handleNext}
                        color="primary"
                        disabled={createSessionState?.isLoading || editSessionState?.isLoading}
                        sx={{ textTransform: 'none' }}
                      >
                        {(createSessionState?.isLoading || editSessionState?.isLoading) &&
                          <CircularProgress size={12} color='inherit' sx={{ marginRight: '8px' }} />
                        }
                        {!isNullOrUndefinedOrEmpty(sessionId) ? 'Update' : 'Finish'}
                      </Button>
                    }
                  </Box>
                </section>
              </CardContent>
            </Card>
          </Grid>
        }
      </section>
    </React.Fragment>
  )
}

export default SessionCreate
