import { type AsyncStateObject, type Action, type SessionCoacheeListDto } from '../../../interfaces'
import { APP_ACTION_STATUS, COMMON_ACTIONS, SESSION_ACTIONS } from '../../../utils/constants'

const INITIAL_STATE: AsyncStateObject<SessionCoacheeListDto> = {
  data: {
    count: 0,
    data: []
  },
  isLoading: false,
  status: APP_ACTION_STATUS.INITIAL,
  error: undefined
}

const sessionCoacheeListReducer = (
  state = INITIAL_STATE,
  action: Action<SessionCoacheeListDto>
): AsyncStateObject<SessionCoacheeListDto> => {
  switch (action.type) {
    case SESSION_ACTIONS.GET_SESSION_COACHEE_LIST + COMMON_ACTIONS.REQUEST:
      return {
        ...state,
        isLoading: true,
        status: APP_ACTION_STATUS.LOADING,
        error: undefined
      }
    case SESSION_ACTIONS.GET_SESSION_COACHEE_LIST + COMMON_ACTIONS.SUCCESS:
      return {
        ...state,
        isLoading: false,
        status: APP_ACTION_STATUS.SUCCESS,
        data: action.data
      }
    case SESSION_ACTIONS.GET_SESSION_COACHEE_LIST + COMMON_ACTIONS.ERROR:
      return {
        ...state,
        isLoading: false,
        data: {
          count: 0,
          data: []
        },
        status: APP_ACTION_STATUS.ERROR,
        error: action.error
      }
    case SESSION_ACTIONS.GET_SESSION_COACHEE_LIST + COMMON_ACTIONS.CLEAR:
      return INITIAL_STATE
    default:
      return state
  }
}

export default sessionCoacheeListReducer
