import React from 'react'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import { Box } from '@mui/system'
import { Card, CardContent, Grid } from '@mui/material'

const Sessions = () => {
  return <Card sx={{ padding: '1rem', marginBottom: '1rem' }}>
      <CardContent>
        <Box display='flex' flexDirection='column' gap={1}>
          <Box display='flex' justifyContent='space-between'>
            <Box><Skeleton animation="wave" variant="rounded" width='30rem' height='2rem' /></Box>
            <Box display='flex' flexDirection='row' gap={2}>
              <Box><Skeleton animation="wave" variant="rounded" width='2rem' height='2rem' /></Box>
              <Box><Skeleton animation="wave" variant="rounded" width='2rem' height='2rem' /></Box>
            </Box>
          </Box>
          <Box display='flex' flexDirection='row' gap={2}>
            <Box><Skeleton animation="wave" variant="rounded" width='8rem' height='2rem' /></Box>
            <Box><Skeleton animation="wave" variant="rounded" width='8rem' height='2rem' /></Box>
            <Box><Skeleton animation="wave" variant="rounded" width='8rem' height='2rem' /></Box>
          </Box>
          <Box><Skeleton animation="wave" variant="rounded" width='100%' height='1.5rem' /></Box>
          <Box><Skeleton animation="wave" variant="rounded" width='100%' height='1.5rem' /></Box>
          <Box><Skeleton animation="wave" variant="rounded" width='100%' height='1.5rem' /></Box>
          <Box><Skeleton animation="wave" variant="rounded" width='100%' height='1.5rem' /></Box>
          <Box><Skeleton animation="wave" variant="rounded" width='100%' height='1.5rem' /></Box>
          <Box display='flex' justifyContent='space-between'>
            <Box><Skeleton animation="wave" variant="rounded" width='15rem' height='2rem' /></Box>
            <Box><Skeleton animation="wave" variant="rounded" width='15rem' height='2rem' /></Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
}
const ViewSessionSkelton = () => {
  return (
    <Stack spacing={1}>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Card sx={{ overflowY: 'auto', height: '52rem' }}>
            <CardContent>
             <Sessions/>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Stack>
  )
}

export default ViewSessionSkelton
