import {
  type Action, type GoalCreateRequestPayload,
  type GoalByUserRequestParams,
  type StatusUpdatePayLoad,
  type GoalUpdateRequestPayload,
  type MyGoalsRequestPayload,
  type AllGoalRequestPayload,
  type GoalListSummeryRequestParams
} from '../../interfaces'
import { COMMON_ACTIONS, GOAL_ACTIONS } from '../../utils/constants'

const createGoal = (payload: GoalCreateRequestPayload): Action<GoalCreateRequestPayload> => {
  return { type: GOAL_ACTIONS.CREATE_GOAL + COMMON_ACTIONS.REQUEST, data: payload }
}
const GoalStatusChange = (payload: StatusUpdatePayLoad): Action<StatusUpdatePayLoad> => {
  return { type: GOAL_ACTIONS.GOAL_STATUS_UPDATE + COMMON_ACTIONS.REQUEST, data: payload }
}

const clearGoalStatus = (): Action<null> => {
  return { type: GOAL_ACTIONS.GOAL_STATUS_UPDATE + COMMON_ACTIONS.CLEAR, data: null }
}
const getAllGoalList = (payload: AllGoalRequestPayload): Action<AllGoalRequestPayload> => {
  return { type: GOAL_ACTIONS.GET_ALL_GOAL_LIST + COMMON_ACTIONS.REQUEST, data: payload }
}

const getGoalByUser = (params: GoalByUserRequestParams): Action<GoalByUserRequestParams> => {
  return { type: GOAL_ACTIONS.GET_GOAL_BY_USER + COMMON_ACTIONS.REQUEST, data: params }
}

const clearCreateGoal = (): Action<null> => {
  return { type: GOAL_ACTIONS.CREATE_GOAL + COMMON_ACTIONS.CLEAR, data: null }
}

const clearUpdateGoal = (): Action<null> => {
  return { type: GOAL_ACTIONS.UPDATE_GOAL + COMMON_ACTIONS.CLEAR, data: null }
}

const getGoalById = (goalId: string): Action<string> => {
  return { type: GOAL_ACTIONS.GET_GOAL_BY_ID + COMMON_ACTIONS.REQUEST, data: goalId }
}

const updateGoal = (payload: GoalUpdateRequestPayload): Action<GoalUpdateRequestPayload> => {
  return { type: GOAL_ACTIONS.UPDATE_GOAL + COMMON_ACTIONS.REQUEST, data: payload }
}

/*
  This actions is used take the GetMyGoalsByAssine
  @Kalana Elapatha
*/
const getMyGoalList = (payload: MyGoalsRequestPayload): Action<MyGoalsRequestPayload> => {
  return { type: GOAL_ACTIONS.GET_GOAL_BY_USER + COMMON_ACTIONS.REQUEST, data: payload }
}

/*
  This actions is used take the GetGoalListSummery in createEngagement goalSelect page
  @Kalana Elapatha
*/

const getGoalListSummary = (params: GoalListSummeryRequestParams): Action<GoalListSummeryRequestParams> => {
  return { type: GOAL_ACTIONS.GET_GOAL_LIST_SUMMARY + COMMON_ACTIONS.REQUEST, data: params }
}

const goalsByEngagementId = (engagementId: string): Action<string> => {
  return { type: GOAL_ACTIONS.GET_GOALS_BY_ENGAGEMENT_ID + COMMON_ACTIONS.REQUEST, data: engagementId }
}
const clearGoalsByEngagementId = (): Action<null> => {
  return { type: GOAL_ACTIONS.GET_GOALS_BY_ENGAGEMENT_ID + COMMON_ACTIONS.CLEAR, data: null }
}

const clearGoalDetails = (): Action<null> => {
  return { type: GOAL_ACTIONS.GET_GOAL_BY_ID + COMMON_ACTIONS.CLEAR, data: null }
}
export const goalActions = {
  createGoal,
  updateGoal,
  getAllGoalList,
  getGoalByUser,
  clearCreateGoal,
  GoalStatusChange,
  clearGoalStatus,
  getGoalById,
  clearUpdateGoal,
  getMyGoalList,
  getGoalListSummary,
  goalsByEngagementId,
  clearGoalsByEngagementId,
  clearGoalDetails
}
