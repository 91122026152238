import React from 'react'
import { type SessionCoacheeDataDto, type AsyncStateObject, type SessionCoacheeListDto } from '../../../interfaces'
import {
  Box, Button, Card, CardContent, Checkbox, Chip,
  Grid, InputAdornment, TextField, Typography
} from '@mui/material'
import { TableSkeleton } from '../../skeleton'
import moment from 'moment'
import SearchIcon from '@mui/icons-material/Search'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import { COACH_VIEW_COACHEE_STATUS } from '../../../utils/constants'
import styles from './SessionCoacheeViewCard.module.scss'
import SessionCocheesFilterDrawer from '../SessionCocheesFilterDrawer/SessionCocheesFilterDrawer'
import ClearIcon from '@mui/icons-material/Clear'

const SessionCoacheeViewCard: React.FC<{
  coacheeListResponse: AsyncStateObject<SessionCoacheeListDto>
  pagination: React.ReactNode
  searchKey: string
  onSearchEngagement: (event: React.ChangeEvent<HTMLInputElement>) => void
  drawerOpen: boolean
  toggleDrawer: (open: boolean) => any
  handleFilterStatus: (event: React.ChangeEvent<HTMLInputElement>, statusId: number) => void
  statusIds: number[]
  sessionStatusFilter: any[]
  filterCoachessbyStatus: () => void
  clearSearchGoal: () => void
  openSessionModal: () => void
  selectAllCoachees: () => void
  iseSelectAll: boolean
  handleSelectCoacheeId: (coacheeId: string) => void
  selectedCoacheeIds: string[]

}> = ({
  coacheeListResponse,
  pagination,
  searchKey,
  onSearchEngagement,
  drawerOpen,
  toggleDrawer,
  handleFilterStatus,
  statusIds,
  sessionStatusFilter,
  filterCoachessbyStatus,
  clearSearchGoal,
  openSessionModal,
  selectAllCoachees,
  iseSelectAll,
  handleSelectCoacheeId,
  selectedCoacheeIds
}) => {
  const isLoading = coacheeListResponse.isLoading
  const coacheeList = coacheeListResponse.data.data
  return (
      <Card
        sx={{
          minWidth: 275,
          borderRadius: '0.5rem',
          border: '1px solid #DAE0E6',
          boxShadow: 'none',
          marginTop: '1rem'
        }}
      >
        <CardContent
          sx={{ borderBottom: '0.1rem solid #DAE0E6', boxShadow: 'none', marginBottom: '0rem' }}
        >
          <Grid xs={12} md={12} lg={12} container display="flex" alignItems="center">
            <Grid display="flex" item xs={2} md={1} lg={1 / 2}>
              <Checkbox
                onClick={selectAllCoachees}
                readOnly={coacheeList.every((coachee) => coachee.isFeedbackReceived)}
                disabled={coacheeList.every((coachee) => coachee.isFeedbackReceived)}
              />
            </Grid>
            <Grid display="flex" item xs={10} md={4} lg={3 + 1 / 2}>
              <Grid xs={12}>
                <Typography
                  fontWeight="600"
                  variant="body1"
                  align="left"
                  style={{ wordWrap: 'break-word' }}
                >
                  Select all
                </Typography>
              </Grid>
            </Grid>
            <Grid xs={6} md={5} lg={2}
              display="flex" item
              sx={{
                marginTop: { xs: '1rem', md: '1rem' },
                justifyContent: {
                  xs: 'space-between'
                }
              }} >
              <Grid xs={12} display='flex' sx={{ justifyContent: { xs: 'start', lg: 'center' } }}>
                <Button
                  disabled={(!iseSelectAll && selectedCoacheeIds.length < 1)}
                  onClick={openSessionModal}
                  sx={{
                    width: '75%',
                    fontWeight: 600,
                    color: 'black',
                    textTransform: 'none',
                    border: '1px solid gray',
                    boxShadow: 'none',
                    '&:hover': {
                      background: 'white',
                      border: '1px solid gray'

                    },
                    marginLeft: '10px'
                  }}
                  variant="outlined">Give Feedback
                </Button>
              </Grid>
            </Grid>
            <Grid xs={6} md={2} lg={5}
              display="flex"
              alignItems={'center'} item sx={{ marginTop: { xs: '1rem', lg: 'center' } }} >
              <Grid xs={12} md={12} lg={11}>
                <Box sx={{ display: 'flex' }}>
                  <TextField
                    value={searchKey}
                    onChange={onSearchEngagement}
                    placeholder="Search"
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          {(searchKey.length > 0)
                            ? (
                              <ClearIcon onClick={clearSearchGoal} style={{ cursor: 'pointer' }} />
                              )
                            : (
                              <SearchIcon />
                              )}
                        </InputAdornment>
                      )
                    }}
                    sx={{ width: '100%' }}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid xs={12} md={2} lg={1}
              display="flex" justifyContent="space-between" item
              sx={{ marginTop: { xs: '1rem', md: '1rem' } }} >
              <Grid xs={12} display='flex' justifyContent='end' >
                <Button sx={{ textTransform: 'none', height: '2.25rem' }} color="secondary" size="small"
                  variant="contained" onClick={toggleDrawer(true)} endIcon={<FilterAltOutlinedIcon />}>
                  Filters
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {isLoading && (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
              <TableSkeleton />
            </div>
          )}
          <Card sx={{
            minWidth: 275,
            borderRadius: '1rem',
            border: '1px solid #DAE0E6',
            boxShadow: 'none',
            marginTop: '1rem'
          }}>
            {!isLoading && coacheeList.length > 0 &&
              coacheeList.map((coachee: SessionCoacheeDataDto, index: number) => (
                <CardContent key={index}
                  sx={{ borderBottom: '0.1rem solid #DAE0E6', boxShadow: 'none', marginBottom: '0rem' }}
                >
                  <Grid lg={12} md={12} xs={12} container display="flex" alignItems="center">
                    {iseSelectAll &&
                      <Grid display="flex" item xs={1} md={1} lg={1 / 2}>
                        <Checkbox
                          readOnly={coachee.isFeedbackReceived}
                          disabled={coachee.isFeedbackReceived}
                          checked={iseSelectAll}
                        />
                      </Grid>}
                    {!iseSelectAll &&
                      <Grid display="flex" item xs={1} md={1} lg={1 / 2}>
                        <Checkbox
                          readOnly={coachee.isFeedbackReceived}
                          disabled={coachee.isFeedbackReceived}
                          checked={coachee.isFeedbackReceived ? true : selectedCoacheeIds.includes(coachee.id)}
                          onClick={() => { handleSelectCoacheeId(coachee.id) }} />
                      </Grid>}

                    <Grid display="flex" item xs={11} md={5} lg={7}>
                      <Grid xs={12}>
                        <Typography
                          fontWeight="600"
                          variant="body1"
                          align="left"
                          style={{ wordWrap: 'break-word' }}
                        >
                          {coachee.name}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid xs={6} md={4} lg={2}
                      display="flex" justifyContent="space-between" item
                      sx={{ marginTop: { xs: '1rem', md: '1rem' } }} >
                      <Grid xs={12}>
                        <Typography fontWeight="500" sx={{ whiteSpace: 'nowrap' }}>
                          {coachee.sessionStartDate !== null && coachee.sessionEndDate !== null
                            ? `${moment(coachee.sessionStartDate).format('DD/MM/YYYY')} -
                                    ${moment(coachee.sessionEndDate).format('DD/MM/YYYY')}`
                            : (coachee.sessionStartDate !== null
                                ? `${moment(coachee.sessionStartDate).format('DD/MM/YYYY')} -`
                                : (coachee.sessionEndDate !== null
                                    ? `- ${moment(coachee.sessionEndDate).format('DD/MM/YYYY')}`
                                    : '-'))
                          }
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid xs={6} md={2} lg={2}
                      display="flex" justifyContent="space-between"
                      alignItems={'center'} item sx={{ marginTop: { xs: '1rem' } }} >
                      <Grid xs={3} md={1} lg={1}>
                        <Box sx={{ display: 'flex', justifyContent: { xs: 'end', md: 'start', lg: 'start' } }}>
                          <Typography>
                            <Chip
                              id={coachee.status === COACH_VIEW_COACHEE_STATUS.COMPLETED
                                ? styles.cardChipCompleted
                                : coachee.status === COACH_VIEW_COACHEE_STATUS.IN_PROGRESS
                                  ? styles.cardChipInProgress
                                  : styles.cardChipNew}
                              label={coachee.status}
                            />
                          </Typography>
                        </Box>
                      </Grid>
                      {coachee.assessments.length === 0
                        ? (
                          <Grid xs={3} md={1} lg={1} display="flex" justifyContent="space-between">
                            <Typography
                              variant="caption"
                              component="div"
                              color="text.secondary"
                            >
                              -
                            </Typography>
                          </Grid>
                          )
                        : (
                          <Grid xs={3} md={1} lg={1} display="flex" justifyContent="space-between">
                            <Typography
                              variant="caption"
                              component="div"
                              color="text.secondary"
                              fontSize= '14px'
                              // fontWeight="1000"

                            >
                              {coachee.assessments[0]?.noOfCorrectAnswers}/{coachee.assessments[0]?.noOfQuestions}
                            </Typography>
                          </Grid>
                          )}
                    </Grid>
                  </Grid>

                </CardContent>
              ))}
          </Card>
          {!isLoading && coacheeList.length === 0 && (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
              There are no matching coachee details
            </div>
          )}
        </CardContent>
        <Box
          sx={{ height: '5rem' }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {pagination}
        </Box>
        <SessionCocheesFilterDrawer
          filterCoachessbyStatus={filterCoachessbyStatus}
          sessionStatusFilter={sessionStatusFilter}
          handleFilterStatus={handleFilterStatus}
          statusIds={statusIds}
          drawerState={drawerOpen}
          toggleDrawer={toggleDrawer}
        />
      </Card>
  )
}

export default SessionCoacheeViewCard
