import { type AsyncStateObject, type Action, type GoalStatusResponse } from '../../../interfaces'
import { APP_ACTION_STATUS, COMMON_ACTIONS, GOAL_ACTIONS } from '../../../utils/constants'

const INITIAL_STATE: AsyncStateObject<GoalStatusResponse> = {
  data: { message: '' },
  isLoading: false,
  status: APP_ACTION_STATUS.INITIAL,
  error: undefined
}

const goalStatusReducer = (state = INITIAL_STATE, action: Action<GoalStatusResponse>):
AsyncStateObject<GoalStatusResponse> => {
  switch (action.type) {
    case GOAL_ACTIONS.GOAL_STATUS_UPDATE + COMMON_ACTIONS.REQUEST:
      return {
        ...state,
        isLoading: true,
        status: APP_ACTION_STATUS.LOADING,
        error: undefined
      }
    case GOAL_ACTIONS.GOAL_STATUS_UPDATE + COMMON_ACTIONS.SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        status: APP_ACTION_STATUS.SUCCESS
      }
    case GOAL_ACTIONS.GOAL_STATUS_UPDATE + COMMON_ACTIONS.ERROR:
      return {
        ...state,
        isLoading: false,
        data: { message: '' },
        status: APP_ACTION_STATUS.ERROR,
        error: action.error
      }
    case GOAL_ACTIONS.GOAL_STATUS_UPDATE + COMMON_ACTIONS.CLEAR:
      return INITIAL_STATE
    default:
      return state
  }
}

export default goalStatusReducer
