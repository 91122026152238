import React, { useState, useEffect } from 'react'
import {
  unstable_useBlocker as useBlocker, useNavigate
} from 'react-router-dom'
import ConfirmationPopup from '../../components/common/ConfirmationPopup/ConfirmationPopup'

const useCallbackPrompt = (when: boolean) => {
  const navigate = useNavigate()
  const [showPrompt, setShowPrompt] = useState<boolean>(false)
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)

  const onCallback = (con: boolean) => {
    setShowPrompt(false)
    if (con) setConfirmedNavigation(true)
  }

  const blocker = useBlocker(() => {
    if (!showPrompt) {
      setShowPrompt(true)
    }
    if (when) {
      return !confirmedNavigation
    }
    return false
  })

  useEffect(() => {
    if (confirmedNavigation && when) {
      navigate(blocker.location?.pathname ?? '/dashboard')
    }
  }, [blocker, confirmedNavigation, when])

  useEffect(() => {
    const handleBeforeUnload = (event: { preventDefault: () => void, returnValue: string }) => {
      if (when) {
        event.preventDefault()
        event.returnValue = ''
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [when])

  return [showPrompt, onCallback]
}

const UsePrompt: React.FC<{
  isBlocking: boolean
}> = ({
  isBlocking
}) => {
  const [showPrompt, onCallback] = useCallbackPrompt(isBlocking)
  return (
    <ConfirmationPopup
      isOpenConfirmationDialog={showPrompt as boolean}
      content='Are you sure you want to leave? You have with unsaved changes.'
      onCallback={onCallback as () => void}
      title='Unsaved Changes'
      cancelButtonTitle='Stay here'
      confirmButtonTitle='Confirm'
    />
  )
}

export default UsePrompt
