import { combineReducers } from 'redux'
import { type NotesState } from '../../../interfaces'
import addNoteReducer from './addNoteReducer'
import viewOwnNotesReducer from './viewOwnNoteReducer'
import viewOthersNotesReducer from './viewOtherNoteReducer'
import deleteNoteReducer from './deleteNoteReducer'
import editNoteReducer from './editNoteReducer'

const notesReducer = combineReducers<NotesState>({
  addNote: addNoteReducer,
  editNote: editNoteReducer,
  deleteNote: deleteNoteReducer,
  viewOwnNotes: viewOwnNotesReducer,
  viewOtherNotes: viewOthersNotesReducer
})

export default notesReducer
