import { type AxiosResponse } from 'axios'
import { type SessionStatusList, type EngagementStatusList, type GoalStatusList } from '../interfaces'
import { axiosPrivateAPI } from '.'

const getGoalStatusList = async (): Promise<AxiosResponse<GoalStatusList[]>> => {
  return await axiosPrivateAPI.get('/api/v1/data/goal/statusList')
}
const getEngagementList = async (): Promise<AxiosResponse<EngagementStatusList>> => {
  return await axiosPrivateAPI.get('/api/v1/data/engagements/statusList')
}
const getSessionList = async (): Promise<AxiosResponse<SessionStatusList[]>> => {
  return await axiosPrivateAPI.get('/api/v1/data/sessions/statusList')
}

export const statusService = {
  getGoalStatusList,
  getEngagementList,
  getSessionList
}
