import { Box, Card, CardContent, Divider, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { type Attachments } from '../../../interfaces'
import styles from './EngagementMaterialView.module.scss'

const EngagementMaterialView: React.FC<{
  downloadFile: (fileURL: string) => void
  myFiles: Attachments[]
  attachmentImageHandler: (fileName: string) => string
}> = ({ myFiles, downloadFile, attachmentImageHandler }) => {
  const [showMaterial, setShowMaterial] = useState<boolean>(false)

  const showMaterials = () => {
    setShowMaterial(!showMaterial)
  }

  return (
    <Box mt={'1rem'} pb={'1rem'} borderBottom={'1px solid rgba(0, 0, 0, 0.12)'}>
      <Box display="flex" justifyContent="start">
        <Typography fontWeight={600} fontSize="1rem">
          Materials
        </Typography>
        <Box onClick={() => { showMaterials() }} style={{ cursor: 'pointer' }}>
          {showMaterial ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
        </Box>
      </Box>
      {showMaterial && (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap'
          }}
        >
          {myFiles.length > 0
            ? <Grid container spacing={2} mt={2}>
              {myFiles?.map((file: Attachments, index: number) => {
                return <>
                  <Grid key={index} item xs={12} md={4}>
                    <Card>
                      <CardContent sx={{ minHeight: '5rem' }}>
                        <Box display='flex' flexDirection='column' gap='1rem'>
                          <Box display='flex' justifyContent='center'>
                            <Grid container display={'flex'} alignItems={'center'}>
                              <Grid item xs={2}>
                                <img alt="Attachment" src={attachmentImageHandler(file.attachmentTitle)} />
                              </Grid>
                              <Grid item xs={10}>
                                <Box display='flex' flexDirection='column' alignItems='start'>
                                  <Typography
                                    className={styles.fileName}
                                    justifyContent='center'
                                    fontWeight={500}
                                    fontSize='1rem'
                                    sx={{ maxWidth: '350px' }}>
                                    {file.attachmentTitle}
                                  </Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                          <Divider />
                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                            <Grid container>
                              <Grid item xs={10} />
                              <Grid item xs={2} display='flex' justifyContent='end'>
                                <FileDownloadOutlinedIcon
                                  id={styles.Icon}
                                  onClick={() => { downloadFile(file.attachmentUrl) }} />
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </>
              })}
            </Grid>
            : (
              <Typography variant="body1">-There is no materials to display-</Typography>
              )
            }
        </Box>
      )}
    </Box>
  )
}

export default EngagementMaterialView
