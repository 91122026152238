export const GOAL_STATUS_CHANGE_ACTIONS = {
  ACCEPT: 'ACCEPT',
  REQUEST_EDIT: 'REQUEST_EDIT',
  REJECT: 'REJECT'
}

export const GOAL_STATUS_OPTIONS = [
  { id: 1, name: 'Pending' },
  { id: 2, name: 'Request Edit' },
  { id: 3, name: 'Accept' },
  { id: 4, name: 'Reject' },
  { id: 5, name: 'Assigned' },
  { id: 6, name: 'In Progress' },
  { id: 7, name: 'Completed' }
]
