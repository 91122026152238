/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { call, put, takeEvery } from 'redux-saga/effects'
import { dashboardService } from '../../services'
import {
  type CoacheeStatsResponse,
  type Action,
  type ErrorResponse,
  type CoacheeGoalSummaryRequestParam,
  type CoacheeGoalSummaryDto,
  type CoacheeSessionSummaryRequestParam,
  type CoacheeSessionSummaryDto,
  type RecentEngagementAsCoacheeResponse,
  type CoachStatsResponse,
  type CoachGoalSummaryRequestParam,
  type CoachGoalSummaryDto,
  type CoachSessionSummaryRequestParam,
  type CoachSessionSummaryDto,
  type RecentEngagementAsCoachResponse,
  type SupervisorStatsResponse
} from '../../interfaces'
import { COMMON_ACTIONS, DASHBOARD_ACTION_TYPE } from '../../utils/constants'

function * getCoacheeStats () {
  try {
    const response: Awaited<ReturnType<typeof dashboardService.getCoacheeStats>> =
       yield call(dashboardService.getCoacheeStats)
    yield put<Action<CoacheeStatsResponse>>({
      type: DASHBOARD_ACTION_TYPE.GET_COACHEE_STATS + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
  } catch (error) {
    yield put<Action<ErrorResponse>>({
      type: DASHBOARD_ACTION_TYPE.GET_COACHEE_STATS + COMMON_ACTIONS.ERROR,
      data: { message: error as string },
      error: error as string
    })
  }
}
function * getCoacheeGoalSummary (action: Action<CoacheeGoalSummaryRequestParam>) {
  try {
    const response: Awaited<ReturnType<typeof dashboardService.getCoacheeGoalSummary>> =
       yield call(dashboardService.getCoacheeGoalSummary, action.data)
    yield put<Action<CoacheeGoalSummaryDto[]>>({
      type: DASHBOARD_ACTION_TYPE.GET_COACHEE_GOAL_SUMMARY + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
  } catch (error) {
    yield put<Action<ErrorResponse>>({
      type: DASHBOARD_ACTION_TYPE.GET_COACHEE_GOAL_SUMMARY + COMMON_ACTIONS.ERROR,
      data: { message: error as string },
      error: error as string
    })
  }
}
function * getCoacheeSessionSummary (action: Action<CoacheeSessionSummaryRequestParam>) {
  try {
    const response: Awaited<ReturnType<typeof dashboardService.getCoacheeSessionSummary>> =
       yield call(dashboardService.getCoacheeSessionSummary, action.data)
    yield put<Action<CoacheeSessionSummaryDto[]>>({
      type: DASHBOARD_ACTION_TYPE.GET_COACHEE_SESSION_SUMMARY + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
  } catch (error) {
    yield put<Action<ErrorResponse>>({
      type: DASHBOARD_ACTION_TYPE.GET_COACHEE_SESSION_SUMMARY + COMMON_ACTIONS.ERROR,
      data: { message: error as string },
      error: error as string
    })
  }
}
function * getCoacheeRecentEngagement (action: Action<null>) {
  try {
    const response: Awaited<ReturnType<typeof dashboardService.getCoacheeRecentEngagement>> =
       yield call(dashboardService.getCoacheeRecentEngagement)
    yield put<Action<RecentEngagementAsCoacheeResponse>>({
      type: DASHBOARD_ACTION_TYPE.GET_RECENT_ENGAGEMENTS_AS_COACHEE + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
  } catch (error) {
    yield put<Action<ErrorResponse>>({
      type: DASHBOARD_ACTION_TYPE.GET_RECENT_ENGAGEMENTS_AS_COACHEE + COMMON_ACTIONS.ERROR,
      data: { message: error as string },
      error: error as string
    })
  }
}
function * getCoachStats (action: Action<null>) {
  try {
    const response: Awaited<ReturnType<typeof dashboardService.getCoachStats>> =
       yield call(dashboardService.getCoachStats)
    yield put<Action<CoachStatsResponse>>({
      type: DASHBOARD_ACTION_TYPE.GET_COACH_STATS + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
  } catch (error) {
    yield put<Action<ErrorResponse>>({
      type: DASHBOARD_ACTION_TYPE.GET_COACH_STATS + COMMON_ACTIONS.ERROR,
      data: { message: error as string },
      error: error as string
    })
  }
}
function * getCoachGoalSummary (action: Action<CoachGoalSummaryRequestParam>) {
  try {
    const response: Awaited<ReturnType<typeof dashboardService.getCoachGoalSummary>> =
       yield call(dashboardService.getCoachGoalSummary, action.data)
    yield put<Action<CoachGoalSummaryDto[]>>({
      type: DASHBOARD_ACTION_TYPE.GET_COACH_GOAL_SUMMARY + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
  } catch (error) {
    yield put<Action<ErrorResponse>>({
      type: DASHBOARD_ACTION_TYPE.GET_COACH_GOAL_SUMMARY + COMMON_ACTIONS.ERROR,
      data: { message: error as string },
      error: error as string
    })
  }
}
function * getCoachSessoinSummary (action: Action<CoachSessionSummaryRequestParam>) {
  try {
    const response: Awaited<ReturnType<typeof dashboardService.getCoachSessoinSummary>> =
       yield call(dashboardService.getCoachSessoinSummary, action.data)
    yield put<Action<CoachSessionSummaryDto[]>>({
      type: DASHBOARD_ACTION_TYPE.GET_COACH_SESSION_SUMMARY + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
  } catch (error) {
    yield put<Action<ErrorResponse>>({
      type: DASHBOARD_ACTION_TYPE.GET_COACH_SESSION_SUMMARY + COMMON_ACTIONS.ERROR,
      data: { message: error as string },
      error: error as string
    })
  }
}
function * getCoachRecentEngagement (action: Action<null>) {
  try {
    const response: Awaited<ReturnType<typeof dashboardService.getCoachRecentEngagement>> =
       yield call(dashboardService.getCoachRecentEngagement)
    yield put<Action<RecentEngagementAsCoachResponse>>({
      type: DASHBOARD_ACTION_TYPE.GET_RECENT_ENGAGEMENTS_AS_COACH + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
  } catch (error) {
    yield put<Action<ErrorResponse>>({
      type: DASHBOARD_ACTION_TYPE.GET_RECENT_ENGAGEMENTS_AS_COACH + COMMON_ACTIONS.ERROR,
      data: { message: error as string },
      error: error as string
    })
  }
}
function * getSupervisorStats (action: Action<null>) {
  try {
    const response: Awaited<ReturnType<typeof dashboardService.getSupervisorStats>> =
       yield call(dashboardService.getSupervisorStats)
    yield put<Action<SupervisorStatsResponse>>({
      type: DASHBOARD_ACTION_TYPE.GET_SUPERVISOR_STATS + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
  } catch (error) {
    yield put<Action<ErrorResponse>>({
      type: DASHBOARD_ACTION_TYPE.GET_SUPERVISOR_STATS + COMMON_ACTIONS.ERROR,
      data: { message: error as string },
      error: error as string
    })
  }
}

function * dashboardSagas () {
  yield takeEvery(DASHBOARD_ACTION_TYPE.GET_COACHEE_STATS + COMMON_ACTIONS.REQUEST, getCoacheeStats)
  yield takeEvery(DASHBOARD_ACTION_TYPE.GET_COACHEE_GOAL_SUMMARY + COMMON_ACTIONS.REQUEST, getCoacheeGoalSummary)
  yield takeEvery(DASHBOARD_ACTION_TYPE.GET_COACHEE_SESSION_SUMMARY + COMMON_ACTIONS.REQUEST, getCoacheeSessionSummary)
  yield takeEvery(DASHBOARD_ACTION_TYPE.GET_COACH_STATS + COMMON_ACTIONS.REQUEST, getCoachStats)
  yield takeEvery(DASHBOARD_ACTION_TYPE.GET_COACH_GOAL_SUMMARY + COMMON_ACTIONS.REQUEST, getCoachGoalSummary)
  yield takeEvery(DASHBOARD_ACTION_TYPE.GET_COACH_SESSION_SUMMARY + COMMON_ACTIONS.REQUEST, getCoachSessoinSummary)
  yield takeEvery(DASHBOARD_ACTION_TYPE.GET_SUPERVISOR_STATS + COMMON_ACTIONS.REQUEST, getSupervisorStats)
  yield takeEvery(
    DASHBOARD_ACTION_TYPE.GET_RECENT_ENGAGEMENTS_AS_COACHEE + COMMON_ACTIONS.REQUEST, getCoacheeRecentEngagement)
  yield takeEvery(
    DASHBOARD_ACTION_TYPE.GET_RECENT_ENGAGEMENTS_AS_COACH + COMMON_ACTIONS.REQUEST, getCoachRecentEngagement)
}

export default dashboardSagas
