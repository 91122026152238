import { Box, Button, Chip, Grid, Typography } from '@mui/material'
import React from 'react'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import styles from './SessionHeaderDescription.module.scss'

const SessionHeaderDescription: React.FC<{
  sessionName: string
  sessionStatus: string
  sessionDuration: string
  sessionDescription: string
  sessionCoachName: string
  openCompleteSessionModal: () => void
  isMarkAsCompleted?: string
  isCoachee: boolean
  sessionAssessmentStatus: boolean
  sessionAssessmentCompulsoryStatus: boolean
}> = ({
  sessionName,
  sessionStatus,
  sessionDuration,
  sessionDescription,
  sessionCoachName,
  openCompleteSessionModal,
  isCoachee,
  isMarkAsCompleted,
  sessionAssessmentStatus,
  sessionAssessmentCompulsoryStatus
}) => {
  return (
      <Box borderBottom={'1px solid rgba(0, 0, 0, 0.12)'}>
        <Box display='flex' justifyContent='space-between'>
          <Typography fontWeight={600} variant='h4'>{sessionName}</Typography>
          {isCoachee
            ? <Button
              disabled={(sessionAssessmentCompulsoryStatus && !sessionAssessmentStatus) ||
                isMarkAsCompleted === 'Completed'}
              onClick={openCompleteSessionModal}
              variant='contained'
              color='primary'
              sx={{ textTransform: 'none' }}>
              Mark as completed
            </Button>
            : ''}

        </Box>
        <Box display='flex' justifyContent='start'>
          <Grid container md={12} mt='1rem' gap={3}>
            <Grid item md={2}>
              <Chip
                className={styles.sessionStatusChip}
                variant='filled'
                label={sessionStatus}
              />
            </Grid>
            <Grid item xs={5} sm={5} md={3} lg={1} display='flex'>
              <Box display='flex' alignItems='center'>
                <AccessTimeOutlinedIcon sx={{ color: '#5F6D7E' }} />
                <Typography ml='0.5rem'
                  fontWeight={600}
                  fontSize='1rem'>{sessionDuration}</Typography>
              </Box>
            </Grid>
            <Grid item sm={5} md={5} display='flex' alignItems='center'>
              <Box display='flex'>
                <PersonOutlineOutlinedIcon color='primary' />
                <Typography display='flex' fontWeight={500} fontSize='1rem'>
                  <Typography className={styles.sessionCoachName}>Coach : </Typography>
                  <Typography>
                    {sessionCoachName?.charAt(0).toUpperCase() + sessionCoachName?.slice(1)}
                  </Typography>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box margin='1rem 0 1rem 0'>
          <Typography
            fontWeight={600}
            fontSize='1rem'>Description</Typography>
          <Box>
            <Typography className={styles.sessionDescription}
            >{sessionDescription}
            </Typography>
          </Box>
        </Box>
      </Box>
  )
}

export default SessionHeaderDescription
