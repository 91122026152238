import * as React from 'react'
import { Box, Divider, Step, StepLabel, Stepper, Typography } from '@mui/material'

import { ColorlibConnector } from './ColorlibConnector'

const EngagementStepper: React.FC<{
  generalDetailForm: React.ReactNode
  goalSelect: React.ReactNode
  addContent: React.ReactNode
  summary: React.ReactNode
  steps: string[]
  activeStep: number
}> = (props) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Stepper connector={<ColorlibConnector />} activeStep={props.activeStep} sx={{ padding: 0 }}>
        {props.steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {}
          const labelProps: {
            optional?: React.ReactNode
          } = {}
          return (
            <Step sx={{ padding: 0 }} key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          )
        })}

      </Stepper>
      <Divider sx={{ marginTop: '2rem' }} />
      <React.Fragment>
        <Typography sx={{ mt: 2, mb: 1 }}>{props.activeStep === 0
          ? (props.goalSelect)
          : (props.activeStep === 1
              ? (props.generalDetailForm)
              : (props.activeStep === 2 ? (props.addContent) : props.summary))}</Typography>
      </React.Fragment>
    </Box>
  )
}
export default EngagementStepper
