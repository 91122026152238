import { Box, Card, Chip, Grid, Typography } from '@mui/material'
import React from 'react'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined'
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined'
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded'
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined'
import { type AppState, type SessionCoacheeDetails } from '../../../interfaces'
import { useSelector } from 'react-redux'

const SessionCoacheesHeaderCard: React.FC<{ sessionCoacheeDetails: SessionCoacheeDetails }> =
 ({ sessionCoacheeDetails }) => {
   const sessionDetails = useSelector((state: AppState) => state?.session?.sessionDetails?.data)
   const sessionCoach = sessionDetails?.coachList ?? []
   return (
    <Card sx={{ padding: '1rem' }}>
      <Typography fontWeight={600} variant='h5'>{sessionDetails?.name}</Typography>
      <Grid container md={12} mt='1rem'>
          <Grid item md={1 + 1 / 2}>
            <Chip
              sx={{
                backgroundColor: '#F5FAFF',
                borderRadius: '0.5rem',
                color: '#437EF7',
                fontWeight: '600',
                fontSize: '1rem'
              }}
              variant='filled'
              label={sessionDetails?.type}
            />
          </Grid>
          <Grid item md={1} display='flex'>
            <Box display='flex' alignItems='center'>
              <AccessTimeOutlinedIcon sx={{ color: '#5F6D7E' }} />
              <Typography ml='0.5rem'
                fontWeight={600}
                fontSize='1rem'>{sessionDetails?.sessionDuration}</Typography>
            </Box>
          </Grid>
          <Grid item md={8} display='flex' alignItems='center'>
            <Box display='flex'>
              <PersonOutlineOutlinedIcon color='primary' />
              <Typography display='flex' fontWeight={500} fontSize='1rem'>
                <Typography
                  fontWeight={500}
                  fontSize='1rem'
                  color='primary'
                  marginRight='0.5rem'>Coach:</Typography>
                {sessionCoach[0]?.name?.split('@')[0].split('.')[0].replace(/"/g, '')}
                </Typography>
            </Box>

          </Grid>
      </Grid>
      <Grid container md={12} sx={{ marginTop: '1rem' }} gap={1 / 2} display='flex' justifyContent='space-between'>
          <Grid item md={2 + 6 / 7}>
              <Card sx={{ padding: '1rem' }}>
                <Box display='flex' justifyContent='space-between'>
                  <Typography fontWeight={600}>Total Coachees</Typography>
                  <Box sx={{
                    padding: '0.25rem',
                    backgroundColor: '#F5FAFF',
                    borderRadius: '0.25rem'
                  }}>
                        <PeopleAltOutlinedIcon sx={{ zIndex: '10' }} color='primary'/></Box>
                </Box>
                <Box display='flex' justifyContent='space-between'>
                  <Typography fontWeight={600} fontSize='1.25rem'>{sessionCoacheeDetails?.totalCoachees}</Typography>
                </Box>
              </Card>
          </Grid>
          <Grid item md={2 + 6 / 7}>
              <Card sx={{ padding: '1rem' }}>
                <Box display='flex' justifyContent='space-between'>
                  <Typography fontWeight={600}>Session Completed</Typography>
                  <Box sx={{
                    padding: '0.25rem',
                    backgroundColor: '#F0FAF0',
                    borderRadius: '0.25rem'
                  }}>
                        <TaskOutlinedIcon sx={{ zIndex: '10' }} color='success'/></Box>
                </Box>
                <Box display='flex' justifyContent='space-between'>
                  <Typography
                  fontWeight={600} fontSize='1.25rem'>{sessionCoacheeDetails?.sessionCompleted}</Typography>
                </Box>
              </Card>
          </Grid>
          <Grid item md={2 + 6 / 7}>
              <Card sx={{ padding: '1rem' }}>
                <Box display='flex' justifyContent='space-between'>
                  <Typography
                  fontWeight={600}>Session Not Started Yet</Typography>
                  <Box sx={{
                    padding: '0.25rem',
                    backgroundColor: '#FFF8EB',
                    borderRadius: '0.25rem'
                  }}>
                        <WarningAmberRoundedIcon sx={{ zIndex: '10', color: '#EEA23E' }}/></Box>
                </Box>
                <Box display='flex' justifyContent='space-between'>
                  <Typography
                  fontWeight={600}
                  fontSize='1.25rem'>{sessionCoacheeDetails?.sessionNotStarted}</Typography>
                </Box>
              </Card>
          </Grid>
          <Grid item md={2 + 6 / 7}>
              <Card sx={{ padding: '1rem' }}>
                <Box display='flex' justifyContent='space-between'>
                  <Typography fontWeight={600}>Assignment Completed</Typography>
                  <Box sx={{
                    padding: '0.25rem',
                    backgroundColor: '#F0FAF0',
                    borderRadius: '0.25rem'
                  }}>
                        <AssignmentTurnedInOutlinedIcon sx={{ zIndex: '10' }} color='success'/></Box>
                </Box>
                <Box display='flex' justifyContent='space-between'>
                  <Typography
                  fontWeight={600}
                  fontSize='1.25rem'>{sessionCoacheeDetails?.assessmentCompleted}</Typography>
                </Box>
              </Card>
          </Grid>
      </Grid>
    </Card>
   )
 }

export default SessionCoacheesHeaderCard
