import {
  type EngagementListResponse, type Action, type AsyncStateObject
} from '../../../interfaces'
import { APP_ACTION_STATUS, COMMON_ACTIONS, ENGAGEMENT_ACTIONS } from '../../../utils/constants'

const INITIAL_STATE: AsyncStateObject<EngagementListResponse> = {
  data: { count: 0, data: [] },
  isLoading: false,
  status: APP_ACTION_STATUS.INITIAL,
  error: undefined

}

const getMyEngagementListAsCoachReducer = (state = INITIAL_STATE, action: Action<EngagementListResponse>) => {
  switch (action.type) {
    case ENGAGEMENT_ACTIONS.GET_MY_ENGAGEMENT_LIST_AS_COACH +
      COMMON_ACTIONS.REQUEST:
      return {
        ...state,
        isLoading: true,
        status: APP_ACTION_STATUS.LOADING,
        error: undefined
      }
    case ENGAGEMENT_ACTIONS.GET_MY_ENGAGEMENT_LIST_AS_COACH + COMMON_ACTIONS.SUCCESS:
      return {
        ...state,
        isLoading: false,
        status: APP_ACTION_STATUS.SUCCESS,
        data: action.data
      }
    case ENGAGEMENT_ACTIONS.GET_MY_ENGAGEMENT_LIST_AS_COACH + COMMON_ACTIONS.ERROR:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        status: APP_ACTION_STATUS.ERROR,
        error: action.error
      }
    case ENGAGEMENT_ACTIONS.GET_MY_ENGAGEMENT_LIST_AS_COACH + COMMON_ACTIONS.CLEAR:
      return INITIAL_STATE
    default:
      return state
  }
}

export default getMyEngagementListAsCoachReducer
