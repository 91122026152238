import React from 'react'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import { Box } from '@mui/system'

const TableColumnDesign = () => {
  return <Box display='flex' flexDirection='column' gap={1}>
        <Box><Skeleton animation="wave" variant="rounded" width='3rem' height='1.5rem' /></Box>
        <Box display='flex' flexDirection='row' gap={3}>
            <Box><Skeleton animation="wave" variant="rounded" width='40rem' height='2.5rem' /></Box>
            <Box><Skeleton animation="wave" variant="rounded" width='25rem' height='2.5rem' /></Box>
            <Box><Skeleton animation="wave" variant="rounded" width='25rem' height='2.5rem' /></Box>
        </Box>
    </Box>
}

const TableSkeleton = () => {
  return (
        <Stack spacing={1}>
            <TableColumnDesign/>
            <TableColumnDesign/>
            <TableColumnDesign/>
            <TableColumnDesign/>
            <TableColumnDesign/>
        </Stack>
  )
}

export default TableSkeleton
