import { all } from 'redux-saga/effects'
import goalSagas from './goals'
import hierarchySagas from './hierarchies'
import prioritySagas from './priorities'
import fileSagas from './files'
import engagementSagas from './engagements'
import statusSagas from './status'
import assessmentSagas from './assessments'
import sessionSagas from './session'
import feedBackSagas from './feedBack'
import coachListSagas from './coachList'
import authSaga from './auth.saga'
import userSagas from './users.saga'
import dashboardSaga from './dashboard.saga'
import noteSaga from './notesSaga'
export default function * rootSaga () {
  yield all([
    authSaga(),
    goalSagas(),
    hierarchySagas(),
    prioritySagas(),
    fileSagas(),
    engagementSagas(),
    statusSagas(),
    sessionSagas(),
    assessmentSagas(),
    feedBackSagas(),
    coachListSagas(),
    userSagas(),
    dashboardSaga(),
    noteSaga()
  ])
}
