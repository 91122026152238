import { call, put, takeEvery } from 'redux-saga/effects'
import {
  type AssessmentSubmitPayload,
  type AssessmentDetails,
  type AssessmentDetailsParams,
  type AssessmentResultPathParams,
  type AssessmentResultDetails
} from '../../interfaces/assessment'
import { type ErrorResponse, type Action } from '../../interfaces'
import { assessmentService } from '../../services/assessmentService'
import { ASSESSMENT_ACTIONS, COMMON_ACTIONS } from '../../utils/constants'
import { type AxiosError } from 'axios'

function * assessmentDetailsById (action: Action<AssessmentDetailsParams>) {
  try {
    const response: Awaited<ReturnType<typeof assessmentService.assessmentDetails>> =
       yield call(assessmentService.assessmentDetails, action.data)
    yield put<Action<AssessmentDetails>>({
      type: ASSESSMENT_ACTIONS.GET_ASSESSMENT_DETAILS_BY_ID + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
  } catch (error) {
    yield put<Action<ErrorResponse>>({
      type: ASSESSMENT_ACTIONS.GET_ASSESSMENT_DETAILS_BY_ID + COMMON_ACTIONS.ERROR,
      data: { message: error as string },
      error: error as string
    })
  }
}

function * assessmentSubmit (action: Action<AssessmentSubmitPayload>) {
  try {
    const response: Awaited<ReturnType<typeof assessmentService.assessmentSubmit>> =
       yield call(assessmentService.assessmentSubmit, action.data)
    yield put<Action<ErrorResponse>>({
      type: ASSESSMENT_ACTIONS.SUBMIT_ASSESSMENT + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
  } catch (error) {
    const e = error as AxiosError
    yield put<Action<ErrorResponse>>({
      type: ASSESSMENT_ACTIONS.SUBMIT_ASSESSMENT + COMMON_ACTIONS.ERROR,
      data: { message: e?.response?.data as string },
      error: error as string
    })
  }
}

function * assessmentResultDetails (action: Action<AssessmentResultPathParams>) {
  try {
    const response: Awaited<ReturnType<typeof assessmentService.assessmentResultDetails>> =
       yield call(assessmentService.assessmentResultDetails, action.data)
    yield put<Action<AssessmentResultDetails>>({
      type: ASSESSMENT_ACTIONS.GET_ASSESSMENT_RESULT_DETAILS + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
  } catch (error) {
    const e = error as AxiosError
    yield put<Action<ErrorResponse>>({
      type: ASSESSMENT_ACTIONS.GET_ASSESSMENT_RESULT_DETAILS + COMMON_ACTIONS.ERROR,
      data: { message: e?.response?.data as string },
      error: error as string
    })
  }
}

function * assessmentSagas () {
  yield takeEvery(ASSESSMENT_ACTIONS.GET_ASSESSMENT_DETAILS_BY_ID + COMMON_ACTIONS.REQUEST, assessmentDetailsById)
  yield takeEvery(ASSESSMENT_ACTIONS.SUBMIT_ASSESSMENT + COMMON_ACTIONS.REQUEST, assessmentSubmit)
  yield takeEvery(ASSESSMENT_ACTIONS.GET_ASSESSMENT_RESULT_DETAILS + COMMON_ACTIONS.REQUEST, assessmentResultDetails)
}

export default assessmentSagas
