import { Box, Button, Card, Chip, Divider, Grid, Link, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
  AppPagination,
  CompleteSessionModal,
  SessionFeedbackCard,
  SessionHeaderDescription,
  SessionMaterial,
  SomethingWentWrong,
  ViewSessionSkelton
} from '../../components'
import CircularProgress, {
  type CircularProgressProps
} from '@mui/material/CircularProgress'
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined'
import { useSelector, useDispatch } from 'react-redux'
import {
  type SessionDetails,
  type AppState,
  type Attachments,
  type CompleteSessionPayLoad,
  type SessionDetailsRequestParam,
  type SessionCoacheePayload,
  type EngagementDetailsRequestParam
} from '../../interfaces'
import { useNavigate } from 'react-router'
import {
  APP_ACTION_STATUS,
  ROLES_TYPES,
  SESSION_CHANGE_STATUS,
  ENGAGEMENT_TYPES,
  APP_TABLE_CONFIGS,
  getAssessmentRoute,
  getViewFeedbackRoute,
  getGiveFeedbackRoute,
  getEngagementViewRoute,
  getSessionCoacheeStatRoute,
  getViewGroupFeedbackRoute,
  getViewSessionNotesRoute
} from '../../utils/constants'
import { sessionActions } from '../../redux/actions/sessionActions'
import { useParams } from 'react-router-dom'
import { fileActions } from '../../redux/actions/fileActions'
import styles from './SessionView.module.scss'
import SessionCoacheeStatsView from '../../components/session/SessionCoacheeStats/SessionCoacheeStatsView'
import { engagementsActions } from '../../redux/actions'
import { isNullOrUndefinedOrEmpty } from '../../utils/helpers'

function CircularProgressWithLabel (
  props: CircularProgressProps & { value: number, totalQuestions: number, correctAnswers: number }
) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box id={styles.circularProgressInsideAnswers}>
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{props.correctAnswers}/{props.totalQuestions}</Typography>
      </Box>
    </Box>
  )
}
const SessionView = () => {
  const INIT_ATTACHMENT: Attachments = {
    attachmentTitle: '',
    attachmentUrl: '',
    attachmentId: ''
  }
  const statusIds = []
  let _statusIds: undefined

  if (statusIds?.length === 0) {
    _statusIds = undefined
  }
  const key = ''
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { roleType, engagementId, sessionId } =
    useParams<{ roleType: string, engagementId: string, sessionId: string }>()
  const sessionDetails: SessionDetails = useSelector((state: AppState) => state.session.sessionDetails.data)
  const sessionDetailsDataAndStatus = useSelector((state: AppState) => state.session.sessionDetails)
  const downloadFilesResponse = useSelector((state: AppState) => state.files.downloadFile)
  const downloadFiles = useSelector((state: AppState) => state.files.downloadFile.data)
  const sessionDetailsResponse = useSelector((state: AppState) => state.session.sessionDetails)
  const engagementDetailsResponse = useSelector((state: AppState) => state.engagements.engagementDetails)

  const sessionCoachName = sessionDetails?.coachList[0]?.name.split('.')[0]
  const sessionAssessments = sessionDetails?.assessments

  const [isErrorFetchData, setIsErrorFetchData] = useState<string>('')
  const [openCompleteSessionModal, setOpenCompleteSessionModal] = useState(false)
  const [myFiles, setMyFiles] = useState<Attachments[]>([])
  const isCoach = roleType === ENGAGEMENT_TYPES.COACH
  const isCoachee = roleType === ENGAGEMENT_TYPES.COACHEE
  const sessionCoacheeListResponse = useSelector((state: AppState) => state.session.sessionCoacheeList)
  const isMarkedAsCompleted = sessionDetails?.status
  const sessionCompleteStatus = useSelector((state: AppState) => state.session.startAndComplete)
  const [markAsCompletedActivated, setMarkAsCompletedActivated] = useState(false)
  const [selectedAttachment, setSelectedAttachment] = React.useState<Attachments>(INIT_ATTACHMENT)
  const [page, setPage] = React.useState(APP_TABLE_CONFIGS.DEFAULT_PAGE)
  const [pageCount, setPageCount] = React.useState(APP_TABLE_CONFIGS.DEFAULT_PAGE_COUNT)

  useEffect(() => {
    const sessionParam: SessionDetailsRequestParam = {
      pathParam: {
        id: sessionId ?? ''
      },
      queryParam: {
        roleType: roleType ?? ''
      }
    }
    dispatch(sessionActions.getSessionDetailsById(sessionParam))

    // THIS CALL FOR VALIDATE PUPROPSE OF ENGAGEMENTID
    const param: EngagementDetailsRequestParam = {
      pathParam: { id: engagementId ?? '' },
      queryParam: { roleType: roleType ?? '' }
    }
    dispatch(engagementsActions.engagementDetails(param))

    if (roleType === ENGAGEMENT_TYPES.COACH || roleType === ENGAGEMENT_TYPES.SUPERVISOR) {
      const sessionCoacheeList: SessionCoacheePayload = {
        pathParam: {
          id: sessionId ?? ''
        },
        requestBody: {
          statusIds: _statusIds,
          searchField: key,
          limit: APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE,
          page
        }
      }
      dispatch(sessionActions.getSessionCoacheeList(sessionCoacheeList))
    }
    return () => {
      dispatch(sessionActions.clearCompleteSessionStatus())
      dispatch(engagementsActions.clearGetEngagementDetailById())
      dispatch(sessionActions.clearGetSessionCoacheeList())
    }
  }, [])

  useEffect(() => {
    if (engagementDetailsResponse?.status === APP_ACTION_STATUS.ERROR) {
      setIsErrorFetchData(engagementDetailsResponse?.error ?? 'Something went wrong when fetching data.!')
    }
    if (sessionDetailsDataAndStatus?.status === APP_ACTION_STATUS.ERROR) {
      setIsErrorFetchData(sessionDetailsDataAndStatus?.error ?? 'Something went wrong when fetching data.!')
    }
  }, [engagementDetailsResponse?.status, sessionDetailsDataAndStatus?.status])

  useEffect(() => {
    if (sessionCoacheeListResponse?.status === APP_ACTION_STATUS.ERROR) {
      dispatch(sessionActions.clearGetSessionCoacheeList())
    }
  }, [sessionCoacheeListResponse?.status])

  useEffect(() => {
    if (sessionCoacheeListResponse.status === APP_ACTION_STATUS.SUCCESS) {
      const count = Math.ceil(sessionCoacheeListResponse.data.count / APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE)
      setPageCount(count)
    }
  }, [sessionCoacheeListResponse.status])

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
    // void getCoacheeList(getCoacheeListRequestPayload(searchKey, value))
  }

  const handleCompleteSessionModalClose = () => {
    setOpenCompleteSessionModal(false)
  }
  const completeSessionModal = () => {
    setOpenCompleteSessionModal(!openCompleteSessionModal)
  }

  useEffect(() => {
    if (sessionDetailsDataAndStatus.status === APP_ACTION_STATUS.SUCCESS) {
      const attArray: any[] = []
      for (let i = 0; i < sessionDetailsDataAndStatus?.data?.attachments?.length; i++) {
        attArray.push({
          attachmentId: sessionDetailsDataAndStatus?.data?.attachments[i].attachmentId,
          attachmentTitle: sessionDetailsDataAndStatus?.data?.attachments[i].attachmentTitle,
          attachmentUrl: sessionDetailsDataAndStatus?.data?.attachments[i].attachmentUrl
        })
      }
      setMyFiles(attArray)
    }
  }, [sessionDetailsDataAndStatus.status])

  const fileDownload = (id: string) => {
    const attachment = myFiles.find((file) => file.attachmentUrl === id)
    setSelectedAttachment(attachment ?? INIT_ATTACHMENT)
    dispatch(fileActions.downloadFile(attachment?.attachmentUrl ?? ''))
  }

  useEffect(() => {
    if (downloadFilesResponse.status === APP_ACTION_STATUS.SUCCESS) {
      const blob = new Blob([downloadFiles])
      const fileURL = URL.createObjectURL(blob)
      const downloadLink = document.createElement('a')
      downloadLink.href = fileURL
      downloadLink.download = selectedAttachment.attachmentTitle
      downloadLink.textContent = 'Download File'
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
      dispatch(fileActions.clearDownloadFile())
    }
  }, [downloadFilesResponse.status])

  const completeSession = (sessionID: string) => {
    const completeStatusSessionPayload: CompleteSessionPayLoad = {
      sessionId: sessionID,
      statusId: SESSION_CHANGE_STATUS.TO_COMPLETED // This statusId for change the session to completed status
    }
    setMarkAsCompletedActivated(true)
    dispatch(sessionActions.updateSessionStatus(completeStatusSessionPayload))
    handleCompleteSessionModalClose()
  }

  useEffect(() => {
    if (sessionCompleteStatus.status === APP_ACTION_STATUS.SUCCESS && markAsCompletedActivated) {
      navigate(getEngagementViewRoute(engagementId ?? '', roleType ?? ''))
    }
  }, [sessionCompleteStatus.status])
  const viewAllCoachees = () => {
    const _engagementId = engagementId ?? ''
    const _sessionId = sessionId ?? ''
    const _roleType = roleType ?? ''
    navigate(getSessionCoacheeStatRoute(_engagementId, _sessionId, _roleType))
  }
  const viewAssessmentById = (assessmentId: string, sessionId: string) => {
    const _engagementId = engagementId ?? ''
    const _sessionId = sessionId ?? ''
    const _roleType = roleType ?? ''
    navigate(getAssessmentRoute(_engagementId, _sessionId, _roleType, assessmentId, sessionDetails?.userId))
  }
  const ViewSessionFeedBack = () => {
    const _engagementId = engagementId ?? ''
    const _sessionId = sessionId ?? ''
    const _roleType = roleType ?? ''
    if (sessionDetails.coacheeCount === 1) {
      navigate(
        getViewFeedbackRoute(_engagementId, _sessionId, _roleType, sessionDetails?.individualCoacheeId ?? '')
      )
    } else {
      navigate(getViewGroupFeedbackRoute(_engagementId, _sessionId, _roleType))
    }
  }

  const GiveSessionFeedback = () => {
    if (sessionDetails.isReviewDone) {
      navigate(getViewFeedbackRoute(engagementId ?? '', sessionId ?? '', roleType ?? '', sessionDetails?.userId))
    } else {
      navigate(getGiveFeedbackRoute(engagementId ?? '', sessionId ?? '', roleType ?? ''))
    }
  }

  const NavigateToNotes = () => {
    const coachId =
      sessionDetailsResponse?.data?.coachList.length > 0 ? sessionDetailsResponse?.data?.coachList[0].id : ''
    navigate(getViewSessionNotesRoute(engagementId ?? '', sessionId ?? '', roleType ?? '', coachId))
  }
  return (
    <React.Fragment>
      <section className='page-root' >
        {!isNullOrUndefinedOrEmpty(isErrorFetchData) &&
          <SomethingWentWrong
            isShowDefault={true}
            subNotification={isErrorFetchData}
          />
        }
        {isNullOrUndefinedOrEmpty(isErrorFetchData) &&
          <>
            {sessionDetailsResponse?.isLoading && <ViewSessionSkelton />}
            {!sessionDetailsResponse?.isLoading &&
              <Grid container>
                <Grid item md={12}>
                  <Card sx={{ padding: '1.5rem' }}>
                    <SessionHeaderDescription
                      isMarkAsCompleted={isMarkedAsCompleted}
                      isCoachee={isCoachee}
                      openCompleteSessionModal={completeSessionModal}
                      sessionCoachName={sessionCoachName}
                      sessionDescription={sessionDetails?.description}
                      sessionDuration={sessionDetails?.sessionDuration}
                      sessionName={sessionDetails?.name}
                      sessionStatus={sessionDetails?.type}
                      sessionAssessmentStatus={sessionDetails.isAssesmentTaken}
                      sessionAssessmentCompulsoryStatus={sessionDetails.isAssessmentCompulsory}

                    />
                    <SessionMaterial
                      markAsCompleted={isMarkedAsCompleted}
                      downloadFile={fileDownload}
                      myFiles={myFiles}
                      sessionDetailsResponse={sessionDetailsResponse.data}
                    />
                    <Link onClick={NavigateToNotes} sx={{ cursor: 'pointer' }}>
                      <Typography
                        color='primary'
                        fontSize='1rem'
                        fontWeight={600}
                        sx={{ pt: 3 }}
                      >
                        View Notes
                      </Typography>
                    </Link>
                    <Divider sx={{ marginTop: '1rem' }} variant="fullWidth" />
                    {isCoachee && (
                      <Box marginTop='1rem'>
                        {(sessionDetails?.isAssesmentTaken)
                          ? (
                            <>
                              {sessionAssessments?.map((assessment: any, index: number) => {
                                const completionPercentage =
                                (assessment.correctAnswers / assessment.totalQuestions) * 100
                                return (
                                  <Card key={index} sx={{ padding: '1rem' }}>
                                    <Grid container>
                                      <Grid item md={12}>
                                        <Box display='flex' justifyContent='space-between'>
                                          <Box display='flex' alignItems='center'>
                                            {sessionDetails?.isAssesmentTaken && (
                                              <CircularProgressWithLabel
                                                totalQuestions={assessment.totalQuestions}
                                                correctAnswers={assessment.correctAnswers}
                                                value={completionPercentage}
                                              />
                                            )}
                                            <Typography
                                              marginLeft='1rem'
                                              fontSize='1rem'
                                              fontWeight={600}
                                            >
                                              Assessment
                                            </Typography>
                                          </Box>
                                          <Box>
                                            <Button
                                              disabled={isMarkedAsCompleted === 'Completed' ||
                                                isNullOrUndefinedOrEmpty(sessionDetails.sessionAssessmentId)}
                                              onClick={() => {
                                                viewAssessmentById(assessment.assessmentId, sessionDetails?.sessionId)
                                              }}
                                              sx={{
                                                textTransform: 'none',
                                                background: 'white',
                                                color: '#437EF7',
                                                boxShadow: 'none',
                                                '&:hover': {
                                                  background: 'white'
                                                }
                                              }}
                                              variant="contained"
                                              endIcon={<ArrowForwardIosOutlinedIcon />}
                                            >
                                              Go to assessment
                                            </Button>
                                          </Box>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Card>
                                )
                              })}
                            </>
                            )
                          : (
                            <>
                              <Card sx={{ padding: '1rem' }}>
                                <Grid container>
                                  <Grid item md={12}>
                                    <Box display='flex' justifyContent='space-between'>
                                      <Box display='flex' alignItems='center'>
                                        <Typography
                                          marginLeft='1rem'
                                          fontSize='1rem'
                                          fontWeight={600}
                                        >
                                          Assessment
                                        </Typography>
                                        <Chip
                                          label={isNullOrUndefinedOrEmpty(sessionDetails.sessionAssessmentId)
                                            ? 'No Assessment for this session'
                                            : 'Not Attempted'}
                                          color='primary'
                                          size='small'
                                          sx={{ marginLeft: '1rem' }}
                                        />
                                      </Box>
                                      <Box>
                                        <Button
                                          disabled={isMarkedAsCompleted === 'Completed' ||
                                            isNullOrUndefinedOrEmpty(sessionDetails.sessionAssessmentId)}
                                          onClick={() => {
                                            viewAssessmentById(
                                              sessionDetails?.sessionAssessmentId, sessionDetails?.sessionId)
                                          }}
                                          sx={{
                                            textTransform: 'none',
                                            background: 'white',
                                            color: '#437EF7',
                                            boxShadow: 'none',
                                            '&:hover': {
                                              background: 'white'
                                            }
                                          }}
                                          variant="contained"
                                          endIcon={<ArrowForwardIosOutlinedIcon />}

                                        >
                                          Go to assessment
                                        </Button>
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Card>
                            </>
                            )}
                      </Box>
                    )
                    }

                    <Box display='flex' justifyContent='space-between' sx={{ marginTop: '2rem' }}>
                      <Typography
                        fontSize='1rem'
                        fontWeight={600}
                      >
                        {sessionDetails?.roles?.some((r) => r === ROLES_TYPES.COACHEE) ? 'Feedbacks' : 'Coachees'}
                      </Typography>
                      <Typography>
                        {isCoach && (
                          <Button
                            onClick={ViewSessionFeedBack}
                            sx={{
                              fontWeight: 600,
                              color: '#437EF7',
                              textTransform: 'none',
                              border: '0px',
                              boxShadow: 'none'
                            }}
                            variant="text"
                          >
                            View Feedback
                          </Button>
                        )}
                        {isCoachee && (
                          <Button
                            onClick={GiveSessionFeedback}
                            sx={{
                              fontWeight: 600,
                              color: 'black',
                              textTransform: 'none',
                              border: '1px solid gray',
                              boxShadow: 'none',
                              '&:hover': {
                                background: 'white',
                                border: '1px solid gray'
                              },
                              marginLeft: '10px'
                            }}
                            variant="outlined"
                          >
                            My Feedback
                          </Button>
                        )}
                      </Typography>
                    </Box>
                    {sessionDetails?.roles?.some((r) => r === ROLES_TYPES.SESSION_COACH && ROLES_TYPES.SUPERVISOR) && (
                      <SessionCoacheeStatsView
                        coacheeListResponse={sessionCoacheeListResponse}
                        pagination={<AppPagination
                          page={page}
                          handleChangePage={handleChangePage}
                          count={pageCount}
                        />}
                      />
                    )}

                    {sessionDetails?.roles?.some(
                      (r) => r === ROLES_TYPES.COACHEE && sessionDetails.feedback.length > 0) && (
                      <Box>
                        <SessionFeedbackCard
                          feedbackDate={sessionDetails?.feedback[0]?.dateTime}
                          feedbackDescription={sessionDetails?.feedback[0]?.content}
                          feedbackFromName={sessionDetails?.feedback[0]?.receivedFromName}
                          rating={sessionDetails?.feedback[0]?.rating}
                        />
                      </Box>
                    )}
                    {isCoach && (
                      <Box display='flex' justifyContent='center' mt={'1rem'}>
                        <Typography fontWeight={600}>
                          <Link onClick={viewAllCoachees} sx={{ cursor: 'pointer', textDecoration: 'none' }}>
                            See all coachee stats
                          </Link>
                        </Typography>
                      </Box>
                    )}
                  </Card>
                </Grid>
              </Grid>

            }
          </>
        }
        <CompleteSessionModal
          handleClose={handleCompleteSessionModalClose}
          completeSession={completeSession}
          sessionID={sessionDetails?.sessionId}
          modalOpen={openCompleteSessionModal} />

      </section>
    </React.Fragment>
  )
}

export default SessionView
