import csv from '../../assets/attachmentTypes/csv.svg'
import doc from '../../assets/attachmentTypes/doc.svg'
import jpg from '../../assets/attachmentTypes/jpg.svg'
import mp from '../../assets/attachmentTypes/mp.svg'
import pdf from '../../assets/attachmentTypes/pdf.svg'
import png from '../../assets/attachmentTypes/png.svg'
import ppt from '../../assets/attachmentTypes/ppt.svg'
import svg from '../../assets/attachmentTypes/svg.svg'
import rar from '../../assets/attachmentTypes/rar.svg'
import txt from '../../assets/attachmentTypes/txt.svg'
import xlsx from '../../assets/attachmentTypes/xlsx.svg'
import zip from '../../assets/attachmentTypes/zip.svg'
import defaultFile from '../../assets/attachmentTypes/defaultFile.svg'

const attachmentImageHandler = (fileName: string) => {
  const extension = fileName.split('.').pop()
  if (extension === 'png') {
    return png
  } else if (extension === 'csv') {
    return csv
  } else if (extension === 'docx') {
    return doc
  } else if (extension === 'jpg') {
    return jpg
  } else if (extension === 'mp') {
    return mp
  } else if (extension === 'pdf') {
    return pdf
  } else if (extension === 'zip') {
    return zip
  } else if (extension === 'ppt') {
    return ppt
  } else if (extension === 'rar') {
    return rar
  } else if (extension === 'svg') {
    return svg
  } else if (extension === 'txt') {
    return txt
  } else if (extension === 'xlsx') {
    return xlsx
  } else return defaultFile
}

export default attachmentImageHandler
