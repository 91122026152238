import React, { useEffect, useState } from 'react'
import {
  APP_ACTION_STATUS,
  APP_CONFIGS,
  APP_TABLE_CONFIGS,
  GOALS_TYPES,
  GOAL_STATUS_ACTION,
  GOAL_STATUS_CHANGE_ACTIONS,
  HIERARCHY_TYPES,
  PERMISSION,
  PERMISSION_TYPES,
  getGoalEditRoute
} from '../../utils/constants'
import {
  type AppState, type StatusUpdatePayLoad, type GoalListData,
  type AssigneesTypes,
  type MyGoalsRequestPayload, type AllGoalRequestPayload
} from '../../interfaces'
import { useDispatch, useSelector } from 'react-redux'
import { goalActions, priorityAction, statusAction } from '../../redux/actions'
import {
  AppPagination, GoalStatusPopup, GoalViewCard,
  GoalViewDrawer, GoalViewHeader, GoalViewTabPanel
} from '../../components'
import { useNavigate } from 'react-router-dom'
import { hasPermission } from '../../utils/helpers'

const GoalView = () => {
  const showMyGoal = hasPermission([PERMISSION.VIEW_GOALS_ASSIGNED_TO_HIM_SELF])
  const showAllGoal = hasPermission([PERMISSION.VIEW_GOALS_ASSIGNED_TO_HIM_SELF,
    PERMISSION.VIEW_GOALS_CREATED_BY_HIM_SELF], PERMISSION_TYPES.FULL_PERMISSION)
  const loggedUserFromAPI: any = localStorage.getItem(APP_CONFIGS.LOGGED_USER)
  const loggedUser = loggedUserFromAPI?.substring(1, loggedUserFromAPI.length - 1)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const allGoalListResponse = useSelector((state: AppState) => state.goals.allGoalList) // goals by assignee
  const goalListByUserResponse = useSelector(
    (state: AppState) => state.goals.goalByUser) // all goals by user created and assignee
  const goalStatusResponse = useSelector((state: AppState) => state.status.goalStatusList)
  const priorityListResponse = useSelector((state: AppState) => state.priority.priorityList)
  const statusUpdateResponse = useSelector((state: AppState) => state.goals.updateGoalState)

  // Mange the Right Drawer
  const [drawerOpen, setDrawerOpen] = useState(false)

  const [goalType, setGoalType] = React.useState<GOALS_TYPES>(showMyGoal ? GOALS_TYPES.MY_GOALS : GOALS_TYPES.ALL_GOALS)

  // pagination
  const [page, setPage] = React.useState(APP_TABLE_CONFIGS.DEFAULT_PAGE)
  const [pageCount, setPageCount] = React.useState(APP_TABLE_CONFIGS.DEFAULT_PAGE_COUNT)

  const [statusIds, setStatusIds] = React.useState<number[]>([])
  const [priorityIds, setPriorityIds] = React.useState<number[]>([])

  const [searchKey, setSearchKey] = React.useState<string>('')
  const [openPopup, setOpenPopup] = useState(false)

  const [statusGoalId, setStatusGoalId] = React.useState<string>('')
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const [currentActionType, setCurrentActionType] = React.useState<string>('')

  const [statusChangeNote, setStatusChangeNote] = useState('')

  const open = Boolean(anchorEl)

  useEffect(() => {
    void getAllGoals(getAllGoalRequestPayloadObjectCreate())
    void getGoalStatus()
    void getPriorityList()
    void getMyGoalsByAssignee(getMyGoalsByAssigneeRequestPayloadObjectCreate())
  }, [])

  // for find how many pages displays
  useEffect(() => {
    if (allGoalListResponse.status === APP_ACTION_STATUS.SUCCESS ||
      goalListByUserResponse.status === APP_ACTION_STATUS.SUCCESS) {
      if (goalType === GOALS_TYPES.MY_GOALS) {
        const count = Math.ceil(goalListByUserResponse.data.count / APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE)
        setPageCount(count)
      } else {
        const count = Math.ceil(allGoalListResponse.data.count / APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE)
        setPageCount(count)
      }
    }
  }, [allGoalListResponse.status, goalListByUserResponse.status, goalType])

  useEffect(() => {
    if (statusUpdateResponse.status === APP_ACTION_STATUS.SUCCESS) {
      dispatch(goalActions.clearGoalStatus())
      if (goalType === GOALS_TYPES.MY_GOALS) {
        void getMyGoalsByAssignee(getMyGoalsByAssigneeRequestPayloadObjectCreate())
      } else {
        void getAllGoals(getAllGoalRequestPayloadObjectCreate())
      }
    }
    if (statusUpdateResponse.status === APP_ACTION_STATUS.ERROR) {
      // alert(statusUpdateResponse.error)
    }
  }, [statusUpdateResponse.status])

  const getAllGoals = async (requestPayload: AllGoalRequestPayload) => {
    const getAllGoalRequestPayload = requestPayload
    dispatch(goalActions.getAllGoalList(getAllGoalRequestPayload))
  }

  /*
    This is the function to take the My Goals Details
    URL : /api/v1/goalsView/detail
    No path Params only body Params
    There is assignee attribute in body that can be use to get all my goals against the username
*/

  const getMyGoalsByAssignee = async (requestPayload: MyGoalsRequestPayload) => {
    dispatch(goalActions.getMyGoalList(requestPayload))
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, goalId: string) => {
    setAnchorEl(event.currentTarget)
    setStatusGoalId(goalId)
  }

  // Input Note in status change popup
  const handleNoteChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setStatusChangeNote(event.target.value)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  // Get Goal Status
  const getGoalStatus = async () => {
    dispatch(statusAction.getGoalStatus())
  }

  // Get Priority List
  const getPriorityList = async () => {
    dispatch(priorityAction.getPriorityList())
  }

  // Drawer open and close
  const toggleDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    setPriorityIds([])
    setStatusIds([])
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return
    }

    setDrawerOpen(open)
  }

  // Navigate between MyGoals and AllGoals in GoalViewTabPanel Component
  const handleChangeGoalType = (event: React.MouseEvent<HTMLElement>, newType: GOALS_TYPES) => {
    if (newType !== null) {
      setGoalType(newType)
      setStatusIds([])
      setPriorityIds([])
      setSearchKey('')
      setPage(APP_TABLE_CONFIGS.DEFAULT_PAGE)

      if (newType === GOALS_TYPES.MY_GOALS) {
        const getMyGoalRequestPayload: MyGoalsRequestPayload = {
          searchField: '',
          statusIds: [],
          priorityIds: [],
          limit: APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE,
          page: APP_TABLE_CONFIGS.DEFAULT_PAGE
        }
        void getMyGoalsByAssignee(getMyGoalsByAssigneeRequestPayloadObjectCreate(getMyGoalRequestPayload))
      } else {
        const getAllGoalByUserRequestPayload: AllGoalRequestPayload = {
          requestBody: {
            searchField: '',
            statusIds: [],
            priorityIds: [],
            page: APP_TABLE_CONFIGS.DEFAULT_PAGE,
            limit: APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE
          },
          pathParam: {
            username: loggedUser
          }

        }
        void getAllGoals(getAllGoalRequestPayloadObjectCreate(getAllGoalByUserRequestPayload))
      }
    }
  }

  // Page Change
  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
    if (goalType === GOALS_TYPES.MY_GOALS) {
      const getMyGoalRequestPayload: MyGoalsRequestPayload = {
        page: value,
        limit: APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE,
        assignee: loggedUser
      }
      void getMyGoalsByAssignee(getMyGoalsByAssigneeRequestPayloadObjectCreate(getMyGoalRequestPayload))
    } else {
      const getAllGoalByUserRequestPayload: AllGoalRequestPayload = {
        requestBody: {
          page: value,
          limit: APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE
        },
        pathParam: {
          username: loggedUser
        }
      }
      void getAllGoals(getAllGoalRequestPayloadObjectCreate(getAllGoalByUserRequestPayload))
    }
  }

  // Status Filter
  const handleFilterStatus = (event: React.ChangeEvent<HTMLInputElement>, statusId: number) => {
    // const statusArray: number[] = []
    if (event.target.checked) {
      // statusArray.push(statusId)
      setStatusIds(prev => [...prev, statusId])
    } else {
      setStatusIds(statusIds.filter(item => item !== statusId))
    }
  }

  // Priority filter
  const handleFilterPriority = (event: React.ChangeEvent<HTMLInputElement>, priorityId: number) => {
    if (event.target.checked) {
      setPriorityIds(prev => [...prev, priorityId])
    } else {
      setPriorityIds(priorityIds.filter(item => item !== priorityId))
    }
  }
  const clearSearchGoal = () => {
    setSearchKey('')
    setPage(APP_TABLE_CONFIGS.DEFAULT_PAGE)
    if (goalType === GOALS_TYPES.MY_GOALS) {
      const getMyGoalRequestPayload: MyGoalsRequestPayload = {
        limit: APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE,
        page: APP_TABLE_CONFIGS.DEFAULT_PAGE,
        searchField: '',
        assignee: loggedUser
      }
      void getMyGoalsByAssignee(getMyGoalsByAssigneeRequestPayloadObjectCreate(getMyGoalRequestPayload))
    } else {
      const getGoalByUserRequestPayload: AllGoalRequestPayload = {
        requestBody: {
          limit: APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE,
          page: APP_TABLE_CONFIGS.DEFAULT_PAGE,
          searchField: ''
        },
        pathParam: {
          username: loggedUser
        }
      }
      void getAllGoals(getAllGoalRequestPayloadObjectCreate(getGoalByUserRequestPayload))
    }
  }
  //  Search Goal by Goal Name or Tag
  const onSearchGoal = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKey(event.target.value)
    setPage(APP_TABLE_CONFIGS.DEFAULT_PAGE)
    if (event.target.value.trim().length === 0) {
      clearSearchGoal()
    }
    if (goalType === GOALS_TYPES.MY_GOALS) {
      const getMyGoalRequestPayload: MyGoalsRequestPayload = {
        limit: APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE,
        page: APP_TABLE_CONFIGS.DEFAULT_PAGE,
        searchField: event.target.value,
        assignee: loggedUser
      }

      void getMyGoalsByAssignee(getMyGoalsByAssigneeRequestPayloadObjectCreate(getMyGoalRequestPayload))
    } else {
      const getGoalByUserRequestPayload: AllGoalRequestPayload = {
        requestBody: {
          limit: APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE,
          page: APP_TABLE_CONFIGS.DEFAULT_PAGE,
          searchField: event.target.value
        },
        pathParam: {
          username: loggedUser
        }
      }
      void getAllGoals(getAllGoalRequestPayloadObjectCreate(getGoalByUserRequestPayload))
    }
  }

  // Create All Goal Request Params Object for Dispatch function
  const getAllGoalRequestPayloadObjectCreate = (data?: AllGoalRequestPayload): AllGoalRequestPayload => {
    let _statusIds
    let _priorityIds

    if (data?.requestBody.statusIds?.length === 0) {
      _statusIds = undefined
    } else {
      if (statusIds.length > 0) {
        _statusIds = statusIds
      } else {
        _statusIds = undefined
      }
    }

    if (data?.requestBody.priorityIds?.length === 0) {
      _priorityIds = undefined
    } else {
      if (priorityIds.length > 0) {
        _priorityIds = priorityIds
      } else {
        _priorityIds = undefined
      }
    }

    // goal by assignee (for my goals)
    const allGoalRequestPayload: AllGoalRequestPayload = {
      pathParam: {
        username: loggedUser
      },
      requestBody: {
        priorityIds: _priorityIds,
        statusIds: _statusIds,
        searchField: data?.requestBody.searchField !== ''
          ? data?.requestBody.searchField
          : '',
        limit: APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE,
        page: data?.requestBody.page === undefined ? page : data?.requestBody.page
      }

    }
    return allGoalRequestPayload
  }

  /*
      Get My Goal List by passing the assignee
      @Kalana Elapatha
  */

  const getMyGoalsByAssigneeRequestPayloadObjectCreate = (data?: MyGoalsRequestPayload): MyGoalsRequestPayload => {
    let _statusIds
    let _priorityIds

    if (data?.statusIds?.length === 0) {
      _statusIds = undefined
    } else {
      if (statusIds.length > 0) {
        _statusIds = statusIds
      } else {
        _statusIds = undefined
      }
    }

    if (data?.priorityIds?.length === 0) {
      _priorityIds = undefined
    } else {
      if (priorityIds.length > 0) {
        _priorityIds = priorityIds
      } else {
        _priorityIds = undefined
      }
    }
    const myGoalsByAssigneeRequestPayload: MyGoalsRequestPayload = {
      priorityIds: _priorityIds,
      statusIds: _statusIds,
      // searchField: data?.searchField !== '' ? data?.searchField : searchKey !== '' ? searchKey : undefined,
      searchField: data?.searchField !== '' ? data?.searchField : '',
      limit: APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE,
      page: data?.page === undefined ? page : data?.page,
      assignee: loggedUser // shoud be add logged user username
    }
    return myGoalsByAssigneeRequestPayload
  }

  // Click Cancel button in Drawer
  const resetSelectedFilters = () => {
    setDrawerOpen(false)
    setPriorityIds([])
    setStatusIds([])
    if (goalType === GOALS_TYPES.MY_GOALS) {
      const getMyGoalRequestPayload: MyGoalsRequestPayload = {
        limit: APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE,
        page: APP_TABLE_CONFIGS.DEFAULT_PAGE,
        statusIds: [],
        priorityIds: []
      }
      void getMyGoalsByAssignee(getMyGoalsByAssigneeRequestPayloadObjectCreate(getMyGoalRequestPayload))
    } else {
      const getAllGoalByUserRequestPayload: AllGoalRequestPayload = {
        requestBody: {
          limit: APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE,
          page: APP_TABLE_CONFIGS.DEFAULT_PAGE,
          statusIds: [],
          priorityIds: []
        },
        pathParam: {
          username: loggedUser
        }
      }
      void getAllGoals(getAllGoalRequestPayloadObjectCreate(getAllGoalByUserRequestPayload))
    }
  }
  // Click Continue button in Drawer
  const filterGoalsByStatusOrPriority = () => {
    if (goalType === GOALS_TYPES.MY_GOALS) {
      const getMyGoalRequestPayload: MyGoalsRequestPayload = {
        limit: APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE,
        page: APP_TABLE_CONFIGS.DEFAULT_PAGE
      }
      void getMyGoalsByAssignee(getMyGoalsByAssigneeRequestPayloadObjectCreate(getMyGoalRequestPayload))
    } else {
      const getAllGoalByUserRequestPayload: AllGoalRequestPayload = {
        requestBody: {
          limit: APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE,
          page: APP_TABLE_CONFIGS.DEFAULT_PAGE
        },
        pathParam: {
          username: loggedUser
        }
      }
      void getAllGoals(getAllGoalRequestPayloadObjectCreate(getAllGoalByUserRequestPayload))
    }
    setDrawerOpen(false)
    setPage(APP_TABLE_CONFIGS.DEFAULT_PAGE)
  }

  const handleEditClick = (goal: GoalListData) => {
    navigate(getGoalEditRoute(goal._id))
  }

  const onGoalAccept = () => {
    setOpenPopup(true)
    setCurrentActionType(GOAL_STATUS_CHANGE_ACTIONS.ACCEPT)
  }
  const onGoalRequestEdit = () => {
    setOpenPopup(true)
    setCurrentActionType(GOAL_STATUS_CHANGE_ACTIONS.REQUEST_EDIT)
  }
  const onGoalReject = () => {
    setOpenPopup(true)
    setCurrentActionType(GOAL_STATUS_CHANGE_ACTIONS.REJECT)
  }
  const popupClose = () => {
    setOpenPopup(false)
    setStatusChangeNote('')
    handleClose()
  }

  // Goal Action payLoad
  const changeGoalStatus = () => {
    const requestPayLoad: StatusUpdatePayLoad = {
      pathParam: statusGoalId,
      requestBody: {
        note: currentActionType === GOAL_STATUS_CHANGE_ACTIONS.ACCEPT ? undefined : statusChangeNote,
        statusId: currentActionType === GOAL_STATUS_CHANGE_ACTIONS.ACCEPT
          ? GOAL_STATUS_ACTION.ACCEPT
          : currentActionType === GOAL_STATUS_CHANGE_ACTIONS.REJECT
            ? GOAL_STATUS_ACTION.REJECT
            : GOAL_STATUS_ACTION.REQUEST_EDIT
      }
    }
    dispatch(goalActions.GoalStatusChange(requestPayLoad))
    setOpenPopup(false)
    setStatusChangeNote('')
    handleClose()
  }

  const disableStatusActionButton = (goal: GoalListData) => {
    if (goalType === GOALS_TYPES.MY_GOALS) {
      if (goal.tagType === HIERARCHY_TYPES.USER && goal.statusId === GOAL_STATUS_ACTION.PENDIND) {
        return false
      } else {
        return true
      }
    } else {
      // goal.assignees.length > 0 && goal.assignees.some((assign: AssigneesTypes) => assign.userName === loggedUser )
      if (goal.tagType === HIERARCHY_TYPES.USER &&
        goal.statusId === GOAL_STATUS_ACTION.PENDIND &&
        goal.assignees.length > 0 &&
        goal.assignees.some((assign: AssigneesTypes) => assign.username === loggedUser)) {
        return false
      } else {
        return true
      }
    }
  }

  const disableEditButton = (goal: GoalListData): boolean => {
    if (goalType === GOALS_TYPES.ALL_GOALS) {
      // shoud be replace by actual logged user
      if (goal.createdBy === loggedUser && (
        goal.statusId === GOAL_STATUS_ACTION.ACCEPT ||
        goal.statusId === GOAL_STATUS_ACTION.PENDIND ||
        goal.statusId === GOAL_STATUS_ACTION.REQUEST_EDIT)) {
        return false
      } else {
        return true
      }
    } else {
      return false
    }
  }

  return (
    <React.Fragment>
      <section className='page-root' >
        <GoalViewHeader />
        <GoalViewTabPanel
          toggleDrawer={toggleDrawer}
          goalType={goalType}
          allGoalCount={showAllGoal ? allGoalListResponse.data.count : 0}
          myGoalCount={showMyGoal ? goalListByUserResponse.data.count : 0}
          handleChangeGoalType={handleChangeGoalType}
          onSearchGoal={onSearchGoal}
          searchKey={searchKey}
          clearSearchGoal={clearSearchGoal}
          goalViewCard={<GoalViewCard
            goalList={goalType === GOALS_TYPES.MY_GOALS ? goalListByUserResponse : allGoalListResponse}
            pagination={<AppPagination
              page={page}
              handleChangePage={handleChangePage}
              count={pageCount} />}
            goalType={goalType}
            handleEditClick={handleEditClick}
            handleClick={handleClick}
            handleClose={handleClose}
            menuOpen={open}
            anchorEl={anchorEl}
            statusGoalId={statusGoalId}
            onGoalAccept={onGoalAccept}
            onGoalRequestEdit={onGoalRequestEdit}
            onGoalReject={onGoalReject}
            disableStatusActionButton={disableStatusActionButton}
            disableEditButton={disableEditButton}
          />
          }
        />

        <GoalViewDrawer
          drawerState={drawerOpen}
          toggleDrawer={toggleDrawer}
          goalStatus={goalStatusResponse.data}
          priorityList={priorityListResponse.data}
          statusIds={statusIds}
          priorityIds={priorityIds}
          handleFilterStatus={handleFilterStatus}
          handleFilterPriority={handleFilterPriority}
          disableContinueButton={!(statusIds.length > 0 || priorityIds.length > 0)}
          resetSelectedFilters={resetSelectedFilters}
          filterGoalsByStatusOrPriority={filterGoalsByStatusOrPriority}
        />
        {openPopup &&
          <GoalStatusPopup
            popupClose={popupClose}
            currentActionType={currentActionType}
            handleNoteChange={handleNoteChange}
            statusChangeNote={statusChangeNote}
            changeGoalStatus={changeGoalStatus}
          />}
      </section>
    </React.Fragment>
  )
}

export default GoalView
