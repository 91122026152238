import { combineReducers } from 'redux'
import { type StatusState } from '../../../interfaces'
import goalStatusListReducer from './goalStatusList'
import engagementStatusListReducer from './engagementStatusList'
import sessionStatusListReducer from './sessionStatusList'

const statusReducer = combineReducers<StatusState>({
  goalStatusList: goalStatusListReducer,
  engagementStatusList: engagementStatusListReducer,
  sessionStatusList: sessionStatusListReducer

})

export default statusReducer
