/* eslint-disable max-len */
export enum APP_ROUTES {
  ROOT = '/',
  DASHBOARD = '/dashboard',
  PROFILE = '/profile',
  NOTIFICATIONS = '/notifications',
  EVENTS = '/events',
  GOALS = '/goals',
  GOAL_CREATE = '/goals/create-goal',
  GOAL_EDIT = '/goals/edit-goal/:id',
  ENGAGEMENT_CREATE = '/engagements/create-engagement',
  ENGAGEMENT_EDIT = '/engagements/:roleType/edit-engagement/:id',
  ENGAGEMENT_VIEW = '/engagements',
  ENGAGEMENT_VIEW_BY_TYPE = '/engagements/:roleType/:engagementId',
  UNAUTHORIZED = '/unauthorized',
  ENGAGEMENT_VIEW_BY_ID = '/engagements/:roleType/view-engagement/:engagementId/',
  ENGAGEMENT_VIEW_BY_SESSION = '/engagements/:roleType/view-engagement/:engagementId/:sessionId',
  SESSION_CREATE = '/engagements/:roleType/view-engagement/:engagementId/create-session',
  SESSION_EDIT = '/engagements/:roleType/view-engagement/:engagementId/edit-session/:sessionId',
  VIEW_SESSION = '/engagements/:roleType/view-engagement/:engagementId/view-session/:sessionId',
  VIEW_NOTES = '/engagements/:roleType/view-engagement/:engagementId/view-session/:sessionId/notes/:coachId',
  VIEW_FEEDBACK = '/engagements/:roleType/view-engagement/:engagementId/view-session/:sessionId/feedback/:user',
  GIVE_FEEDBACK = '/engagements/:roleType/view-engagement/:engagementId/view-session/:sessionId/feedback',
  VIEW_ASSESSMENT = '/engagements/:roleType/view-engagement/:engagementId/view-session/:sessionId/assessment/:assessmentId/:user',
  VIEW_ASSESSMENT_RESULT = '/engagements/:roleType/view-engagement/:engagementId/view-session/:sessionId/assessment/:assessmentId/assessment-result/:user',
  VIEW_SESSION_COACHEES_STATS = '/engagements/:roleType/view-engagement/:engagementId/view-session/:sessionId/coachees',
  VIEW_FEEDBACK_GROUP = '/engagements/:roleType/view-engagement/:engagementId/view-session/:sessionId/group-feedbacks'
}

export const getGoalEditRoute = (id: string): string => {
  return `/goals/edit-goal/${id}`
}

export const getEngagementEditRoute = (id: string, roleType: string): string => {
  return `/engagements/${roleType}/edit-engagement/${id}`
}

export const getEngagementViewByTypeRoute = (engagementId: string, roleType: string): string => {
  return `/engagements/${roleType}/${engagementId}`
}

export const getEngagementViewRoute = (engagementId: string, roleType: string): string => {
  return `/engagements/${roleType}/view-engagement/${engagementId}`
}

export const getEngagementViewBySessionRoute = (engagementId: string, roleType: string, sessionId: string): string => {
  return `/engagements/${roleType}/view-engagement/${engagementId}/${sessionId}`
}

export const getViewSessionRoute = (engagementId: string, sessionId: string, roleType: string): string => {
  return `/engagements/${roleType}/view-engagement/${engagementId}/view-session/${sessionId}`
}

export const getViewSessionNotesRoute = (engagementId: string, sessionId: string, roleType: string, coachId: string): string => {
  return `/engagements/${roleType}/view-engagement/${engagementId}/view-session/${sessionId}/notes/${coachId}`
}

export const getCreateSessionRoute = (engagementId: string, roleType: string): string => {
  return `/engagements/${roleType}/view-engagement/${engagementId}/create-session`
}

export const getEditSessionRoute = (engagementId: string, sessionId: string, roleType: string): string => {
  return `/engagements/${roleType}/view-engagement/${engagementId}/edit-session/${sessionId}`
}

export const getViewFeedbackRoute =
(engagementId: string, sessionId: string, roleType: string, user: string): string => {
  return `/engagements/${roleType}/view-engagement/${engagementId}/view-session/${sessionId}/feedback/${user}`
}

export const getGiveFeedbackRoute = (engagementId: string, sessionId: string, roleType: string): string => {
  return `/engagements/${roleType}/view-engagement/${engagementId}/view-session/${sessionId}/feedback`
}

export const getAssessmentRoute =
(engagementId: string, sessionId: string, roleType: string, assessment: string, user: string): string => {
  return `/engagements/${roleType}/view-engagement/${engagementId}/view-session/${sessionId}/assessment/${assessment}/${user}`
}

export const getAssessmentResultRoute =
(engagementId: string, sessionId: string, roleType: string, assessment: string, user: string): string => {
  return `/engagements/${roleType}/view-engagement/${engagementId}/view-session/${sessionId}/assessment/${assessment}/assessment-result/${user}`
}

export const getSessionCoacheeStatRoute = (engagementId: string, sessionId: string, roleType: string): string => {
  return `/engagements/${roleType}/view-engagement/${engagementId}/view-session/${sessionId}/coachees`
}

export const getViewGroupFeedbackRoute = (engagementId: string, sessionId: string, roleType: string): string => {
  return `/engagements/${roleType}/view-engagement/${engagementId}/view-session/${sessionId}/group-feedbacks`
}
