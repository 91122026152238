import React, { useState, useEffect, useCallback } from 'react'
import {
  ALERT_VARIANT,
  APP_ACTION_STATUS,
  APP_TABLE_CONFIGS,
  ENGAGEMENT_CREATE_STEPS,
  ENGAGEMENT_STATUS,
  ENGAGEMENT_fORM_FIELDS,
  FORM_VALIDATOR_TYPES,
  GOAL_STATUS_ACTION,
  HIERARCHY_TYPES
} from '../../utils/constants'
import moment from 'moment'
import {
  type SelectGolas,
  type EngementCreteFormState,
  type AppState,
  type EngagementCreateRequestPayload,
  type GoalListSummeryRequestParams,
  type AlertMessage,
  type EngagementUpdateRequestPayload,
  type CoachListDropDownObject,
  type Attachments,
  type GoalListSummaryData,
  type EngagementDetailsRequestParam,
  type AllGoalsByEngagementIdRequestPayload,
  type GoalListData,
  type AddToQueDto
} from '../../interfaces'
import formValidator from '../../utils/helpers/formValidator'
import { Box, Button, Card, CardContent, CircularProgress, Grid, Typography } from '@mui/material'
import {
  EngagementAddContent,
  EngagementGeneralDetailsForm,
  EngagementGoalSelect,
  EngagementStepper,
  EngagementSummery
} from '../../components/engagement'
import { useDispatch, useSelector } from 'react-redux'
import { alertActions, engagementsActions, goalActions, userAction } from '../../redux/actions'
import EngagementGoalSelectCard from '../../components/engagement/EngagementGoalSelectCard/EngagementGoalSelectCard'
import { AppPagination, SomethingWentWrong } from '../../components'
import { fileActions } from '../../redux/actions/fileActions'
import { useDropzone } from 'react-dropzone'
import { useNavigate, useParams } from 'react-router-dom'
import { type GetAllCoachesParam } from '../../interfaces/users'
import { type DeleteFileRequestParam } from '../../interfaces/fileDelete'
import { UsePrompt, isNullOrUndefinedOrEmpty } from '../../utils/helpers'

const CreateEngagement = () => {
  const INITIAL_STATE: EngementCreteFormState = {
    engagementName: {
      value: '',
      validator: FORM_VALIDATOR_TYPES.TEXT,
      isRequired: true,
      disable: false,
      error: null
    },
    engagementDescription: {
      value: '',
      validator: FORM_VALIDATOR_TYPES.TEXT,
      isRequired: true,
      disable: false,
      error: null
    },
    startDate: {
      value: moment().add(1, 'day'),
      validator: FORM_VALIDATOR_TYPES.DATE,
      isRequired: true,
      disable: false,
      error: null
    },
    endDate: {
      value: null,
      validator: FORM_VALIDATOR_TYPES.DATE,
      isRequired: true,
      disable: false,
      error: null
    },
    engagementCoach: {
      value: null,
      validator: FORM_VALIDATOR_TYPES.OBJECT,
      isRequired: true,
      disable: false,
      error: null
    },
    sbu: {
      value: { id: '', name: '' },
      validator: FORM_VALIDATOR_TYPES.OBJECT,
      isRequired: false,
      disable: false,
      error: null
    },
    department: {
      value: { id: '', name: '' },
      validator: FORM_VALIDATOR_TYPES.OBJECT,
      isRequired: false,
      disable: false,
      error: null
    },
    role: {
      value: { id: '', name: '' },
      validator: FORM_VALIDATOR_TYPES.OBJECT,
      isRequired: false,
      disable: false,
      error: null
    }
  }

  const INIT_ATTACHEMENT: Attachments = {
    attachmentTitle: '',
    attachmentUrl: '',
    attachmentId: ''
  }

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { id, roleType } = useParams<{ id: string, roleType: string }>()
  // todo need to implement single byPassing
  const [isErrorFetchData, setIsErrorFetchData] = useState<string>('')
  const [activeStep, setActiveStep] = React.useState(0)
  const [engagementGeneralDetailForm, setEngagementGeneralDetailForm] = useState<EngementCreteFormState>(INITIAL_STATE)
  const [selectedGoal, setSelectedGoals] = React.useState<SelectGolas[]>([])
  const [selectedAttachment, setSelectedAttachment] = React.useState<Attachments>(INIT_ATTACHEMENT)
  const [myFiles, setMyFiles] = useState<File[]>([])
  const [myUploadedFiles, setMyUploadedFiles] = useState<Attachments[]>([])
  const [page, setPage] = React.useState(APP_TABLE_CONFIGS.DEFAULT_PAGE)
  const [pageCount, setPageCount] = React.useState(APP_TABLE_CONFIGS.DEFAULT_PAGE_COUNT)
  const [searchResult, setSearchResult] = useState<string>('')
  const [activeTab, setActiveTab] = React.useState(1)
  const [goalIds, setGoalIds] = useState<string[]>([])
  const [errorMessage, setErrorMessage] = useState('')
  const [isFilter, setIsFilter] = useState<boolean>(false)
  const [filteredCoachesList, setFilteredCoachesList] = useState<CoachListDropDownObject[]>([])
  const [goalList, setGoalList] = useState<GoalListSummaryData[]>([])
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [isDirty, setIsDirty] = useState(false)

  const fileUploadURLs = useSelector((state: AppState) => state.files.fileUploadURL)
  const GoalListSummary = useSelector((state: AppState) => state.goals.goalListSummary)
  const createEngagementState = useSelector((state: AppState) => state.engagements.createEngagement)
  const getEngagementDetailById = useSelector((state: AppState) => state.engagements.engagementDetails)
  const getGoalsByEngagementId = useSelector((state: AppState) => state.engagements.getGoalsByEngagementId)
  const downloadFiles = useSelector((state: AppState) => state.files.downloadFile?.data)
  const getAllGoalsByEngagementId = useSelector((state: AppState) => state.engagements.getAllGoalsByEngagementId)
  const downloadFilesResponse = useSelector((state: AppState) => state.files.downloadFile)
  const updateEngagements = useSelector((state: AppState) => state.engagements.updateEngagement)
  const getCoachesListResponse = useSelector((state: AppState) => state.user.getAllCoaches)
  const deleteFileResponse = useSelector((state: AppState) => state.files.deleteFile)

  useEffect(() => {
    return () => {
      setAsInit()
      dispatch(engagementsActions.clearGetEngagementDetailById())
      dispatch(engagementsActions.clearGetGoalsByEngagementId())
      dispatch(engagementsActions.clearAllGetGoalsByEngagementId())
      dispatch(engagementsActions.clearCreateEngagement())
      dispatch(engagementsActions.clearUpdateEngagement())
      dispatch(fileActions.clearfileUploadURL())
      dispatch(engagementsActions.clearEngagementStatus())
    }
  }, [])

  useEffect(() => {
    if (!isNullOrUndefinedOrEmpty(id)) {
      const param: EngagementDetailsRequestParam = {
        pathParam: { id: id ?? '' },
        queryParam: { roleType: roleType ?? '' }
      }
      dispatch(engagementsActions.engagementDetails(param))
      dispatch(engagementsActions.getGoalsByEngagementId(id ?? ''))
    } else {
      const page = APP_TABLE_CONFIGS.DEFAULT_PAGE
      const searchKey = undefined
      const activeTab = 1
      getGoalList(page, searchKey, activeTab)
    }
  }, [id])

  const getGoalList = (_page: number, _serachKey?: string, _activeTab?: number) => {
    if (isNullOrUndefinedOrEmpty(id)) {
      const goalRequestParams: GoalListSummeryRequestParams = {
        tagType: _activeTab,
        limit: APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE,
        page: _page,
        statusId: GOAL_STATUS_ACTION.ACCEPT,
        searchField: _serachKey
      }
      dispatch(goalActions.getGoalListSummary(goalRequestParams))
    } else {
      const allGoalsParam: AllGoalsByEngagementIdRequestPayload = {
        pathParam: { id: id ?? '' },
        quesryParam: {
          limit: APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE,
          page: _page,
          searchField: _serachKey,
          tagType: _activeTab
        }
      }
      dispatch(engagementsActions.getAllGoalsByEngagementId(allGoalsParam))
    }
  }

  const isChekedSelectedGoals = (id: string): boolean => {
    return goalIds.includes(id)
  }

  useEffect(() => {
    if (getCoachesListResponse.status === APP_ACTION_STATUS.SUCCESS) {
      setCoachesListForDropDown()
    }
  }, [getCoachesListResponse.status])

  const setCoachesListForDropDown = () => {
    const _sbu = engagementGeneralDetailForm.sbu.value
    const _dep = engagementGeneralDetailForm.department.value
    const _role = engagementGeneralDetailForm.role.value

    if (getCoachesListResponse.data !== null) {
      let _filteredList = getCoachesListResponse.data.coaches
      if (_sbu.id !== '') {
        _filteredList = _filteredList?.filter((i) => i.sbuId === _sbu.id)
      }
      if (_dep.id !== '') {
        _filteredList = _filteredList?.filter((i) => i.departmentId === _dep.id)
      }
      if (_role.id !== '') {
        _filteredList = _filteredList?.filter((i) => i.roleId === _role.id)
      }
      setFilteredCoachesList(_filteredList.map((i) => { return { id: i.id, name: i.name } }))
    }
  }

  useEffect(() => {
    if (getEngagementDetailById.status === APP_ACTION_STATUS.SUCCESS) {
      const engagementData = getEngagementDetailById.data // Assuming this contains the engagement details data
      if (engagementData != null) {
        const newState: EngementCreteFormState = {
          ...engagementGeneralDetailForm,
          engagementName: {
            ...engagementGeneralDetailForm.engagementName,
            value: engagementData.name,
            disable: engagementData.status === ENGAGEMENT_STATUS.IN_PROGRESS
          },
          engagementDescription: {
            ...engagementGeneralDetailForm.engagementDescription,
            value: engagementData.description,
            disable: engagementData.status === ENGAGEMENT_STATUS.IN_PROGRESS
          },
          startDate: {
            ...engagementGeneralDetailForm.startDate,
            value: moment(engagementData.startDate),
            disable: engagementData.status === ENGAGEMENT_STATUS.IN_PROGRESS
          },
          endDate: {
            ...engagementGeneralDetailForm.endDate,
            value: moment(engagementData.endDate)
          },
          engagementCoach: {
            ...engagementGeneralDetailForm.engagementCoach,
            value: { id: engagementData.coaches[0].id, name: engagementData.coaches[0].name },
            disable: engagementData.status === ENGAGEMENT_STATUS.IN_PROGRESS
          }
        }

        const attArray: Attachments[] = []
        for (let i = 0; i < engagementData.attachments.length; i++) {
          attArray.push({
            attachmentId: engagementData.attachments[i].attachmentId,
            attachmentTitle: engagementData.attachments[i].attachmentTitle,
            attachmentUrl: engagementData.attachments[i].attachmentUrl
          })
        }
        setMyUploadedFiles(attArray)
        setEngagementGeneralDetailForm(newState)
      } else {
        setIsErrorFetchData('Something went wrong when fetching data.!')
      }
    }
    if (getEngagementDetailById?.status === APP_ACTION_STATUS.ERROR) {
      setIsErrorFetchData(getEngagementDetailById?.error ?? 'Something went wrong when fetching data.!')
    }
  }, [getEngagementDetailById.status])

  // for find how many pages displays
  useEffect(() => {
    if (GoalListSummary.status === APP_ACTION_STATUS.SUCCESS) {
      if (activeTab === HIERARCHY_TYPES.ORGANAIZATION) {
        const count = Math.ceil(GoalListSummary.data.count / APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE)
        setPageCount(count)
      } else if (activeTab === HIERARCHY_TYPES.ROLE) {
        const count = Math.ceil(GoalListSummary.data.count / APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE)
        setPageCount(count)
      } else {
        const count = Math.ceil(GoalListSummary.data.count / APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE)
        setPageCount(count)
      }
    }
    if (getAllGoalsByEngagementId.status === APP_ACTION_STATUS.SUCCESS) {
      if (activeTab === HIERARCHY_TYPES.ORGANAIZATION) {
        const count = Math.ceil(getAllGoalsByEngagementId.data.count / APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE)
        setPageCount(count)
      } else if (activeTab === HIERARCHY_TYPES.ROLE) {
        const count = Math.ceil(getAllGoalsByEngagementId.data.count / APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE)
        setPageCount(count)
      } else {
        const count = Math.ceil(getAllGoalsByEngagementId.data.count / APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE)
        setPageCount(count)
      }
    }
    if (getAllGoalsByEngagementId?.status === APP_ACTION_STATUS.ERROR) {
      setIsErrorFetchData(getAllGoalsByEngagementId?.error ?? 'Something went wrong when fetching data.!')
    }
    if (GoalListSummary?.status === APP_ACTION_STATUS.ERROR) {
      setIsErrorFetchData(GoalListSummary?.error ?? 'Something went wrong when fetching data.!')
    }
  }, [GoalListSummary.status, getAllGoalsByEngagementId.status, activeTab])

  useEffect(() => {
    if (createEngagementState.status === APP_ACTION_STATUS.SUCCESS) {
      navigate(-1)
    }
  }, [createEngagementState.status])

  useEffect(() => {
    if (updateEngagements.status === APP_ACTION_STATUS.SUCCESS) {
      setIsDirty(false)
      navigate(-1)
    }
  }, [updateEngagements.status])

  const setAsInit = () => {
    setEngagementGeneralDetailForm(INITIAL_STATE)
    setMyFiles([])
    setSelectedGoals([])
    setGoalIds([])
  }

  useEffect(() => {
    if (getAllGoalsByEngagementId.status === APP_ACTION_STATUS.SUCCESS) {
      if (!isNullOrUndefinedOrEmpty(id)) {
        setGoalList([...getAllGoalsByEngagementId.data.data])
      }
    }
    if (GoalListSummary.status === APP_ACTION_STATUS.SUCCESS) {
      if (isNullOrUndefinedOrEmpty(id)) {
        setGoalList([...GoalListSummary.data.data])
      }
    }
  }, [getAllGoalsByEngagementId.status, GoalListSummary.status])

  useEffect(() => {
    if (getGoalsByEngagementId.status === APP_ACTION_STATUS.SUCCESS) {
      if (!isNullOrUndefinedOrEmpty(getGoalsByEngagementId?.data)) {
        const goals = getGoalsByEngagementId?.data
        const page = APP_TABLE_CONFIGS.DEFAULT_PAGE
        const searchKey = undefined
        const activeTab = goals?.[0].tagType
        getGoalList(page, searchKey, activeTab)
        setActiveTab(goals?.[0].tagType)
        const engagementGoals: any = getGoalsByEngagementId.data.map(i => ({
          goalId: i._id,
          goalName: i.name,
          tags: i.tags
        }))
        setSelectedGoals(engagementGoals)
        setGoalIds(getGoalsByEngagementId.data.map((goal: GoalListData) => goal._id))
      }
    }
    if (getGoalsByEngagementId?.status === APP_ACTION_STATUS.ERROR) {
      setIsErrorFetchData(getGoalsByEngagementId?.error ?? 'Something went wrong when fetching data.!')
    }
  }, [getGoalsByEngagementId.status])

  // Page Change
  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
    getGoalList(value, searchResult, activeTab)
  }

  const clearSearchResult = () => {
    setSearchResult('')
    setPage(APP_TABLE_CONFIGS.DEFAULT_PAGE)
    getGoalList(APP_TABLE_CONFIGS.DEFAULT_PAGE, '', activeTab)
  }

  //  Search Goal by Goal Name or Tag
  const searchGoalListSummary = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchResult(event.target.value)
    setPage(APP_TABLE_CONFIGS.DEFAULT_PAGE)

    // Check if the input value is empty and clear the search if needed
    if (event.target.value.trim().length === 0) {
      clearSearchResult()
    } else {
      getGoalList(APP_TABLE_CONFIGS.DEFAULT_PAGE, event.target.value, activeTab)
    }
  }

  interface AttachmentProps {
    attachmentTitle: string
    attachmentUrl: string
  }
  const createEngagement = async () => {
    const attArray: AttachmentProps[] = []
    for (let i = 0; i < fileUploadURLs.data.length; i++) {
      if (myFiles.some((file: File) => file.name === fileUploadURLs.data[i].fields.key.split('~')[1])) {
        attArray.push({
          attachmentTitle: fileUploadURLs.data[i].fields.key.split('~')[1],
          attachmentUrl: fileUploadURLs.data[i].fields.key
        })
      }
    }
    /*
    remove tags[] before set to the payload
    */
    const goalsWithoutTags = selectedGoal.map((goal: any) => {
      const { tags, ...goalWithoutTags } = goal
      return goalWithoutTags
    })

    const createEngagementPayload: EngagementCreateRequestPayload = {

      name: engagementGeneralDetailForm.engagementName.value,
      description: engagementGeneralDetailForm.engagementDescription.value,
      startDate: moment(engagementGeneralDetailForm.startDate.value).format('YYYY-MM-DD'),
      endDate: moment(engagementGeneralDetailForm.endDate.value).format('YYYY-MM-DD'),
      coach: [engagementGeneralDetailForm.engagementCoach.value],
      goals: [...goalsWithoutTags],
      attachments: attArray
    }
    dispatch(engagementsActions.createEngagement(createEngagementPayload))
    dispatch(fileActions.clearfileUploadURL())
  }

  const updateEngagement = async () => {
    if (!isNullOrUndefinedOrEmpty(id)) {
      const attArray: AttachmentProps[] = []
      for (let i = 0; i < fileUploadURLs.data.length; i++) {
        if (myFiles.some((file: File) => file.name === fileUploadURLs.data[i].fields.key.split('~')[1])) {
          attArray.push({
            attachmentTitle: fileUploadURLs.data[i].fields.key.split('~')[1],
            attachmentUrl: fileUploadURLs.data[i].fields.key
          })
        }
      }
      /*
    remove tags[] before set to the payload
    */
      const goalsWithoutTags = selectedGoal.map((goal: any) => {
        const { tags, ...goalWithoutTags } = goal
        return goalWithoutTags
      })

      const updateEngagementPayload: EngagementUpdateRequestPayload = {
        engagementId: id ?? '',
        requestBody: {
          name: engagementGeneralDetailForm.engagementName.value,
          description: engagementGeneralDetailForm.engagementDescription.value,
          startDate: moment(engagementGeneralDetailForm.startDate.value).format('YYYY-MM-DD'),
          endDate: moment(engagementGeneralDetailForm.endDate.value).format('YYYY-MM-DD'),
          coach: [engagementGeneralDetailForm.engagementCoach.value],
          goals: [...goalsWithoutTags],
          attachments: attArray
        }
      }
      dispatch(engagementsActions.updateEngagement(updateEngagementPayload))
      dispatch(fileActions.clearfileUploadURL())
    }
  }

  const handleNext = (): void => {
    if (activeStep === 0) {
      if (selectedGoal.length < 1) {
        const data: AlertMessage = {
          message: 'Please select goals !!!',
          options: {
            variant: ALERT_VARIANT.WARNING,
            autoHideDuration: 2000
          }

        }
        dispatch(alertActions.triggerAlert(data))
      } else {
        void getCoachesList()
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
      }
    } else if (activeStep === 1) {
      formValidator(engagementGeneralDetailForm)
        .then(([formValidateData, formIsValid]) => {
          setEngagementGeneralDetailForm(formValidateData)
          if (formIsValid) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1)
          }
        })
        .catch(e => {

        })
    } else if (activeStep === ENGAGEMENT_CREATE_STEPS.length - 1) {
      if (isNullOrUndefinedOrEmpty(id)) {
        void createEngagement()
        setShowErrorMessage(false)
      } else {
        void updateEngagement()
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
  }

  // GoalStepper
  const handleBack = (): void => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
    setShowErrorMessage(false)
  }

  const onSetIsFilter = () => {
    if (isNullOrUndefinedOrEmpty(id) ||
      getEngagementDetailById?.data?.status !== ENGAGEMENT_STATUS.IN_PROGRESS) {
      setIsFilter(!isFilter)

      if (isFilter) {
        if (getCoachesListResponse.data !== null) {
          const _filteredList = getCoachesListResponse.data.coaches
          setFilteredCoachesList(_filteredList.map((i) => { return { id: i.id, name: i.name } }))
        }
        setEngagementGeneralDetailForm((prevEngagementGeneralDetailForm) => ({
          ...prevEngagementGeneralDetailForm,
          sbu: {
            ...prevEngagementGeneralDetailForm.sbu,
            value: { id: '', name: '' },
            error: null
          },
          department: {
            ...prevEngagementGeneralDetailForm.department,
            value: { id: '', name: '' },
            error: null
          },
          role: {
            ...prevEngagementGeneralDetailForm.role,
            value: { id: '', name: '' },
            error: null
          }
        }))
      }
    }
  }
  // general detail form element change
  const onInputHandleForm = (property: string, value: any) => {
    setIsDirty(true)
    if (property === ENGAGEMENT_fORM_FIELDS.SBU) {
      setEngagementGeneralDetailForm((prevEngagementGeneralDetailForm) => ({
        ...prevEngagementGeneralDetailForm,
        sbu: {
          ...prevEngagementGeneralDetailForm.sbu,
          value: value !== null ? value : { id: '', name: '' },
          error: null
        },
        department: {
          ...prevEngagementGeneralDetailForm.department,
          value: { id: '', name: '' },
          error: null
        },
        engagementCoach: {
          ...prevEngagementGeneralDetailForm.engagementCoach,
          value: null,
          error: null
        }
      }))
      const _role = engagementGeneralDetailForm.role.value
      if (getCoachesListResponse?.data !== null) {
        let _filteredList = getCoachesListResponse.data.coaches
        if (value !== null) {
          _filteredList = _filteredList?.filter((i) => i.sbuId === value.id)
        }
        if (_role.id !== '') {
          _filteredList = _filteredList?.filter((i) => i.roleId === _role.id)
        }
        setFilteredCoachesList(_filteredList.map((i) => { return { id: i.id, name: i.name } }))
      }
    } else if (property === ENGAGEMENT_fORM_FIELDS.DEPARTMENT) {
      setEngagementGeneralDetailForm((prevEngagementGeneralDetailForm) => ({
        ...prevEngagementGeneralDetailForm,
        department: {
          ...prevEngagementGeneralDetailForm.department,
          value: value !== null ? value : { id: '', name: '' },
          error: null
        },
        engagementCoach: {
          ...prevEngagementGeneralDetailForm.engagementCoach,
          value: null,
          error: null
        }
      }))
      const _sbu = engagementGeneralDetailForm.sbu.value
      const _role = engagementGeneralDetailForm.role.value
      if (getCoachesListResponse?.data !== null) {
        let _filteredList = getCoachesListResponse.data.coaches
        if (value !== null) {
          _filteredList = _filteredList?.filter((i) => i.departmentId === value.id)
        }
        if (_sbu.id !== '') {
          _filteredList = _filteredList?.filter((i) => i.sbuId === _sbu.id)
        }
        if (_role.id !== '') {
          _filteredList = _filteredList?.filter((i) => i.roleId === _role.id)
        }
        setFilteredCoachesList(_filteredList.map((i) => { return { id: i.id, name: i.name } }))
      }
    } else if (property === ENGAGEMENT_fORM_FIELDS.ROLE) {
      setEngagementGeneralDetailForm((prevEngagementGeneralDetailForm) => ({
        ...prevEngagementGeneralDetailForm,
        role: {
          ...prevEngagementGeneralDetailForm.role,
          value: value !== null ? value : { id: '', name: '' },
          error: null
        },
        engagementCoach: {
          ...prevEngagementGeneralDetailForm.engagementCoach,
          value: null,
          error: null
        }
      }))
      const _sbu = engagementGeneralDetailForm.sbu.value
      const _dep = engagementGeneralDetailForm.department.value
      if (getCoachesListResponse?.data !== null) {
        let _filteredList = getCoachesListResponse.data.coaches
        if (value !== null) {
          _filteredList = _filteredList?.filter((i) => i.roleId === value.id)
        }
        if (_sbu.id !== '') {
          _filteredList = _filteredList?.filter((i) => i.sbuId === _sbu.id)
        }
        if (_dep.id !== '') {
          _filteredList = _filteredList?.filter((i) => i.departmentId === _dep.id)
        }
        setFilteredCoachesList(_filteredList.map((i) => { return { id: i.id, name: i.name } }))
      }
    } else if (property === ENGAGEMENT_fORM_FIELDS.START_DATE) {
      setEngagementGeneralDetailForm((prevEngagementGeneralDetailForm) => ({
        ...prevEngagementGeneralDetailForm,
        startDate: {
          ...prevEngagementGeneralDetailForm.startDate,
          value,
          error: null
        },
        endDate: {
          ...prevEngagementGeneralDetailForm.endDate,
          value: moment(value).add(1, 'days'),
          error: null
        }
      }))
    } else {
      setEngagementGeneralDetailForm((prevEngagementGeneralDetailForm) => ({
        ...prevEngagementGeneralDetailForm,
        [property]: {
          ...prevEngagementGeneralDetailForm[property as keyof typeof prevEngagementGeneralDetailForm],
          value,
          error: null
        }
      }))
    }
  }

  // handle  create engagement goal select stepper values

  const selectGoals = (goalId: string, goalName: string, tags: [], index: number) => {
    setIsDirty(true)
    if (isNullOrUndefinedOrEmpty(id) ||
      getEngagementDetailById?.data?.status !== ENGAGEMENT_STATUS.IN_PROGRESS) {
      const isExsist = goalIds.includes(goalId)

      if (isExsist) {
        setGoalIds(goalIds.filter((item) => item !== goalId))
        // setGetGoalsByEngagementById(goalIds.filter((item) => item !== goalId))
      } else {
        setGoalIds([...goalIds, goalId])
        // setGetGoalsByEngagementById([...goalIds, goalId])
      }

      const goalIndex = selectedGoal.findIndex((goal) => goal.goalId === goalId)

      if (goalIndex !== -1) {
        // Goal is already selected, remove it from the array
        const updatedGoals = [...selectedGoal]
        updatedGoals.splice(goalIndex, 1)

        setSelectedGoals(updatedGoals)
      } else {
        // Goal is not selected, add it to the array
        setSelectedGoals([...selectedGoal, { goalId, goalName, tags }])
      }
    }
  }
  const everySelectedGoalHasSameTags = (goals: SelectGolas[]): boolean => {
    if (goals.length <= 1) {
      return true // If there is only one or no goal, they all have the same tags
    }
    const firstGoalTags = goals[0].tags
    return goals.every((goal) => JSON.stringify(goal.tags) === JSON.stringify(firstGoalTags))
  }
  useEffect(() => {
    // Check if all the selected goals have the same tags
    const hasSameTags = everySelectedGoalHasSameTags(selectedGoal)
    setShowErrorMessage(!hasSameTags) // If tags are different, show the error message
  }, [selectedGoal])

  // handle  create engagement goal select values

  const hadleActiveTab = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: number
  ) => {
    if (newAlignment !== null) {
      setActiveTab(newAlignment)
      setSelectedGoals([])
      setSearchResult('')

      const page = APP_TABLE_CONFIGS.DEFAULT_PAGE
      const searchKey = undefined
      const activeTab = newAlignment
      getGoalList(page, searchKey, activeTab)
    }
  }

  const getCoachesList = async () => {
    const _tags: Array<{ tag: string, tagId: string }> = selectedGoal.reduce((tags, obj) => {
      return tags.concat(obj.tags ?? [])
    }, [])

    const _tagIds = _tags.map((t) => {
      return t.tagId
    })

    const param: GetAllCoachesParam = {
      tagType: activeTab,
      tagIds: _tagIds.filter((value, index, self) => {
        return self.indexOf(value) === index
      })
    }
    dispatch(userAction.getAllCoachesList(param))
  }

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setIsDirty(true)
    const fileValidity = acceptedFiles.map((file: File) => {
      const fileSizeInMB = file.size / (1024 * 1024)
      if (fileSizeInMB > 20) {
        return false
      } else {
        setErrorMessage('')
        return true
      }
    })

    const fullValidityStatus = fileValidity.every((valid: boolean) => valid)
    const validFileTypes = ['doc', 'docx', 'pdf', 'txt', 'rft', 'json', 'csv', 'xls',
      'xlsx', '.ppt', 'pptx', 'jpg', 'jpeg', 'png', 'svg', 'zip', 'rar']

    const fullFileTypeValidity = acceptedFiles.map((file: any) => {
      const lastDotPosition = file.path.lastIndexOf('.')
      if (lastDotPosition === -1) return file.path
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      else return file.path.substr(lastDotPosition + 1)
    })

    const isFileTypeValid = fullFileTypeValidity.every((filesType) => validFileTypes.includes(filesType))

    if (!fullValidityStatus) {
      setErrorMessage(
        `File Too Large - The file you're trying to upload exceeds the maximum size limit. 
        Please choose a file smaller than 20MB`
      )
    }
    if (!isFileTypeValid) {
      setErrorMessage('File type is not supported.')
    }
    if (acceptedFiles.length > 0 && fullValidityStatus && isFileTypeValid) {
      if (myUploadedFiles.length + myFiles.length + acceptedFiles.length < 4) {
        acceptedFiles.forEach((acceptedFile: any) => {
          const param: AddToQueDto = {
            file: acceptedFile,
            type: 1
          }
          dispatch(fileActions.addToQue(param))
        })
        setMyFiles([...myFiles, ...acceptedFiles])
      } else {
        setErrorMessage('Maximum file count (3) exceeded. Please select up to 3 files.!')
      }
    } else {
      setMyFiles([...myFiles])
    }
  }, [myFiles, dispatch, myUploadedFiles])

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop
  })

  const removeFile = (file: any) => {
    const newFiles = [...myFiles]
    newFiles.splice(newFiles.indexOf(file), 1)
    setMyFiles(newFiles)
  }

  const removeFileFromUploaded = (file: Attachments) => {
    const _param: DeleteFileRequestParam = {
      key: file.attachmentUrl,
      id: id ?? ''
    }
    dispatch(fileActions.fileDelete(_param))
    setSelectedAttachment(file)
  }

  useEffect(() => {
    if (deleteFileResponse.status === APP_ACTION_STATUS.SUCCESS) {
      const newUploadedFiles = [...myUploadedFiles]
      newUploadedFiles.splice(newUploadedFiles.indexOf(selectedAttachment), 1)
      setMyUploadedFiles(newUploadedFiles)
      dispatch(fileActions.clearFileDelete())
    }
  }, [deleteFileResponse.status])

  const openFileInNewTab = (index: number) => {
    const fileURL = URL.createObjectURL(myFiles[index])
    const downloadLink = document.createElement('a')
    downloadLink.href = fileURL
    downloadLink.download = myFiles[index].name
    downloadLink.textContent = 'Download File'
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }

  const fileDownload = (id: string) => {
    const attachment = myUploadedFiles.find((file) => file.attachmentId === id)
    setSelectedAttachment(attachment ?? INIT_ATTACHEMENT)
    dispatch(fileActions.downloadFile(attachment?.attachmentUrl ?? ''))
  }

  useEffect(() => {
    if (downloadFilesResponse.status === APP_ACTION_STATUS.SUCCESS) {
      const downloadLink = document.createElement('a')
      const blob = new Blob([downloadFiles])
      const fileURL = URL.createObjectURL(blob)
      downloadLink.href = fileURL
      downloadLink.download = selectedAttachment.attachmentTitle
      downloadLink.textContent = 'Download File'
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
      dispatch(fileActions.clearDownloadFile())
      setSelectedAttachment(INIT_ATTACHEMENT)
    }
  }, [downloadFilesResponse.status])

  return (
    <React.Fragment>
      <section className='page-root' >
        {!isNullOrUndefinedOrEmpty(id) && <UsePrompt isBlocking={isDirty} />}
        <Grid lg={12}>
          <Card className='section-card'>
            <CardContent>
              <section className='section-card-header'>
                <Typography fontWeight="600" fontSize="1rem">
                  {isNullOrUndefinedOrEmpty(id) ? 'Create Engagement' : 'Update Engagement'}
                </Typography>
              </section>
              <section className='section-card-body'>
                {!isNullOrUndefinedOrEmpty(isErrorFetchData) &&
                  <SomethingWentWrong
                    isShowDefault={true}
                    subNotification={isErrorFetchData}
                  />
                }
                {isNullOrUndefinedOrEmpty(isErrorFetchData) &&
                  <EngagementStepper
                    activeStep={activeStep}
                    generalDetailForm={
                      <EngagementGeneralDetailsForm
                        generalDetailForm={engagementGeneralDetailForm}
                        getCoachesListResponse={getCoachesListResponse}
                        onSetIsFilter={onSetIsFilter}
                        isFilter={isFilter}
                        onInputHandleForm={onInputHandleForm}
                        coachList={filteredCoachesList} />
                    }
                    steps={ENGAGEMENT_CREATE_STEPS}
                    summary={<EngagementSummery
                      isDoawnloading={downloadFilesResponse.isLoading}
                      myFiles={myFiles}
                      myUploadedFiles={myUploadedFiles}
                      selectedGoals={selectedGoal}
                      openFileInNewTab={openFileInNewTab}
                      formData={engagementGeneralDetailForm}
                      selectedAttachment={selectedAttachment}
                      fileDownload={fileDownload}
                    />
                    }
                    goalSelect={<EngagementGoalSelect
                      clearSearchResult={clearSearchResult}
                      searchResult={searchResult}
                      searchGoalListSummary={searchGoalListSummary}
                      pagination={<AppPagination
                        page={page}
                        handleChangePage={handleChangePage}
                        count={pageCount} />}
                      selectedGoal={selectedGoal}
                      hierarchyLevelGoals={
                        <EngagementGoalSelectCard
                          selectedCheckBoxes={selectedGoal}
                          goalList={goalList}
                          onSelectGoals={selectGoals}
                          showErrorMessage={showErrorMessage}
                          isChekedSelectedGoals={isChekedSelectedGoals}
                          isLoading={GoalListSummary.isLoading ||
                            getGoalsByEngagementId.isLoading || getAllGoalsByEngagementId.isLoading}
                        />
                      }
                      onToggleButton={hadleActiveTab}
                      activeTab={activeTab}
                    />}
                    addContent={<EngagementAddContent
                      isLoading={downloadFilesResponse.isLoading || deleteFileResponse.isLoading}
                      getInputProps={getInputProps}
                      getRootProps={getRootProps}
                      removeFile={removeFile}
                      removeFileFromUploaded={removeFileFromUploaded}
                      open={open}
                      myFiles={myFiles}
                      updateFiles={myUploadedFiles}
                      errorMessage={errorMessage}
                      openFileInNewTab={openFileInNewTab}
                      selectedAttachment={selectedAttachment}
                      fileDownload={fileDownload}
                    />
                    }
                  />
                }
              </section>
              <section className='section-card-footer'>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                  <Box sx={{ flex: '1 1 auto' }} />
                  {activeStep !== 0 && (
                    <Button variant="contained" onClick={handleBack}
                      color="secondary" sx={{ textTransform: 'none' }}>Back</Button>
                  )}
                  {activeStep !== ENGAGEMENT_CREATE_STEPS.length - 1
                    ? <Button
                      variant="contained"
                      onClick={handleNext}
                      color="primary"
                      sx={{ textTransform: 'none' }}
                      disabled={!isNullOrUndefinedOrEmpty(isErrorFetchData)}
                    >
                      Next
                    </Button>
                    : <Button
                      variant="contained"
                      onClick={handleNext}
                      color="primary"
                      disabled={createEngagementState?.isLoading || updateEngagements?.isLoading}
                      sx={{ textTransform: 'none' }}
                    >
                      {(createEngagementState?.isLoading || updateEngagements?.isLoading) &&
                        <CircularProgress size={12} color='inherit' sx={{ marginRight: '8px' }} />
                      }
                      {!isNullOrUndefinedOrEmpty(id) ? 'Update' : 'Finish'}
                    </Button>
                  }
                </Box>
              </section>
            </CardContent>
          </Card>
        </Grid>
      </section>
    </React.Fragment>
  )
}

export default CreateEngagement
