import { type AxiosResponse } from 'axios'
import {
  type GetOwnRequestParamDto,
  type AddNoteRequestParamDto,
  type AddNoteResponseDto,
  type NoteDto,
  type GetOthersRequestParamDto,
  type DeleteNoteResponseDto,
  type DeleteNoteRequestParamDto,
  type EditNoteRequestParamDto,
  type EditNoteResponseDto
} from '../interfaces/notes'
import { axiosPrivateAPI } from '.'
import { ENGAGEMENT_TYPES, NOTE_TAB_TYPE } from '../utils/constants'

const addNote = async (param: AddNoteRequestParamDto): Promise<AxiosResponse<AddNoteResponseDto>> => {
  const _id = param.pathParam.id
  if (param.roleType === ENGAGEMENT_TYPES.COACH) {
    return await axiosPrivateAPI.post(`/api/v1/sessions/${_id}/coach/notes`, param.bodyParam)
  } else {
    return await axiosPrivateAPI.post(`/api/v1/sessions/${_id}/sessionCoachee/notes`, param.bodyParam)
  }
}

const editNote = async (param: EditNoteRequestParamDto): Promise<AxiosResponse<EditNoteResponseDto>> => {
  const _id: string = param.pathParam.id
  const _noteId: string = param.pathParam.noteId
  if (param.roleType === ENGAGEMENT_TYPES.COACH) {
    return await axiosPrivateAPI.put(`/api/v1/sessions/${_id}/coach/notes/${_noteId}`, param.bodyParam)
  } else {
    return await axiosPrivateAPI.put(`/api/v1/sessions/${_id}/sessionCoachee/notes/${_noteId}`, param.bodyParam)
  }
}

const deleteNote = async (param: DeleteNoteRequestParamDto): Promise<AxiosResponse<DeleteNoteResponseDto>> => {
  const _id: string = param.pathParam.id
  const noteId: string = param.pathParam.noteId
  if (param.roleType === ENGAGEMENT_TYPES.COACH) {
    return await axiosPrivateAPI.delete(`/api/v1/sessions/${_id}/coach/notes/${noteId}`)
  } else {
    return await axiosPrivateAPI.delete(`/api/v1/sessions/${_id}/sessionCoachee/notes/${noteId}`)
  }
}

const viewOwnNotes = async (param: GetOwnRequestParamDto): Promise<AxiosResponse<NoteDto[]>> => {
  const _id = param.pathParam.id
  if (param.roleType === ENGAGEMENT_TYPES.COACH) {
    return await axiosPrivateAPI.get(`/api/v1/sessions/${_id}/coach/notes`)
  } else {
    return await axiosPrivateAPI.get(`/api/v1/sessions/${_id}/sessionCoachee/notes`)
  }
}

const viewOthersNotes = async (param: GetOthersRequestParamDto): Promise<AxiosResponse<NoteDto[]>> => {
  const _id = param.pathParam.id
  if (param.requestViewType === NOTE_TAB_TYPE.COACH_NOTE) {
    return await axiosPrivateAPI.get(`/api/v1/sessions/${_id}/coach/all-notes`, { params: param.queryParam })
  } else {
    return await axiosPrivateAPI.get(`/api/v1/sessions/${_id}/sessionCoachee/all-notes`, { params: param.queryParam })
  }
}

export const notesService = {
  addNote,
  editNote,
  deleteNote,
  viewOwnNotes,
  viewOthersNotes
}
