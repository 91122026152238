import { type Action, type SupervisorStatsResponse, type AsyncStateObject } from '../../../interfaces'
import { APP_ACTION_STATUS, DASHBOARD_ACTION_TYPE, COMMON_ACTIONS } from '../../../utils/constants'

const INITIAL_STATE: AsyncStateObject<SupervisorStatsResponse | null> = {
  data: null,
  isLoading: false,
  status: APP_ACTION_STATUS.INITIAL,
  error: undefined
}

const supervisorStatsReducer = (
  state = INITIAL_STATE,
  action: Action<SupervisorStatsResponse>
): AsyncStateObject<SupervisorStatsResponse | null> => {
  switch (action.type) {
    case DASHBOARD_ACTION_TYPE.GET_SUPERVISOR_STATS + COMMON_ACTIONS.REQUEST:
      return {
        ...state,
        isLoading: true,
        status: APP_ACTION_STATUS.LOADING,
        error: undefined
      }
    case DASHBOARD_ACTION_TYPE.GET_SUPERVISOR_STATS + COMMON_ACTIONS.SUCCESS:
      return {
        ...state,
        isLoading: false,
        status: APP_ACTION_STATUS.SUCCESS,
        data: action.data
      }
    case DASHBOARD_ACTION_TYPE.GET_SUPERVISOR_STATS + COMMON_ACTIONS.ERROR:
      return {
        ...state,
        isLoading: false,
        data: null,
        status: APP_ACTION_STATUS.ERROR,
        error: action.error
      }
    case DASHBOARD_ACTION_TYPE.GET_SUPERVISOR_STATS + COMMON_ACTIONS.CLEAR:
      return INITIAL_STATE
    default:
      return state
  }
}

export default supervisorStatsReducer
