import { combineReducers } from 'redux'
import { type HierarchiesState } from '../../../interfaces'
import organizationHierarchyReducer from './organizationHierarchy'
import roleHierarchyReducer from './roleHierarchy'
import userHierarchyReducer from './userHierarchy'

const hierarchyReducer = combineReducers<HierarchiesState>({
  organizationHierarchy: organizationHierarchyReducer,
  roleHierarchy: roleHierarchyReducer,
  userHierarchy: userHierarchyReducer
})

export default hierarchyReducer
