import * as React from 'react'
import { Box, Divider, Step, StepLabel, Stepper, Typography, useMediaQuery, useTheme } from '@mui/material'
import { ColorlibConnector } from '../../engagement/EngagementStepper/ColorlibConnector'

const GoalStepper: React.FC<{
  generalDetailForm: React.ReactNode
  targetGroup: React.ReactNode
  summary: React.ReactNode
  steps: string[]
  activeStep: number
}> = (props) => {
  const {
    generalDetailForm,
    targetGroup,
    summary,
    steps,
    activeStep
  } = props

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const stepperOrientation = isMobile ? 'vertical' : 'horizontal'
  return (
    <Box sx={{ maxWidth: '100%', padding: '2rem' }}>
      <Stepper connector={ !isMobile ? <ColorlibConnector /> : null} activeStep={activeStep}
      sx={{ padding: 0 }} orientation={stepperOrientation}>
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {}
            const labelProps: {
              optional?: React.ReactNode
            } = {}
            return (
              <Step sx={{ padding: 0 }} key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            )
          })}
      </Stepper>
      <Divider sx={{ marginTop: '2rem' }} />
      <React.Fragment>
        <Typography sx={{ mt: 2, mb: 1 }}>
          {activeStep === 0
            ? (generalDetailForm)
            : (activeStep === 1
                ? targetGroup
                : summary)}
        </Typography>
      </React.Fragment>
    </Box>
  )
}

export default GoalStepper
