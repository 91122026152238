import { type AxiosResponse } from 'axios'
import { axiosPrivateAPI } from '.'
import { type FilteredCoacheListDto, type GetAllCoachesParam } from '../interfaces/users'

const getAllCoachesList = async (param: GetAllCoachesParam):
Promise<AxiosResponse<FilteredCoacheListDto>> => {
  return await axiosPrivateAPI.post('/api/v1/users/coaches', param)
}

export const usersService = {
  getAllCoachesList
}
