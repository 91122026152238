import React from 'react'
import ClearIcon from '@mui/icons-material/Clear'
import styles from './GoalStatusPopup.module.scss'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  DialogContentText,
  TextField,
  Divider

} from '@mui/material'
import { GOAL_STATUS_CHANGE_ACTIONS } from '../../../utils/constants'

const GoalStatus: React.FC<{
  popupClose: () => void
  currentActionType: string
  statusChangeNote: string
  handleNoteChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  changeGoalStatus: () => void
}> = ({
  popupClose,
  currentActionType,
  statusChangeNote,
  handleNoteChange,
  changeGoalStatus
}) => {
  return (
      <Dialog
        open={true}
        sx={{ borderRadius: '10px' }}>
        <Typography style={{ display: 'flex', alignItems: 'center' }}>
          <DialogTitle id={styles.popupHeader}>
            {currentActionType === GOAL_STATUS_CHANGE_ACTIONS.ACCEPT
              ? 'Confirm Goal Accept'
              : currentActionType === GOAL_STATUS_CHANGE_ACTIONS.REJECT
                ? 'Confirm Reject'
                : 'Edit Requested'}
          </DialogTitle>

          <ClearIcon sx={{
            marginLeft: 'auto',
            mr: 2,
            '&:hover': {
              cursor: 'pointer'
            }
          }} onClick={popupClose} />
        </Typography>
        <Divider />
        <DialogContent>
          <DialogContentText>
            <Typography sx={{ pb: 2 }}>{
              currentActionType === GOAL_STATUS_CHANGE_ACTIONS.ACCEPT
                ? 'Are you sure you want to accept this goal?'
                : currentActionType === GOAL_STATUS_CHANGE_ACTIONS.REJECT
                  ? 'Please state and confirm why you want to reject this goal'
                  : 'You can edit your assigned goal by requesting to your Assignor'
            }</Typography>
          </DialogContentText>
          {currentActionType !== GOAL_STATUS_CHANGE_ACTIONS.ACCEPT && (
            <TextField
              placeholder={
                currentActionType === GOAL_STATUS_CHANGE_ACTIONS.REJECT
                  ? 'Reject Note'
                  : 'Request Note'
              }
              style={{ width: '100%' }}
              multiline
              rows={4}
              variant="outlined"
              value={statusChangeNote}
              onChange={handleNoteChange}
              inputProps={{ maxLength: 255 }}
            />
          )}
        </DialogContent>
        <Divider sx={{ mb: 0 }} />
        <DialogActions sx={{ pl: 4, py: 2 }}>
          <Button
            sx={{ textTransform: 'none', width: '80px' }}
            variant="contained"
            size="small"
            onClick={popupClose}
            color="secondary" >
            Cancel
          </Button>
          <Button
            sx={{ mr: 1, width: '80px' }}
            variant="contained"
            size="small"
            color="primary"
            onClick={changeGoalStatus}
            style={{ textTransform: 'none' }}
            disabled={(statusChangeNote.trim().length === 0 || /^\s*$/.test(statusChangeNote)) &&
            currentActionType !== GOAL_STATUS_CHANGE_ACTIONS.ACCEPT}
            >

            {currentActionType === GOAL_STATUS_CHANGE_ACTIONS.ACCEPT
              ? 'Confirm'
              : currentActionType === GOAL_STATUS_CHANGE_ACTIONS.REJECT
                ? 'Reject'
                : 'Request'}
          </Button>
        </DialogActions>
      </Dialog>
  )
}

export default GoalStatus
