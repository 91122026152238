import { type AxiosResponse } from 'axios'
import { call, put, takeLeading } from 'redux-saga/effects'
import { authService } from '../../services'
import { AUTH_ACTION_TYPE, COMMON_ACTIONS, SECRET_KEY } from '../../utils/constants'
import CryptoJS from 'crypto-js'
import { type AuthorizedUserInfoDto, type AuthorizedUserDto } from '../../interfaces/auth'

function * authorizeUser (action: { type: string }) {
  try {
    const authorizedUser: AxiosResponse<AuthorizedUserDto> = yield call(authService.authorizeUser)

    const authorizedUserInfo: AuthorizedUserInfoDto = {
      _id: authorizedUser.data._id,
      name: authorizedUser.data.name,
      username: authorizedUser.data.username,
      designation: authorizedUser.data.designation,
      mobile: authorizedUser.data.mobile,
      isActive: true
    }

    const _userInfo = JSON.stringify(authorizedUserInfo)
    const _userPermissions = JSON.stringify(authorizedUser.data.permissions)

    const _encryptedUserInfo = CryptoJS.AES.encrypt(_userInfo, SECRET_KEY).toString()
    const _encryptedUserPermissions = CryptoJS.AES.encrypt(_userPermissions, SECRET_KEY).toString()

    yield call(authService.setAuthorizedUserInfo, _encryptedUserInfo)
    yield call(authService.setAuthorizedUserPermissions, _encryptedUserPermissions)

    yield put({ type: AUTH_ACTION_TYPE.AUTHORIZE_USER + COMMON_ACTIONS.SUCCESS })
    yield put({ type: AUTH_ACTION_TYPE.FETCH_AUTHORIZE_USER + COMMON_ACTIONS.REQUEST })
    yield put({ type: AUTH_ACTION_TYPE.FETCH_AUTHORIZE_USER_PERMISSION + COMMON_ACTIONS.REQUEST })
  } catch (error) {
    yield put({
      type: AUTH_ACTION_TYPE.AUTHORIZE_USER + COMMON_ACTIONS.ERROR,
      error: error as string
    })
  }
}

function * fetchAuthorizedUser () {
  try {
    const _encryptedUserInfo: string | undefined = yield call(authService.fetchAuthorizedUserInfo)
    if (_encryptedUserInfo === undefined) { throw Error() } else {
      const bytes = CryptoJS.AES.decrypt(_encryptedUserInfo, SECRET_KEY)
      const userInfo = bytes.toString(CryptoJS.enc.Utf8)
      yield put({ type: AUTH_ACTION_TYPE.FETCH_AUTHORIZE_USER + COMMON_ACTIONS.SUCCESS, data: JSON.parse(userInfo) })
    }
  } catch (error) {
    yield put({
      type: AUTH_ACTION_TYPE.FETCH_AUTHORIZE_USER + COMMON_ACTIONS.ERROR,
      error: 'Failed to fetch user data'
    })
  }
}

function * fetchAuthorizedUserPermissions () {
  try {
    const _encryptedUserPermissions: string | null = yield call(authService.fetchAuthorizedUserPermissions)
    if (_encryptedUserPermissions === null) { throw Error() } else {
      const bytes = CryptoJS.AES.decrypt(_encryptedUserPermissions, SECRET_KEY)
      const userPermissions = bytes.toString(CryptoJS.enc.Utf8)
      yield put(
        {
          type: AUTH_ACTION_TYPE.FETCH_AUTHORIZE_USER_PERMISSION + COMMON_ACTIONS.SUCCESS,
          data: JSON.parse(userPermissions)
        }
      )
    }
  } catch (error) {
    yield put({
      type: AUTH_ACTION_TYPE.FETCH_AUTHORIZE_USER_PERMISSION + COMMON_ACTIONS.ERROR,
      error: 'Failed to fetch user data'
    })
  }
}

function * logout () {
  try {
    yield call(authService.logout)
    yield put({ type: AUTH_ACTION_TYPE.LOGOUT + COMMON_ACTIONS.SUCCESS })
  } catch (error) {
    yield put({ type: AUTH_ACTION_TYPE.LOGOUT + COMMON_ACTIONS.ERROR, error: 'Unable to clear browser data' })
  }
}

function * authSaga () {
  yield takeLeading(AUTH_ACTION_TYPE.AUTHORIZE_USER + COMMON_ACTIONS.REQUEST, authorizeUser)
  yield takeLeading(AUTH_ACTION_TYPE.FETCH_AUTHORIZE_USER + COMMON_ACTIONS.REQUEST, fetchAuthorizedUser)
  yield takeLeading(AUTH_ACTION_TYPE.LOGOUT + COMMON_ACTIONS.REQUEST, logout)
  yield takeLeading(
    AUTH_ACTION_TYPE.FETCH_AUTHORIZE_USER_PERMISSION + COMMON_ACTIONS.REQUEST,
    fetchAuthorizedUserPermissions
  )
}
export default authSaga
