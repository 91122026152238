import { type AxiosResponse } from 'axios'
import { type PriorityList } from '../interfaces'
import { axiosPrivateAPI } from '.'

const getPriorityList = async (): Promise<AxiosResponse<PriorityList[]>> => {
  return await axiosPrivateAPI.get('/api/v1/data/priorities')
}

export const priorityService = {
  getPriorityList
}
