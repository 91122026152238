import {
  ALERT_VARIANT,
  APP_ACTION_STATUS,
  FORM_VALIDATOR_TYPES, GOAL_CREATE_STEPS,
  HIERARCHY_TYPES,
  PERMISSION
} from '../../utils/constants'
import {
  type OrgAndRoleHierarchy,
  type AppState,
  type GetHierarchyRequestParams,
  type GoalCreateRequestPayload,
  type GoalCreteFormState,
  type UserHierarchy,
  type Tags,
  type GoalUpdateRequestPayload,
  type AlertMessage
} from '../../interfaces'
import {
  FormFeildViewSkelton,
  GoalGeneralDetailsForm,
  GoalStepper,
  GoalSummary,
  GoalTargetGroup
} from '../../components'
import { alertActions, goalActions, hierarchyAction, priorityAction } from '../../redux/actions'
import { UsePrompt, formValidator, hasPermission, isNullOrUndefinedOrEmpty } from '../../utils/helpers'
import { Alert, Box, Button, Card, CardContent, CircularProgress, Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import filterBy from './helpers'
import moment from 'moment'

const GoalCreate = () => {
  const INITIAL_STATE: GoalCreteFormState = {
    goalName: {
      value: '',
      validator: FORM_VALIDATOR_TYPES.TEXT,
      isRequired: true,
      disable: false,
      error: null
    },
    goalDescription: {
      value: '',
      validator: FORM_VALIDATOR_TYPES.TEXT,
      isRequired: true,
      disable: false,
      error: null
    },
    priority: {
      value: null,
      validator: FORM_VALIDATOR_TYPES.OBJECT,
      isRequired: true,
      disable: false,
      error: null
    },
    startDate: {
      value: moment(),
      validator: FORM_VALIDATOR_TYPES.DATE,
      isRequired: true,
      disable: false,
      error: null
    },
    endDate: {
      value: null,
      validator: FORM_VALIDATOR_TYPES.DATE,
      isRequired: true,
      disable: false,
      error: null
    }
  }

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [isErrorFetchData, setIsErrorFetchData] = useState<string>('')
  const organizationHierarchy = useSelector((state: AppState) => state.hierarchy.organizationHierarchy)
  const roleHierarchy = useSelector((state: AppState) => state.hierarchy.roleHierarchy)
  const userHierarchy = useSelector((state: AppState) => state.hierarchy.userHierarchy)
  const goalCreateResponse = useSelector((state: AppState) => state.goals.createGoal)
  const priorityListResponse = useSelector((state: AppState) => state.priority.priorityList)
  const goalUpdateResponse = useSelector((state: AppState) => state.goals.updateGoal)
  const goalDetailsById = useSelector((state: AppState) => state?.goals?.goalById)
  const [activeStep, setActiveStep] = React.useState(0) // GoalStepper
  const [formData, setFormData] = useState<GoalCreteFormState>(INITIAL_STATE)
  const [isEditableForm, setIsEditableForm] = useState<boolean>(false)
  const [hierarchyType, setHierarchyType] = React.useState<HIERARCHY_TYPES>(HIERARCHY_TYPES.ORGANAIZATION)
  const [searchKey, setSearchKey] = useState<string>('')
  const [isDirty, setIsDirty] = useState(false)

  const [selectedTags, setSelectedTags] = useState<OrgAndRoleHierarchy[]>([])
  const [selectedUserTags, setSelectedUserTags] = useState<UserHierarchy[]>([])
  const [selectedTreeNodes, setSelectedTreeNodes] = React.useState<string[]>([])

  const [treeData, setTreeData] = useState<OrgAndRoleHierarchy[]>([])
  const [userData, setUserData] = useState<UserHierarchy[]>([])
  const [staticTreeData, setStaticTreeData] = useState<OrgAndRoleHierarchy[]>([])
  const [isTagAdded, setIsTagAdded] = useState<boolean>(false)
  const [editTagType, setEditTagType] = useState<number>(0)
  const [dummyApiTagType, setDummyApiTagType] = useState<number>(0)

  const { id } = useParams<{ id: string }>()

  const hasPermissionToCreateOwnGoal = hasPermission([PERMISSION.CREATE_GOAL_FOR_HIM_SELF])
  const hasPermissionToCreateGoalForOthers = hasPermission([PERMISSION.CREATE_GOAL_FOR_OTHERS])

  useEffect(() => {
    if (!isNullOrUndefinedOrEmpty(id)) {
      dispatch(goalActions.getGoalById(id ?? ''))
    }
    void getPriorityList()

    if ((!hasPermissionToCreateGoalForOthers) && (hasPermissionToCreateOwnGoal)) {
      setHierarchyType(HIERARCHY_TYPES.USER)
    }

    return () => {
      dispatch(goalActions.clearCreateGoal())
      dispatch(goalActions.clearGoalDetails())
      dispatch(hierarchyAction.clearOrganizationHierarchy())
      dispatch(hierarchyAction.clearRoleHierarchy())
      dispatch(hierarchyAction.clearUserHierarchy())
      setIsErrorFetchData('')
    }
  }, [])
  useEffect(() => {
    if (goalDetailsById?.data !== null && goalDetailsById?.status === APP_ACTION_STATUS.SUCCESS) {
      setFormData({
        ...formData,
        goalName: {
          ...formData.goalName,
          value: goalDetailsById.data.name
        },
        goalDescription: {
          ...formData.goalDescription,
          value: goalDetailsById.data?.description
        },
        priority: {
          ...formData.priority,
          value: { id: goalDetailsById.data.priorityId, name: goalDetailsById.data.priority }
        },
        startDate: {
          ...formData.startDate,
          value: moment(goalDetailsById.data.startDate)
        },
        endDate: {
          ...formData.endDate,
          value: moment(goalDetailsById.data.endDate)
        }
      })
      setHierarchyType(goalDetailsById.data.tagType)
      /*
          This coming from dummyAPI data in feature need romeve after created the API from BE
          */
      setDummyApiTagType(goalDetailsById.data.tagType)
      setEditTagType(goalDetailsById.data.tagType)
      const _selectedTreeNodes: string[] = []
      goalDetailsById.data.tags.map((tag: Tags) => _selectedTreeNodes.push(tag.tagId))
      setSelectedTreeNodes(_selectedTreeNodes)
      setIsEditableForm(goalDetailsById.data.isEditable)

      if (goalDetailsById.data.tagType === HIERARCHY_TYPES.USER) {
        const _selectedUserTags: UserHierarchy[] = []
        goalDetailsById.data.tags.map((tag: Tags) => _selectedUserTags.push({
          _id: tag.tagId,
          username: tag?.tag as string
        }))
        setSelectedUserTags(_selectedUserTags)
      }
      setIsErrorFetchData('')
    }
    if (goalDetailsById?.status === APP_ACTION_STATUS.ERROR) {
      setIsErrorFetchData(goalDetailsById?.error ?? 'Something went wrong when fetching data.!')
    }
  }, [goalDetailsById.status])

  // get Organization Hierarchy data success
  useEffect(() => {
    if (organizationHierarchy.status === APP_ACTION_STATUS.SUCCESS) {
      const orgHierarchyArray: OrgAndRoleHierarchy[] = []
      orgHierarchyArray.push(organizationHierarchy.data)
      setTreeData(orgHierarchyArray)
      setStaticTreeData(orgHierarchyArray)

      if (!isNullOrUndefinedOrEmpty(id)) {
        if (editTagType === HIERARCHY_TYPES.ORGANAIZATION) {
          const levelIndex =
          findOrganizationHierarchySelectedIndex(orgHierarchyArray, goalDetailsById?.data?.tags[0].tagId ?? '')

          const _selectedTags: OrgAndRoleHierarchy[] = []
          goalDetailsById?.data?.tags.map((tag: Tags) => _selectedTags.push({
            levelId: tag.tagId,
            levelName: tag?.tag as string,
            levelIndex
          }))
          setSelectedTags(_selectedTags)
          setIsTagAdded(false)
        }
      }
      setIsErrorFetchData('')
    }
    if (organizationHierarchy?.status === APP_ACTION_STATUS.ERROR) {
      setIsErrorFetchData('Something went wrong when fetching data.!')
    }
  }, [organizationHierarchy.status])

  // get Role Hierarchy data success
  useEffect(() => {
    if (roleHierarchy.status === APP_ACTION_STATUS.SUCCESS) {
      const roleHierarchyArray: OrgAndRoleHierarchy[] = []
      roleHierarchyArray.push(roleHierarchy.data)
      setTreeData(roleHierarchyArray)
      setStaticTreeData(roleHierarchyArray)

      if (!isNullOrUndefinedOrEmpty(id)) {
        if (editTagType === HIERARCHY_TYPES.ROLE) {
          const levelIndex =
          findRoleHierarchySelectedIndex(roleHierarchyArray, goalDetailsById?.data?.tags[0].tagId ?? '')

          const _selectedTags: OrgAndRoleHierarchy[] = []
          goalDetailsById?.data?.tags.map((tag: Tags) => _selectedTags.push({
            levelId: tag.tagId,
            levelName: tag?.tag as string,
            levelIndex
          }))
          setSelectedTags(_selectedTags)
          setIsTagAdded(false)
        }
      }
      setIsErrorFetchData('')
    }
    if (roleHierarchy?.status === APP_ACTION_STATUS.ERROR) {
      setIsErrorFetchData('Something went wrong when fetching data.!')
    }
  }, [roleHierarchy.status])

  // get User Hierarchy data success
  useEffect(() => {
    if (userHierarchy.status === APP_ACTION_STATUS.SUCCESS) {
      setUserData(userHierarchy.data)
      setIsErrorFetchData('')
    }
    if (userHierarchy?.status === APP_ACTION_STATUS.ERROR) {
      setIsErrorFetchData('Something went wrong when fetching data.!')
    }
  }, [userHierarchy.status])

  useEffect(() => {
    if (activeStep === 1 && hierarchyType === HIERARCHY_TYPES.ORGANAIZATION) {
      void getOrganizationHierarchy()
    } else if (activeStep === 1 && hierarchyType === HIERARCHY_TYPES.ROLE) {
      void getRoleHierarchy()
    } else if (activeStep === 1 && hierarchyType === HIERARCHY_TYPES.USER) {
      void getUserHierarchy()
    }
  }, [activeStep, hierarchyType])

  // Goal create response
  useEffect(() => {
    if (goalCreateResponse.status === APP_ACTION_STATUS.SUCCESS) {
      navigate(-1)
    }
  }, [goalCreateResponse.status])

  useEffect(() => {
    if (goalUpdateResponse.status === APP_ACTION_STATUS.SUCCESS) {
      dispatch(goalActions.clearUpdateGoal())
      setIsDirty(false)
      navigate(-1)
    }
    if (goalUpdateResponse.status === APP_ACTION_STATUS.ERROR) {
      dispatch(goalActions.clearUpdateGoal())
    }
  }, [goalUpdateResponse.status])

  const findRoleHierarchySelectedIndex = (dataArray: OrgAndRoleHierarchy[], tagId: string) => {
    if (dataArray.length > 0) {
      for (const org of dataArray) {
        if (org.levelId === tagId) {
          return org.levelIndex
        }
        if ((org.subLevels != null) && org.subLevels?.length > 0) {
          for (const stack of org.subLevels) {
            if (stack.levelId === tagId) {
              return stack.levelIndex
            }
            if ((stack.subLevels != null) && stack.subLevels?.length > 0) {
              for (const designation of stack.subLevels) {
                if (designation.levelId === tagId) {
                  return designation.levelIndex
                }
              }
            }
          }
        }
      }
    }
    return -1
  }

  const findOrganizationHierarchySelectedIndex = (dataArray: OrgAndRoleHierarchy[], tagId: string) => {
    if (dataArray.length > 0) {
      for (const org of dataArray) {
        if (org.levelId === tagId) {
          return org.levelIndex
        }
        if ((org.subLevels != null) && org.subLevels?.length > 0) {
          for (const sbu of org.subLevels) {
            if (sbu.levelId === tagId) {
              return sbu.levelIndex
            }
            if ((sbu.subLevels != null) && sbu.subLevels?.length > 0) {
              for (const department of sbu.subLevels) {
                if (department.levelId === tagId) {
                  return department.levelIndex
                }
                if ((department.subLevels != null) && department.subLevels?.length > 0) {
                  for (const team of department.subLevels) {
                    if (team.levelId === tagId) {
                      return team.levelIndex
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    return -1
  }

  const getPriorityList = async () => {
    dispatch(priorityAction.getPriorityList())
  }

  // get organization hierarchy
  const getOrganizationHierarchy = async () => {
    const hierarchyRequestParams: GetHierarchyRequestParams = {
      tagType: HIERARCHY_TYPES.ORGANAIZATION
    }
    dispatch(hierarchyAction.getOrganizationHierarchy(hierarchyRequestParams))
  }

  // get role hierarchy
  const getRoleHierarchy = async () => {
    const hierarchyRequestParams: GetHierarchyRequestParams = {
      tagType: HIERARCHY_TYPES.ROLE
    }
    dispatch(hierarchyAction.getRoleHierarchy(hierarchyRequestParams))
  }

  // get role hierarchy
  const getUserHierarchy = async () => {
    const hierarchyRequestParams: GetHierarchyRequestParams = {
      tagType: HIERARCHY_TYPES.USER
    }
    dispatch(hierarchyAction.getUserHierarchy(hierarchyRequestParams))
  }

  // GoalStepper - Next button
  const handleNext = (): void => {
    setIsDirty(true)
    if (activeStep === 0) {
      formValidator(formData)
        .then(([formValidateData, formIsValid]) => {
          setFormData(formValidateData)
          if (formIsValid) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1)
          }
        })
        .catch(e => {
        })
    } else if (activeStep === GOAL_CREATE_STEPS.length - 2) {
      if (selectedTags.length > 0 || selectedUserTags.length > 0) {
        if (editTagType === dummyApiTagType) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1)
        } else {
          if (!isNullOrUndefinedOrEmpty(id)) {
            const data: AlertMessage = {
              message: 'Tag type is changed !!!',
              options: {
                variant: ALERT_VARIANT.WARNING,
                autoHideDuration: 2000
              }

            }
            dispatch(alertActions.triggerAlert(data))
          }

          setActiveStep((prevActiveStep) => prevActiveStep + 1)
        }
      } else {
        setIsTagAdded(prev => !prev)
      }
    } else if (activeStep === GOAL_CREATE_STEPS.length - 1) {
      if (isNullOrUndefinedOrEmpty(id)) {
        void createGoal()
      } else {
        void updateGoal()
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
  }

  // GoalStepper - Back button
  const handleBack = (): void => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  //  Form element change
  const onInputHandleForm = (property: string, value: any) => {
    setIsDirty(true)
    // Update the form data based on the property being updated
    setFormData((prevFormData) => ({
      ...prevFormData,
      [property]: {
        ...prevFormData[property as keyof typeof prevFormData],
        value,
        error: null
      }
    }))
  }

  // create new goal
  const createGoal = async () => {
    const addedTags: Tags[] = []
    if (hierarchyType !== HIERARCHY_TYPES.USER) {
      selectedTags.length > 0 && selectedTags.map((tag: OrgAndRoleHierarchy) => addedTags.push({
        tagId: tag.levelId
      }))
    } else {
      selectedUserTags.length > 0 && selectedUserTags.map((tag: UserHierarchy) => addedTags.push({
        tagId: tag._id
      }))
    }
    const createGoalPayload: GoalCreateRequestPayload = {
      name: formData.goalName.value,
      description: formData.goalDescription.value,
      priorityId: formData.priority.value !== null
        ? formData.priority.value.id
        : 2,
      isEditable: isEditableForm,
      startDate: moment(formData.startDate.value).format('YYYY-MM-DD'),
      endDate: moment(formData.endDate.value).format('YYYY-MM-DD'),
      tagType: hierarchyType,
      tags: addedTags
    }
    dispatch(goalActions.createGoal(createGoalPayload))
  }

  // Chnage Hierarchy View
  const handleChangeHierarchy = (event: React.MouseEvent<HTMLElement>, newType: number) => {
    if (newType !== null) {
      event.preventDefault()
      setSelectedTags([])
      setHierarchyType(newType)
      setStaticTreeData([])
      setTreeData([])
      setSelectedTreeNodes([])
      setSelectedUserTags([])
      setUserData([])
      setSearchKey('')
      setIsTagAdded(false)
    }
  }

  // Goal editable button
  const handleEditModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsEditableForm(event.target.checked)
  }

  // Add Organization or Role Tags
  const addTag = (selected: OrgAndRoleHierarchy) => {
    const tags: OrgAndRoleHierarchy[] = [...selectedTags]
    const tagIds: string[] = [...selectedTreeNodes]
    setEditTagType(hierarchyType)
    if (tags.length > 0) {
      const isIncludeLevel = tags.some((tag: OrgAndRoleHierarchy) => tag.levelIndex === selected.levelIndex)
      if (isIncludeLevel) {
        const index = tags.findIndex((tag) => tag.levelId === selected.levelId)
        if (index < 0) {
          tags.push({
            levelName: selected.levelName,
            levelId: selected.levelId,
            levelIndex: selected.levelIndex
          })
          tagIds.push(selected.levelId)
        }
      } else {
        const data: AlertMessage = {
          message: 'Please Select the same hierarchy level tag type!!!',
          options: {
            variant: ALERT_VARIANT.WARNING,
            autoHideDuration: 2000
          }

        }
        dispatch(alertActions.triggerAlert(data))
      }
    } else {
      tags.push({
        levelName: selected.levelName,
        levelId: selected.levelId,
        levelIndex: selected.levelIndex
      })
      tagIds.push(selected.levelId)
    }

    setSelectedTreeNodes(tagIds)
    setSelectedTags(tags)
    setIsTagAdded(false)
  }

  // Add User Tags
  const addUserTag = (selected: UserHierarchy) => {
    const tags: UserHierarchy[] = [...selectedUserTags]
    const tagIds: string[] = [...selectedTreeNodes]
    setEditTagType(hierarchyType)
    if (tags.length > 0) {
      const data: AlertMessage = {
        message: 'You can not select multiple individual users!!!',
        options: {
          variant: ALERT_VARIANT.WARNING,
          autoHideDuration: 2000
        }

      }
      dispatch(alertActions.triggerAlert(data))
    } else {
      tags.push(selected)
      tagIds.push(selected._id)
    }

    setSelectedTreeNodes(tagIds)
    setSelectedUserTags(tags)
    setIsTagAdded(false)
  }

  // Delete added tag
  const handleDelete = (id: string) => {
    if (hierarchyType !== HIERARCHY_TYPES.USER) {
      const tags: OrgAndRoleHierarchy[] = [...selectedTags]
      const tagIds: string[] = [...selectedTreeNodes]
      const index = tags.findIndex((tag) => tag.levelId === id)
      if (index > -1) {
        tags.splice(index, 1)
        tagIds.splice(index, 1)
      }
      setSelectedTreeNodes(tagIds)
      setSelectedTags(tags)
      setIsTagAdded(false)
    } else {
      const tags: UserHierarchy[] = [...selectedUserTags]
      const tagIds: string[] = [...selectedTreeNodes]
      const index = tags.findIndex((tag) => tag._id === id)
      if (index > -1) {
        tags.splice(index, 1)
        tagIds.splice(index, 1)
      }
      setSelectedTreeNodes(tagIds)
      setSelectedUserTags(tags)
      setIsTagAdded(false)
    }
  }

  // Search by value
  const onSearchHierarchyData = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKey(event.target.value)
    if (hierarchyType !== HIERARCHY_TYPES.USER) {
      setTreeData(filterBy(staticTreeData, event.target.value))
    } else {
      if (userHierarchy.data.length > 0 && event.target.value !== '') {
        const result = userHierarchy.data.filter((user: UserHierarchy) => {
          return (
            user.username.toLowerCase().includes(event.target.value.toLowerCase())
          )
        })
        setUserData(result)
      } else {
        setUserData(userHierarchy.data)
      }
    }
  }

  const updateGoal = async () => {
    if (!isNullOrUndefinedOrEmpty(id)) {
      const addedTags: Tags[] = []
      if (hierarchyType !== HIERARCHY_TYPES.USER) {
        selectedTags.length > 0 && selectedTags.map((tag: OrgAndRoleHierarchy) => addedTags.push({
          tagId: tag.levelId
        }))
      } else {
        selectedUserTags.length > 0 && selectedUserTags.map((tag: UserHierarchy) => addedTags.push({
          tagId: tag._id
        }))
      }
      const updateGoalPayload: GoalUpdateRequestPayload = {
        requestBody: {
          name: formData.goalName.value,
          description: formData.goalDescription.value,
          priorityId: formData.priority.value !== null
            ? formData.priority.value.id
            : 2,
          isEditable: isEditableForm,
          startDate: moment(formData.startDate.value).format('YYYY-MM-DD'),
          endDate: moment(formData.endDate.value).format('YYYY-MM-DD'),
          tagType: hierarchyType,
          tags: addedTags
        },
        goalId: id ?? ''
      }
      dispatch(goalActions.updateGoal(updateGoalPayload))
    }
  }

  return (
    <React.Fragment>
      <section className='page-root' >
        {!isNullOrUndefinedOrEmpty(id) && <UsePrompt isBlocking={isDirty} />}
        <Card className='section-card'>
          <CardContent>
            <section className='section-card-header'>
              <Typography fontWeight="600" fontSize="1rem">
                {isNullOrUndefinedOrEmpty(id) ? 'Create Goal' : 'Update Goal'}
              </Typography>
            </section>
            <section className='section-card-body'>
              {goalDetailsById?.isLoading && <FormFeildViewSkelton/> }
              {!goalDetailsById?.isLoading &&
                <>
                  {!isNullOrUndefinedOrEmpty(isErrorFetchData) &&
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <Alert sx={{ width: '100%' }} severity="error">{isErrorFetchData}</Alert>
                    </div>
                  }
                  {isNullOrUndefinedOrEmpty(isErrorFetchData) &&
                    <GoalStepper
                      steps={GOAL_CREATE_STEPS}
                      activeStep={activeStep}
                      generalDetailForm={
                        <GoalGeneralDetailsForm
                          priorityList={priorityListResponse.data}
                          formData={formData}
                          onInputHandleForm={onInputHandleForm}
                        />
                      }
                      targetGroup={
                        <GoalTargetGroup
                          isTagAdded={isTagAdded}
                          searchKey={searchKey}
                          hierarchyType={hierarchyType}
                          handleChangeHierarchy={handleChangeHierarchy}
                          hierarchyData={treeData}
                          userData={userData}
                          isEditable={isEditableForm}
                          handleEditModeChange={handleEditModeChange}
                          addTag={addTag}
                          addUserTag={addUserTag}
                          selectedTags={selectedTags}
                          selectedUserTags={selectedUserTags}
                          handleDelete={handleDelete}
                          onSearchHierarchyData={onSearchHierarchyData}
                          selectedTreeNodes={selectedTreeNodes}
                          hierarchyIsLoading={hierarchyType === HIERARCHY_TYPES.ORGANAIZATION
                            ? organizationHierarchy.isLoading
                            : hierarchyType === HIERARCHY_TYPES.ROLE
                              ? roleHierarchy.isLoading
                              : userHierarchy.isLoading}
                        />
                      }
                      summary={
                        <GoalSummary
                          formData={formData}
                          isEditable={isEditableForm}
                          tags={hierarchyType !== HIERARCHY_TYPES.USER
                            ? selectedTags
                            : selectedUserTags
                          }
                          hierarchyType={hierarchyType}
                        />
                      }
                    />
                  }
                </>
              }
            </section>
            <section className='section-card-footer'>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                <Box sx={{ flex: '1 1 auto' }} />
                {activeStep !== 0 && (
                    <Button
                    variant="contained"
                    onClick={handleBack}
                    color="secondary"
                    sx={{ textTransform: 'none' }}
                  >
                    Back
                  </Button>
                )}
                {activeStep !== GOAL_CREATE_STEPS.length - 1
                  ? <Button variant="contained"
                    onClick={handleNext}
                    color="primary" sx={{ textTransform: 'none' }}>
                    Next</Button>
                  : <Button
                      variant="contained"
                      onClick={handleNext}
                      color="primary"
                      disabled={goalCreateResponse?.isLoading || goalUpdateResponse?.isLoading}
                      sx={{ textTransform: 'none' }}
                    >
                    {(goalCreateResponse?.isLoading || goalUpdateResponse?.isLoading) &&
                      <CircularProgress size={12} color='inherit' sx={{ marginRight: '8px' }} />
                    }
                    {!isNullOrUndefinedOrEmpty(id) ? 'Update' : 'Finish'}
                  </Button>
                }
              </Box>
            </section>
          </CardContent>
        </Card>
      </section>
    </React.Fragment>
  )
}

export default GoalCreate
