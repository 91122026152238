/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { type AxiosResponse } from 'axios'
import { axiosPrivateAPI } from '.'
import {
  type FeedBackCreateResponse,
  type sessionRequestPayLoad,
  type SessionTypeDto,
  type CompleteSession,
  type CompleteSessionPayLoad,
  type SessionDetails,
  type SessionCoacheeListDto,
  type SessionCoacheePayload,
  type SessionCoacheeDetails,
  type SessionDetailsRequestParam,
  type editSessionPayLoad,
  type EditSessionResponse,
  type SessionDeleteRequestParam,
  type SessionDeleteResponse
} from '../interfaces'

const getSessionTypeList = async (): Promise<AxiosResponse<SessionTypeDto[]>> => {
  return await axiosPrivateAPI.get('/api/v1/data/sessionTypes')
}
const createSession = async (payload: sessionRequestPayLoad):
Promise<AxiosResponse<FeedBackCreateResponse>> => {
  return await axiosPrivateAPI.post('/api/v1/sessions', payload)
}
const editSession = async (payload: editSessionPayLoad):
Promise<AxiosResponse<EditSessionResponse>> => {
  return await axiosPrivateAPI.put(`/api/v1/sessions/${payload._id}/update`, payload)
}

const sessionDetails = async (param: SessionDetailsRequestParam): Promise<AxiosResponse<SessionDetails>> => {
  return await axiosPrivateAPI.get(`/api/v1/sessions/${param.pathParam.id}`, { params: param.queryParam })
}

const startAndCompleteSession = async (payload: CompleteSessionPayLoad): Promise<AxiosResponse<CompleteSession>> => {
  const params = payload.statusId
  return await axiosPrivateAPI.put(`/api/v1/sessions/${payload.sessionId}`, { statusId: params })
}
const getSessionCoacheeList = async (payload: SessionCoacheePayload): Promise<AxiosResponse<SessionCoacheeListDto>> => {
  return await axiosPrivateAPI.post(`/api/v1/sessions/${payload.pathParam.id}/coachees`, payload.requestBody)
}

const sessionCoacheeDetails = async (sessionId: string): Promise<AxiosResponse<SessionCoacheeDetails>> => {
  return await axiosPrivateAPI.get(`/api/v1/sessions/${sessionId}/coacheesDetail`)
}
const sessionDelete = async (params: SessionDeleteRequestParam): Promise<AxiosResponse<SessionDeleteResponse>> => {
  return await axiosPrivateAPI.delete(`/api/v1/sessions/${params.id}`)
}

export const sessionService = {
  sessionDetails,
  startAndCompleteSession,
  getSessionTypeList,
  createSession,
  getSessionCoacheeList,
  sessionCoacheeDetails,
  editSession,
  sessionDelete
}
