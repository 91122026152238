/* eslint-disable @typescript-eslint/restrict-plus-operands */
import {
  type AsyncStateObject, type Action
} from '../../../interfaces'
import { APP_ACTION_STATUS, COMMON_ACTIONS, FILE_ACTIONS } from '../../../utils/constants'

const INITIAL_STATE: AsyncStateObject<string | Blob> = {
  data: '',
  isLoading: false,
  status: APP_ACTION_STATUS.INITIAL,
  error: undefined

}

const downloadFileReducer = (state = INITIAL_STATE, action: Action<string | Blob>) => {
  switch (action.type) {
    case FILE_ACTIONS.DOWNLOAD_FILE + COMMON_ACTIONS.REQUEST:
      return {
        ...state,
        isLoading: true,
        status: APP_ACTION_STATUS.LOADING,
        error: undefined
      }
    case FILE_ACTIONS.DOWNLOAD_FILE + COMMON_ACTIONS.SUCCESS:
      return {
        ...state,
        isLoading: false,
        status: APP_ACTION_STATUS.SUCCESS,
        data: action.data
      }
    case FILE_ACTIONS.DOWNLOAD_FILE + COMMON_ACTIONS.ERROR:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        status: APP_ACTION_STATUS.ERROR,
        error: action.error
      }
    case FILE_ACTIONS.DOWNLOAD_FILE + COMMON_ACTIONS.CLEAR:
      return INITIAL_STATE
    default:
      return state
  }
}

export default downloadFileReducer
