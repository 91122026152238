import React from 'react'
import { Box, Card, Grid, Typography } from '@mui/material'
import moment from 'moment'
import SessionFeedbackSentiment from '../SessionFeedbackSentiment/SessionFeedbackSentiment'
const SessionFeedbackCard: React.FC<{
  rating: number
  feedbackDescription: string
  feedbackDate: string | Date
  feedbackFromName: string

}> =
      ({ rating, feedbackDescription, feedbackDate, feedbackFromName }) => {
        return (
        <Card sx={{ padding: '1rem', borderLeft: '0.25rem solid #3B82F6', marginTop: '1rem' }}>
            <Grid container display='flex' justifyContent='space-between'>
                <Grid item md={1 / 2}>
                    <Card sx={{
                      border: '1px solid #3B82F6',
                      width: '4rem',
                      height: '4rem',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: '#F5FAFF'
                    }}>
                        <SessionFeedbackSentiment rating={rating} />
                    </Card>
                </Grid>
                <Grid item md={11}>
                    <Typography
                        fontWeight={600} fontSize='1rem'
                        color='primary'>{rating === 1
                          ? 'Amazing'
                          : (rating === 2
                              ? 'Happy'
                              : (rating === 3
                                  ? 'Neutral'
                                  : (rating === 4 ? 'Sad' : 'Terrible')))}</Typography>
                    <Typography
                        fontWeight={500}
                        fontSize='1rem'
                        color='#5F6D7E'>{feedbackDescription}</Typography>
                    <Box display='flex' justifyContent='space-between' marginTop='1rem'>
                        <Typography color='#919BA7'>{moment(feedbackDate).format('DD/MM/YYYY')}</Typography>
                        <Typography color='#919BA7'>{feedbackFromName}</Typography>
                    </Box>

                </Grid>
            </Grid>
        </Card>
        )
      }

export default SessionFeedbackCard
