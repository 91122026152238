import { Box, Card, CardContent, Checkbox, Divider, Grid, Typography } from '@mui/material'
import React from 'react'
import AccessTimeFilledOutlinedIcon from '@mui/icons-material/AccessTimeFilledOutlined'
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined'
import {
  type AssessmentQuestion,
  type SessionAssessments, type SessionCreateFormState,
  type AssessmentFeedback, type ReviewQuestion, type Attachments
} from '../../../interfaces'
import DescriptionIcon from '@mui/icons-material/Description'
import attachmentImageHandler from '../../../utils/helpers/attachmentImageHandler'
import { SESSION_ATTACHMENT_TYPE } from '../../../utils/constants'

const Summary: React.FC<{
  sessionGeneralDetailForm: SessionCreateFormState
  myFiles: any
  updateFiles: Attachments[]
  assessment: SessionAssessments
  feedback: AssessmentFeedback
  inputSessionDurationValue: string
  finalSessionVideo: any[]
}> = ({
  sessionGeneralDetailForm, myFiles, assessment, feedback, updateFiles,
  inputSessionDurationValue, finalSessionVideo
}) => {
  return (
      <Box>
        <Typography fontWeight={600} sx={{ pb: 3 }} align="left">
          Summary
        </Typography>
        <Grid container alignItems="center" spacing={1} sx={{ pb: 2 }}>
          <Grid item>
            <DescriptionIcon color='primary' />
          </Grid>
          <Grid item sx={{ pr: 6 }}>
            <Typography fontWeight={600}>
              Session Type
            </Typography>
            {sessionGeneralDetailForm.sessionTypes.value?.name}
          </Grid>
          <Grid item>
            <AccessTimeFilledOutlinedIcon color="primary" />
          </Grid>
          <Grid item sx={{ pr: 6 }}>
            <Typography fontWeight={600}>
              Duration
            </Typography>
            {inputSessionDurationValue}
          </Grid>
          <Grid item>
            <PermIdentityOutlinedIcon color="primary" />
          </Grid>
          <Grid item>
            <Typography fontWeight={600}>
              Coach
            </Typography>
            {sessionGeneralDetailForm.sessionCoach.value?.name}
          </Grid>
        </Grid>

        <Divider sx={{ py: 2 }} />
        <Typography fontWeight={600} sx={{ pt: 4 }} align="left">
          Session Name
        </Typography>
        {sessionGeneralDetailForm.sessionName.value}
        <Typography fontWeight={600} sx={{ pt: 4 }} align="left">
          Description
        </Typography>
        {sessionGeneralDetailForm.sessionDescription.value}
        <Grid item display='flex' gap="1rem" sx={{ pt: 4 }}>
          <Typography fontWeight={600} align="left">
           {(sessionGeneralDetailForm.sessionTypes.value?.name === SESSION_ATTACHMENT_TYPE.VIDEO_LINK)
             ? SESSION_ATTACHMENT_TYPE.VIDEO_LINK
             : SESSION_ATTACHMENT_TYPE.MATERIAL}
          </Typography>
          <Typography fontWeight={400}>
            {
              (sessionGeneralDetailForm.sessionTypes.value?.name === SESSION_ATTACHMENT_TYPE.VIDEO_LINK)
                ? (finalSessionVideo.length > 0
                    ? (
                        finalSessionVideo.map((value, index) => (
                          <Grid container item key={index}>
                          <Grid item xs={12} sx={{ pb: 1 }}>
                            {value.title} - <a href={value.url} target="_blank" rel="noopener noreferrer">{value.url}</a>
                          </Grid>
                        </Grid>
                        )))
                    : (
                    <Typography variant="body1">-No Videos for this session-</Typography>
                      )
                  )
                : myFiles.length === 0 && updateFiles.length === 0
                  ? (
                    <Typography variant="body1">-No attachments for this session-</Typography>
                    )
                  : null // Or handle other cases as needed
            }

          </Typography>
        </Grid>
        <Grid item display='flex' gap="1rem" sx={{ pt: 2 }}>
          {(sessionGeneralDetailForm.sessionTypes.value?.name !== SESSION_ATTACHMENT_TYPE.VIDEO_LINK) &&
            (
              myFiles.length > 0 && myFiles.map((file: any, index: number) => {
                return <Card key={index} >
                  <Box alignItems='center' gap='1rem' padding='1rem' sx={{ maxWidth: '330px' }}>
                    <Grid item xs={6} md={12} display={'flex'} alignItems={'center'}>
                      <img alt="Attachment" width={35} src={attachmentImageHandler(file.name)} />
                      <Typography fontWeight={600}>{file.path.split('.')[0]} </Typography>
                    </Grid>
                  </Box>
                </Card>
              })
            )}

          {(sessionGeneralDetailForm.sessionTypes.value?.name !== SESSION_ATTACHMENT_TYPE.VIDEO_LINK) &&
            (updateFiles.length > 0 && updateFiles.map((file: Attachments, index: number) => {
              return <Card key={index} >
                <Box alignItems='center' gap='1rem' padding='1rem' sx={{ maxWidth: '330px' }}>
                  <Grid item xs={6} md={12} display={'flex'} alignItems={'center'}>
                    <img alt="Attachment" width={35} src={attachmentImageHandler(file.attachmentTitle)} />
                    <Typography fontWeight={600}>{file.attachmentTitle} </Typography>
                  </Grid>
                </Box>
              </Card>
            }))}

        </Grid>
        <Grid item display='flex' gap="1rem" sx={{ pt: 4 }}>
          <Typography fontWeight={600} align="left">
            Assessment
          </Typography>
          <Typography fontWeight={400}>
            {assessment.assessmentQuestions.length === 0 ? '-No assesment Questions for this session-' : ''}
          </Typography>
        </Grid>
        <Card>
          {!(assessment.assessmentQuestions.length === 0) &&
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.2rem' }}>
              <Checkbox
                size="small"
                checked={assessment.isCompulsory}
              />
              <Typography fontWeight={600} fontSize={13} sx={{ pt: 0 }} align="left">
                Compulsory
              </Typography>
            </Box>
          }
          {assessment.assessmentQuestions.map((q: AssessmentQuestion, questionIndex: number) => (
            <Typography key={questionIndex} sx={{ pt: 0 }}>
              <CardContent sx={{ position: 'relative' }}>
                <Typography variant="subtitle1" sx={{ pb: 1, fontWeight: 600 }}>
                  {questionIndex + 1}. {q.questionLabel}
                </Typography>
              </CardContent>
              {q.options.map((option: any, optionIndex: number) => (
                <Typography key={optionIndex} sx={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    size="small"
                    checked={option.isCorrectAnswer}
                  />
                  <Typography variant="body1" sx={{ mr: 1 }}>
                    {String.fromCharCode(65 + optionIndex)}
                  </Typography>
                  <Typography>{option.optionLabel}</Typography>
                </Typography>
              ))}
            </Typography>
          ))}
        </Card>

        <Grid item display='flex' gap="1rem" sx={{ pt: 4 }}>
          <Typography fontWeight={600} align="left">
            Feedbacks
          </Typography>
          <Typography fontWeight={400}>
            {feedback.reviewQuestions.length === 0 ? '-No feedback Questions for this session-' : ''}
          </Typography>
        </Grid>
        <Card>
          {feedback.reviewQuestions.length !== 0 &&
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.2rem' }}>
              <Checkbox
                size="small"
                checked={feedback.configs.isCoacheeToCoach}
                defaultChecked
              />
              <Typography fontWeight={600} fontSize={13} sx={{ pt: 0 }} align="left">
                Coachee to Coach
              </Typography>
            </Box>
          }

          <CardContent>
            {feedback.reviewQuestions.map((question: ReviewQuestion, questionIndex: number) => (
              <Typography key={questionIndex} sx={{ pt: 0 }}>
                <CardContent sx={{ position: 'relative' }}>
                  <Typography variant="subtitle1" sx={{ pb: 1, fontWeight: 600 }}>
                    {questionIndex + 1}. {question.questionLabel}
                  </Typography>
                </CardContent>
              </Typography>
            ))}
          </CardContent>
        </Card >
      </Box>
  )
}

export default Summary
