
export const GROUP_FEEDBACK_TYPE = {
  POOR: '1 - poor',
  BAD: '2 - bad',
  NEUTRAl: '3 - neutral',
  GOOD: '4 - good',
  EXCELLENT: '5 - excellent'

}

export const GROUP_FEEDBACK = [
  {
    ID: 1,
    NAME: GROUP_FEEDBACK_TYPE.POOR,
    COLOR: 'rgba(214,40,40,255)'
  },
  {
    ID: 2,
    NAME: GROUP_FEEDBACK_TYPE.BAD,
    COLOR: 'rgba(89,195,195,255)'
  },
  {
    ID: 3,
    NAME: GROUP_FEEDBACK_TYPE.NEUTRAl,
    COLOR: 'rgba(36,123,160,255)'
  },
  {
    ID: 4,
    NAME: GROUP_FEEDBACK_TYPE.GOOD,
    COLOR: 'rgba(92,177,255,255)'
  },
  {
    ID: 5,
    NAME: GROUP_FEEDBACK_TYPE.EXCELLENT,
    COLOR: 'rgba(19,93,203,255)'
  }
]
