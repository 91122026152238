import { isEmpty, isNumber, isArray, isObjectLike, isString, isNull, isUndefined, isBoolean } from 'lodash'
import { FORM_VALIDATOR_TYPES } from '../constants'
import { type FormField } from '../../interfaces'
import moment from 'moment'

const formValidator = async (data: Record<string, any>): Promise<[any, boolean]> => {
  let isInvalid = false
  let validatedData = data
  for await (const [field, fieldData] of Object.entries(data)) {
    let error: string | null = null
    switch (fieldData.validator) {
      case FORM_VALIDATOR_TYPES.TEXT: {
        const [isError, errorMessage] = await validateText(fieldData)
        if (isError) isInvalid = true
        error = errorMessage
        validatedData = {
          ...validatedData,
          [field]: {
            ...fieldData as Record<string, any>,
            error
          }
        }
        break
      }
      case FORM_VALIDATOR_TYPES.DURATION: {
        const [isError, errorMessage] = await validateDuration(fieldData)
        if (isError) isInvalid = true
        error = errorMessage
        validatedData = {
          ...validatedData,
          [field]: {
            ...fieldData as Record<string, any>,
            error
          }
        }
        break
      }
      case FORM_VALIDATOR_TYPES.NUMBER: {
        const [isError, errorMessage] = await validateNumber(fieldData)
        if (isError) isInvalid = true
        error = errorMessage

        validatedData = {
          ...validatedData,
          [field]: {
            ...fieldData,
            error
          }
        }
        break
      }
      case FORM_VALIDATOR_TYPES.DATE: {
        const [isError, errorMessage] = await validateDate(fieldData)
        if (isError) isInvalid = true
        error = errorMessage

        validatedData = {
          ...validatedData,
          [field]: {
            ...fieldData,
            error
          }
        }
        break
      }

      case FORM_VALIDATOR_TYPES.BOOLEAN: {
        const [isError, errorMessage] = await validateBool(fieldData)
        if (isError) isInvalid = true
        error = errorMessage

        validatedData = {
          ...validatedData,
          [field]: {
            ...fieldData,
            error
          }
        }
        break
      }
      case FORM_VALIDATOR_TYPES.ARRAY: {
        const [isError, errorMessage] = await validateArray(fieldData)
        if (isError) isInvalid = true
        error = errorMessage
        validatedData = {
          ...validatedData,
          [field]: {
            ...fieldData,
            error
          }
        }
        break
      }
      case FORM_VALIDATOR_TYPES.EMAIL: {
        const [isError, errorMessage] = await validateEmail(fieldData)
        if (isError) isInvalid = true
        error = errorMessage

        validatedData = {
          ...validatedData,
          [field]: {
            ...fieldData,
            error
          }
        }
        break
      }
      case FORM_VALIDATOR_TYPES.OBJECT: {
        const [isError, errorMessage] = await validateObject(fieldData)
        if (isError) isInvalid = true
        error = errorMessage
        validatedData = {
          ...validatedData,
          [field]: {
            ...fieldData,
            error
          }
        }
        break
      }
      case FORM_VALIDATOR_TYPES.FILE: {
        const [isError, errorMessage] = await validateFile(fieldData)
        if (isError) isInvalid = true
        error = errorMessage

        validatedData = {
          ...validatedData,
          [field]: {
            ...fieldData,
            error
          }
        }
        break
      }
      case FORM_VALIDATOR_TYPES.LINK: {
        const [isError, errorMessage] = await validateUrl(fieldData)
        if (isError) isInvalid = true
        error = errorMessage

        validatedData = {
          ...validatedData,
          [field]: {
            ...fieldData,
            error
          }
        }
        break
      }
      default:
        validatedData = {
          ...validatedData,
          [field]: {
            ...fieldData as Record<string, any>,
            error
          }
        }
    }
  }
  return ([validatedData, !isInvalid])
}

const validateText = async (data: FormField<any>): Promise<[boolean, string | null]> => {
  return await new Promise((resolve) => {
    const invalidMessage = 'Invalid text.'
    const requiredMessage = 'This field is required.'

    // const spaceOnlyMessage = 'Text cannot contain only spaces.'

    if (data.isRequired && isEmpty(data.value)) {
      resolve([true, requiredMessage])
    }

    if (!isString(data.value)) {
      resolve([true, invalidMessage])
    }

    if (/^\s+$/.test(data.value)) {
      resolve([true, requiredMessage])
    }

    resolve([false, null])
  })
}

const isValidDuration = (input: any) => {
  const pattern = /^(\d+[m]\s*)?(\d+[w]\s*)?(\d+[d]\s*)?(\d+[h]\s*)?$/
  return pattern.test(input)
}

const validateDuration = async (data: FormField<any>): Promise<[boolean, string | null]> => {
  return await new Promise((resolve) => {
    const invalidMessage = 'Invalid date.'
    const requiredMessage = 'This field is required.'
    if (data.value === '') {
      resolve([true, requiredMessage])
    }
    if (!isValidDuration(data.value)) {
      resolve([true, invalidMessage])
    }
    // If the validation passes
    resolve([false, null])
  })
}

const validateNumber = async (data: FormField<any>): Promise<[boolean, string | null]> => {
  return await new Promise((resolve) => {
    const invalidMessage = 'Invalid number.'
    const requiredMessage = 'This field is required.'
    if (data.isRequired && (isNull(data.value) || isUndefined(data.value))) {
      resolve([true, requiredMessage])
    }

    if (!isNumber(data.value)) {
      resolve([true, invalidMessage])
    }

    resolve([false, null])
  })
}

const validateDate = async (data: FormField<any>): Promise<[boolean, string | null]> => {
  return await new Promise((resolve) => {
    const invalidMessage = 'Invalid date.'
    const requiredMessage = 'This field is required.'
    if (data.isRequired && data.value === null) {
      resolve([true, requiredMessage])
    }

    if (!moment(data.value).isValid()) {
      resolve([true, invalidMessage])
    }

    resolve([false, null])
  })
}

const validateBool = async (data: FormField<any>): Promise<[boolean, string | null]> => {
  return await new Promise((resolve) => {
    const invalidMessage = 'Invalid boolean.'
    const requiredMessage = 'This field is required.'
    if (data.isRequired && (isNull(data.value) || isUndefined(data.value))) {
      resolve([true, requiredMessage])
    }

    if (!isBoolean(data.value)) {
      resolve([true, invalidMessage])
    }

    resolve([false, null])
  })
}

const validateArray = async (data: FormField<any>): Promise<[boolean, string | null]> => {
  return await new Promise((resolve) => {
    const invalidMessage = 'Invalid array.'
    const requiredMessage = 'This field is required.'
    if (
      data.isRequired && (isNull(data.value) || isUndefined(data.value) || data.value.length === 0)
    ) {
      resolve([true, requiredMessage])
    }

    if (!isArray(data.value)) {
      resolve([true, invalidMessage])
    }

    resolve([false, null])
  })
}

const validateEmail = async (data: FormField<any>): Promise<[boolean, string | null]> => {
  return await new Promise((resolve) => {
    const invalidMessage = 'Invalid email.'
    const requiredMessage = 'This field is required.'

    if (data.isRequired && isEmpty(data.value)) {
      resolve([true, requiredMessage])
    }

    if (!isString(data.value)) {
      resolve([true, invalidMessage])
    } else {
      const lastAtPos = data.value.lastIndexOf('@')
      const lastDotPos = data.value.lastIndexOf('.')
      if (
        !(
          lastAtPos < lastDotPos && lastAtPos > 0 &&
          !data.value.includes('@@') &&
          lastDotPos > 2 && (data.value.length - lastDotPos
          ) > 2)
      ) {
        resolve([true, invalidMessage])
      }
    }

    resolve([false, null])
  })
}

const validateObject = async (data: FormField<any>): Promise<[boolean, string | null]> => {
  return await new Promise((resolve) => {
    const invalidMessage = 'Invalid object.'
    const requiredMessage = 'This field is required.'
    if (
      data.isRequired && (isNull(data.value) || isUndefined(data.value) || Object.keys(data.value).length === 0)
    ) {
      resolve([true, requiredMessage])
    }

    if (!isObjectLike(data.value) || data.value.constructor !== Object) {
      resolve([true, invalidMessage])
    }

    resolve([false, null])
  })
}

const validateFile = async (data: FormField<any>): Promise<[boolean, string | null]> => {
  return await new Promise((resolve) => {
    const invalidMessage = 'Invalid file.'
    const requiredMessage = 'This field is required.'
    if (
      data.isRequired && (isNull(data.value) || isUndefined(data.value) || isEmpty(data.value))
    ) {
      resolve([true, requiredMessage])
    }

    if ((data.isAllowed != null) && data.isAllowed !== '*') {
      if (!validateFileExtension(data.value.name, data.isAllowed)) {
        resolve([true, invalidMessage])
      }
    }

    resolve([false, null])
  })
}

const validateFileExtension = (filename: any, format: any): boolean => {
  const extension = filename.split('.').pop().toLowerCase()
  return format.includes(extension.toLowerCase())
}

const validateUrl = async (data: FormField<any>): Promise<[boolean, string | null]> => {
  return await new Promise((resolve) => {
    const invalidLink = 'Invalid Link.'
    const requiredMessage = 'This field is required.'
    if (
      data.isRequired && (isNull(data.value) || isUndefined(data.value) || isEmpty(data.value))
    ) {
      resolve([true, requiredMessage])
    }
    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i

    if (!urlRegex.test(data.value) && data.isAllowed !== '*') {
      resolve([true, invalidLink])
    }

    resolve([false, null])
  })
}
export default formValidator
