import { type AsyncStateObject, type Action, type ReviewQuestion } from '../../../interfaces'
import { APP_ACTION_STATUS, SESSION_LIST_ACTION } from '../../../utils/constants'

const INITIAL_STATE: AsyncStateObject<ReviewQuestion[]> = {
  data: [],
  isLoading: false,
  status: APP_ACTION_STATUS.INITIAL,
  error: undefined
}

const ReviewQuestionReducer = (
  state = INITIAL_STATE,
  action: Action<ReviewQuestion[]>
) => {
  switch (action.type) {
    case SESSION_LIST_ACTION.STORE_FEEDBACK_QUESTION:
      return {
        ...state,
        isLoading: false,
        status: APP_ACTION_STATUS.SUCCESS,
        data: state.data.concat(action.data)
      }
    case SESSION_LIST_ACTION.STORE_FEEDBACK_QUESTION_CLEAR:
      return INITIAL_STATE
    default:
      return state
  }
}

export default ReviewQuestionReducer
