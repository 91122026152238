import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './styles/base/_base.scss'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import { ThemeProvider } from '@emotion/react'
import store from './redux/store'
import PrimaryTheme from './styles/theme/theme'
import { SnackbarProvider } from 'notistack'
import { msalConfig } from './configs/authConfig'
import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'

export const msalInstance = new PublicClientApplication(msalConfig)
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <Provider store={store}>
    <MsalProvider instance={msalInstance}>
      <ThemeProvider theme={PrimaryTheme}>
        <SnackbarProvider>
          <App />
        </SnackbarProvider>
      </ThemeProvider>
    </MsalProvider>
  </Provider >
)
reportWebVitals()
