import { Box, Card, CardContent, Chip, Grid, Typography } from '@mui/material'
import React from 'react'
import { type SessionCoacheeListDto, type AsyncStateObject, type SessionCoacheeDataDto } from '../../../interfaces'
import moment from 'moment'
import { COACH_VIEW_COACHEE_STATUS } from '../../../utils/constants'
import styles from './SessionCoacheeStatsView.module.scss'

const SessionCoacheeStatsView: React.FC<{
  coacheeListResponse: AsyncStateObject<SessionCoacheeListDto>
  pagination: React.ReactNode
}> = ({ coacheeListResponse, pagination }) => {
  const isLoading = coacheeListResponse.isLoading
  const coacheeList = coacheeListResponse.data.data

  return (

        < Card sx={{
          minWidth: 275,
          borderRadius: '1rem',
          border: '1px solid #DAE0E6',
          boxShadow: 'none',
          marginTop: '1rem'
        }}>
            {!isLoading && coacheeList.length > 0 &&
                coacheeList.map((coachee: SessionCoacheeDataDto, index: number) => (
                    <CardContent key={index}
                        sx={{ borderBottom: '0.1rem solid #DAE0E6', boxShadow: 'none', marginBottom: '0rem' }}
                    >
                        <Grid lg={12} md={12} xs={12} container display="flex" alignItems="center">
                            <Grid display="flex" item xs={11} md={4} lg={7}>
                                <Grid xs={12}>
                                    <Typography
                                        fontWeight="600"
                                        variant="body1"
                                        align="left"
                                        style={{ wordWrap: 'break-word' }}
                                    >
                                        {coachee.name}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid xs={6} md={5} lg={2}
                                display="flex" justifyContent="space-between" item
                                sx={{ marginTop: { xs: '1rem', md: '1rem' } }} >
                                <Grid xs={12}>
                                    <Typography fontWeight="500" sx={{ whiteSpace: 'nowrap' }}>
                                        {coachee.sessionStartDate !== null && coachee.sessionEndDate !== null
                                          ? `${moment(coachee.sessionStartDate).format('DD/MM/YYYY')} -
                                    ${moment(coachee.sessionEndDate).format('DD/MM/YYYY')}`
                                          : (coachee.sessionStartDate !== null
                                              ? `${moment(coachee.sessionStartDate).format('DD/MM/YYYY')} -`
                                              : (coachee.sessionEndDate !== null
                                                  ? `- ${moment(coachee.sessionEndDate).format('DD/MM/YYYY')}`
                                                  : '-'))
                                        }
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid xs={6} md={2} lg={2}
                                display="flex" justifyContent="space-between"
                                alignItems={'center'} item sx={{ marginTop: { xs: '1rem' } }} >
                                <Grid xs={3} md={1} lg={1}>
                                    <Box sx={{
                                      display: 'flex',
                                      justifyContent: { xs: 'end', md: 'start', lg: 'start' }
                                    }}>
                                        <Typography>
                                            <Chip
                                                id={coachee.status === COACH_VIEW_COACHEE_STATUS.COMPLETED
                                                  ? styles.cardChipCompleted
                                                  : coachee.status === COACH_VIEW_COACHEE_STATUS.IN_PROGRESS
                                                    ? styles.cardChipInProgress
                                                    : styles.cardChipNew}
                                                label={coachee.status}
                                            />
                                        </Typography>
                                    </Box>
                                </Grid>
                                {coachee.assessments[0]?.noOfCorrectAnswers === null ||
                                    coachee.assessments[0]?.noOfQuestions === null
                                  ? (
                                        <Grid xs={3} md={1} lg={1} display="flex" justifyContent="space-between">
                                            <Typography
                                                variant="caption"
                                                component="div"
                                                color="text.secondary"
                                            >
                                                -
                                            </Typography>
                                        </Grid>
                                    )
                                  : (
                                        <Grid xs={3} md={1} lg={1} display="flex" justifyContent="space-between">
                                            <Typography
                                                variant="caption"
                                                component="div"
                                                color="text.secondary"
                                                fontSize='14px'

                                            >
                                                {coachee.assessments[0]?.noOfCorrectAnswers}/{coachee.assessments[0]?.noOfQuestions}
                                            </Typography>
                                        </Grid>
                                    )}
                            </Grid>
                        </Grid>

                    </CardContent>
                ))
            }
        </Card >

  )
}

export default SessionCoacheeStatsView
