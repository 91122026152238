import { Grid } from '@mui/material'
import React from 'react'
import DashboardStatCard from '../DashboardStatCard/DashboardStatCard'
import DashboardStatChartCard from '../DashboardStatChartCard/DashboardStatChartCard'
import DashboardEngagementCard from '../DashboardEngagementCard/DashboardEngagementCard'
import DashboardSessionCard from '../DashboardSessionCard/DashboardSessionCard'
import {
  type DashboardStatChartDto,
  type AsyncStateObject,
  type CoacheeStatsResponse,
  type RecentEngagementAsCoacheeResponse,
  type DashboardRecentEngagementDto,
  type CoacheeSessionSummaryDto,
  type DashboardSessionSummaryDto,
  type CoacheeGoalSummaryDto,
  type DashboardGoalSummaryDto,
  type DropdownOptionnDto
} from '../../../interfaces'
import DashboardGoalSummaryCard from '../DashboardGoalSummaryCard/DashboardGoalSummaryCard'
import { ENGAGEMENT_TYPES } from '../../../utils/constants'

const DashboardCoacheeView: React.FC<{
  goalStatus: DropdownOptionnDto
  onHandleStatusChange: (value: DropdownOptionnDto) => void
  coacheeStatsResponse: AsyncStateObject<CoacheeStatsResponse | null>
  recentCoacheeEngagementResponse: AsyncStateObject<RecentEngagementAsCoacheeResponse | null>
  coacheeGoalSummaryDataResponse: AsyncStateObject<CoacheeGoalSummaryDto[]>
  coacheeSessionSummaryDataResponse: AsyncStateObject<CoacheeSessionSummaryDto[]>
}> = ({
  goalStatus,
  onHandleStatusChange,
  coacheeStatsResponse,
  recentCoacheeEngagementResponse,
  coacheeSessionSummaryDataResponse,
  coacheeGoalSummaryDataResponse
}) => {
  const sessions = coacheeStatsResponse?.data?.sessions
  const goals = coacheeStatsResponse?.data?.goals
  const chartData: DashboardStatChartDto = {
    total: goals?.totalGoals ?? 0,
    pending: goals?.pendingGoals ?? 0,
    rejected: goals?.rejectedGoals ?? 0,
    accepted: goals?.acceptedGoals ?? 0,
    assigned: goals?.assignedGoals ?? 0,
    inProgress: goals?.inProgressGoals ?? 0,
    completed: goals?.completedGoals ?? 0
  }

  const recentEngagementData = recentCoacheeEngagementResponse?.data
  const engagementData: DashboardRecentEngagementDto = {
    _id: recentEngagementData?._id ?? '',
    name: recentEngagementData?.name ?? '',
    endDate: recentEngagementData?.endDate ?? '',
    totalSessions: recentEngagementData?.totalSessions ?? 0,
    sessionsLeft: recentEngagementData?.sessionsLeft ?? 0,
    sessionCompletedPercentage: recentEngagementData?.sessionCompletedPercentage ?? 0.0,
    coaches: recentEngagementData?.coaches ?? []
  }

  const recentSessions = coacheeSessionSummaryDataResponse?.data ?? []
  const recentSessionsList: DashboardSessionSummaryDto[] = recentSessions.map((session) => {
    return {
      engagementId: session.engagementId,
      sessionId: session.sessionId,
      name: session.name,
      sessionType: session.sessionType,
      sessionDuration: session.sessionDuration,
      status: session.status
    }
  })

  const recentGoals = coacheeGoalSummaryDataResponse?.data ?? []
  const recentGoalsList: DashboardGoalSummaryDto[] = recentGoals.map((goal) => {
    return {
      _id: goal._id,
      name: goal.name,
      priority: goal.priority,
      priorityId: goal.priorityId,
      startDate: goal.startDate,
      endDate: goal.endDate,
      status: goal.status,
      statusId: goal.statusId
    }
  })
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={3}>
              <DashboardStatCard
                isLoading={coacheeStatsResponse?.isLoading}
                count={sessions?.totalSessions ?? 0}
                title={'Total Sessions'}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <DashboardStatCard
                isLoading={coacheeStatsResponse?.isLoading}
                count={sessions?.completedSessions ?? 0}
                title={'Completed Sessions'}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <DashboardStatCard
                isLoading={coacheeStatsResponse?.isLoading}
                count={sessions?.inProgressSessions ?? 0}
                title={'In Progress Sessions'}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <DashboardStatCard
                isLoading={coacheeStatsResponse?.isLoading}
                count={sessions?.newSessions ?? 0}
                title={'Pending Sessions'}
              />
            </Grid>
            <Grid item xs={12} className='dashboard-chart'>
              <DashboardStatChartCard
                title='Total Goals Allocated'
                isLoading={coacheeStatsResponse?.isLoading}
                data={chartData}
                showAction={false}
              />
            </Grid>
            <Grid item xs={12}>
              <DashboardGoalSummaryCard
                selectedStatus={goalStatus}
                onChange={onHandleStatusChange}
                isLoading={coacheeGoalSummaryDataResponse?.isLoading}
                data={recentGoalsList}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DashboardEngagementCard
                isLoading={recentCoacheeEngagementResponse?.isLoading}
                data={engagementData}
                type={ENGAGEMENT_TYPES.COACHEE}
              />
            </Grid>
            <Grid item xs={12}>
              <DashboardSessionCard
                isLoading={coacheeSessionSummaryDataResponse?.isLoading}
                data={recentSessionsList}
                type={ENGAGEMENT_TYPES.COACHEE}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default DashboardCoacheeView
