import { type AxiosResponse } from 'axios'
import {
  type Action
} from '../../interfaces'
import { type CoachList } from '../../interfaces/coachList'
import { COMMON_ACTIONS, SESSION_ACTIONS } from '../../utils/constants'
import { call, put, takeEvery } from 'redux-saga/effects'
import { commonService } from '../../services/commonService'

function * getSessionCoachList (action: Action<string>) {
  try {
    const response: AxiosResponse<CoachList[]> = yield call(commonService.getSessionCoachList, action.data)
    yield put<Action<CoachList[]>>({
      type: SESSION_ACTIONS.GET_SESSION_COACH_LIST + COMMON_ACTIONS.SUCCESS,
      data: response.data
    })
  } catch (error) {
    yield put<Action<CoachList[]>>({
      type: SESSION_ACTIONS.GET_SESSION_COACH_LIST + COMMON_ACTIONS.ERROR,
      data: [],
      error: error as string
    })
  }
}
function * coachListSagas () {
  yield takeEvery(SESSION_ACTIONS.GET_SESSION_COACH_LIST + COMMON_ACTIONS.REQUEST, getSessionCoachList)
}
export default coachListSagas
