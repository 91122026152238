import { Box, Typography } from '@mui/material'
import React from 'react'

const Profile = () => {
  return (
    <React.Fragment>
      <section className='page-root'>
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'calc(100vh - 8rem)'}>
          <Box>
            <Typography
              display={'flex'}
              justifyContent={'center'}
              textTransform="none"
              fontWeight="600" variant='h4' color="#5F6D7E">
              🚧
            </Typography>
            <Typography
              display={'flex'}
              justifyContent={'center'}
              textTransform="none"
              fontWeight="600" variant='h4' color="#5F6D7E">
              Coming Soon!
            </Typography>
            <Typography
              display={'flex'}
              justifyContent={'center'}
              textTransform="none"
              fontWeight="600" variant='h4' color="#5F6D7E">
              This page is currently under development.
            </Typography>
          </Box>
        </Box>
      </section>
    </React.Fragment>
  )
}

export default Profile
