import { Box, Drawer, Card, CardContent, Typography, IconButton, Divider } from '@mui/material'
import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import {
  type AsyncStateObject,
  type NoteDto,
  type SessionCoacheeDataDto
} from '../../../interfaces'
import SingleNote from '../SingleNote/SingleNote'
import { SingleNoteSkelton } from '../../skeleton'
import styles from './CoacheeNotesDrawer.module.scss'

const CoacheeNotesDrawer: React.FC<{
  drawerOpen: boolean
  selectedCoachee: SessionCoacheeDataDto
  toggleDrawer: (open: boolean) => any
  viewOthersNoteResponse: AsyncStateObject<NoteDto[]>
}> = ({
  drawerOpen,
  selectedCoachee,
  toggleDrawer,
  viewOthersNoteResponse
}) => {
  return (
    <Box>
      <React.Fragment key="right">
        <Drawer
          sx={{ zIndex: '1202' }}
          anchor="right"
          open={drawerOpen}
          onClose={toggleDrawer(false)}
          slotProps={{ backdrop: { invisible: true } }}
        >
          <Box
            sx={{ width: 350 }}
            role="presentation"
            onKeyDown={() => toggleDrawer(false)}
          >
            <Card sx={{ boxShadow: 'none' }}>
              <CardContent
                sx={{
                  boxShadow: 'none',
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <Typography fontWeight='700' variant="h5" sx={{ fontSize: 'medium', py: 1 }}>
                  {selectedCoachee.name}
                </Typography>

                <IconButton>
                  <CloseIcon onClick={toggleDrawer(false)} />

                </IconButton>
              </CardContent>
              <Divider />
              <Card sx={{ boxShadow: 'none' }}>
                <CardContent>
                  {viewOthersNoteResponse?.isLoading &&
                    <Box display='flex' flexDirection='column'>
                      <SingleNoteSkelton/><SingleNoteSkelton/><SingleNoteSkelton/>
                    </Box>
                  }
                  {!viewOthersNoteResponse?.isLoading &&
                    <>
                      {viewOthersNoteResponse?.data?.map((note: NoteDto, index: number) =>
                        <Box key={index}>
                          <SingleNote
                            index={index}
                            note={note}
                            isShowAction={false}
                          />
                        </Box>
                      )}
                      {viewOthersNoteResponse?.data?.length === 0 && (
                        <div className={styles.noDataNotification}>
                          No notes
                        </div>
                      )}
                    </>
                  }
                </CardContent>
              </Card>
              <Divider />
            </Card>
          </Box>
        </Drawer>
      </React.Fragment>
    </Box >
  )
}

export default CoacheeNotesDrawer
