/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip
} from '@mui/material'
import Typography from '@mui/material/Typography'

import TreeView from '@mui/lab/TreeView'
import TreeItem from '@mui/lab/TreeItem'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import SearchIcon from '@mui/icons-material/Search'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import ClearIcon from '@mui/icons-material/Clear'
import React, { useEffect, useState } from 'react'
import {
  type AlertMessage,
  type AppState,
  type OrgAndRoleHierarchy,
  type UserHierarchy
} from '../../../interfaces'
import { ALERT_VARIANT, APP_CONFIGS, APP_TABLE_CONFIGS, HIERARCHY_TYPES, PERMISSION } from '../../../utils/constants'
import { useSelector, useDispatch } from 'react-redux'
import { alertActions } from '../../../redux/actions'
import styles from './GoalTargetGroup.module.scss'
import { hasPermission } from '../../../utils/helpers'

const GoalTargetGroup: React.FC<{
  searchKey: string
  hierarchyType: HIERARCHY_TYPES
  handleChangeHierarchy: (event: React.MouseEvent<HTMLElement>, newType: number) => void
  hierarchyData: OrgAndRoleHierarchy[]
  userData: UserHierarchy[]
  isEditable: boolean
  handleEditModeChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  addTag: (selected: OrgAndRoleHierarchy) => void
  addUserTag: (selected: UserHierarchy) => void
  selectedTags: OrgAndRoleHierarchy[]
  selectedUserTags: UserHierarchy[]
  handleDelete: (levelId: string) => void
  onSearchHierarchyData: (event: React.ChangeEvent<HTMLInputElement>) => void
  selectedTreeNodes: string[]
  hierarchyIsLoading: boolean
  isTagAdded: boolean
}> = (props) => {
  const {
    searchKey,
    hierarchyType,
    handleChangeHierarchy,
    hierarchyData,
    userData,
    isEditable,
    handleEditModeChange,
    addTag,
    addUserTag,
    selectedTags,
    selectedUserTags,
    handleDelete,
    onSearchHierarchyData,
    selectedTreeNodes,
    hierarchyIsLoading,
    isTagAdded
  } = props
  const [expanded, setExpanded] = React.useState<string[]>([])
  const authorizedUserPermissions = useSelector(
    (state: AppState) => state.authUser.authorizedUserPermission
  )
  const dispatch = useDispatch()
  useEffect(() => {
    generateNodes()
  }, [hierarchyData, userData, hierarchyType])

  const generateNodes = (): void => {
    const nodeIds: string[] = []

    if (hierarchyData.length > 0) {
      for (const org of hierarchyData) {
        nodeIds.push(org.levelId)
        if (org.subLevels !== undefined && org.subLevels.length > 0) {
          for (const sbu of org.subLevels) {
            nodeIds.push(sbu.levelId)
          }
        }
        if (org.subLevels !== undefined && org.subLevels.length > 0) {
          for (const sbu of org.subLevels) {
            if (sbu.subLevels !== undefined && sbu.subLevels.length > 0) {
              for (const department of sbu.subLevels) {
                nodeIds.push(department.levelId)
              }
            }
          }
        }
        if (org.subLevels !== undefined && org.subLevels.length > 0) {
          for (const sbu of org.subLevels) {
            if (sbu.subLevels !== undefined && sbu.subLevels.length > 0) {
              for (const department of sbu.subLevels) {
                if (department.subLevels !== undefined && department.subLevels.length > 0) {
                  for (const team of department.subLevels) {
                    nodeIds.push(team.levelId)
                  }
                }
              }
            }
          }
        }
      }
    }

    setExpanded(nodeIds)
  }

  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpanded(nodeIds)
  }

  /*
    Permission Handling Start
*/
  const entitlementsWithHirachyIDs: any = authorizedUserPermissions.data.slice(1)
  const loggedInUserEmail: any = localStorage.getItem(APP_CONFIGS.LOGGED_USER)
  const userEmail: string = loggedInUserEmail.slice(1, -1)

  const hasPermisstionToCreateOwnGoal = hasPermission([PERMISSION.CREATE_GOAL_FOR_HIM_SELF])
  const hasPermisstionToCreateGoalForOthers = hasPermission([PERMISSION.CREATE_GOAL_FOR_OTHERS])

  /*
  Permission Handling End
  */
  const getHirachyPremissionById = (id: any) => {
    return entitlementsWithHirachyIDs.map((item: any, index: number) => {
      return item.hierarchyGroupIds.includes(id)
    })
  }

  const loggedUserMatchedUserHierarchyObj: any = userData.find(loggedUserObj => loggedUserObj.username === userEmail)
  const rolesArray = loggedUserMatchedUserHierarchyObj?.roles.map((item: any) => item.roleName)

  const getHierarchyPermissionByLoggedUser = (rolesArray: string[]) => {
    const isUser = rolesArray.includes('User')
    const isCoachOrSupervisor = rolesArray.includes('User') &&
      (rolesArray.includes('Coach') || rolesArray.includes('Supervisor'))
    if (isUser && !isCoachOrSupervisor) {
      return 1
    }

    if (isUser && isCoachOrSupervisor) {
      return 2
    }
  }
  const getUserRoleByUserName = (userName: string) => {
    if (userEmail === userName) {
      const loggedUserMatchedUserHierarchyObj: any = userData.find(loggedUserObj => loggedUserObj.username === userName)
      const rolesArray = loggedUserMatchedUserHierarchyObj?.roles.map((item: any) => item.roleName)
      return getHierarchyPermissionByLoggedUser(rolesArray)
    } else {
      return false
    }
  }
  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          borderColor: 'divider',
          display: 'flex',
          flexDirection: { xs: 'column-reverse', sm: 'row' },
          justifyContent: 'space-between'
        }}
      >
        <Box sx={{
          width: { sm: '75%', xs: '100%' },
          marginTop: { xs: '1rem', sm: 0 }
        }}>
          <ToggleButtonGroup
            value={hierarchyType}
            exclusive
            onChange={handleChangeHierarchy}
          >
            {hasPermisstionToCreateGoalForOthers &&
              <ToggleButton
                size="medium"
                sx={{ textTransform: 'none' }}
                value={HIERARCHY_TYPES.ORGANAIZATION}
              >
                Organization
              </ToggleButton>
            }
            {hasPermisstionToCreateGoalForOthers &&
              <ToggleButton
                size="medium"
                sx={{ textTransform: 'none', minWidth: '4rem' }}
                value={HIERARCHY_TYPES.ROLE}
              >
                Role
              </ToggleButton>
            }
            {hasPermisstionToCreateOwnGoal &&
              <ToggleButton
                size="medium"
                sx={{ textTransform: 'none', minWidth: '4rem' }}
                value={HIERARCHY_TYPES.USER}
              >
                User
              </ToggleButton>
            }
          </ToggleButtonGroup>
        </Box>
        <Box sx={{ width: 275 }}>
          <TextField
            placeholder={'Search'}
            value={searchKey}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
            size="medium"
            sx={{ width: '100%' }}
            onChange={onSearchHierarchyData}
          />
        </Box>
      </Box>
      <Card sx={{ minWidth: 275, marginTop: '1.5rem' }}>
        <CardContent className={styles.cardContent}>
          {hierarchyType === HIERARCHY_TYPES.ORGANAIZATION
            ? (
              <TreeView
                multiSelect
                expanded={expanded}
                selected={selectedTreeNodes}
                onNodeToggle={handleToggle}
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                sx={{
                  height: 240,
                  flexGrow: 1,
                  maxWidth: '100vw',
                  overflowY: 'auto'
                }}
              >
                {hierarchyIsLoading && <p className={styles.isLoading}>Loading...</p>}
                {!hierarchyIsLoading && hierarchyData.length > 0 && hierarchyData
                  .map((org: OrgAndRoleHierarchy, index: number) => (
                    <TreeItem
                      sx={{
                        marginBottom: '0.1rem',
                        color: !(getHirachyPremissionById(org.levelId).some((status: any) =>
                          status === true))
                          ? 'gray'
                          : 'black'
                      }}
                      nodeId={org.levelId}
                      label={org.levelName}
                      key={index}
                      onClick={() => {
                        if ((getHirachyPremissionById(org.levelId).some((status: any) => {
                          return status === true
                        }
                        ))) {
                          props.addTag(org)
                        }
                      }}
                    >
                      {
                        org.subLevels !== undefined && org.subLevels.length > 0 &&
                        org.subLevels.map((sbu: OrgAndRoleHierarchy, index: number) => (
                          <TreeItem
                            sx={{
                              color: !(getHirachyPremissionById(sbu.levelId).some((status: any) =>
                                status === true))
                                ? 'gray'
                                : 'black'
                            }}
                            nodeId={sbu.levelId}
                            label={sbu.levelName}
                            key={index}
                            onClick={() => {
                              if ((getHirachyPremissionById(sbu.levelId).some((status: any) => status === true))) {
                                props.addTag(sbu)
                              }
                            }}
                          >
                            {
                              sbu.subLevels !== undefined && sbu.subLevels.length > 0 &&
                              sbu.subLevels.map((department: OrgAndRoleHierarchy, index: number) => (
                                <TreeItem
                                  sx={{
                                    color: !(getHirachyPremissionById(department.levelId).some((status: any) =>
                                      status === true))
                                      ? 'gray'
                                      : 'black'
                                  }}
                                  nodeId={department.levelId}
                                  label={department.levelName}
                                  key={index}
                                  onClick={() => {
                                    if ((getHirachyPremissionById(department.levelId).some((status: any) =>
                                      status === true))) {
                                      props.addTag(department)
                                    }
                                  }}
                                >
                                  {
                                    department.subLevels !== undefined && department.subLevels.length > 0 &&
                                    department.subLevels.map((team: OrgAndRoleHierarchy, index: number) => (
                                      <TreeItem
                                        sx={{
                                          color: !(getHirachyPremissionById(team.levelId).some((status: any) =>
                                            status === true))
                                            ? 'gray'
                                            : 'black'
                                        }}
                                        nodeId={team.levelId}
                                        label={team.levelName}
                                        key={index}
                                        onClick={() => {
                                          if ((getHirachyPremissionById(team.levelId).some((status: any) =>
                                            status === true))) {
                                            props.addTag(team)
                                          }
                                        }}
                                      />
                                    ))
                                  }
                                </TreeItem>
                              ))
                            }
                          </TreeItem>
                        ))
                      }
                    </TreeItem>
                  ))}
              </TreeView>
              )
            : hierarchyType === HIERARCHY_TYPES.ROLE
              ? (
                <TreeView
                  multiSelect
                  expanded={expanded}
                  selected={selectedTreeNodes}
                  onNodeToggle={handleToggle}
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpandIcon={<ChevronRightIcon />}
                  sx={{
                    height: 240,
                    flexGrow: 1,
                    overflowY: 'auto',
                    maxWidth: '100vw'
                  }}
                >
                  {hierarchyIsLoading && <p className={styles.isLoading}>Loading...</p>}
                  {!hierarchyIsLoading && hierarchyData.length > 0 && hierarchyData
                    .map((org: OrgAndRoleHierarchy, index: number) => (
                      <TreeItem
                        sx={{
                          marginBottom: '0.1rem',
                          color: !(getHirachyPremissionById(org.levelId).some((status: any) =>
                            status === true))
                            ? 'gray'
                            : 'black'
                        }}
                        nodeId={org.levelId}
                        label={org.levelName}
                        key={index}
                        onClick={() => {
                          if ((getHirachyPremissionById(org.levelId).some((status: any) => status === true))) {
                            props.addTag(org)
                          }
                        }}
                      >
                        {
                          org.subLevels !== undefined && org.subLevels.length > 0 &&
                          org.subLevels.map((stack: OrgAndRoleHierarchy, index: number) => (
                            <TreeItem
                              sx={{
                                color: !(getHirachyPremissionById(stack.levelId).some((status: any) =>
                                  status === true))
                                  ? 'gray'
                                  : 'black'
                              }}
                              nodeId={stack.levelId}
                              label={stack.levelName}
                              key={index}
                              onClick={() => { addTag(stack) }}
                            >
                              {
                                stack.subLevels !== undefined && stack.subLevels.length > 0 &&
                                stack.subLevels.map((designation: OrgAndRoleHierarchy, index: number) => (
                                  <TreeItem
                                    sx={{
                                      color: !(getHirachyPremissionById(designation.levelId).some((status: any) =>
                                        status === true))
                                        ? 'gray'
                                        : 'black'
                                    }}
                                    nodeId={designation.levelId}
                                    label={designation.levelName}
                                    key={index}
                                    onClick={() => {
                                      if ((getHirachyPremissionById(designation.levelId).some((status: any) =>
                                        status === true))) {
                                        props.addTag(designation)
                                      }
                                    }}

                                  />
                                ))
                              }
                            </TreeItem>
                          ))
                        }
                      </TreeItem>
                    ))}
                </TreeView>
                )
              : (
                <TreeView
                  className={styles.treeView}
                  multiSelect
                  selected={selectedTreeNodes}
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpandIcon={<ChevronRightIcon />}

                  sx={{
                    height: 240,
                    flexGrow: 1,
                    maxWidth: '100vw',
                    overflowY: 'auto'
                  }}
                >
                  {hierarchyIsLoading && <p className={styles.isLoading}>Loading...</p>}
                  {!hierarchyIsLoading && userData.length > 0 && userData
                    .map((user: UserHierarchy, index: number) => (
                      <TreeItem
                        sx={{
                          marginBottom: '0.1rem',
                          color: (getUserRoleByUserName(user.username) === 1 && (user.username === userEmail))
                            ? '#437EF7'
                            : 'black'
                        }}
                        nodeId={user._id}
                        label={user.username}
                        key={index}
                        onClick={() => {
                          if (hasPermisstionToCreateOwnGoal && hasPermisstionToCreateGoalForOthers) {
                            addUserTag(user)
                          } else if (hasPermisstionToCreateOwnGoal && user.username === userEmail) {
                            addUserTag(user)
                          }
                        }}
                      />
                    ))}
                </TreeView>
                )}
        </CardContent>
      </Card>

      <Typography
        sx={{ marginTop: '1rem' }}
        fontWeight="600"
        variant="subtitle2"
      >

        <Box display='flex'><Typography fontWeight={600}>Selected Tags</Typography>
          <Typography color='red'>*</Typography></Box>

      </Typography>
      {isTagAdded ? <Box><Typography color='red'>This field is required</Typography></Box> : ''}

      <Grid container spacing={1} marginTop="1rem">
        {hierarchyType !== HIERARCHY_TYPES.USER && selectedTags.map((tag, index) => (
          <Grid item key={index}>
            <Chip
              deleteIcon={<ClearIcon />}
              onDelete={() => { handleDelete(tag.levelId) }}
              sx={{
                fontWeight: '500',
                borderRadius: '0.25rem',
                backgroundColor: '#F5FAFF',
                color: '#437EF7',
                '& .MuiChip-deleteIcon': {
                  color: '#437EF7'
                }
              }}
              label={tag.levelName}
            />
          </Grid>
        ))}
        {hierarchyType === HIERARCHY_TYPES.USER && selectedUserTags.map((tag, index) => (
          <Grid item key={index}>
            <Chip
              deleteIcon={<ClearIcon />}
              onDelete={() => { handleDelete(tag._id) }}
              sx={{
                fontWeight: '500',
                borderRadius: '0.25rem',
                backgroundColor: '#F5FAFF',
                color: '#437EF7',
                '& .MuiChip-deleteIcon': {
                  color: '#437EF7'
                }
              }}
              label={tag.username}
            />
          </Grid>
        ))}
      </Grid>
      <Box display="flex" sx={{ marginTop: '1rem' }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isEditable}
              onChange={handleEditModeChange}
              disabled={hierarchyType !== HIERARCHY_TYPES.USER}
            />
          }
          label="Editable" />
        <Tooltip title="allow to edit in future">
          <IconButton>
            <HelpOutlineIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </Box >
  )
}

export default GoalTargetGroup
