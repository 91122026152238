import {
  Box, Button, Card,
  CardActions, CardContent,
  Checkbox, Divider, Grid, TextField, Typography
} from '@mui/material'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import React, { useEffect, useState } from 'react'
import { type AssessmentFeedback, type AppState, type ReviewQuestion, type Configs } from '../../../interfaces'
import { sessionActions } from '../../../redux/actions/sessionActions'
import { useDispatch, useSelector } from 'react-redux'

const SessionFeedBack: React.FC<{
  setFeedBack: (feedBack: AssessmentFeedback) => void
}> = ({ setFeedBack }) => {
  const dispatch = useDispatch()
  const getFeedBack = useSelector((state: AppState) => state.session.feedBackQuestion.data)
  const [questions, setQuestions] = useState<ReviewQuestion>(
    {
      questionLabel: '',
      questionOrder: 1,
      options: [{ optionLabel: '', optionOrder: 0 }]
    }
  )

  const [showQuestions, setShowQuestions] = useState(false)
  const [coachCompulsory, setCoachCompulsory] = useState(true)
  const [coacheeCompulsory, setCoacheeCompulsory] = useState(true)
  const [isAddNew, setIsAddNew] = React.useState(true)
  const [isEdit, setIsEdit] = React.useState(false)

  const feedbackQuestions = useSelector((state: AppState) => state.session.feedBackQuestion.data)

  useEffect(() => {
    const configs: Configs =
    {
      isCoachToCoachee: !coachCompulsory,
      isCoacheeToCoach: coacheeCompulsory // Assuming the other checkbox value is opposite of isCoachToCoachee
    }
    // You can add more Configs objects here if needed

    const reviewQuestions: ReviewQuestion[] = getFeedBack

    const updatedFeedBack: AssessmentFeedback = {
      configs,
      reviewQuestions
    }
    setFeedBack(updatedFeedBack)
    setShowQuestions(true)
  }, [getFeedBack])

  const handleFeedBackQuestionChange = (value: string) => {
    const updatedQuestions = { ...questions }
    updatedQuestions.questionLabel = value
    setQuestions(updatedQuestions)
  }

  const addFeedBackQuestions = () => {
    if (questions.questionLabel !== '') {
      dispatch(sessionActions.storeSessionFeedBackQuestion([{
        ...questions,
        questionOrder: feedbackQuestions.length + 1
      }]))
      setCoachCompulsory(true)
      setCoacheeCompulsory(true)
      setShowQuestions(true)
      setIsAddNew(false)

      setQuestions({ questionLabel: '', questionOrder: 1, options: [{ optionLabel: '', optionOrder: 0 }] })
    }
  }

  const coacheefeedBackConfig = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCoacheeCompulsory(event.target.checked)
    const configs: Configs =
    {
      isCoachToCoachee: coachCompulsory,
      isCoacheeToCoach: !coacheeCompulsory
    }
    // You can add more Configs objects here if needed
    const reviewQuestions: ReviewQuestion[] = getFeedBack
    const updatedFeedBack: AssessmentFeedback = {
      configs,
      reviewQuestions
    }
    setFeedBack(updatedFeedBack)
  }

  const deleteQuestions = (questionOder: number) => {
    const updatedQuestions = [...feedbackQuestions]
    dispatch(sessionActions.clearStoreSessionFeedBackQuestion())
    const _withoutDeletedOne = updatedQuestions.filter((q) => q.questionOrder !== questionOder)
    if (_withoutDeletedOne.length === 0) setIsAddNew(true)
    dispatch(sessionActions.storeSessionFeedBackQuestion(
      _withoutDeletedOne.map((q, index) => {
        return {
          ...q,
          questionOrder: index + 1
        }
      })
    ))
    setQuestions({
      questionLabel: '',
      questionOrder: 1,
      options: [{ optionLabel: '', optionOrder: 0 }]
    })
  }

  const addingQuestions = (value: string) => {
    const updatedQuestions = { ...questions }
    updatedQuestions.questionLabel = value
    setQuestions(updatedQuestions)
  }

  const editQuestions = (question: ReviewQuestion) => {
    setQuestions(question)
    setIsEdit(true)
    setIsAddNew(false)
  }

  const saveEditedQuestion = () => {
    const updatedQuestions = [...feedbackQuestions]
    dispatch(sessionActions.clearStoreSessionFeedBackQuestion())
    const _withoutUpdatedOne = updatedQuestions.filter((q) => q.questionOrder !== questions.questionOrder)
    dispatch(sessionActions.storeSessionFeedBackQuestion([..._withoutUpdatedOne, questions]))
    setIsEdit(false)
    setQuestions({
      questionLabel: '',
      questionOrder: 1,
      options: [{ optionLabel: '', optionOrder: 0 }]
    })
  }

  return (
    <Box>
      <Typography fontWeight={600} sx={{ pb: 3 }} align="left">Feedbacks</Typography>
      <Box sx={{ minWidth: 275 }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12}>
            <Card variant="outlined">
              <React.Fragment>
                <CardActions>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.2rem' }}>
                    <Checkbox
                      value={coacheeCompulsory}
                      onChange={coacheefeedBackConfig}
                      defaultChecked
                      disabled={true}
                    />
                    <Typography sx={{ fontSize: 14, fontWeight: 600, pt: 1 }}
                      color="optionLabel.secondary" gutterBottom>
                      Coachee to Coach
                    </Typography>
                  </Box>
                </CardActions>
                <CardActions>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.15rem' }}>
                    <ErrorOutlineOutlinedIcon sx={{ ml: 5, pb: 1 }} />
                    <Typography sx={{ mt: 0.1, pb: 1 }} color="optionLabel.secondary">
                      Let Coachee to give Feedback to the coach
                    </Typography>
                  </Box>
                </CardActions>
              </React.Fragment>
            </Card>
          </Grid>
        </Grid>
        {!isAddNew &&
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', pt: 1 }}>
            <Button
              variant="contained"
              size="small"
              color="secondary"
              onClick={() => { setIsAddNew(true) }}
            >
              Add New Question
            </Button>
          </Box>
        }
        {isAddNew && <Box sx={{ minWidth: 275 }}>
          <Typography fontWeight={600} sx={{ my: 2 }}>New Feedback Questions</Typography>
          <CardContent sx={{ justifyContent: 'center', textAlign: 'center' }}>
            <Card sx={{ pt: 1 }}>
              <TextField
                variant="outlined"
                value={questions.questionLabel}
                size="small"
                sx={{ width: '96%' }}
                onChange={(e) => { handleFeedBackQuestionChange(e.target.value) }}
                placeholder='Enter a new question for the form'
              />
              <Box sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                py: 4,
                pr: 3
              }}>
                <Button variant="outlined" size="small"
                  sx={{ color: 'black', borderColor: 'black' }}
                  onClick={addFeedBackQuestions}
                  disabled={questions.questionLabel === ''}
                >
                  Add Question
                </Button>
              </Box>
            </Card>
          </CardContent>
        </Box>}
        {showQuestions && (
          <Box sx={{ py: 2 }}>
            {getFeedBack.sort((a, b) => a.questionOrder - b.questionOrder)
              .map((question: ReviewQuestion, questionIndex: number) => (
                isEdit && question.questionOrder === questions.questionOrder
                  ? (
                    <Card key={questionIndex} sx={{ p: 2 }}>
                      <Typography>
                        <TextField
                          type="text"
                          size="small"
                          value={questions.questionLabel}
                          onChange={(e) => { addingQuestions(e.target.value) }}
                          sx={{ py: 2, width: '100%' }}
                        />
                      </Typography>
                      <Divider />

                      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                          sx={{ color: 'black', borderColor: 'black' }}
                          variant="outlined"
                          size="small"
                          onClick={saveEditedQuestion}
                        >
                          Save
                        </Button>
                        <Button
                          variant="outlined"
                          size="small"
                          sx={{ color: 'black', borderColor: 'black', ml: 1 }}
                          onClick={() => { deleteQuestions(question.questionOrder) }}
                        >
                          Delete
                        </Button>
                      </Box>
                    </Card>

                    )
                  : (
                    <Card key={questionIndex} sx={{ p: 2 }}>
                      <Typography variant="subtitle1"
                        sx={{ pb: 1, fontWeight: 600 }}>
                        {questionIndex + 1}. {question.questionLabel}
                      </Typography>
                      <Divider />

                      <Box sx={{ display: 'flex', justifyContent: 'flex-end', pt: 1 }}>
                        <Button
                          variant="outlined"
                          size="small"
                          sx={{ color: 'black', borderColor: 'black' }}
                          onClick={() => { editQuestions(question) }}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="outlined"
                          size="small"
                          sx={{ color: 'black', borderColor: 'black', ml: 1 }}
                          onClick={() => { deleteQuestions(question.questionOrder) }}
                        >
                          Delete
                        </Button>
                      </Box>
                    </Card>
                    )
              ))}
          </Box>
        )}

      </Box>
    </Box>
  )
}

export default SessionFeedBack
