/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import RadioGroup from '@mui/material/RadioGroup/RadioGroup'
import Radio from '@mui/material/Radio/Radio'
import { Alert, Button, Card, CardContent, Checkbox, Divider, FormControlLabel, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import {
  type EngagementDetailsRequestParam,
  type AlertMessage,
  type AppState,
  type AssessmentSubmitPayload
} from '../../interfaces'
import { assessmentActions } from '../../redux/actions/assessmentActions'
import { useNavigate, useParams } from 'react-router-dom'
import { ALERT_VARIANT, APP_ACTION_STATUS, getAssessmentResultRoute } from '../../utils/constants'
import { alertActions, engagementsActions } from '../../redux/actions'
import { isNullOrUndefinedOrEmpty } from '../../utils/helpers'
import { FeedbackViewSkelton, SomethingWentWrong } from '../../components'

const AssessmentView = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { engagementId, sessionId, roleType, assessmentId, user } =
    useParams<{ engagementId: string, sessionId: string, roleType: string, assessmentId: string, user: string }>()

  const [isErrorFetchData, setIsErrorFetchData] = useState<string>('')
  const assessmentDetails = useSelector((state: AppState) => state.assessments.assessmentDetails.data)
  const assessmentDetailsResponse = useSelector((state: AppState) => state.assessments.assessmentDetails)
  const { control, handleSubmit } = useForm<any>()
  const assessmentSubmit = useSelector((state: AppState) => state.assessments.assessmentSubmit)
  const engagementDetailsResponse = useSelector((state: AppState) => state.engagements.engagementDetails)
  const assessmentQuestions = assessmentDetails?.questions

  useEffect(() => {
    const assessmentDetailsParams: any = {
      id: sessionId ?? '',
      assessmentId: assessmentId ?? ''
    }
    dispatch(assessmentActions.assessmentDetails(assessmentDetailsParams))

    // THIS CALL FOR VALIDATE PUPROPSE OF ENGAGEMENTID
    const param: EngagementDetailsRequestParam = {
      pathParam: { id: engagementId ?? '' },
      queryParam: { roleType: roleType ?? '' }
    }
    dispatch(engagementsActions.engagementDetails(param))
    return () => {
      dispatch(assessmentActions.clearAssessmentResultDetails())
      dispatch(assessmentActions.clearAssessmentDetails())
      dispatch(assessmentActions.clearAssessmentSubmit())
      dispatch(engagementsActions.clearGetEngagementDetailById())
    }
  }, [])

  useEffect(() => {
    if (engagementDetailsResponse?.status === APP_ACTION_STATUS.ERROR) {
      setIsErrorFetchData(engagementDetailsResponse?.error ?? 'Something went wrong when fetching data.!')
    }
    if (assessmentDetailsResponse?.status === APP_ACTION_STATUS.ERROR) {
      setIsErrorFetchData(assessmentDetailsResponse?.error ?? 'Something went wrong when fetching data.!')
    }
  }, [engagementDetailsResponse?.status, assessmentDetailsResponse?.status])

  const onSubmit = (data: any) => {
    const answers: any[] = assessmentQuestions.map((quiz: any) => {
      const optionIds = quiz.options
        .filter((option: any) =>
          data[`${quiz.questionOrder}`]?.includes(option.optionOrder)
        )
        .map((option: any) => option.optionOrder)
      return {
        questionNo: quiz.questionOrder,
        options: optionIds
      }
    })

    const submitAnswerPayload: any = {
      status: 'Completed',
      answers
    }

    const assessmentSubmitPayload: AssessmentSubmitPayload = {
      sessionId: sessionId ?? '',
      coacheeId: user ?? '',
      assessmentId: assessmentId ?? '',
      requestBody: submitAnswerPayload
    }
    const submitAnsForAllQuestions = answers.every((answerOption) => answerOption.options.length > 0)

    if (submitAnsForAllQuestions) {
      dispatch(assessmentActions.assessmentSubmit(assessmentSubmitPayload))
    } else {
      const warningMessage: AlertMessage = {
        message: 'Please provide answers for the questions before submit',
        options: {
          variant: ALERT_VARIANT.WARNING,
          autoHideDuration: 2000
        }
      }
      dispatch(alertActions.triggerAlert(warningMessage))
    }
  }

  useEffect(() => {
    if (assessmentSubmit.status === 'success') {
      const _engagementId = engagementId ?? ''
      const _sessionId = sessionId ?? ''
      const _roleType = roleType ?? ''
      const _assessment = assessmentId ?? ''
      const _user = user ?? ''
      navigate(getAssessmentResultRoute(_engagementId, _sessionId, _roleType, _assessment, _user))
    }
  }, [assessmentSubmit.status])
  return (
    <React.Fragment>
      <section className='page-root' >
        <Card className='section-card'>
          <CardContent>
            <section className='section-card-header'>
              <Typography fontWeight="600" variant='h6'>Assessment</Typography>
            </section>
            <section className='section-card-body'>
              <Alert severity="info" sx={{ marginBottom: '1rem' }}>
                Please be informed that completing the assessment
                for this session is mandatory to complete the session.
              </Alert>
              <form onSubmit={handleSubmit(onSubmit)}>
                {(assessmentDetailsResponse?.isLoading) && <FeedbackViewSkelton />}
                {!(assessmentDetailsResponse?.isLoading) &&
                  <>
                    {!isNullOrUndefinedOrEmpty(isErrorFetchData) && <SomethingWentWrong isShowDefault={false} subNotification={isErrorFetchData} />}
                      {isNullOrUndefinedOrEmpty(isErrorFetchData) &&
                        <>
                          {assessmentQuestions?.map((quiz: any) => (
                            <Box sx={{ marginTop: '1rem' }} key={quiz.questionOrder} >
                              <Divider />
                              <Typography
                                fontWeight={600}
                                fontSize='1.25rem'>{quiz.questionOrder}.{quiz.questionLabel}</Typography>

                              {quiz.type === 'single-select'
                                ? <Controller
                                  name={`${quiz.questionOrder}`}
                                  control={control}
                                  defaultValue=""
                                  render={({ field }) => (
                                    <RadioGroup {...field}>
                                      {quiz.options.map((option: any) => (
                                        <FormControlLabel
                                          key={option.optionOrder}
                                          value={option.optionOrder}
                                          control={<Radio />}
                                          label={option.optionLabel}
                                        />
                                      ))}
                                    </RadioGroup>
                                  )}
                                />
                                : (quiz.type === 'boolean'
                                    ? (<Controller
                                    name={`${quiz.questionOrder}`}
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                      <RadioGroup {...field}>
                                        {quiz.options.map((option: any) => (
                                          <FormControlLabel
                                            key={option.optionOrder}
                                            value={option.optionOrder}
                                            control={<Radio />}
                                            label={option.optionLabel}
                                          />
                                        ))}
                                      </RadioGroup>
                                    )}
                                  />)
                                    : <Controller
                                    name={`${quiz.questionOrder}`}
                                    control={control}
                                    defaultValue={[]}
                                    render={({ field }) => (
                                      <Box display='grid'>
                                        {quiz.options.map((option: any) => (
                                          <FormControlLabel
                                            key={option.optionOrder}
                                            label={option.optionLabel}
                                            control={
                                              <Checkbox
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    field.onChange([
                                                      ...field.value,
                                                      option.optionOrder
                                                    ])
                                                  } else {
                                                    field.onChange(
                                                      field.value.filter(
                                                        (id: any) => id !== option.optionOrder
                                                      )
                                                    )
                                                  }
                                                }}
                                                value={option.optionOrder}
                                                checked={field.value.includes(
                                                  option.optionOrder
                                                )}
                                              />
                                            }
                                          />
                                        ))}
                                      </Box>
                                    )}
                                  />)
                              }
                            </Box>
                          ))}
                        </>
                      }
                  </>
                }
                <Box display='flex' justifyContent='end'>
                  <Button
                    type='submit'
                    sx={{ textTransform: 'none' }}
                    color='primary'
                    variant='contained'
                    disabled={!isNullOrUndefinedOrEmpty(isErrorFetchData)}
                    >Submit</Button>
                </Box>
              </form>
            </section>
          </CardContent>
        </Card>
      </section>
    </React.Fragment>
  )
}

export default AssessmentView
