/* eslint-disable no-console */
import React from 'react'
import styles from './Login.module.scss'
import { Button } from '@mui/material'
import { logo } from '../../assets'
import { type AlertMessage, type AppState } from '../../interfaces'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { alertActions, authActions } from '../../redux/actions'
import { ALERT_ORIGIN_HORIZONTAL_TYPE, ALERT_VARIANT, APP_CONFIGS, APP_ROUTES } from '../../utils/constants'
import { loginRequest } from '../../configs/authConfig'

const Login = () => {
  const { instance, accounts } = useMsal()
  const isAuthenticated = useIsAuthenticated()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const today = new Date()

  const userAuthorizing = useSelector(
    (state: AppState) => state.authUser.userAuthorizing
  )
  const authorizedUser = useSelector(
    (state: AppState) => state.authUser.authorizedUser
  )
  const authorizedUserPermissions = useSelector(
    (state: AppState) => state.authUser.authorizedUserPermission
  )

  React.useEffect(() => {
    if (isAuthenticated) {
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (accounts[0]) {
        dispatch(authActions.authorizedUser())
        instance.setActiveAccount(accounts[0])
      }
    }
  }, [isAuthenticated])

  React.useEffect(() => {
    const _isAuthorized =
      !userAuthorizing.isLoading &&
      userAuthorizing.isAuthorized &&
      authorizedUser.data.isActive &&
      isAuthenticated
    if (_isAuthorized) {
      localStorage.setItem(APP_CONFIGS.LOGGED_USER, JSON.stringify(authorizedUser.data.username))
      const userEntitlements = []
      for (let i = 0; i < authorizedUserPermissions.data.length; i++) {
        userEntitlements.push(authorizedUserPermissions.data[i].entitlement)
      }
      localStorage.setItem(APP_CONFIGS.USER_ENTITLEMENT, JSON.stringify(userEntitlements))
      navigate(APP_ROUTES.DASHBOARD)
    }
  }, [userAuthorizing, authorizedUser, authorizedUserPermissions])

  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch((error: string) => {
      const alert: AlertMessage = {
        message: error,
        options: {
          key: new Date().getTime() + Math.random(),
          persist: true,
          variant: ALERT_VARIANT.ERROR,
          anchorOrigin: {
            horizontal: ALERT_ORIGIN_HORIZONTAL_TYPE.CENTER
          }
        }
      }
      dispatch(alertActions.triggerAlert(alert))
    })
  }
  return (
    <section
      className={`${styles.container} container`}>
      <section className={styles.login}>
        <aside className={styles.loginRandomImage} />
        <aside className={styles.loginActions}>
          <div className={styles.logoWrapper}>
            <img alt="Enlightia" className={styles.logo} src={logo} />
          </div>
          <p>We are thrilled to have you onboard, and we are confident that our platform will
            enhance your productivity and enable seamless collaboration within your organization</p>
          <p>Get ready to unlock new possibilities and elevate your work experience!</p>
          <div className={styles.loginNotification}>
            {
              !!userAuthorizing.isLoading &&
              <p className={styles.isAuthorizing}>Authorizing...</p>
            }
            {
              !userAuthorizing.isLoading && userAuthorizing.error !== null &&
              <p className={styles.error}>{userAuthorizing.error}</p>
            }
          </div>
          <Button
            className={`${styles.primaryBtn} `}
            variant="contained"
            disabled={userAuthorizing?.isLoading}
            onClick={handleLogin}
          >
            Sign in
          </Button>
          <div className={styles.loginFooter}>
            <span className="f-14"> &copy; {today.getFullYear()} {APP_CONFIGS.APP_OWNER}</span>
          </div>
        </aside>
      </section>
    </section>
  )
}

export default Login
