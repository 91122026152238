import * as React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import {
  Chip,
  Divider,
  Grid,
  Typography
} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { Box } from '@mui/system'
import styles from './GoalViewCard.module.scss'
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined'
import moment from 'moment'
import { GOALS_TYPES, PRIORITY_TYPES } from '../../../utils/constants'
import { type Tags, type GoalListData, type AsyncStateObject, type GoalListResponse } from '../../../interfaces'
import { TableSkeleton } from '../../skeleton'

const GoalViewCard: React.FC<{
  pagination: React.ReactNode
  goalList: AsyncStateObject<GoalListResponse>
  goalType: GOALS_TYPES
  handleEditClick: (goal: GoalListData) => void
  statusGoalId: string
  handleClick: (event: React.MouseEvent<HTMLButtonElement>, goalId: string) => void
  handleClose: () => void
  menuOpen: boolean
  anchorEl: null | HTMLElement
  onGoalAccept: () => void
  onGoalRequestEdit: () => void
  onGoalReject: () => void
  disableStatusActionButton: (goal: GoalListData) => boolean
  disableEditButton: (goal: GoalListData) => boolean
}> = ({
  pagination,
  goalList,
  goalType,
  handleEditClick,
  statusGoalId,
  handleClick,
  handleClose,
  menuOpen,
  anchorEl,
  onGoalAccept,
  onGoalRequestEdit,
  onGoalReject,
  disableStatusActionButton,
  disableEditButton
}) => {
  const goals = goalList.data.data
  const goalIsLoading = goalList.isLoading

  const [activeCards, setActiveCards] = React.useState<any[]>([])

  const handleCardActive = (id: string) => {
    setActiveCards((prevActiveCards) => {
      // Check if the clicked card is already active
      const isActive = prevActiveCards.includes(id)

      // Create a new array based on the current state
      let newActiveCards

      if (isActive) {
        // Remove the clicked card from the active cards array
        newActiveCards = prevActiveCards.filter((cardId) => cardId !== id)
      } else {
        // Add the clicked card to the active cards array
        newActiveCards = [...prevActiveCards, id]
      }

      return newActiveCards
    })
  }

  return (
      <Card
        sx={{
          minWidth: 275,
          borderRadius: '1rem',
          border: '1px solid #DAE0E6',
          boxShadow: 'none'
        }}
      >
        {goalIsLoading && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
            <TableSkeleton/>
          </div>
        )}
        {!goalIsLoading && goals.length > 0 && goals.map((goal: GoalListData, index: number) => (
          <CardContent key={index}
            sx={{ borderBottom: '0.1rem solid #DAE0E6', boxShadow: 'none' }}
          >
            <Grid lg={12} md={12} xs={12} container display="flex" alignItems="center" >
              <Grid display="flex" item xs={12} md={4} lg={6}>
                <Grid xs={12}>
                  <Typography
                    fontWeight="600"
                    variant="body1"
                    align="left"
                    style={{ wordWrap: 'break-word' }}
                  >
                    {goal.name}
                  </Typography>
                  {
                    goal.tags.length > 0 && goal.tags.map((tag: Tags, index: number) => {
                      return <>
                        <Chip
                          id={styles.goalCardEmailSlogan}
                          label={tag.tag}
                          sx={{
                            borderRadius: '0.2rem',
                            marginTop: { xs: '1rem', md: '0.5rem' },
                            marginRight: '0.5rem'
                          }}
                        /></>
                    })
                  }
                </Grid>
              </Grid>
              <Grid xs={12} md={4} lg={3}
                display="flex" justifyContent="space-between" item sx={{ marginTop: { xs: '1rem', md: '1rem' } }} >
                <Grid xs={12}>
                  <Typography id={styles.DateRange} fontWeight="500" sx={{ whiteSpace: 'nowrap' }}>
                    {`${moment(goal.startDate).format('DD/MM/YYYY')} - ${moment(goal.endDate).format('DD/MM/YYYY')}`}
                  </Typography>
                </Grid>
              </Grid>
              <Grid xs={10} md={4} lg={3}
                display="flex" justifyContent="space-between"
                alignItems={'center'} item sx={{ marginTop: { xs: '1rem' } }} >
                <Grid xs={5} md={5} lg={3}>
                  <Typography
                    id={styles.Status}
                    fontWeight="500"
                    sx={{ minWidth: '5rem' }}
                  >
                    {goal.status}
                  </Typography>
                </Grid>

                <Grid xs={3} md={3} lg={2}>
                  <Box sx={{ display: 'flex', justifyContent: { xs: 'end', md: 'start', lg: 'start' } }}>
                    <Typography>
                      <Chip
                        id={goal.priority === PRIORITY_TYPES.HIGH
                          ? styles.cardChipHigh
                          : goal.priority === PRIORITY_TYPES.MEDIUM
                            ? styles.cardChipMedium
                            : styles.cardChipLow}
                        label={goal.priority}
                      />
                    </Typography>
                  </Box>
                </Grid>
                <Grid xs={1} md={2} lg={1}>
                  <Box display='flex'>
                    {
                      goalType === GOALS_TYPES.ALL_GOALS && (
                        <IconButton
                          id={styles.editIcon}
                          aria-controls={menuOpen ? 'basic-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={menuOpen ? 'true' : undefined}
                          onClick={() => { handleEditClick(goal) }}
                          disabled={disableEditButton(goal)}
                        >
                          <CreateOutlinedIcon fontSize='small' />
                        </IconButton>
                      )
                    }
                    {
                      goalType === GOALS_TYPES.MY_GOALS && (
                        <IconButton
                          id={styles.expandMenu}
                          aria-controls={menuOpen ? 'basic-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={menuOpen ? 'true' : undefined}
                          onClick={(event) => { handleClick(event, goal._id) }}
                          disabled={disableStatusActionButton(goal)}
                        >
                          <MoreVertIcon fontSize='small' />
                        </IconButton>
                      )
                    }
                  </Box>

                  {goal._id === statusGoalId && (
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={menuOpen}
                      onClose={handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button'
                      }}
                    >

                      <MenuItem onClick={onGoalAccept}>
                        Accept
                      </MenuItem>

                      <MenuItem onClick={onGoalRequestEdit} disabled={!goal.isEditable}>
                        Edit Requested
                      </MenuItem>

                      <MenuItem onClick={onGoalReject}>
                        Reject
                      </MenuItem>

                    </Menu>
                  )}
                </Grid>

                <Grid xs={1} md={2} lg={2}>
                  {activeCards.includes(goal._id)
                    ? (
                      <IconButton id={styles.expandMoreIcon}
                        onClick={() => { handleCardActive(goal._id) }}
                      >
                        <ExpandLessIcon fontSize='small' />
                      </IconButton>
                      )
                    : (
                      <IconButton id={styles.expandLessIcon}
                        onClick={() => { handleCardActive(goal._id) }}
                      >
                        <ExpandMoreIcon fontSize='small' />
                      </IconButton>
                      )}
                </Grid>
              </Grid>
            </Grid>
            {activeCards.includes(goal._id) && (
              <>
                <Box sx={{ padding: '1rem' }} id={goal.note === null ? styles.descriptionPadding : ''} >
                  <Divider sx={{ marginBottom: '1rem' }} />
                  <Typography
                    fontWeight="600"
                    variant="body1"
                    align="left"
                    style={{ wordWrap: 'break-word' }}
                  >
                    {goal.description}
                  </Typography>
                </Box>
                {goal.note !== null &&
                  <Box sx={{ paddingLeft: '1rem' }}>
                    <Box sx={{ padding: '1rem', wordWrap: 'break-word' }} id={styles.noteContainer}>
                      {goal.note}
                    </Box>
                  </Box>}
              </>
            )}
          </CardContent>
        ))}

        {!goalIsLoading && goals.length === 0 && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
            There are no matching goals
          </div>
        )}
        <Box
          sx={{ height: '5rem' }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {pagination}
        </Box>
      </Card>
  )
}

export default GoalViewCard
