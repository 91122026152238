import { Card, Chip, Grid, Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'

const GoalEngagementsCard: React.FC<{
  goalName: string
  goalDescription: string
  goalTags: any[]
  goalStartDate: string
  goalEndDate: string
  goalId: string
  openGoalModal: (goalId: string) => void
}> = ({
  goalName,
  goalDescription,
  goalTags,
  goalStartDate,
  goalEndDate,
  openGoalModal,
  goalId
}) => {
  return (
    <Card
      sx={{ padding: '1rem', marginBottom: '1rem' }}
      onClick={() => {
        openGoalModal(goalId)
      }}
      style={{ cursor: 'pointer' }}
    >
      <Typography fontWeight={600} fontSize="1.25rem">
        {goalName}
      </Typography>
      <Typography
        fontWeight={600}
        margin="1rem0 0 0"
        fontSize="1rem"
        color="#5F6D7E"
      >
        {goalDescription}
      </Typography>
      <Grid container margin="1rem 0 1rem 0">
        <Grid item sm={12} display="flex" flexWrap='wrap'>
          {goalTags.map((tag: any) => {
            return (
              <Chip
                key={tag.tagId}
                sx={{
                  backgroundColor: '#F5FAFF',
                  borderRadius: '0.5rem',
                  color: '#437EF7',
                  fontWeight: '600',
                  fontSize: '1rem',
                  margin: '0.5rem 0 0 0.5rem'
                }}
                variant="filled"
                label={tag.tag}
              />
            )
          })}
        </Grid>
      </Grid>
      <Typography fontWeight={600} color="#A1A1AA" variant="body1">
        {moment(goalStartDate).format('DD/MM/YYYY')}-
        {moment(goalEndDate).format('DD/MM/YYYY')}
      </Typography>
    </Card>
  )
}

export default GoalEngagementsCard
