import { combineReducers } from 'redux'
import { type DashboardState } from '../../../interfaces'
import coacheeGoalSummaryReducer from './coacheeGoalSummary.reducer'
import coacheeSessionSummaryReducer from './coacheeSessionSummary.reducer'
import coachGoalSummaryReducer from './coachGoalSummary.reducer'
import coachSessionSummaryReducer from './coachSessionSummary.reducer'
import coacheeRecentEngagementReducer from './coacheeRecentEngagement.reducer'
import coachRecentEngagementReducer from './coachRecentEngagement.reducer'
import coacheeStatsReducer from './coacheeStats.reducer'
import coachStatsReducer from './coachStats.reducer'
import supervisorStatsReducer from './supervisorStats.reducer'

const dashboardReducer = combineReducers<DashboardState>({
  coacheeGoalSummary: coacheeGoalSummaryReducer,
  coachGoalSummary: coachGoalSummaryReducer,
  coacheeSessionSummary: coacheeSessionSummaryReducer,
  coachSessionSummary: coachSessionSummaryReducer,
  coacheeRecentEngagement: coacheeRecentEngagementReducer,
  coachRecentEngagement: coachRecentEngagementReducer,
  coacheeStats: coacheeStatsReducer,
  coachStats: coachStatsReducer,
  supervisorStats: supervisorStatsReducer
})

export default dashboardReducer
