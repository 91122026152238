export const APP_CONFIGS = {
  APP_OWNER: 'Acentura Inc',
  APP_ENV: process.env.REACT_APP_ENV,
  API_BASE: process.env.REACT_APP_API_BASE,
  AUTHORITY: process.env.REACT_APP_AUTHORITY,
  CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
  REDIRECT_URL: process.env.REACT_APP_REDIRECT_URL,
  APP_SCOPES: process.env.REACT_APP_SCOPES,
  USER_INFO_COOKIE: 'coaching-app-user-info',
  USER_PERMISSIONS: 'coaching-app-user-permissions',
  USER_ENTITLEMENT: 'user-entitlements',
  LOGGED_USER: 'logged-User',
  IS_LOGGED: 'is-logged'
}

export const SECRET_KEY = '1234'
