/* eslint-disable max-len */
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  TextField,
  Typography
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  type ReviewQuestion,
  type AppState,
  type EngagementDetailsRequestParam
} from '../../interfaces'
import { APP_ACTION_STATUS, FEEDBACK_SCREEN_MODE, getViewSessionRoute } from '../../utils/constants'
import styles from './FeedBackView.module.scss'
import {
  type ViewCoacheeFeedbackParam,
  type CoacheeFeedback,
  type CoacheeFeedbackPayload,
  type ViewFeedbackQnA,
  type ViewFeedbackQnAOptions,
  type FeedBackRequestParams
} from '../../interfaces/feedBack'
import { engagementsActions, feedBackActions } from '../../redux/actions'
import { useNavigate, useParams } from 'react-router-dom'
import { FeedbackViewSkelton, SomethingWentWrong } from '../../components'
import { isNullOrUndefinedOrEmpty } from '../../utils/helpers'

const FeedBackView = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { engagementId, sessionId, roleType, user } =
  useParams<{ engagementId: string, sessionId: string, roleType: string, user: string }>()
  const [questionList, setquestionList] = useState<ReviewQuestion[]>([])
  const [noDataNotification, setNoDataNotification] = useState<string>('')
  const [screenMode, setScreenMode] = useState('')
  const [isErrorFetchData, setIsErrorFetchData] = useState<string>('')

  const getFeedbackDetailsResponse = useSelector((state: AppState) => state.feedbacks.feedbackDetails)
  const addFeedbackResponse = useSelector((state: AppState) => state.feedbacks.addFeedback)
  const viewFeedbackResponse = useSelector((state: AppState) => state.feedbacks.viewFeedback)
  const engagementDetailsResponse = useSelector((state: AppState) => state.engagements.engagementDetails)
  const authorizedUser = useSelector((state: AppState) => state.authUser.authorizedUser)
  useEffect(() => {
    onInit()
    return () => {
      setIsErrorFetchData('')
      dispatch(feedBackActions.addFeedbackClear())
      dispatch(feedBackActions.clearGetFeedBackDetails())
      dispatch(feedBackActions.clearViewFeedback())
      dispatch(engagementsActions.clearGetEngagementDetailById())
    }
  }, [])

  const onInit = () => {
    // THIS CALL FOR VALIDATE PUPROPSE OF ENGAGEMENTID
    const param: EngagementDetailsRequestParam = {
      pathParam: { id: engagementId ?? '' },
      queryParam: { roleType: roleType ?? '' }
    }
    dispatch(engagementsActions.engagementDetails(param))

    if (isNullOrUndefinedOrEmpty(user)) {
      // FEEDBACK PAGE LOAD FOR GIVE FEEDBACK TO THE COACH FROM COACHEE
      setScreenMode(FEEDBACK_SCREEN_MODE.CREATE)
      const viewFeedbackParams: FeedBackRequestParams = {
        id: sessionId ?? ''
      }
      dispatch(feedBackActions.getFeedBackDetails(viewFeedbackParams))
    } else {
      // FEEDBACK PAGE LOAD FOR VIEW FEEDBACK
      setScreenMode(FEEDBACK_SCREEN_MODE.VIEW)
      const _param: ViewCoacheeFeedbackParam = {
        id: sessionId ?? '',
        coachee: user ?? ''
      }
      dispatch(feedBackActions.viewFeedback(_param))
    }
  }

  useEffect(() => {
    if (engagementDetailsResponse?.status === APP_ACTION_STATUS.ERROR) {
      setIsErrorFetchData(engagementDetailsResponse?.error ?? 'Something went wrong when fetching data.!')
    }
    if (viewFeedbackResponse?.status === APP_ACTION_STATUS.ERROR) {
      setIsErrorFetchData(viewFeedbackResponse.error ?? 'Something went wrong when fetching data.!')
    }
    if (getFeedbackDetailsResponse?.status === APP_ACTION_STATUS.ERROR) {
      setIsErrorFetchData(getFeedbackDetailsResponse.error ?? 'Something went wrong when fetching data.!')
    }
  }, [engagementDetailsResponse?.status, viewFeedbackResponse?.status, getFeedbackDetailsResponse?.status])

  useEffect(() => {
    if (viewFeedbackResponse.status === APP_ACTION_STATUS.SUCCESS) {
      if (viewFeedbackResponse?.data !== null) {
        setquestionList(
          viewFeedbackResponse.data.questionsAndAnswers.map((item: ViewFeedbackQnA) => {
            return {
              type: item.type,
              description: item.description,
              questionOrder: item.questionOrder,
              questionLabel: item.questionLabel,
              answer: item.options.find((o) => o.isSelected)?.optionOrder,
              comment: item.comment,
              isError: false,
              options: item.options.map((o: ViewFeedbackQnAOptions) => {
                return {
                  optionLabel: o.optionLabel,
                  optionOrder: o.optionOrder
                }
              })
            }
          })
        )
      } else {
        setquestionList([])
        setNoDataNotification('Feedback questions are not available for this session')
      }
    }
  }, [viewFeedbackResponse.status])

  useEffect(() => {
    if (getFeedbackDetailsResponse.status === APP_ACTION_STATUS.SUCCESS) {
      if (getFeedbackDetailsResponse?.data !== null && getFeedbackDetailsResponse.data.reviewQuestions.length > 0) {
        setquestionList(
          getFeedbackDetailsResponse.data.reviewQuestions.map((item: ReviewQuestion) => {
            return {
              ...item,
              answer: -1,
              comment: '',
              isError: false
            }
          })
        )
      } else {
        setquestionList([])
        setNoDataNotification('Feedback questions are not available for this session')
      }
    }
  }, [getFeedbackDetailsResponse.status])

  const addFeedbackAnswer = (_questionOrder: number, answer: number) => {
    setquestionList(questionList.map((q: ReviewQuestion) => {
      return {
        ...q,
        answer: q.questionOrder === _questionOrder ? answer : q.answer
      }
    }))
  }
  const addFeedbackComment = (_questionOrder: number, comment: string) => {
    setquestionList(questionList.map((q: ReviewQuestion) => {
      return {
        ...q,
        comment: q.questionOrder === _questionOrder ? comment : q.comment
      }
    }))
  }

  const onSubmitAnswers = () => {
    setquestionList(questionList.map((q: ReviewQuestion) => {
      return {
        ...q,
        isError: q.answer === -1
      }
    }))

    if (!questionList.some((q) => q.answer === -1)) {
      const _feedbacks = questionList.map<CoacheeFeedback>((q) => {
        return {
          optionOrder: q.answer ?? -1,
          questionOrder: q.questionOrder ?? -1,
          comment: q.comment ?? ''
        }
      })

      const _payload: CoacheeFeedbackPayload = {
        pathParam: {
          id: sessionId ?? '',
          coachee: authorizedUser?.data?._id,
          review: getFeedbackDetailsResponse?.data?._id
        },
        requestBody: _feedbacks
      }

      dispatch(feedBackActions.addFeedback(_payload))
    }
  }

  useEffect(() => {
    if (addFeedbackResponse.status === APP_ACTION_STATUS.SUCCESS) {
      navigate(getViewSessionRoute(engagementId ?? '', sessionId ?? '', roleType ?? ''), { replace: true })
    }
  }, [addFeedbackResponse.status])

  const onClose = () => {
    navigate(getViewSessionRoute(engagementId ?? '', sessionId ?? '', roleType ?? ''), { replace: true })
  }

  return (
    <React.Fragment>
      <section className='page-root' >
        <Card className='section-card'>
          <CardContent>
            <section className='section-card-header'>
              <Typography fontWeight="600" variant='h6'>
                {
                  ((Boolean(viewFeedbackResponse.isLoading)) || (Boolean(getFeedbackDetailsResponse.isLoading)))
                    ? 'Feedbacks : '
                    : screenMode === FEEDBACK_SCREEN_MODE.VIEW
                      ? viewFeedbackResponse?.data !== null
                        ? `Feedbacks : ${viewFeedbackResponse.data.sessionName ?? ''}`
                        : 'Feedbacks'
                      : `Feedbacks : ${getFeedbackDetailsResponse.data.sessionName ?? ''}`
                }
              </Typography>
            </section>
            <section className='section-card-body'>
              <Alert severity="info" sx={{ marginBottom: '1rem' }}>
                We value your feedback! Please rate your experience on a scale of 1 to 5,
                where 1 stands for &apos;Poor&apos; and 5 stands for &apos;Excellent&apos;.
                Your rating helps us improve our services to serve you better.
                Thank you for taking the time to share your thoughts!
              </Alert>
              <>
                {(getFeedbackDetailsResponse.isLoading || viewFeedbackResponse.isLoading) && <FeedbackViewSkelton />}
                {!(getFeedbackDetailsResponse.isLoading || viewFeedbackResponse.isLoading) &&
                  <>
                    {!isNullOrUndefinedOrEmpty(isErrorFetchData) &&
                      <SomethingWentWrong
                        isShowDefault={false}
                        subNotification={isErrorFetchData}
                      />
                    }
                    {isNullOrUndefinedOrEmpty(isErrorFetchData) &&
                      <>
                        {(questionList.length > 0 && questionList.map((question: ReviewQuestion, questionIndex: number) => (
                          <>
                            <Typography key={questionIndex} style={{ paddingTop: questionIndex + 1 !== 1 ? '10px' : '0px' }}>
                              <Typography variant="subtitle1"
                                className={(question.isError === true) ? styles.errorQuestion : styles.normalQuestion}>
                                {questionIndex + 1}. {question.questionLabel} *
                              </Typography>
                              <Typography style={{ display: 'flex', gap: '10px', padding: '15px 0px' }}>
                                {question.options?.map((rating) => (
                                  <Button
                                    key={rating.optionOrder}
                                    className={question.answer === rating.optionOrder ? styles.selected : styles.unSelected}
                                    onClick={() => { addFeedbackAnswer(question.questionOrder ?? -1, rating.optionOrder ?? -1) }}
                                    disabled={screenMode === FEEDBACK_SCREEN_MODE.VIEW}
                                  >
                                    {rating.optionLabel}
                                  </Button>
                                ))}
                              </Typography>
                              <Typography>
                                <TextField
                                  label="Comment"
                                  variant="outlined"
                                  fullWidth
                                  size='small'
                                  disabled={screenMode === FEEDBACK_SCREEN_MODE.VIEW}
                                  value={question.comment}
                                  onChange={(e) => { addFeedbackComment(question.questionOrder ?? -1, e.target.value) }}
                                />
                              </Typography>
                            </Typography>
                            {questionIndex + 1 !== questionList.length ? <Divider sx={{ py: 1 }} /> : <></>}
                          </>
                        )))}
                        {!(getFeedbackDetailsResponse.isLoading || viewFeedbackResponse.isLoading) &&
                          (questionList.length === 0 &&
                            <SomethingWentWrong
                              isShowDefault={false}
                              subNotification={noDataNotification}
                            />
                          )
                        }
                      </>
                    }
                  </>
                }
              </>
            </section>
            <section className='section-card-footer'>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                <Box sx={{ flex: '1 1 auto' }} />
                  {screenMode === FEEDBACK_SCREEN_MODE.CREATE &&
                    <Button
                      variant="contained"
                      color="primary"
                      size="medium"
                      sx={{ textTransform: 'capitalize' }}
                      onClick={onSubmitAnswers}
                      disabled={questionList.length === 0}
                    >
                      Submit
                    </Button>
                  }
                  <Button
                    variant="contained"
                    color="secondary"
                    size="medium"
                    sx={{ textTransform: 'capitalize' }}
                    onClick={onClose}
                  >
                    Close
                  </Button>
              </Box>
            </section>
          </CardContent>
        </Card>
      </section>
    </React.Fragment>
  )
}

export default FeedBackView
