import { Box, Card, Grid, Typography, List, CardActions, CardContent, Divider, LinearProgress } from '@mui/material'
import React from 'react'
import Link from '@mui/material/Link'
import styles from './EngagementAddContent.module.scss'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { type Attachments } from '../../../interfaces'
import attachmentImageHandler from '../../../utils/helpers/attachmentImageHandler'

const EngagementAddContent: React.FC<{
  isLoading: boolean
  myFiles: any
  updateFiles: Attachments[]
  selectedAttachment: Attachments
  openFileInNewTab: (index: number) => void
  fileDownload: (id: string) => void
  removeFile: (file: any) => void
  removeFileFromUploaded: (file: Attachments) => void
  getRootProps: any
  getInputProps: any
  open: any
  errorMessage?: string
}> = ({
  isLoading, myFiles, updateFiles, openFileInNewTab, selectedAttachment,
  fileDownload, removeFile, removeFileFromUploaded, getRootProps, getInputProps,
  open, errorMessage
}) => {
  const myUploadedFiles = updateFiles.map((file: Attachments, index: number) => (
      <Grid key={file.attachmentId} item sx={{ width: '20rem' }}>
        <Card>
          <CardContent sx={{ minHeight: '5rem' }}>
            <Box display='flex' justifyContent='center'>
              <Grid container>
                <Grid item xs={2}>
                <img alt="Attachment" src={attachmentImageHandler(file.attachmentTitle)} />
                </Grid>
                <Grid item xs={10}>
                  <Box display='flex' flexDirection='column' alignItems='start'>
                    <Typography
                      className={styles.fileName}
                      justifyContent='center'
                      fontWeight={500} fontSize='1rem'>
                      {file.attachmentTitle}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
          <Divider />
          {isLoading && selectedAttachment.attachmentId === file.attachmentId &&
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>
          }
          <CardActions disableSpacing>
            <Grid container>
              <Grid item xs={10} />
              <Grid item xs={2} display='flex' justifyContent='space-between'>
                <Box style={{ cursor: 'pointer' }}>
                  <FileDownloadOutlinedIcon color='action' onClick={() => { fileDownload(file.attachmentId ?? '') }} />
                </Box>
                <Box style={{ cursor: 'pointer' }}>
                  <DeleteOutlineOutlinedIcon color='action' onClick={() => { removeFileFromUploaded(file) }} />
                </Box>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </Grid>
  ))
  const _myFiles = myFiles.map((file: any, index: number) => (
      <Grid key={file.path} item sx={{ width: '20rem' }}>
        <Card>
          <CardContent sx={{ minHeight: '5rem' }}>
            <Box display='flex' justifyContent='center'>
              <Grid container>
                <Grid item xs={2}>
                  <img alt="Attachment" src={attachmentImageHandler(file.name)} />

                </Grid>
                <Grid item xs={10}>
                  <Box display='flex' flexDirection='column' alignItems='start'>
                    <Typography
                      className={styles.fileName}
                      justifyContent='center'
                      fontWeight={500} fontSize='1rem'>
                      {file.name}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
          <Divider></Divider>
          <CardActions disableSpacing>
            <Grid container>
              <Grid item xs={10} />
              <Grid item xs={2} display='flex' justifyContent='space-between'>
                <Box style={{ cursor: 'pointer' }}>
                  <FileDownloadOutlinedIcon color='action' onClick={() => { openFileInNewTab(index) }} />
                </Box>
                <Box style={{ cursor: 'pointer' }}>
                  <DeleteOutlineOutlinedIcon color='action' onClick={() => { removeFile(file) }} />
                </Box>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </Grid>
  ))

  return (
      <>
        <Box
          sx={{
            border: '1px dashed #DAE0E6',
            minHeight: '10rem',
            width: '100%'
          }}
        >
          <Box display='flex'
            justifyContent='center'
            {...getRootProps({
              sx: {
                border: 'none',
                backgroundColor: '#fff'
              }
            })}
          >
            <Box><input {...getInputProps()} /></Box>
            <Box display='flex' flexDirection='column'>
              <Box display='flex' justifyContent='center' marginTop='2rem'>
                <AddCircleOutlineOutlinedIcon
                  onClick={open}
                  fontSize='large'
                  color='primary'
                  id={styles.addCircleIcon}
                />
              </Box>
              <Typography display='flex' justifyContent='center' fontWeight={600} variant="h6" component="p">
                Drop your files here
              </Typography>
              <Box display='flex' justifyContent='center' alignItems={'center'}>
                <Typography variant="h6" component="p">
                  <Link underline="hover" onClick={open} sx={{ cursor: 'pointer' }}>
                    Browse Files
                  </Link>
                </Typography>
                <Typography marginLeft='1rem'>
                  from your computer
                </Typography>
              </Box>
              <Box display='flex' justifyContent='center' marginBottom='2rem'>
                <Typography fontWeight={600} color='text.secondary'>
                Supported file formats are .docx, .pdf, .xlsx, .pptx, .csv, .zip, .rar and maximum file size is 20MB
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Grid container sx={{ mt: 2 }}>
          <List sx={{ display: 'flex', justifyContent: 'center', gap: '1rem', flexWrap: 'wrap' }}>
            {myUploadedFiles}{_myFiles}
          </List>
        </Grid>
        {(errorMessage != null)
          ? <Typography display='flex' color='red'
            justifyContent='center' fontWeight={600} variant="h6" component="p">
            {errorMessage}
          </Typography>
          : ''}
      </>
  )
}

export default EngagementAddContent
