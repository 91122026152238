import {
  type AssessmentSubmitPayload,
  type Action, type AssessmentDetailsParams,
  type AssessmentResultPathParams
} from '../../interfaces'
import { ASSESSMENT_ACTIONS, COMMON_ACTIONS } from '../../utils/constants'

const assessmentDetails = (params: AssessmentDetailsParams): Action<AssessmentDetailsParams> => {
  return { type: ASSESSMENT_ACTIONS.GET_ASSESSMENT_DETAILS_BY_ID + COMMON_ACTIONS.REQUEST, data: params }
}
const clearAssessmentDetails = (): Action<null> => {
  return { type: ASSESSMENT_ACTIONS.GET_ASSESSMENT_DETAILS_BY_ID + COMMON_ACTIONS.CLEAR, data: null }
}

const assessmentSubmit = (payload: AssessmentSubmitPayload): Action<AssessmentSubmitPayload> => {
  return { type: ASSESSMENT_ACTIONS.SUBMIT_ASSESSMENT + COMMON_ACTIONS.REQUEST, data: payload }
}

const assessmentResultDetails = (params: AssessmentResultPathParams): Action<AssessmentResultPathParams> => {
  return { type: ASSESSMENT_ACTIONS.GET_ASSESSMENT_RESULT_DETAILS + COMMON_ACTIONS.REQUEST, data: params }
}

const clearAssessmentResultDetails = (): Action<null> => {
  return { type: ASSESSMENT_ACTIONS.GET_ASSESSMENT_RESULT_DETAILS + COMMON_ACTIONS.CLEAR, data: null }
}

const clearAssessmentSubmit = (): Action<null> => {
  return { type: ASSESSMENT_ACTIONS.SUBMIT_ASSESSMENT + COMMON_ACTIONS.CLEAR, data: null }
}

export const assessmentActions = {
  assessmentDetails,
  assessmentSubmit,
  assessmentResultDetails,
  clearAssessmentResultDetails,
  clearAssessmentSubmit,
  clearAssessmentDetails
}
