import { Box, Skeleton } from '@mui/material'
import React from 'react'

const FormFeildViewSkelton = () => {
  return (
    <Box display='flex' flexDirection='column' gap={1}>
      <Box><Skeleton animation="wave" variant="rounded" width='100%' height='2rem' /></Box>
      <Box display='flex' flexDirection='column' gap={2} mt={'2rem'}>
        <Box><Skeleton animation="wave" variant="rounded" width='30%' height='1.5rem' /></Box>
        <Box><Skeleton animation="wave" variant="rounded" width='100%' height='2rem' /></Box>
      </Box>
      <Box display='flex' flexDirection='column' gap={2} mt={'2rem'}>
        <Box><Skeleton animation="wave" variant="rounded" width='30%' height='1.5rem' /></Box>
        <Box><Skeleton animation="wave" variant="rounded" width='100%' height='2rem' /></Box>
      </Box>
      <Box display='flex' flexDirection='column' gap={2} mt={'2rem'}>
        <Box><Skeleton animation="wave" variant="rounded" width='30%' height='1.5rem' /></Box>
        <Box><Skeleton animation="wave" variant="rounded" width='100%' height='2rem' /></Box>
      </Box>
      <Box display='flex' flexDirection='column' gap={2} mt={'2rem'}>
        <Box><Skeleton animation="wave" variant="rounded" width='30%' height='1.5rem' /></Box>
        <Box><Skeleton animation="wave" variant="rounded" width='100%' height='2rem' /></Box>
      </Box>
      <Box display='flex' flexDirection='column' gap={2} mt={'2rem'}>
        <Box><Skeleton animation="wave" variant="rounded" width='30%' height='1.5rem' /></Box>
        <Box><Skeleton animation="wave" variant="rounded" width='100%' height='2rem' /></Box>
      </Box>
    </Box>
  )
}

export default FormFeildViewSkelton
