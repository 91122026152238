/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { type Action } from '../../interfaces'
import { COMMON_ACTIONS, STATUS_ACTIONS } from '../../utils/constants'

const getGoalStatus = (): Action<null> => {
  return { type: STATUS_ACTIONS.GET_GOAL_STATUS_LIST + COMMON_ACTIONS.REQUEST, data: null }
}
const getEngagementStatus = (): Action<null> => {
  return { type: STATUS_ACTIONS.GET_ENGAGEMENT_STATUS_LIST + COMMON_ACTIONS.REQUEST, data: null }
}

const getSessionStatus = (): Action<null> => {
  return { type: STATUS_ACTIONS.GET_SESSION_STATUS_LIST + COMMON_ACTIONS.REQUEST, data: null }
}

export const statusAction = {
  getGoalStatus,
  getEngagementStatus,
  getSessionStatus
}
