import { type AxiosResponse } from 'axios'
import { axiosPrivateAPI } from '.'
import { type CoachList } from '../interfaces/coachList'

const getSessionCoachList = async (
  engagementId: string
): Promise<AxiosResponse<CoachList[]>> => {
  return await axiosPrivateAPI.get(
    `/api/v1/engagements/${engagementId}/coaches`
  )
}
export const commonService = {
  getSessionCoachList
}
