import React, { useEffect, useState } from 'react'
import {
  DashboardCoachView,
  DashboardCoacheeView,
  DashboardHeader,
  DashboardSupervisorView
} from '../../components/dashboard'
import {
  APP_TABLE_CONFIGS,
  DASHBOARD_VIEW_TYPES,
  GOAL_SUMMARY_ROWS_COUNT,
  SESSION_SUMMARY_ROWS_COUNT
} from '../../utils/constants'
import { useDispatch, useSelector } from 'react-redux'
import {
  type CoachSessionSummaryRequestParam,
  type AppState,
  type CoacheeSessionSummaryRequestParam,
  type CoacheeGoalSummaryRequestParam,
  type CoachGoalSummaryRequestParam,
  type DropdownOptionnDto,
  type AllEngagementRequestPayload
} from '../../interfaces'
import { dashboardActions, engagementsActions, statusAction } from '../../redux/actions'
import { isNullOrUndefinedOrEmpty } from '../../utils/helpers'

const Dashboard = () => {
  const INITIAL_GOAL_STATUS: DropdownOptionnDto = { id: -1, name: '' }
  const INITIAL_ENGAGEMENT_STATUS: DropdownOptionnDto = { id: -1, name: '' }

  const dispatch = useDispatch()

  const [viewType, setViewType] = useState<DASHBOARD_VIEW_TYPES>(DASHBOARD_VIEW_TYPES.COACHEE)
  const [goalStatus, setGoalStatus] = useState<{ id: number, name: string }>(INITIAL_GOAL_STATUS)
  const [engagementStatus, setEngagementStatus] = useState<{ id: number, name: string }>(INITIAL_ENGAGEMENT_STATUS)

  const authorizedUser = useSelector((state: AppState) => state.authUser.authorizedUser)

  const coacheeStatsResponse = useSelector((state: AppState) => state.dashboard.coacheeStats)
  const coacheeSessionSummaryDataResponse = useSelector((state: AppState) => state.dashboard.coacheeSessionSummary)
  const coacheeGoalSummaryDataResponse = useSelector((state: AppState) => state.dashboard.coacheeGoalSummary)
  const recentCoacheeEngagementResponse =
    useSelector((state: AppState) => state.dashboard.coacheeRecentEngagement)

  const coachStatsResponse = useSelector((state: AppState) => state.dashboard.coachStats)
  const coachSessionSummaryDataResponse = useSelector((state: AppState) => state.dashboard.coachSessionSummary)
  const coachGoalSummaryDataResponse = useSelector((state: AppState) => state.dashboard.coachGoalSummary)
  const recentCoachEngagementResponse =
    useSelector((state: AppState) => state.dashboard.coachRecentEngagement)

  const supervisorStatsResponse = useSelector((state: AppState) => state.dashboard.supervisorStats)
  const supervisorEngagementSummaryResponse = useSelector((state: AppState) => state.engagements.getAllEngagementList)

  useEffect(() => {
    onInit()
  }, [])

  const onInit = () => {
    dispatch(statusAction.getGoalStatus())
    dispatch(statusAction.getEngagementStatus())
    dispatch(dashboardActions.getCoacheeStats())
    dispatch(dashboardActions.getCoacheeRecentEngagement())
    getCoacheeSessionSummary()
    getCoacheeGoalSummary()
  }

  const getCoacheeSessionSummary = () => {
    const param: CoacheeSessionSummaryRequestParam = {
      limit: SESSION_SUMMARY_ROWS_COUNT
    }
    dispatch(dashboardActions.getCoacheeSessionSummary(param))
  }
  const getCoachSessionSummary = () => {
    const param: CoachSessionSummaryRequestParam = {
      limit: SESSION_SUMMARY_ROWS_COUNT
    }
    dispatch(dashboardActions.getCoachSessoinSummary(param))
  }

  const getCoacheeGoalSummary = (status?: number) => {
    const param: CoacheeGoalSummaryRequestParam = {
      statusId: status,
      limit: GOAL_SUMMARY_ROWS_COUNT
    }
    dispatch(dashboardActions.getCoacheeGoalSummary(param))
  }
  const getCoachGoalSummary = (status?: number) => {
    const param: CoachGoalSummaryRequestParam = {
      statusId: status,
      limit: GOAL_SUMMARY_ROWS_COUNT
    }
    dispatch(dashboardActions.getCoachGoalSummary(param))
  }

  const getSupervisorEngagementSummary = (status?: number) => {
    const payload: AllEngagementRequestPayload = {
      pathParam: {
        username: authorizedUser?.data?.username
      },
      requestBody: {
        statusIds: status !== undefined ? [status] : undefined,
        searchField: '',
        limit: APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE,
        page: 1
      }

    }
    dispatch(engagementsActions.getAllEngagementList(payload))
  }

  const onHandleGoalStatusChange = (value: DropdownOptionnDto) => {
    setGoalStatus(value)
    if (viewType === DASHBOARD_VIEW_TYPES.COACHEE) {
      if (value.id !== -1) getCoacheeGoalSummary(value.id)
      else getCoacheeGoalSummary()
    }
    if (viewType === DASHBOARD_VIEW_TYPES.COACH) {
      if (value.id !== -1) getCoachGoalSummary(value.id)
      else getCoachGoalSummary()
    }
  }

  const onHandleEngagementStatusChange = (value: DropdownOptionnDto) => {
    setEngagementStatus(value)
    if (value.id !== -1) getSupervisorEngagementSummary(value.id)
    else getSupervisorEngagementSummary()
  }

  const handleChangeViewType = (event: React.MouseEvent<HTMLElement>, type: DASHBOARD_VIEW_TYPES) => {
    if (!isNullOrUndefinedOrEmpty(type)) {
      setViewType(type)
      if (type === DASHBOARD_VIEW_TYPES.COACHEE) {
        dispatch(dashboardActions.getCoacheeStats())
        dispatch(dashboardActions.getCoacheeRecentEngagement())
        getCoacheeSessionSummary()
        getCoacheeGoalSummary()
        onClearSupervisorData()
        onClearCoachData()
      }
      if (type === DASHBOARD_VIEW_TYPES.COACH) {
        dispatch(dashboardActions.getCoachStats())
        dispatch(dashboardActions.getCoachRecentEngagement())
        getCoachSessionSummary()
        getCoachGoalSummary()
        onClearSupervisorData()
        onClearCoacheeData()
      }
      if (type === DASHBOARD_VIEW_TYPES.SUPERVISOR) {
        dispatch(dashboardActions.getSupervisorStats())
        getSupervisorEngagementSummary()
        onClearCoacheeData()
        onClearCoachData()
      }
    }
  }

  const onClearCoacheeData = () => {
    dispatch(dashboardActions.clearGetCoacheeGoalSummary())
    dispatch(dashboardActions.clearGetCoacheeSessionSummary())
    dispatch(dashboardActions.clearGetCoacheeStats())
    dispatch(dashboardActions.clearGetCoacheeRecentEngagement())
  }
  const onClearCoachData = () => {
    dispatch(dashboardActions.clearGetCoachGoalSummary())
    dispatch(dashboardActions.clearGetCoachSessoinSummary())
    dispatch(dashboardActions.clearGetCoachStats())
    dispatch(dashboardActions.clearGetCoachRecentEngagement())
  }
  const onClearSupervisorData = () => {
    dispatch(dashboardActions.clearGetSupervisorStats())
  }

  return (
    <React.Fragment>
      <DashboardHeader
        viewType={viewType}
        handleChangeViewType={handleChangeViewType}
      />
      <section className='page-root-dashboard' >
        {viewType === DASHBOARD_VIEW_TYPES.COACHEE &&
          <DashboardCoacheeView
            coacheeStatsResponse={coacheeStatsResponse}
            recentCoacheeEngagementResponse={recentCoacheeEngagementResponse}
            coacheeSessionSummaryDataResponse={coacheeSessionSummaryDataResponse}
            coacheeGoalSummaryDataResponse={coacheeGoalSummaryDataResponse}
            goalStatus={goalStatus}
            onHandleStatusChange={onHandleGoalStatusChange}
          />
        }
        {viewType === DASHBOARD_VIEW_TYPES.COACH &&
          <DashboardCoachView
            coachStatsResponse={coachStatsResponse}
            recentCoachEngagementResponse={recentCoachEngagementResponse}
            coachSessionSummaryDataResponse={coachSessionSummaryDataResponse}
            coachGoalSummaryDataResponse={coachGoalSummaryDataResponse}
            goalStatus={goalStatus}
            onHandleStatusChange={onHandleGoalStatusChange}
          />
        }
        {viewType === DASHBOARD_VIEW_TYPES.SUPERVISOR &&
          <DashboardSupervisorView
            supervisorStatsResponse={supervisorStatsResponse}
            supervisorEngagementSummaryResponse={supervisorEngagementSummaryResponse}
            engagementStatus={engagementStatus}
            onHandleStatusChange={onHandleEngagementStatusChange}
          />
        }
      </section>
    </React.Fragment>
  )
}

export default Dashboard
