import { combineReducers } from 'redux'
import { type AppState } from '../../interfaces/app'
import sideBarItemReducer from './sideBars/sideBarItem'
import goalReducer from './goals'
import hierarchyReducer from './hierarchies'
import priorityReducer from './priorities'
import statusReducer from './status'
import fileReducer from './files'
import engagementReducer from './engagements'
import alertReducer from './alert'
import sessionReducer from './session'
import assessmentReducer from './assessments'
import feedBackReducer from './feedBack'
import coachListReducer from './coachList'
import authReducer from './auth'
import userReducer from './users'
import dashboardReducer from './dashboard'
import notesReducer from './notes'

const rootReducer = combineReducers<AppState>({
  sideBar: sideBarItemReducer,
  goals: goalReducer,
  hierarchy: hierarchyReducer,
  priority: priorityReducer,
  status: statusReducer,
  files: fileReducer,
  engagements: engagementReducer,
  session: sessionReducer,
  alerts: alertReducer,
  assessments: assessmentReducer,
  feedbacks: feedBackReducer,
  coachList: coachListReducer,
  authUser: authReducer,
  user: userReducer,
  dashboard: dashboardReducer,
  notes: notesReducer
})

export default rootReducer
