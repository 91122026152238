import { type OrgAndRoleHierarchy } from '../../interfaces'

const filterBy = (arr: OrgAndRoleHierarchy[], searchKey: string): OrgAndRoleHierarchy[] => {
  return searchKey !== ''
    ? arr.reduce((acc: OrgAndRoleHierarchy[], item: any) => {
      if (item.subLevels !== undefined && item.subLevels.length > 0) {
        const filtered = filterBy(item.subLevels, searchKey)
        if (filtered !== undefined && filtered?.length > 0) return [...acc, { ...item, subLevels: filtered }]
      }
      const { subLevels, ...itemWithoutSubLevels } = item
      return item.levelName.toLowerCase().includes(searchKey.toLowerCase()) === true
        ? [...acc, itemWithoutSubLevels]
        : acc
    }, [])
    : arr
}

export default filterBy
