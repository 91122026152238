import axios, { type AxiosResponse } from 'axios'
import { axiosPrivateAPI, axiosPublicAPI } from '.'
import {
  type SaveFileRequestPayload,
  type FileUploadURLParams,
  type DownloadFileResponse
} from '../interfaces'
import { type DeleteFileRequestParam, type DeleteFileResponse } from '../interfaces/fileDelete'

/*
  FileUploadURLResponse need to apply this type with correct sub type in future
 */
const getFileUploadURL = async (params: FileUploadURLParams): Promise<AxiosResponse<any>> => {
  const queryParams = {
    filename: params.filename
  }
  return await axiosPrivateAPI.get(`/api/v1/files/${params.type}`, { params: queryParams })
}
const downloadFile = async (params: string): Promise<AxiosResponse<DownloadFileResponse>> => {
  const param = new URLSearchParams()
  param.append('key', params)
  const value = await axiosPrivateAPI.get(`/api/v1/files?${param.toString()}`)
  return value
}

const downloadFileAs = async (url: string): Promise<AxiosResponse<Blob>> => {
  return await axios.get(url, {
    headers: {
      'Content-Type': 'application/octet-stream'
    },
    responseType: 'blob'
  })
}

/*
  This is the actual Service that file is uploading
  Need to define the actual Types for bellow service method upto now keep it as
  type any but before goto QA need define the type
*/

const saveFileToS3 = async (payload: SaveFileRequestPayload): Promise<AxiosResponse<any>> => {
  const payloadFormData = new FormData()

  payloadFormData.append('key', payload.key)
  payloadFormData.append('username', payload.username)
  payloadFormData.append('bucket', payload.bucket)
  payloadFormData.append('X-Amz-Algorithm', payload['X-Amz-Algorithm'])
  payloadFormData.append('X-Amz-Credential', payload['X-Amz-Credential'])
  payloadFormData.append('X-Amz-Date', payload['X-Amz-Date'])
  payloadFormData.append('Policy', payload.Policy)
  payloadFormData.append('X-Amz-Signature', payload['X-Amz-Signature'])
  payloadFormData.append('file', payload.file)

  return await axiosPublicAPI.post(`https://s3.ap-southeast-1.amazonaws.com/${payload.bucket}`, payloadFormData)
}

const deleteFiles = async (params: DeleteFileRequestParam): Promise<AxiosResponse<DeleteFileResponse>> => {
  return await axiosPrivateAPI.delete('api/v1/files', { params })
}

export const fileService = {
  getFileUploadURL,
  saveFileToS3,
  downloadFile,
  downloadFileAs,
  deleteFiles
}
