
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom'
import {
  Profile, GoalView, GoalCreate,
  CreateEngagement, EngagementView,
  SessionView, AssessmentView, FeedBackGroupView, Login, Dashboard, CoacheesView, Notifications, Events, Notes
} from '../pages'
import { APP_FEATURES, APP_ROUTES, BREAD_CRUMB, FEATURE_PERMISSION } from '../utils/constants'
import React from 'react'
import SessionCreate from '../pages/SessionCreate'
import EngagementViewById from '../pages/EngagementViewById'
import { UnAuthorized, NotFound } from '../components'
import AssessmentResultView from '../pages/AssessmentResultView'
import FeedBackView from '../pages/FeedBackView'
import PrivateRoute from './PrivateRoute'
import { AppLayout } from '../templates'

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path={APP_ROUTES.ROOT} element={<Login />} />
      <Route
        path={APP_ROUTES.UNAUTHORIZED}
        element={
          <AppLayout breadCrumb={BREAD_CRUMB.UNAUTHORIZED}>
            <UnAuthorized />
          </AppLayout>}
      />
      <Route
        path='*'
        element={
          <AppLayout breadCrumb={BREAD_CRUMB.NOTFOUND}>
            <NotFound />
          </AppLayout>}
      />
      <Route
        path={APP_ROUTES.PROFILE}
        element={
          <AppLayout breadCrumb={BREAD_CRUMB.PROFILE}>
            <Profile />
          </AppLayout>}
      />
      <Route
        path={APP_ROUTES.NOTIFICATIONS}
        element={
          <AppLayout breadCrumb={BREAD_CRUMB.NOTIFICATIONS}>
            <Notifications />
          </AppLayout>}
      />
      <Route
        path={APP_ROUTES.EVENTS}
        element={
          <AppLayout breadCrumb={BREAD_CRUMB.EVENTS}>
            <Events />
          </AppLayout>}
      />
      <Route
        path={APP_ROUTES.DASHBOARD}
        element={
          <PrivateRoute
            permissionKeys={FEATURE_PERMISSION[APP_FEATURES.VIEW_DASHBOARD]}
            redirectTo={APP_ROUTES.ROOT}
          >
            <AppLayout breadCrumb={BREAD_CRUMB.DASHBOARD}><Dashboard /></AppLayout>
          </PrivateRoute>
        }
      />
      <Route
        path={APP_ROUTES.GOALS}
        element={
          <PrivateRoute
            permissionKeys={FEATURE_PERMISSION[APP_FEATURES.VIEW_GOAL]}
            redirectTo={APP_ROUTES.ROOT}
          >
            <AppLayout breadCrumb={BREAD_CRUMB.GOAL}><GoalView /></AppLayout>
          </PrivateRoute>
        }
      />
      <Route
        path={APP_ROUTES.GOAL_CREATE}
        element={
          <PrivateRoute
            permissionKeys={FEATURE_PERMISSION[APP_FEATURES.CREATE_GOAL]}
            redirectTo={APP_ROUTES.ROOT}
          >
            <AppLayout breadCrumb={BREAD_CRUMB.GOAL_CREATE}><GoalCreate /></AppLayout>
          </PrivateRoute>
        }
      />
      <Route
        path={APP_ROUTES.GOAL_EDIT}
        element={
          <PrivateRoute
            permissionKeys={FEATURE_PERMISSION[APP_FEATURES.EDIT_ENGAGEMENT]}
            redirectTo={APP_ROUTES.ROOT}
          >
            <AppLayout breadCrumb={BREAD_CRUMB.GOAL_EDIT}><GoalCreate /></AppLayout>
          </PrivateRoute>
        }
      />
      <Route
        path={APP_ROUTES.ENGAGEMENT_CREATE}
        element={
          <PrivateRoute
            permissionKeys={FEATURE_PERMISSION[APP_FEATURES.CREATE_ENGAGEMENT]}
            redirectTo={APP_ROUTES.ROOT}
          >
            <AppLayout breadCrumb={BREAD_CRUMB.ENGAGEMENT_CREATE}><CreateEngagement /></AppLayout>
          </PrivateRoute>
        }
      />
      <Route
        path={APP_ROUTES.ENGAGEMENT_VIEW}
        element={
          <PrivateRoute
            permissionKeys={FEATURE_PERMISSION[APP_FEATURES.VIEW_ENGAGEMENT]}
            redirectTo={APP_ROUTES.ROOT}
          >
            <AppLayout breadCrumb={BREAD_CRUMB.ENGAGEMENT_VIEW}><EngagementView /></AppLayout>
          </PrivateRoute>
        }
      />
      <Route
        path={APP_ROUTES.ENGAGEMENT_VIEW_BY_TYPE}
        element={
          <PrivateRoute
            permissionKeys={FEATURE_PERMISSION[APP_FEATURES.VIEW_ENGAGEMENT]}
            redirectTo={APP_ROUTES.ROOT}
          >
            <AppLayout breadCrumb={BREAD_CRUMB.ENGAGEMENT_VIEW}><EngagementView /></AppLayout>
          </PrivateRoute>
        }
      />
      <Route
        path={APP_ROUTES.ENGAGEMENT_VIEW_BY_ID}
        element={
          <PrivateRoute
            permissionKeys={FEATURE_PERMISSION[APP_FEATURES.VIEW_ENGAGEMENT]}
            redirectTo={APP_ROUTES.ROOT}
          >
            <AppLayout breadCrumb={BREAD_CRUMB.ENGAGEMENT_VIEW_BY_ID}><EngagementViewById /></AppLayout>
          </PrivateRoute>
        }
      />
      <Route
        path={APP_ROUTES.ENGAGEMENT_VIEW_BY_SESSION}
        element={
          <PrivateRoute
            permissionKeys={FEATURE_PERMISSION[APP_FEATURES.VIEW_ENGAGEMENT]}
            redirectTo={APP_ROUTES.ROOT}
          >
            <AppLayout breadCrumb={BREAD_CRUMB.ENGAGEMENT_VIEW_BY_ID}><EngagementViewById /></AppLayout>
          </PrivateRoute>
        }
      />
      <Route
        path={APP_ROUTES.ENGAGEMENT_EDIT}
        element={
          <PrivateRoute
            permissionKeys={FEATURE_PERMISSION[APP_FEATURES.EDIT_ENGAGEMENT]}
            redirectTo={APP_ROUTES.ROOT}
          >
            <AppLayout breadCrumb={BREAD_CRUMB.ENGAGEMENT_EDIT}><CreateEngagement /></AppLayout>
          </PrivateRoute>
        }
      />
      <Route
        path={APP_ROUTES.SESSION_CREATE}
        element={
          <PrivateRoute
            permissionKeys={FEATURE_PERMISSION[APP_FEATURES.VIEW_ENGAGEMENT]}
            redirectTo={APP_ROUTES.ROOT}
          >
            <AppLayout breadCrumb={BREAD_CRUMB.SESSION_CREATE}><SessionCreate /></AppLayout>
          </PrivateRoute>
        }
      />
      <Route
        path={APP_ROUTES.SESSION_EDIT}
        element={
          <PrivateRoute
            permissionKeys={FEATURE_PERMISSION[APP_FEATURES.VIEW_ENGAGEMENT]}
            redirectTo={APP_ROUTES.ROOT}
          >
            <AppLayout breadCrumb={BREAD_CRUMB.SESSION_EDIT}><SessionCreate /></AppLayout>
          </PrivateRoute>
        }
      />
      <Route
        path={APP_ROUTES.VIEW_SESSION}
        element={
          <PrivateRoute
            permissionKeys={FEATURE_PERMISSION[APP_FEATURES.VIEW_SESSION]}
            redirectTo={APP_ROUTES.ROOT}
          >
            <AppLayout breadCrumb={BREAD_CRUMB.VIEW_SESSION}><SessionView /></AppLayout>
          </PrivateRoute>
        }
      />
      <Route
        path={APP_ROUTES.VIEW_NOTES}
        element={
          <PrivateRoute
            permissionKeys={FEATURE_PERMISSION[APP_FEATURES.VIEW_SESSION]}
            redirectTo={APP_ROUTES.ROOT}
          >
            <AppLayout breadCrumb={BREAD_CRUMB.VIEW_NOTES}><Notes /></AppLayout>
          </PrivateRoute>
        }
      />
      <Route
        path={APP_ROUTES.VIEW_ASSESSMENT}
        element={
          <PrivateRoute
            permissionKeys={FEATURE_PERMISSION[APP_FEATURES.VIEW_ASSESSMENT]}
            redirectTo={APP_ROUTES.ROOT}
          >
            <AppLayout breadCrumb={BREAD_CRUMB.VIEW_ASSESSMENT}><AssessmentView /></AppLayout>
          </PrivateRoute>
        }
      />
      <Route
        path={APP_ROUTES.VIEW_ASSESSMENT_RESULT}
        element={
          <PrivateRoute
            permissionKeys={FEATURE_PERMISSION[APP_FEATURES.VIEW_ASSESSMENT_RESULT]}
            redirectTo={APP_ROUTES.ROOT}
          >
            <AppLayout breadCrumb={BREAD_CRUMB.VIEW_ASSESSMENT_RESULT}><AssessmentResultView /></AppLayout>
          </PrivateRoute>
        }
      />
      <Route
        path={APP_ROUTES.VIEW_FEEDBACK}
        element={
          <PrivateRoute
            permissionKeys={FEATURE_PERMISSION[APP_FEATURES.VIEW_FEEDBACK]}
            redirectTo={APP_ROUTES.ROOT}
          >
            <AppLayout breadCrumb={BREAD_CRUMB.VIEW_FEEDBACK}><FeedBackView /></AppLayout>
          </PrivateRoute>
        }
      />
      <Route
        path={APP_ROUTES.GIVE_FEEDBACK}
        element={
          <PrivateRoute
            permissionKeys={FEATURE_PERMISSION[APP_FEATURES.VIEW_FEEDBACK]}
            redirectTo={APP_ROUTES.ROOT}
          >
            <AppLayout breadCrumb={BREAD_CRUMB.GIVE_FEEDBACK}><FeedBackView /></AppLayout>
          </PrivateRoute>
        }
      />
      <Route
        path={APP_ROUTES.VIEW_FEEDBACK_GROUP}
        element={
          <PrivateRoute
            permissionKeys={FEATURE_PERMISSION[APP_FEATURES.VIEW_FEEDBACK]}
            redirectTo={APP_ROUTES.ROOT}
          >
            <AppLayout breadCrumb={BREAD_CRUMB.VIEW_FEEDBACK_GROUP}><FeedBackGroupView /></AppLayout>
          </PrivateRoute>
        }
      />
       <Route
        path={APP_ROUTES.VIEW_SESSION_COACHEES_STATS}
        element={
          <PrivateRoute
            permissionKeys={FEATURE_PERMISSION[APP_FEATURES.VIEW_SESSION]}
            redirectTo={APP_ROUTES.ROOT}
          >
            <AppLayout breadCrumb={BREAD_CRUMB.VIEW_SESSION_COACHEE_STATS}><CoacheesView /></AppLayout>
          </PrivateRoute>
        }
      />
    </Route>
  )
)

const AppRoutes = () => {
  return <RouterProvider router={router}/>
}
export default AppRoutes
