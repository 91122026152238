import React from 'react'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import { Box } from '@mui/system'
import { Divider } from '@mui/material'

const Question = () => {
  return <Box display='flex' flexDirection='column' gap={1}>
    <Box><Skeleton animation="wave" variant="rounded" width='40rem' height='2rem' /></Box>
    <Box display='flex' flexDirection='row' gap={2}>
      <Box><Skeleton animation="wave" variant="circular" width='2rem' height='2rem' /></Box>
      <Box><Skeleton animation="wave" variant="circular" width='2rem' height='2rem' /></Box>
      <Box><Skeleton animation="wave" variant="circular" width='2rem' height='2rem' /></Box>
      <Box><Skeleton animation="wave" variant="circular" width='2rem' height='2rem' /></Box>
      <Box><Skeleton animation="wave" variant="circular" width='2rem' height='2rem' /></Box>
    </Box>
    <Box><Skeleton animation="wave" variant="rounded" width='100%' height='2rem' /></Box>
  </Box>
}

const FeedbackViewSkelton = () => {
  return (
    <Stack spacing={1}>
      <Question />
      <Divider sx={{ py: 1 }} />
      <Question />
      <Divider sx={{ py: 1 }} />
      <Question />
      <Divider sx={{ py: 1 }} />
      <Question />
    </Stack>
  )
}

export default FeedbackViewSkelton
