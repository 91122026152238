import { combineReducers } from 'redux'
import { type EngagementsState } from '../../../interfaces'
import createEngagementReducer from './createEngagement'
import getMyEngagementListAsCoachReducer from './getMyEngagementListAsCoach'
import getMyEngagementListAsCoacheeReducer from './getMyEngagementListAsCoachee'
import getAllEngagementListReducer from './getAllEngagementList'
import updateEngagementReducer from './updateEngagement'
import getGoalsByEngagementByIdReducer from './getGoalsByEngagementId'
import engagementDetailsReducer from './engagementDetails'
import getAllGoalsByEngagementByIdReducer from './getAllGoalsByEngagementId'

const engagementReducer = combineReducers<EngagementsState>({
  createEngagement: createEngagementReducer,
  getMyEngagementListAsCoach: getMyEngagementListAsCoachReducer,
  getMyEngagementListAsCoachee: getMyEngagementListAsCoacheeReducer,
  getAllEngagementList: getAllEngagementListReducer,
  updateEngagement: updateEngagementReducer,
  getGoalsByEngagementId: getGoalsByEngagementByIdReducer,
  getAllGoalsByEngagementId: getAllGoalsByEngagementByIdReducer,
  engagementDetails: engagementDetailsReducer
})

export default engagementReducer
