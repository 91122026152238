import { type AxiosResponse } from 'axios'
import { axiosPrivateAPI } from '.'
import {
  type GoalCreateRequestPayload,
  type GoalListResponse,
  type GoalStatusResponse,
  type StatusUpdatePayLoad,
  type GoalListData,
  type GoalUpdateRequestPayload,
  type GoalCreatAndUpdateResponse,
  type MyGoalsRequestPayload,
  type AllGoalRequestPayload,
  type GoalListSummeryRequestParams,
  type GoalListSummeryResponse
} from '../interfaces'

const createGoal = async (payload: GoalCreateRequestPayload): Promise<AxiosResponse<GoalCreatAndUpdateResponse>> => {
  return await axiosPrivateAPI.post('/api/v1/goals', payload)
}

const updateGoal = async (payload: GoalUpdateRequestPayload): Promise<AxiosResponse<GoalCreatAndUpdateResponse>> => {
  return await axiosPrivateAPI.put(`/api/v1/goals/${payload.goalId}`, payload.requestBody)
}
/*
  This service is used to fetch the data from API that giving response as All Goal List againg path param username
  @Kalana Elapatha
*/
const getAllGoalList = async (
  payload: AllGoalRequestPayload
): Promise<AxiosResponse<GoalListResponse>> => {
  return await axiosPrivateAPI.post(`/api/v1/users/${payload.pathParam.username}/goals`, payload.requestBody)
}

/*
  This service is used to fetch the data from API that giving response as MyGoal List againg assigner as body param
  @Kalana Elapatha
*/
const getGoalByUser = async (payload: MyGoalsRequestPayload): Promise<AxiosResponse<GoalListResponse>> => {
  return await axiosPrivateAPI.post('/api/v1/goalsView/detail', payload)
}

const getGoalById = async (goalId: string): Promise<AxiosResponse<GoalListData>> => {
  return await axiosPrivateAPI.get(`/api/v1/goals/${goalId}`)
}

const GoalStatusChange = async (payload: StatusUpdatePayLoad): Promise<AxiosResponse<GoalStatusResponse>> => {
  return await axiosPrivateAPI.put(`/api/v1/goals/${payload.pathParam}/action`, payload.requestBody)
}

const getGoalListSummary = async (params: GoalListSummeryRequestParams):
Promise<AxiosResponse<GoalListSummeryResponse>> => {
  return await axiosPrivateAPI.get('/api/v1/goalsView/summary', { params })
}

const goalsByEngagement = async (engagementId: string): Promise<AxiosResponse<GoalListData[]>> => {
  return await axiosPrivateAPI.get(`/api/v1/engagements/${engagementId}/goals`)
}
export const goalService = {
  GoalStatusChange,
  createGoal,
  updateGoal,
  getAllGoalList,
  getGoalByUser,
  getGoalById,
  getGoalListSummary,
  goalsByEngagement
}
