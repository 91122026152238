/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { Box, Button, Card, CardContent, Chip, Divider, Grid, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { type AppState, type GoalListData } from '../../../interfaces'
import moment from 'moment'
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined'
import { ModalSkeleton } from '../../skeleton'
import { useSelector } from 'react-redux'
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { sm: '20rem', md: '50rem', lg: '60rem' },
  bgcolor: 'background.paper',
  boxShadow: 24
}

const GoalModal: React.FC<{
  modalOpen: boolean
  handleClose: () => void
  goalDetails: GoalListData | null
}> = ({
  modalOpen,
  handleClose,
  goalDetails
}) => {
  const goalStatus = useSelector((state: AppState) => state.goals.goalById.status)
  return (
      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={style}>
          <Box sx={{ padding: '1rem' }} display='flex' justifyContent='end'>
            <IconButton onClick={handleClose} aria-label="delete" size="small">
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider variant="fullWidth" />
          <CardContent>
            {goalStatus === 'success'
              ? <>
                <Typography fontWeight={600} fontSize='1.25rem'>{goalDetails?.name}</Typography>
                <Box display='flex' sx={{ marginTop: '1rem', marginBottom: '1rem' }}>
                  <Grid container display='flex'>
                    <Grid item sm={3} md={3} display='flex'>
                      <Typography marginRight='0.5rem' color='primary'>Priority :</Typography>
                      <Chip size='small' color="primary" sx={{
                        borderRadius: '0.2rem'
                      }} label={goalDetails?.priority}
                      />
                    </Grid>
                    <Grid display='flex' item sm={5} md={3}><CalendarTodayOutlinedIcon sx={{ marginRight: '0.5rem' }}
                      fontSize='small'
                      color='primary' /><Typography
                        fontWeight={600}
                        variant='body2'>{`${moment(goalDetails?.startDate).format('DD/MM/YYYY')}
                                - ${moment(goalDetails?.endDate).format('DD/MM/YYYY')}`}</Typography></Grid>
                    <Grid item sm={3} md={5} display='flex' flexWrap='wrap'>
                      {goalDetails?.tags.map((tagDetails: any, index: number) => {
                        return <Chip key={index} size='small' color="primary" sx={{
                          borderRadius: '0.2rem', marginRight: '1rem', marginBottom: '0.5rem'
                        }} label={tagDetails?.tag}
                        />
                      })}
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <Typography
                    display='flex' justifyContent='start'
                    fontWeight={600}>Description</Typography>
                  <Box sx={{ marginTop: '1rem' }}>
                    <Typography display='flex'
                      justifyContent='start'
                      fontWeight={500}>{goalDetails?.description}</Typography>
                  </Box>
                </Box>
              </>
              : <ModalSkeleton />}

          </CardContent>
          <Divider variant="fullWidth" />
          <Box sx={{ padding: '1rem' }} display='flex' justifyContent='end' gap={2}>
            <Button
              onClick={handleClose}
              sx={{ textTransform: 'capitalize' }}
              color='secondary'
              variant="contained">Close</Button>
          </Box>

        </Card>
      </Modal>
  )
}

export default GoalModal
