import { type Action, type AsyncStateObject } from '../../../interfaces'
import { type AddFeedbackResponse } from '../../../interfaces/feedBack'
import { APP_ACTION_STATUS, COMMON_ACTIONS, FEEDBACK_ACTIONS } from '../../../utils/constants'

const INITIAL_STATE: AsyncStateObject<AddFeedbackResponse> = {
  data: { message: '' },
  isLoading: false,
  status: APP_ACTION_STATUS.INITIAL,
  error: undefined

}

const addFeedbackReducer = (state = INITIAL_STATE, action: Action<AddFeedbackResponse>) => {
  switch (action.type) {
    case FEEDBACK_ACTIONS.ADD_FEEDBACK + COMMON_ACTIONS.REQUEST:
      return {
        ...state,
        isLoading: true,
        status: APP_ACTION_STATUS.LOADING,
        error: undefined
      }
    case FEEDBACK_ACTIONS.ADD_FEEDBACK + COMMON_ACTIONS.SUCCESS:
      return {
        ...state,
        isLoading: false,
        status: APP_ACTION_STATUS.SUCCESS,
        data: action.data
      }
    case FEEDBACK_ACTIONS.ADD_FEEDBACK + COMMON_ACTIONS.ERROR:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        status: APP_ACTION_STATUS.ERROR,
        error: action.error
      }
    case FEEDBACK_ACTIONS.ADD_FEEDBACK + COMMON_ACTIONS.CLEAR:
      return INITIAL_STATE
    default:
      return state
  }
}

export default addFeedbackReducer
