import { Breadcrumbs, Typography } from '@mui/material'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { type BreadCrumb } from '../../interfaces'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import styles from './AppLayoutHeader.module.scss'
import {
  APP_ROUTES,
  BREAD_CRUB_TEXTS,
  getAssessmentRoute,
  getEngagementViewRoute,
  getViewSessionRoute
} from '../../utils/constants'

const AppLayoutHeader: React.FC<{
  breadCrumb: BreadCrumb[]
}> = ({ breadCrumb }) => {
  const navigate = useNavigate()
  const {
    engagementId,
    roleType,
    sessionId,
    user,
    assessmentId
  } =
  useParams<{
    engagementId: string
    roleType: string
    sessionId: string
    user: string
    assessmentId: string
  }>()

  const _engagementId = engagementId ?? ''
  const _roleType = roleType ?? ''
  const _sessionId = sessionId ?? ''
  const _userId = user ?? ''
  const _assessmentId = assessmentId ?? ''

  const navigateTo = (breadCrumb: BreadCrumb) => {
    switch (breadCrumb.title) {
      case BREAD_CRUB_TEXTS.GOAL:
        navigate(APP_ROUTES.GOALS)
        break
      case BREAD_CRUB_TEXTS.ENGAGEMENT:
        navigate(APP_ROUTES.ENGAGEMENT_VIEW)
        break
      case BREAD_CRUB_TEXTS.ENGAGEMENT_VIEW:
        navigate(getEngagementViewRoute(_engagementId, _roleType))
        break
      case BREAD_CRUB_TEXTS.SESSION_VIEW:
        navigate(getViewSessionRoute(_engagementId, _sessionId, _roleType), { replace: true })
        break
      case BREAD_CRUB_TEXTS.ASSESSMENT_VIEW:
        navigate(getAssessmentRoute(_engagementId, _sessionId, _roleType, _assessmentId, _userId), { replace: true })
        break
      default:
        navigate(APP_ROUTES.DASHBOARD)
        break
    }
  }
  return (
    <React.Fragment>
      <div className={styles.appHeader}>
        <div className={'layout-row'}>
          <aside>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
              {breadCrumb.length > 0 && breadCrumb.map((breadcrumb) => (
                !breadcrumb.isActive &&
                <a
                  key={breadcrumb.id}
                  onClick={() => { navigateTo(breadcrumb) }}
                  color="inherit" style={{ textDecoration: 'none', cursor: 'pointer' }}
                >
                  {breadcrumb.title}
                </a>
              ))}
              {breadCrumb.length > 0 && breadCrumb.map((breadcrumb) => (
                breadcrumb.isActive &&
                <Typography key={breadcrumb.id} color="text.primary">
                  {breadcrumb.title}
                </Typography>
              ))}
            </Breadcrumbs>
          </aside>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AppLayoutHeader
