import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react'
import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useNavigate } from 'react-router-dom'
import { type AppState } from '../interfaces'
import { APP_ACTION_STATUS, APP_CONFIGS, APP_ROUTES } from '../utils/constants'

const PrivateRoute: React.FC<{
  redirectTo: string
  children: React.ReactNode
  permissionKeys: string[]
}> = (props) => {
  const navigate = useNavigate()
  const authorizedUser = useSelector(
    (state: AppState) => state.authUser.authorizedUser
  )

  React.useEffect(() => {
    if (authorizedUser.status === APP_ACTION_STATUS.SUCCESS) {
      if (authorizedUser.data.isActive) {
        const allPermission: any = localStorage.getItem(APP_CONFIGS.USER_ENTITLEMENT)
        const lowercasedAllArray = JSON.parse(allPermission)?.map((permission: string) => permission?.toLowerCase())
        const check = (element: string) => lowercasedAllArray?.includes(element?.toLowerCase())
        const isPermissionGranted = props.permissionKeys?.some(check)
        if (!isPermissionGranted) {
          <Navigate to="/unauthorized" />
        }
      } else {
        navigate(APP_ROUTES.ROOT)
      }
    }
  }, [authorizedUser.status])

  return (
    <React.Fragment>
      <AuthenticatedTemplate>
        {props.children}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Navigate to={props.redirectTo} />
      </UnauthenticatedTemplate>
    </React.Fragment>

  )
}

export default PrivateRoute
