export const COMMON_ACTIONS = {
  REQUEST: '_REQUEST',
  SUCCESS: '_SUCCESS',
  ERROR: '_ERROR',
  CLEAR: '_CLEAR'
}

export const SIDEBAR_ACTIONS = {
  SET_ACTIVE_ITEM: 'SET_ACTIVE_ITEM'
}

export const GOAL_ACTIONS = {
  CREATE_GOAL: 'CREATE_GOAL',
  UPDATE_GOAL: 'UPDATE_GOAL',
  GOAL_STATUS_UPDATE: 'GOAL_STATUS_UPDATE',
  GET_ALL_GOAL_LIST: 'GET_ALL_GOAL_LIST',
  /*
    This bellow const is used to get My Goals by passing Assignee in body parameter
    @Kalana Elapatha
  */
  GET_GOAL_BY_USER: 'GET_GOAL_BY_USER',
  GET_GOAL_BY_ID: 'GET_GOAL_BY_ID',
  GET_GOAL_LIST_SUMMARY: 'GET_GOAL_LIST_SUMMARY',
  GET_GOALS_BY_ENGAGEMENT_ID: 'GET_GOALS_BY_ENGAGEMENT_ID'

}

export const HIERARCHY_ACTIONS = {
  GET_ORGANIZATION_HIERARCHY: 'GET_ORGANIZATION_HIERARCHY',
  GET_ROLE_HIERARCHY: 'GET_ROLE_HIERARCHY',
  GET_USER_HIERARCHY: 'GET_USER_HIERARCHY'
}
export const ENGAGEMENT_ACTIONS = {
  GET_ORGANIZATION_GOALS: 'GET_ORGANIZATION_GOALS',
  GET_ROLE_GOALS: 'GET_ROLE_GOALS',
  GET_USER_GOALS: 'GET_USER_GOALS',
  CREATE_ENGAGEMENT: ' CREATE_ENGAGEMENT',
  UPDATE_ENGAGEMENT: ' UPDATE_ENGAGEMENT',
  GET_MY_ENGAGEMENT_LIST_AS_COACH: 'GET_MY_ENGAGEMENT_LIST_AS_COACH',
  GET_MY_ENGAGEMENT_LIST_AS_COACHEE: 'GET_MY_ENGAGEMENT_LIST_AS_COACHEE',
  GET_ALL_ENGAGEMENT_LIST: 'GET_ALL_ENGAGEMENT_LIST',
  GET_GOALS_BY_ENGAGEMENT_ID: 'GET_GOALS_BY_ENGAGEMENT_ID_FOR_ENGAGEMENT',
  GET_ALL_GOALS_BY_ENGAGEMENT_ID: 'GET_ALL_GOALS_BY_ENGAGEMENT_ID',
  GET_ENGAGEMENT_DETAILS: 'GET_ENGAGEMENT_DETAILS'
}
export const PRIORITY_ACTIONS = {
  GET_PRIORITY_LIST: 'GET_PRIORITY_LIST'
}
export const COACH_LIST_ACTIONS = {
  GET_SESSION_COACH_LIST: 'GET_SESSION_COACH_LIST'
}
export const SESSION_LIST_ACTION = {
  GET_SESSION_LIST: 'GET_SESSION_LIST',
  STORE_ASSESSMENT_QUESTION: 'STORE_ASSESSMENT_QUESTION',
  STORE_FEEDBACK_QUESTION: 'STORE_FEEDBACK_QUESTION',
  CREATE_SESSION: 'CREATE_SESSION',
  STORE_ASSESSMENT_QUESTION_CLEAR: 'STORE_ASSESSMENT_QUESTION_CLEAR',
  STORE_FEEDBACK_QUESTION_CLEAR: 'STORE_FEEDBACK_QUESTION_CLEAR'

}

export const FILE_ACTIONS = {
  GET_FILE_UPLOAD_URL: 'GET_FILE_UPLOAD_URL',
  SAVE_FILE_TO_S3: ' SAVE_FILE_TO_S3',
  SAVE_FILE_TO_QUE: 'SAVE_FILE_TO_QUE',
  DOWNLOAD_FILE: 'DOWNLOAD_FILE',
  DELETE_FILE: 'DELETE_FILE',
  SESSION_DELETE: 'SESSION_DELETE'
}
export const STATUS_ACTIONS = {
  GET_GOAL_STATUS_LIST: 'GET_GOAL_STATUS_LIST',
  GET_ENGAGEMENT_STATUS_LIST: 'GET_ENGAGEMENT_STATUS_LIST',
  GET_SESSION_STATUS_LIST: 'GET_SESSION_STATUS_LIST'
}

export const ALERT_ACTIONS = {
  TRIGGER_ALERT_MESSAGE: 'TRIGGER_ALERT_MESSAGE',
  CLOSE_ALERT_MESSAGE: 'CLOSE_ALERT_MESSAGE',
  REMOVE_ALERT_MESSAGE: 'REMOVE_ALERT_MESSAGE'
}

export const SESSION_ACTIONS = {
  GET_SESSION_DETAILS_BY_ID: 'GET_SESSION_DETAILS_BY_ID',
  UPDATE_SESSION_STATUS_BY_ID: 'UPDATE_SESSION_STATUS_BY_ID',
  GET_SESSION_TYPE_LIST: 'GET_SESSION_TYPE_LIST',
  STORE_ASSESSMENT_QUESTION: 'STORE_ASSESSMENT_QUESTION',
  STORE_FEEDBACK_QUESTION: 'STORE_FEEDBACK_QUESTION',
  CREATE_SESSION: 'CREATE_SESSION',
  UPDATE_SESSION: 'UPDATE_SESSION',
  STORE_ASSESSMENT_QUESTION_CLEAR: 'STORE_ASSESSMENT_QUESTION_CLEAR',
  STORE_FEEDBACK_QUESTION_CLEAR: 'STORE_FEEDBACK_QUESTION_CLEAR',
  GET_SESSION_COACH_LIST: 'GET_SESSION_COACH_LIST',
  GET_SESSION_COACHEE_LIST: 'GET_SESSION_COACHEE_LIST',
  GET_SESSION_COACHEE_DETAILS: 'GET_SESSION_COACHEE_DETAILS',
  SESSION_DELETE: 'SESSION_DELETE'
}

export const ASSESSMENT_ACTIONS = {
  GET_ASSESSMENT_DETAILS_BY_ID: 'GET_ASSESSMENT_DETAILS_BY_ID',
  SUBMIT_ASSESSMENT: 'SUBMIT_ASSESSMENT',
  GET_ASSESSMENT_RESULT_DETAILS: 'GET_ASSESSMENT_RESULT_DETAILS'
}

export const FEEDBACK_ACTIONS = {
  GET_FEEDBACK_DETAILS: 'GET_FEEDBACK_DETAILS',
  ADD_FEEDBACK: 'ADD_FEEDBACK',
  VIEW_FEEDBACK: 'VIEW_FEEDBACK',
  VIEW_GROUP_FEEDBACK: 'VIEW_GROUP_FEEDBACK',
  ADD_COACH_FEEDBACK_TO_COACHEES: 'ADD_COACH_FEEDBACK_TO_COACHEES',
  VIEW_FEEDBACK_COMMENTS: 'VIEW_FEEDBACK_COMMENTS'
}

export const AUTH_ACTION_TYPE = {
  AUTHORIZE_USER: 'AUTHORIZE_USER',
  FETCH_AUTHORIZE_USER: 'FETCH_AUTHORIZE_USER',
  FETCH_AUTHORIZE_USER_PERMISSION: 'FETCH_AUTHORIZE_USER_PERMISSION',
  LOGOUT: 'LOGOUT'
}

export const USERS_ACTION_TYPE = {
  GET_ALL_COACHES: 'GET_ALL_COACHES'
}

export const DASHBOARD_ACTION_TYPE = {
  GET_COACHEE_STATS: 'GET_COACHEE_STATS',
  GET_COACHEE_GOAL_SUMMARY: 'GET_COACHEE_GOAL_SUMMARY',
  GET_COACHEE_SESSION_SUMMARY: 'GET_COACHEE_SESSION_SUMMARY',
  GET_RECENT_ENGAGEMENTS_AS_COACHEE: 'GET_RECENT_ENGAGEMENTS_AS_COACHEE',
  GET_COACH_STATS: 'GET_COACH_STATS',
  GET_COACH_GOAL_SUMMARY: 'GET_COACH_GOAL_SUMMARY',
  GET_COACH_SESSION_SUMMARY: 'GET_COACH_SESSION_SUMMARY',
  GET_RECENT_ENGAGEMENTS_AS_COACH: 'GET_RECENT_ENGAGEMENTS_AS_COACH',
  GET_SUPERVISOR_STATS: 'GET_SUPERVISOR_STATS'
}

export const NOTES_ACTION_TYPE = {
  ADD_NOTE: 'ADD_NOTE',
  EDIT_NOTE: 'EDIT_NOTE',
  DELETE_NOTE: 'DELETE_NOTE',
  VIEW_OWN_NOTES: 'VIEW_OWN_NOTES',
  VIEW_OTHERS_NOTES: 'VIEW_OTHERS_NOTES'
}
