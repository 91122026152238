import React, { type ReactElement } from 'react'
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt'
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied'
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral'
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied'
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied'

const SessionFeedbackSentiment: React.FC<{ rating: number }> = ({ rating }): ReactElement => {
  return (
        <>
            {rating === 1 && <SentimentVerySatisfiedIcon fontSize='large' color='primary'/>}
            {rating === 2 && <SentimentSatisfiedAltIcon fontSize='large' color='primary'/>}
            {rating === 3 && <SentimentNeutralIcon fontSize='large' color='primary'/>}
            {rating === 4 && <SentimentDissatisfiedIcon fontSize='large' color='primary'/>}
            {rating === 5 && <SentimentVeryDissatisfiedIcon fontSize='large' color='primary'/>}
        </>
  )
}

export default SessionFeedbackSentiment
