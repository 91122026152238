import React from 'react'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import { Box } from '@mui/system'
import { Card, CardContent, Grid } from '@mui/material'

const Gaols = () => {
  return <Card sx={{ padding: '1rem', marginBottom: '1rem' }}>
    <CardContent>
      <Box display='flex' flexDirection='column' gap={1}>
        <Box><Skeleton animation="wave" variant="rounded" width='100%' height='2rem' /></Box>
        <Box><Skeleton animation="wave" variant="rounded" width='100%' height='1.5rem' /></Box>
        <Box><Skeleton animation="wave" variant="rounded" width='100%' height='1.5rem' /></Box>
        <Box display='flex' flexDirection='row' gap={2}>
          <Box><Skeleton animation="wave" variant="rounded" width='4rem' height='2rem' /></Box>
          <Box><Skeleton animation="wave" variant="rounded" width='4rem' height='2rem' /></Box>
        </Box>
        <Box><Skeleton animation="wave" variant="rounded" width='100%' height='2rem' /></Box>
      </Box>
    </CardContent>
  </Card>
}
const Sessions = () => {
  return <Card sx={{ padding: '1rem', marginBottom: '1rem' }}>
    <CardContent>
      <Box display='flex' flexDirection='column' gap={1}>
        <Box display='flex' justifyContent='space-between'>
          <Box><Skeleton animation="wave" variant="rounded" width='30rem' height='2rem' /></Box>
          <Box display='flex' flexDirection='row' gap={2}>
            <Box><Skeleton animation="wave" variant="rounded" width='2rem' height='2rem' /></Box>
            <Box><Skeleton animation="wave" variant="rounded" width='2rem' height='2rem' /></Box>
          </Box>
        </Box>
        <Box display='flex' flexDirection='row' gap={2}>
          <Box><Skeleton animation="wave" variant="rounded" width='8rem' height='2rem' /></Box>
          <Box><Skeleton animation="wave" variant="rounded" width='8rem' height='2rem' /></Box>
          <Box><Skeleton animation="wave" variant="rounded" width='8rem' height='2rem' /></Box>
        </Box>
        <Box><Skeleton animation="wave" variant="rounded" width='100%' height='1.5rem' /></Box>
        <Box><Skeleton animation="wave" variant="rounded" width='100%' height='1.5rem' /></Box>
        <Box><Skeleton animation="wave" variant="rounded" width='100%' height='1.5rem' /></Box>
        <Box><Skeleton animation="wave" variant="rounded" width='100%' height='1.5rem' /></Box>
        <Box><Skeleton animation="wave" variant="rounded" width='100%' height='1.5rem' /></Box>
        <Box display='flex' justifyContent='space-between'>
          <Box><Skeleton animation="wave" variant="rounded" width='15rem' height='2rem' /></Box>
          <Box><Skeleton animation="wave" variant="rounded" width='15rem' height='2rem' /></Box>
        </Box>
      </Box>
    </CardContent>
  </Card>
}
const Engagement = () => {
  return <Box display='flex' flexDirection='column' gap={1}>
    <Box><Skeleton animation="wave" variant="rounded" width='60%' height='2rem' /></Box>
    <Box display='flex' flexDirection='row' gap={2}>
      <Box><Skeleton animation="wave" variant="rounded" width='15rem' height='1.5rem' /></Box>
      <Box><Skeleton animation="wave" variant="rounded" width='15rem' height='1.5rem' /></Box>
    </Box>
    <Box><Skeleton animation="wave" variant="rounded" width='100%' height='1rem' /></Box>
    <Box><Skeleton animation="wave" variant="rounded" width='15%' height='2rem' /></Box>
    <Box><Skeleton animation="wave" variant="rounded" width='100%' height='1.5rem' /></Box>
    <Box><Skeleton animation="wave" variant="rounded" width='100%' height='1.5rem' /></Box>
    <Box><Skeleton animation="wave" variant="rounded" width='100%' height='1.5rem' /></Box>
    <Box><Skeleton animation="wave" variant="rounded" width='100%' height='1.5rem' /></Box>
    <Box><Skeleton animation="wave" variant="rounded" width='100%' height='1.5rem' /></Box>
    <Box><Skeleton animation="wave" variant="rounded" width='100%' height='1.5rem' /></Box>
    <Box><Skeleton animation="wave" variant="rounded" width='100%' height='1.5rem' /></Box>
  </Box>
}

const ViewEngagementSkelton = () => {
  return (
    <Stack spacing={1}>
      <Grid container spacing={2}>
        <Grid item md={9}>
          <Card sx={{ overflowY: 'auto', height: '52rem' }}>
            <CardContent>
              <Engagement />
              <Box my='2rem' display='flex' justifyContent='space-between'>
                <Box><Skeleton animation="wave" variant="rounded" width='15rem' height='2rem' /></Box>
                <Box><Skeleton animation="wave" variant="rounded" width='15rem' height='2rem' /></Box>
              </Box>
              <Sessions />
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={3}>
          <Card sx={{ height: '52rem', overflowY: 'auto' }}>
            <CardContent>
              <Box sx={{ marginBottom: '1rem' }}>
                <Skeleton animation="wave" variant="rounded" width='100%' height='2rem' />
              </Box>
              <Gaols />
              <Gaols />
              <Gaols />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Stack>
  )
}

export default ViewEngagementSkelton
