import * as React from 'react'
import Box from '@mui/material/Box'
import {
  Button,
  Chip,
  InputAdornment,
  TextField,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material'

import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import { GOALS_TYPES, PERMISSION, PERMISSION_TYPES } from '../../../utils/constants'
import { hasPermission } from '../../../utils/helpers'

const GoalViewTabPanel: React.FC<{
  goalViewCard: React.ReactNode
  goalType: GOALS_TYPES
  allGoalCount: number
  myGoalCount: number
  handleChangeGoalType: (event: React.MouseEvent<HTMLElement>, newType: GOALS_TYPES) => void
  toggleDrawer: (open: boolean) => any
  searchKey: string
  clearSearchGoal: () => void
  onSearchGoal: (event: React.ChangeEvent<HTMLInputElement>) => void
}> = ({
  goalViewCard,
  goalType,
  allGoalCount,
  myGoalCount,
  handleChangeGoalType,
  toggleDrawer,
  searchKey,
  clearSearchGoal,
  onSearchGoal
}) => {
  const showMyGoal = hasPermission([PERMISSION.VIEW_GOALS_ASSIGNED_TO_HIM_SELF])
  const showAllGoal = hasPermission([PERMISSION.VIEW_GOALS_ASSIGNED_TO_HIM_SELF,
    PERMISSION.VIEW_GOALS_CREATED_BY_HIM_SELF], PERMISSION_TYPES.FULL_PERMISSION)
  return (
      <Box sx={{ width: '100%' }}>
        <Box
          sx={{
            borderColor: 'divider',
            display: 'flex',
            flexDirection: { md: 'row', xs: 'column-reverse', sm: 'column-reverse' },
            justifyContent: 'space-between'
          }}
        >
          <Box sx={{
            width: { md: '40%', sm: '100%' },
            marginTop: { md: 0, xs: '1rem' }
          }}>
            <ToggleButtonGroup
              value={goalType}
              onChange={handleChangeGoalType}
              exclusive
            >
              {showMyGoal
                ? <ToggleButton
                  sx={{ textTransform: 'none' }}
                  value={GOALS_TYPES.MY_GOALS}
                  size="small"
                >
                  My Goals <Chip size="small" color="primary" sx={{ mx: '0.5rem' }} label={myGoalCount} />
                </ToggleButton>
                : ''}
              {showAllGoal
                ? <ToggleButton
                  sx={{ textTransform: 'none' }}
                  value={GOALS_TYPES.ALL_GOALS}
                  size="small"
                >
                  All Goals <Chip size="small" sx={{ mx: '0.5rem', padding: '0rem' }} label={allGoalCount} />
                </ToggleButton>
                : ''}

            </ToggleButtonGroup>
          </Box>
          <Box sx={{
            width: { md: '40%', sm: '100%' },
            display: 'flex',
            gap: '1rem',
            justifyContent: 'space-between'
          }}
          >
            <Box sx={{ width: '80%' }}>
              <TextField
                placeholder="Search by Goal name and Tags"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {(searchKey.length > 0)
                        ? (
                          <ClearIcon onClick={clearSearchGoal} style={{ cursor: 'pointer' }} />
                          )
                        : (
                          <SearchIcon />
                          )}
                    </InputAdornment>
                  )
                }}
                size="small"
                value={searchKey}
                onChange={onSearchGoal}
                sx={{ width: '100%' }}
              />
            </Box>
            <Box>
              <Button sx={{ textTransform: 'none', height: '2.25rem' }} color="secondary" size="small"
                variant="contained" onClick={toggleDrawer(true)} endIcon={<FilterAltOutlinedIcon />}>
                Filters
              </Button>
            </Box>
          </Box>
        </Box>
        <Box marginTop="2rem">
          {goalViewCard}
        </Box>
      </Box>
  )
}

export default GoalViewTabPanel
