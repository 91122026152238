import {
  type Action, type AsyncStateObject, type AssessmentDetails
} from '../../../interfaces'
import { APP_ACTION_STATUS, ASSESSMENT_ACTIONS, COMMON_ACTIONS } from '../../../utils/constants'

const INITIAL_STATE: AsyncStateObject<AssessmentDetails> = {
  data: {
    _id: '',
    sessionId: '',
    engagamentId: '',
    isCompulsory: false,
    questions: []
  },
  isLoading: false,
  status: APP_ACTION_STATUS.INITIAL,
  error: undefined

}

const assessmentsDetailsByIdReducer = (state = INITIAL_STATE, action: Action<AssessmentDetails>) => {
  switch (action.type) {
    case ASSESSMENT_ACTIONS.GET_ASSESSMENT_DETAILS_BY_ID + COMMON_ACTIONS.REQUEST:
      return {
        ...state,
        isLoading: true,
        status: APP_ACTION_STATUS.LOADING,
        error: undefined
      }
    case ASSESSMENT_ACTIONS.GET_ASSESSMENT_DETAILS_BY_ID + COMMON_ACTIONS.SUCCESS:
      return {
        ...state,
        isLoading: false,
        status: APP_ACTION_STATUS.SUCCESS,
        data: action.data
      }
    case ASSESSMENT_ACTIONS.GET_ASSESSMENT_DETAILS_BY_ID + COMMON_ACTIONS.ERROR:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        status: APP_ACTION_STATUS.ERROR,
        error: action.error
      }
    case ASSESSMENT_ACTIONS.GET_ASSESSMENT_DETAILS_BY_ID + COMMON_ACTIONS.CLEAR:
      return INITIAL_STATE
    default:
      return state
  }
}

export default assessmentsDetailsByIdReducer
