import { type AxiosResponse } from 'axios'
import { axiosPrivateAPI } from '.'
import { type OrgAndRoleHierarchy, type GetHierarchyRequestParams, type UserHierarchy } from '../interfaces'

const getOrgAndRoleHierarchy = async (
  params: GetHierarchyRequestParams
): Promise<AxiosResponse<OrgAndRoleHierarchy>> => {
  return await axiosPrivateAPI.get('/api/v1/hierarchy', { params })
}

const getUserHierarchy = async (
  params: GetHierarchyRequestParams
): Promise<AxiosResponse<UserHierarchy[]>> => {
  return await axiosPrivateAPI.get('/api/v1/users', { params })
}

export const hierarchyService = {
  getOrgAndRoleHierarchy,
  getUserHierarchy
}
